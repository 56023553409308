import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getDownloadURL, getStorage, ref as storageRef } from 'firebase/storage'
import { Box, Button } from '@mui/material'
import UploadIcon from '@mui/icons-material/Backup'

import { getFileExtension } from '../utilities'
import { useUploadFile } from '../hooks'

interface UploadFileProps {
	uploadCallback: (storageLocation: string, downloadUrl: string) => void,
	label?: string,
	uploadedImage?: string
}

const UploadFile: React.FC<UploadFileProps> = (props) => {
	const { uploadCallback, label = 'Browse', uploadedImage } = props
	const [uploadFile, uploading, snapshot, error] = useUploadFile()
	const storage = getStorage()
	const bucketRef = storageRef(storage, 'useruploadedfiles')

	const [isDragenter, setIsDragenter] = useState<boolean>(false)
	const [buttonText, setButtonText] = useState<string>(label)
	const [totalFiles, setTotalFiles] = useState<number>()
	const [completedFiles, setCompletedFiles] = useState<number>()

	const upload = async (file: File) => {
		const originalName = file.name || 'unknown.file'
		const extension = getFileExtension(originalName)
		const fileName = `${uuidv4()}.${extension}`
		const ref = storageRef(bucketRef, fileName)
		const metadata = {
			contentType: file.type
		}
		const result = await uploadFile(ref, file, metadata)

		if (result?.ref) {
			const downloadUrl = await getDownloadURL(ref)
			uploadCallback(result.ref.fullPath, downloadUrl)
		}
	}

	const uploadFiles = async (files: Array<File>) => {
		setTotalFiles(files.length)
		setCompletedFiles(0)
		for (const file of files) {
			await upload(file)
			setCompletedFiles(completedFiles => (completedFiles || 0) + 1)
		}
		setTotalFiles(undefined)
		setCompletedFiles(undefined)
	}

	useEffect(() => {
		if (totalFiles !== undefined && completedFiles !== undefined && snapshot) {
			const percentage = Math.round(((completedFiles + (snapshot.bytesTransferred / snapshot.totalBytes)) / totalFiles) * 100)
			setButtonText(`Uploading (${percentage}%)`)
		} else {
			setButtonText(label)
		}
	}, [label, completedFiles, snapshot, totalFiles])

	const handleDragenter = () => {
		setIsDragenter(true)
	}

	const handleDragleave = () => {
		setIsDragenter(false)
	}

	const handleDrop = (e: any) => {
		setIsDragenter(false)
	}

	return (
		<Box sx={{ border: '1px solid #c4f9c4', backgroundColor: (isDragenter ? '#8ff98f' : '#d5ffd5'), borderRadius: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 2, p: 4, position: 'relative' }}>
			{!uploadedImage && (<Box sx={{ position: 'relative', width: '4.5rem', height: '4.5rem', mb: 2 }}>
				<Box sx={{ position: 'absolute', backgroundColor: 'white', width: '2rem', height: '2rem', top: '1.5rem', left: '1.25rem' }}></Box>
				<UploadIcon sx={{ fontSize: '4.5rem', color: 'green', position: 'absolute' }} />
			</Box>)}
			{uploadedImage && (
				<Box sx={{ height: '8rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Box component='img' sx={{ width: '100%', maxHeight: '8rem', maxWidth: '20rem', objectFit: 'cover' }} src={uploadedImage} />
				</Box>
			)}
			<Box>Drag and drop an image, or browse</Box>
			<Button
				variant='contained'
				{...(uploading ? { disabled: true } : [])}
				sx={{ width: '10rem', mt: 2 }}
			>
				<Box>{buttonText}</Box>
			</Button>
			<Box component='input'
				sx={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }}
				type="file"
				multiple
				onChange={(e: any) => {
					if (e.target.files) {
						const files = Array.from(e.target.files)
						uploadFiles(files as Array<File>)
					}
					e.target.value = ''
				}}
				onDragEnter={handleDragenter}
				onDragLeave={handleDragleave}
				onDrop={handleDrop}
			/>
			{error && <strong>Error: {error.message}</strong>}
		</Box>
	)
}

export default UploadFile
