import { Box } from '@mui/material'

import RoleEnum from '../enums/RoleEnum'
import RoleStyles from '../enums/RoleStyles'

const RoleChip = (props: any) => {
	const { assignedRoles, assignedAdmins } = props
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', height: '1.7rem' }}>
			{assignedRoles?.map((assignedRole: any) => (
				<Box key={assignedRole} sx={{ borderRadius: 1, fontSize: '0.8rem', border: '2px solid transparent', color: 'white', ...(RoleStyles[assignedRole as RoleEnum].sx), pt: 0.25, pb: 0.25, pl: 1, pr: 1, mr: 1 }}>
					{assignedRole}
				</Box>
			))}
			{!assignedRoles?.length && !assignedAdmins?.length && (
				<Box sx={{ borderRadius: 1, fontSize: '0.8rem', ...RoleStyles.UNASSIGNED.sx, pt: 0.25, pb: 0.25, pl: 1, pr: 1, mr: 1 }}>
					{RoleStyles.UNASSIGNED.Title}
				</Box>
			)}
		</Box>
	)
}

export default RoleChip
