import { useContext } from 'react'
import { Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import { ArrowForward, Info } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { doc, getFirestore, runTransaction } from 'firebase/firestore'
import { makeKey, useFirebaseAuth } from 'kitfit-community-common'

import OnboardingContext from '../context/onboardingContext'

const helloSchema = yup.object({
	name: yup.string().required().matches(/^[A-Za-z0-9 '\-]+$/, 'Community name must only contain valid characters') // eslint-disable-line no-useless-escape -- this is not a useless escape
}).required()

export type HelloType = yup.InferType<typeof helloSchema>

const Hello = () => {
	const user = useFirebaseAuth()
	const { onboarding, completeStep } = useContext(OnboardingContext)
	const { register, handleSubmit, setError, formState: { errors } } = useForm<HelloType>({ defaultValues: { name: onboarding?.name }, resolver: yupResolver(helloSchema) })

	if (!onboarding) {
		return null
	}

	if (!user?.uid) {
		return null
	}

	const handleHello = async (data: HelloType) => {
		let communityCreated = false
		const communityId = makeKey(data.name)
		try {
			const db = getFirestore()
			await runTransaction(db, async (transaction) => {
				const communityDocRef = doc(db, '/communities', communityId)
				const communityDocSnap = await transaction.get(communityDocRef)
				if (!communityDocSnap.exists()) {
					const prevCommunityId = onboarding.name && makeKey(onboarding.name)
					if (prevCommunityId) {
						const prevCommunityDocRef = doc(db, '/communities', prevCommunityId)
						const prevCommunityDocSnap = await transaction.get(prevCommunityDocRef)
						if (prevCommunityDocSnap.exists() && prevCommunityDocSnap.data().uid === user.uid) {
							await transaction.delete(prevCommunityDocRef)
						}
					}

					await transaction.set(communityDocRef, {
						communityId,
						name: data.name,
						uid: user.uid,
						admins: [user.uid]
					})

					communityCreated = true
				} else if (communityDocSnap.data().uid === user.uid) {
					communityCreated = true
				} else {
					setError('name', { message: 'Community name must be unique' })
				}
			})
		} catch (error) {
			setError('name', { message: 'Error creating community' })
		}

		if (communityCreated) {
			completeStep({ step: 'hello', ...data, communityId })
		}
	}

	return (
		<Box sx={{ flex: 1, boxShadow: '0px 10px 10px rgb(0 0 0 / 10%)', borderRadius: '0.5rem' }}>
			<Box sx={{ backgroundColor: '#6AA84F', color: '#FFF', height: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }}>
				<Typography variant='h1' sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>Hello.</Typography>
				<Box sx={{ mb: 1 }}>Welcome to your PriorityOne community portal</Box>
			</Box>
			<Box sx={{ backgroundColor: '#FFF', minHeight: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderBottomLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }}>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2, mt: 2 }}>
					<Box>Let's start with a name for your community </Box>
					<Tooltip title='Your community name must be unique and only contain valid characters'>
						<IconButton>
							<Info sx={{ color: '#2B78E4' }} fontSize='small' />
						</IconButton>
					</Tooltip>
				</Box>
				<Box component="form" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '66%' }} onSubmit={handleSubmit(handleHello)}>
					<TextField
						margin="normal"
						required
						fullWidth
						id="name"
						label="Community Name"
						inputProps={{
							autoComplete: 'off'
						}}
						autoFocus
						{...register('name')}
					/>
					{errors?.name?.message && <Typography component="div" sx={{ color: '#F00' }}>{errors.name.message}</Typography>}
					<Typography sx={{ color: '#E4E0E4', fontSize: '0.8rem' }}>(Don't worry, you can change this later)</Typography>
					<Button sx={{ mt: 5, mb: 1 }} type='submit' fullWidth variant='contained'>Continue <ArrowForward fontSize='small' sx={{ ml: 1 }} /></Button>
				</Box>
			</Box>
		</Box>
	)
}

export default Hello
