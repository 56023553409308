import { Box, Button, FormControl, TableCell, TableRow } from '@mui/material'
import EllipsisVerticalIcon from 'kitfit-community-common/components/icons/EllipsisVerticalIcon'
import GripVerticalIcon from 'kitfit-community-common/components/icons/GripVerticalIcon'
import { FC } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import { CustomerSegmentStageType } from '../context/AdminCommunityContext'

import FilterButton from './FilterButton'

interface CustomerSegmentStageProps {
	stage: CustomerSegmentStageType,
	stageIndex: number,
	openMenu: (el: HTMLElement) => void,
	editFilter: (val: Array<string>) => void,
	moveStage: (fromIndex: number, toIndex: number) => void,
	draggable: boolean,
	editStage: () => void
}

interface CustomerSegmentStageDnDItemType {
	stage: CustomerSegmentStageType,
	stageIndex: number
}

const CustomerSegmentStage: FC<CustomerSegmentStageProps> = ({ stage, stageIndex, openMenu, moveStage, editFilter, draggable, editStage }) => {
	const [{ isDragging }, drag, dragPreview] = useDrag<CustomerSegmentStageDnDItemType, unknown, { isDragging: boolean }>(() => ({
		type: 'stage',
		item: {
			stage,
			stageIndex
		},
		collect: monitor => ({
			isDragging: monitor.isDragging()
		})
	}))

	const [{ canDrop, isOver }, drop] = useDrop({
		accept: 'stage',
		canDrop: (item) => {
			return item.stageIndex !== stageIndex
		},
		drop: (item: CustomerSegmentStageDnDItemType) => {
			moveStage(item.stageIndex, stageIndex)
		},
		collect: monitor => ({
			canDrop: monitor.canDrop(),
			isOver: monitor.isOver({ shallow: true })
		})
	})

	const refs = (rs: Array<any>) => (ref: any) => {
		rs.forEach(r => r(ref))
	}

	return (
		<TableRow
			ref={refs([dragPreview, drop])}
			sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'cursor': 'pointer', ':hover': { backgroundColor: '#f3f3f3' }, 'opacity': isDragging ? 0.5 : 1, 'border': isOver && canDrop ? 'solid 3px #d6d6d6' : 'inherit' }}
			onClick={editStage}
		>
			{draggable ? (
				<TableCell ref={drag}><GripVerticalIcon /></TableCell>
			) : (
				<TableCell></TableCell>
			)}
			<TableCell>
				{stage.name}
			</TableCell>
			<TableCell sx={{ display: 'flex', alignItems: 'center' }}>
				<FormControl
					key={`read-${stageIndex}-${stage.name}`}
					component={FilterButton}
					margin="normal"
					fullWidth
					id="filter"
					label="Filter"
					filterAttribute={stage.filter[0]}
					filterModifier={stage.filter[1]}
					filterValue={stage.filter[2]}
					onChange={(e: any) => {
						editFilter(e.target.value)
					}}
				/>
				<Box sx={{ flex: '1', display: 'flex', justifyContent: 'flex-end' }}>
					<Button onClick={(e) => {
						e.stopPropagation()
						openMenu(e.currentTarget)
					}}><EllipsisVerticalIcon /></Button>
				</Box>
			</TableCell>
		</TableRow>
	)
}

export default CustomerSegmentStage
