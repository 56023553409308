import ReactDOMServer from 'react-dom/server'
import { NodeHtmlMarkdown } from 'node-html-markdown'

import Markdown from '../../utilities/Markdown'
import { EditorText } from '../Editors/EditorText'
import { BlockItem } from '..'

import { BlockComponentType } from './Block'

export interface ParagraphBlockItemData {
	text: string
}
export const paragraphValue: Omit<BlockItem<ParagraphBlockItemData>, 'id'> = {
	type: 'paragraph',
	data: {
		text: 'Paragraph text'
	}
}

const ParagraphBlock: BlockComponentType<ParagraphBlockItemData> = ({ block, readOnly = false, onChange }) => {
	const text = block.data.text

	const handleChange = ({ doc }: { doc: string }) => {
		if (onChange) {
			const text = NodeHtmlMarkdown.translate(doc)
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, text } }
			})
		}
	}
	return readOnly ?
		<p className='be-paragraph'>{<Markdown children={text} />}</p> :
		<EditorText
			content={ReactDOMServer.renderToStaticMarkup(<Markdown children={text} />)}
			onChange={handleChange}
		/>
}

export default ParagraphBlock
