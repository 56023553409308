import { useState } from 'react'
import { Box, Button } from '@mui/material'
import { InsertProducts } from 'kitfit-community-common'

const InsertProductsDemo = () => {
	const [showInsertProductsDialog, setShowInsertProductsDialog] = useState(false)

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			Insert Products Demo
			<Box>
				<Button variant='contained' onClick={() => setShowInsertProductsDialog(true)}>Insert Products</Button>
			</Box>
			<InsertProducts showDialog={showInsertProductsDialog} onClose={() => setShowInsertProductsDialog(false)} onInsert={(...rest) => console.log('Inserted', ...rest)} />
		</Box>
	)
}

export default InsertProductsDemo
