import { useRef, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import AddUserIcon from '@mui/icons-material/PersonOutline'

import RoleEnum from '../enums/RoleEnum'
import { UserProfileType } from '../context/AdminCommunityContext'

import UserProfileImage from './UserProfileImage'
import UserSelector from './UserSelector'

interface AssignedSelectorProps {
	assignedUsers?: Array<string>,
	assignedRoles?: Array<RoleEnum>,
	usersAvailable?: Record<string, UserProfileType>,
	handleAddUser: (user: UserProfileType) => void,
	handleRemoveUser: (user: UserProfileType) => void
}

const AssignedSelector: React.FC<AssignedSelectorProps> = (props) => {
	const { assignedUsers, assignedRoles, usersAvailable, handleAddUser, handleRemoveUser } = props
	const [showUserSelector, setShowUserSelector] = useState(false)
	const selectorRef = useRef<Element>()

	/*
		Ok, we need:

		* A way to choose the Assigned role
		* A way to choose users
	*/

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }} ref={selectorRef}>
			{assignedUsers?.map(uid => <UserProfileImage key={uid} uid={uid} />)}
			<IconButton color="primary" aria-label="upload picture" component="label" onClick={() => setShowUserSelector(!showUserSelector)}><AddUserIcon /></IconButton>
			{showUserSelector && (
				<UserSelector
					usersAvailable={usersAvailable}
					assignedUsers={assignedUsers}
					assignedRoles={assignedRoles}
					handleAddUser={handleAddUser}
					handleRemoveUser={handleRemoveUser}
					handleClose={() => setShowUserSelector(false)}
					anchorEl={selectorRef?.current}
				/>
			)}
		</Box>
	)
}

export default AssignedSelector
