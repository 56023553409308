import { Box } from '@mui/material'

const Label = (props: any) => {
	const { children, sx = [] } = props
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontSize: '0.8rem', color: '#888888', mt: 1, ...sx }}>
			{children}
		</Box>
	)
}

export default Label
