import ReactDOMServer from 'react-dom/server'
import { NodeHtmlMarkdown } from 'node-html-markdown'
import { Box, Button, Grid, TextField } from '@mui/material'
import { useNavigate } from 'react-router'

import Markdown from '../../utilities/Markdown'
import { EditorText } from '../Editors/EditorText'
import { BlockItem } from '..'

import { BlockComponentType } from './Block'

export interface PopularArticleBlockItemData {
	articles: Array<{
		articleId?: string,
		title: string,
		description: string
	}>
}
export const popularArticleValue: Omit<BlockItem<PopularArticleBlockItemData>, 'id'> = {
	type: 'populararticle',
	data: {
		articles: [
			{
				title: 'Untitled Article',
				description: 'Article description'
			}
		]
	}
}

const PopularArticleBlock: BlockComponentType<PopularArticleBlockItemData> = ({ block, readOnly = false, onChange }) => {
	const { articles = [] } = block.data

	const navigate = useNavigate()

	const articleUrl = (articleId?: string) => articleId ? `/articles/${articleId}` : ''

	const handleArticleIdChange = (index: number) => (articleId: string) => {
		if (onChange) {
			const updatedArticles = [...articles]
			updatedArticles[index] = { ...updatedArticles[index], articleId }
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, articles: updatedArticles } }
			})
		}
	}

	const handleTitleChange = (index: number) => ({ doc }: { doc: string }) => {
		if (onChange) {
			const title = NodeHtmlMarkdown.translate(doc)
			const updatedArticles = [...articles]
			updatedArticles[index] = { ...updatedArticles[index], title }
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, articles: updatedArticles } }
			})
		}
	}

	const handleDescriptionChange = (index: number) => ({ doc }: { doc: string }) => {
		if (onChange) {
			const description = NodeHtmlMarkdown.translate(doc)
			const updatedArticles = [...articles]
			updatedArticles[index] = { ...updatedArticles[index], description }
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, articles: updatedArticles } }
			})
		}
	}

	const handleArticleAdd = () => {
		if (onChange) {
			const updatedArticles = [...articles]
			updatedArticles.push(popularArticleValue.data.articles[0])
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, articles: updatedArticles } }
			})
		}
	}

	const handleArticleRemove = (index: number) => () => {
		if (onChange) {
			const updatedArticles = [...articles.filter((article, i) => i !== index)]
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, articles: updatedArticles } }
			})
		}
	}

	return readOnly ? (
		<Box className='be-populararticle' sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
			<Grid container spacing={2}>
				{articles.map((article, index) => (
					<Grid item onClick={() => navigate(articleUrl(article.articleId))} xs={4} key={`${block.id}_${index}`} sx={{ display: 'flex', cursor: 'pointer' }}>
						<Box sx={{ display: 'flex', flexDirection: 'column', borderRadius: '1rem', border: '1px solid black', p: 1 }}>
							<Box sx={{ fontWeight: 'bold' }}>
								<Markdown children={article.title} />
							</Box>
							<Markdown children={article.description} />
						</Box>
					</Grid>
				))}
			</Grid>
		</Box>
	) : (
		<Box className='be-populararticle'>
			<Button onClick={handleArticleAdd}>Add</Button>
			{articles.map((article, index) => {
				const { articleId, title, description } = article
				return (
					<Box key={`${block.id}_${index}`}>
						<TextField value={articleId || ''} onChange={e => handleArticleIdChange(index)(e.target.value)}/>
						<EditorText
							tagName={'strong'}
							content={ReactDOMServer.renderToStaticMarkup(<Markdown children={title} />)}
							onChange={handleTitleChange(index)}
						/>
						<EditorText
							content={ReactDOMServer.renderToStaticMarkup(<Markdown children={description} />)}
							onChange={handleDescriptionChange(index)}
						/>
						<Button onClick={handleArticleRemove(index)}>Remove</Button>
					</Box>
				)
			})}
		</Box>
	)
}

export default PopularArticleBlock
