import RoleEnum from './RoleEnum'

const RoleStyles = {
	[RoleEnum.OPS]: {
		Title: 'Ops',
		sx: { backgroundColor: '#085394' }
	},
	[RoleEnum.SUPPORT]: {
		Title: 'Support',
		sx: { backgroundColor: '#009E0F' }
	},
	[RoleEnum.PRODUCT]: {
		Title: 'Product',
		sx: { backgroundColor: 'salmon' }
	},
	UNASSIGNED: {
		Title: 'Unassigned',
		sx: { backgroundColor: 'white', color: 'black', border: '2px solid black' }
	}
}

export default RoleStyles
