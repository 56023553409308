import { Button, Tooltip } from '@mui/material'

const EditorButton = (props: any) => {
	const { command, disabled, selected, onClick, title, description, icon, onCommand } = props
	return (
		<Tooltip title={title}>
			<Button
				sx={{
					userSelect: 'none',
					minWidth: '2rem',
					...(description ? [] : { width: '2rem' }),
					paddingLeft: '0.15rem!important',
					paddingRight: '0.15rem!important',
					marginRight: '0.15rem!important',
					...(!selected ? { color: 'white' } : [])
				}}
				disabled={disabled}
				size="small"
				type="button"
				onClick={onClick || ((e) => {
					e.preventDefault() // Avoids losing focus from the editable area
					const result = global.document.execCommand(command, false)
					if (onCommand) {
						onCommand({ result, command })
					}
				})}
			>{icon && icon}{description && description}
			</Button>
		</Tooltip>
	)
}

export default EditorButton
