import { Box, SxProps } from '@mui/material'

import { useAdminCommunityContext } from '../context/AdminCommunityContext'

export enum ProfileImageSize {
	'SMALL' = 'SMALL',
	'MEDIUM' = 'MEDIUM',
	'LARGE' = 'LARGE'
}

interface UserProfileImageProps {
	uid: string,
	name?: string,
	size?: string,
	sx?: SxProps
}

const UserProfileImage: React.FC<UserProfileImageProps> = (props) => {
	const { uid, name, size = ProfileImageSize.SMALL, sx } = props
	const { community, admins } = useAdminCommunityContext()

	if (!(community && uid)) {
		return null
	}

	const profileImage = `https://${community.imageHostname}/${community.communityId}/userProfileImages/${uid}.jpg`
	const displayName = (admins?.[uid] ? admins[uid].name : name) || uid
	let width = size === ProfileImageSize.SMALL ? '2rem' : '8rem'
	if (size === ProfileImageSize.MEDIUM) {
		width = '4rem'
	}

	return (
		<Box component='img' src={profileImage} alt={displayName} title={displayName} sx={{ width, borderRadius: '100%', ...sx }} />
	)
}

export default UserProfileImage
