enum TicketStatusEnum {
	'INPROGRESS' = 'INPROGRESS',
	'COMPLETE' = 'COMPLETE',
	'ONHOLD' = 'ONHOLD',
	'REJECTED' = 'REJECTED'
}

export const compareStatus = (a: TicketStatusEnum, b: TicketStatusEnum): number => {
	const order = [TicketStatusEnum.INPROGRESS, TicketStatusEnum.ONHOLD, TicketStatusEnum.COMPLETE, TicketStatusEnum.REJECTED]
	return order.indexOf(a) - order.indexOf(b)
}

export default TicketStatusEnum
