import { Box, Button } from '@mui/material'
import Slider from 'react-slick'

import '../../external/slick-theme.css'
import '../../external/slick.css'

import { ProductIndexType } from './types'

interface DisplayProductsGalleryProps {
	products: Array<ProductIndexType>,
	displayNumber?: number,
	displayPrices?: boolean
}

const DisplayProductsGallery: React.FC<DisplayProductsGalleryProps> = ({ products, displayNumber, displayPrices = true }) => {
	return (
		<Box>
			Gallery
			<Slider
				dots={true}
				infinite={true}
				speed={500}
				slidesToShow={1}
				slidesToScroll={1}
			>
				{!!products && products.slice(0, displayNumber && displayNumber > 0 ? displayNumber : undefined).map((product, i) => (
					<Box key={i}>
						<Box component='img' src={product.leadImage} sx={{ maxWidth: '10rem', maxHeight: '10rem' }} />
						<Box sx={{ fontWeight: 'bold' }}>{product.name}</Box>
						<Box sx={{ display: 'flex' }}>
							{displayPrices && (
								<>
									<Box>{product.currency}</Box>
									<Box>{product.price}</Box>
								</>
							)}
							<Box sx={{ flex: 1 }}>&nbsp;</Box>
							<Button variant='contained' target='_blank' rel='noopener noreferrer' href={product.buyUrl}>Buy Now</Button>
						</Box>
					</Box>
				))}
			</Slider>
		</Box>
	)
}

export default DisplayProductsGallery
