import { BlockItem, BlockType } from '..'

import HeaderBlock, { HeaderBlockItemData, headerValue } from './HeaderBlock'
import ParagraphBlock, { ParagraphBlockItemData, paragraphValue } from './ParagraphBlock'
import ListBlock, { ListBlockItemData, listValue } from './ListBlock'
import ImageBlock, { ImageBlockItemData, imageValue } from './ImageBlock'
import TopHeaderBlock, { TopHeaderBlockItemData, topHeaderValue } from './TopHeaderBlock'
import SearchBlock, { searchValue } from './SearchBlock'
import PinnedArticleBlock, { PinnedArticleBlockItemData, pinnedArticleValue } from './PinnedArticleBlock'
import PopularArticleBlock, { PopularArticleBlockItemData, popularArticleValue } from './PopularArticleBlock'
import PopularQuestionBlock, { PopularQuestionBlockItemData, popularQuestionValue } from './PopularQuestionBlock'
import ProductBlock, { ProductBlockItemData, productValue } from './ProductBlock'
import { BlockComponentType } from './Block'

type BlockItemTypeForBlockType<T> =
T extends 'header' ? HeaderBlockItemData :
	T extends 'image' ? ImageBlockItemData :
		T extends 'list' ? ListBlockItemData :
			T extends 'paragraph' ? ParagraphBlockItemData :
				T extends 'pinnedarticle' ? PinnedArticleBlockItemData :
					T extends 'populararticle' ? PopularArticleBlockItemData :
						T extends 'popularquestion' ? PopularQuestionBlockItemData :
							T extends 'product' ? ProductBlockItemData :
								T extends 'search' ? unknown :
									T extends 'topheader' ? TopHeaderBlockItemData :
										unknown

//	TODO: we should have LI and OL and probably DL for lists here
//	TODO: ability to define and load custom blocks
//	TODO: Add a table block, I hear good things about `https://www.npmjs.com/package/markdown-table` or `remark-gfm` for rendering, and for input, make something that works like: https://github.com/yuanworks/react-md-table-generator (they do not export an npm package, so we have to DIY) - this one has multimd-table support: https://github.com/aapzu/react-md-table-generator (https://www.npmjs.com/package/markdown-it-multimd-table)

export const BlockValues: { [T in BlockType]: Omit<BlockItem<BlockItemTypeForBlockType<T>>, 'id'> } = {
	'header': headerValue,
	'image': imageValue,
	'list': listValue,
	'paragraph': paragraphValue,
	'pinnedarticle': pinnedArticleValue,
	'populararticle': popularArticleValue,
	'popularquestion': popularQuestionValue,
	'product': productValue,
	'search': searchValue,
	'topheader': topHeaderValue
}

const Blocks: { [T in BlockType]: BlockComponentType<BlockItemTypeForBlockType<T>> } = {
	'header': HeaderBlock,
	'image': ImageBlock,
	'list': ListBlock,
	'paragraph': ParagraphBlock,
	'pinnedarticle': PinnedArticleBlock,
	'populararticle': PopularArticleBlock,
	'popularquestion': PopularQuestionBlock,
	'product': ProductBlock,
	'search': SearchBlock,
	'topheader': TopHeaderBlock
}

export default Blocks
