import { Route, Routes } from 'react-router-dom'

import Home from './Home'
import Login from './Login'
import EditorDemoBlock from './EditorDemoBlock'
import EditorDemoChat from './EditorDemoChat'
import Tickets from './Tickets'
import Ticket from './Ticket'
import Questions from './Questions'
import EmptyPage from './EmptyPage'
import ResetPassword from './ResetPassword'
import NotFound from './NotFound'
import PrivateRoute from './components/PrivateRoute'
import User from './User'
import Onboarding from './Onboarding'
import Layout from './layouts/Layout'
import InsertProductsDemo from './InsertProductsDemo'
import InsertFilterDemo from './InsertFilterDemo'
import Articles from './Articles'
import Article from './Article'
import Settings from './Settings'
import Segments from './Segments'

const BaseRoutes = () => {
	return (
		<Routes>
			<Route index element={<PrivateRoute><Layout><Home /></Layout></PrivateRoute>} />
			<Route path="login" element={<Login />} />
			<Route path="editordemoblock" element={<EditorDemoBlock />} />
			<Route path="editordemochat" element={<EditorDemoChat />} />
			<Route path="insertproductsdemo" element={<InsertProductsDemo />} />
			<Route path="insertfilterdemo" element={<InsertFilterDemo />} />
			<Route path="resetpassword" element={<ResetPassword />} />
			<Route path="onboarding/*" element={<PrivateRoute><Onboarding /></PrivateRoute>} />
			<Route path="home" element={<PrivateRoute><Layout><Home /></Layout></PrivateRoute>} />
			<Route path="tickets" element={<PrivateRoute><Layout><Tickets /></Layout></PrivateRoute>} />
			<Route path="ticket/:ticketId" element={<PrivateRoute><Layout><Ticket /></Layout></PrivateRoute>} />
			<Route path="questions" element={<PrivateRoute><Layout><Questions /></Layout></PrivateRoute>} />
			<Route path="articles" element={<PrivateRoute><Layout><Articles /></Layout></PrivateRoute>} />
			<Route path="article/:articleId" element={<PrivateRoute><Layout><Article /></Layout></PrivateRoute>} />
			<Route path="settings/segments" element={<PrivateRoute><Layout><Segments /></Layout></PrivateRoute>} />
			<Route path="settings" element={<PrivateRoute><Layout><Settings /></Layout></PrivateRoute>} />
			<Route path="emptypage" element={<PrivateRoute><Layout><EmptyPage /></Layout></PrivateRoute>} />
			<Route path="user" element={<PrivateRoute><Layout><User /></Layout></PrivateRoute>} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}

export default BaseRoutes
