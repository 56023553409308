import { FormControl, Select, MenuItem, SxProps } from '@mui/material'
import { QuestionStatusEnum } from 'kitfit-community-common'

import QuestionStatusStyles from '../enums/QuestionStatusStyles'

interface QuestionStatusSelectorProps {
	value: QuestionStatusEnum | QuestionStatusEnum[],
	setValue: (value: QuestionStatusEnum | QuestionStatusEnum[]) => void,
	multiple?: boolean,
	sx?: SxProps,
	inputSx?: SxProps
}

const QuestionStatusSelector: React.FC<QuestionStatusSelectorProps> = (props) => {
	const { value, setValue, multiple, sx = {}, inputSx = {} } = props
	return (
		<FormControl sx={{ minWidth: 120, ...sx }} size='small'>
			<Select
				value={value}
				{...(multiple ? { multiple: true } : [])}
				onChange={e => setValue(e.target.value as QuestionStatusEnum)}
				inputProps={{ sx: { display: 'flex', background: '#fff', ...inputSx } }}
			>
				{Object.keys(QuestionStatusEnum).map((key) => {
					const StatusStyle = QuestionStatusStyles[key as QuestionStatusEnum]
					return <MenuItem key={key} value={key} sx={{ display: 'flex' }}><StatusStyle.Icon sx={{ mr: 1, color: StatusStyle.Colour }} />{StatusStyle.Title}</MenuItem>
				})}
			</Select>
		</FormControl >
	)
}

export default QuestionStatusSelector
