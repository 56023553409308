import { useEffect, useContext, useRef } from 'react'
import { Button } from '@mui/material'
import { getAuth, signOut } from 'firebase/auth'
import { TranslatifyContext } from 'react-translatify-next'
import { useFirebaseAuth } from 'kitfit-community-common'

import './User.css'

import HeaderContext from './context/headerContext'

const handleLogout = async () => {
	const auth = getAuth()
	await signOut(auth)
}

const User = () => {
	const user = useFirebaseAuth()
	const { translate } = useContext(TranslatifyContext)

	const headerContext = useRef(useContext(HeaderContext))
	useEffect(() => {
		headerContext.current.setHeader('User')
	}, [])

	return (
		<div>
			<div className="User-uid">UID: {user.uid}</div>
			<div>{user.email}</div>
			<Button variant="outlined" onClick={handleLogout}>
				{translate('Logout')}
			</Button>
		</div>
	)
}

export default User
