/*
	ref: https://unicode.org/emoji/charts/full-emoji-list.html

	```JavaScript
	var output = {}
	var category = ''
	var subCategory = ''
	document.querySelector('table').querySelectorAll('tr').forEach((tr, index) => {
		if(tr.querySelector('th')) {
			if(tr.querySelector('th').classList.contains('bighead')) {
				category = tr.querySelector('th').innerText.split(' ').join('-').split('&').join('and').toLowerCase()
			}
			else if(tr.querySelector('th').classList.contains('mediumhead')) {
				subCategory = tr.querySelector('th').innerText
			}
		}
		else if(tr.querySelectorAll('td').length > 13) {
			const tds = Array.from(tr.querySelectorAll('td'))
			let description = tds[14].innerText.trim()
			description = description.charAt(0).toUpperCase() + description.substring(1).toLowerCase()
			output[tds[2].innerText] = {
				character: tds[2].innerText,
				unicode: tds[1].innerText,
				category,
				subCategory,
				description
			}
		}
	})
	console.log(JSON.stringify(output, null, 4))
	```

	TODO: Add https://unicode.org/emoji/charts/full-emoji-modifiers.html and ability to (optionally) set skin colour.

	Ref: popularity: https://www.brandwatch.com/blog/the-most-popular-emojis/
*/

//	Checks if Browser supports the emoji
//	WARNING: This might be a bit intesive - we need to test all browsers, and then only show the subset they all support
//	Note: There are fonts, such as https://fonts.google.com/noto/specimen/Noto+Color+Emoji, however it DOES NOT work in Safari, and you need to use the correct version depending on OS, etc, so for now we are just tyring to use emojis that work everywehere.
const checkEmojiSupport = (text: string) => {
	const arrayLikeToString = (arg: any) => Array.prototype.toString.call(arg)

	const getTextFeature = (text: string, color: string) => {
		try {
			const canvas = document.createElement('canvas')
			canvas.width = 1
			canvas.height = 1

			const ctx = canvas.getContext('2d')
			if ( ctx ) {
				ctx.textBaseline = 'top'
				ctx.font = '100px -no-font-family-here-'
				ctx.fillStyle = color
				ctx.scale(0.01, 0.01)
				ctx.fillText(text, 0, 0)
				return ctx.getImageData(0, 0, 1, 1).data
			}
			return false
		} catch (e) {
			return false
		}
	}

	const compareFeatures = (feature1: any, feature2: any) => {
		const feature1Str = arrayLikeToString(feature1)
		const feature2Str = arrayLikeToString(feature2)
		return feature1Str === feature2Str && feature1Str !== '0,0,0,0'
	}

	const feature1 = getTextFeature(text, '#000')
	const feature2 = getTextFeature(text, '#fff')
	return feature1 && feature2 && compareFeatures(feature1, feature2)
}

let emojis: { [key: string]: any } = {
	'😀': {
		'character': '😀',
		'unicode': 'U+1F600',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-smiling',
		'description': 'Grinning face'
	},
	'😃': {
		'character': '😃',
		'unicode': 'U+1F603',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-smiling',
		'description': 'Grinning face with big eyes'
	},
	'😄': {
		'character': '😄',
		'unicode': 'U+1F604',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-smiling',
		'description': 'Grinning face with smiling eyes'
	},
	'😁': {
		'character': '😁',
		'unicode': 'U+1F601',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-smiling',
		'description': 'Beaming face with smiling eyes'
	},
	'😆': {
		'character': '😆',
		'unicode': 'U+1F606',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-smiling',
		'description': 'Grinning squinting face'
	},
	'😅': {
		'character': '😅',
		'unicode': 'U+1F605',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-smiling',
		'description': 'Grinning face with sweat'
	},
	'🤣': {
		'character': '🤣',
		'unicode': 'U+1F923',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-smiling',
		'description': 'Rolling on the floor laughing'
	},
	'😂': {
		'character': '😂',
		'unicode': 'U+1F602',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-smiling',
		'description': 'Face with tears of joy'
	},
	'🙂': {
		'character': '🙂',
		'unicode': 'U+1F642',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-smiling',
		'description': 'Slightly smiling face'
	},
	'🙃': {
		'character': '🙃',
		'unicode': 'U+1F643',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-smiling',
		'description': 'Upside-down face'
	},
	'🫠': {
		'character': '🫠',
		'unicode': 'U+1FAE0',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-smiling',
		'description': 'Melting face'
	},
	'😉': {
		'character': '😉',
		'unicode': 'U+1F609',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-smiling',
		'description': 'Winking face'
	},
	'😊': {
		'character': '😊',
		'unicode': 'U+1F60A',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-smiling',
		'description': 'Smiling face with smiling eyes'
	},
	'😇': {
		'character': '😇',
		'unicode': 'U+1F607',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-smiling',
		'description': 'Smiling face with halo'
	},
	'🥰': {
		'character': '🥰',
		'unicode': 'U+1F970',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-affection',
		'description': 'Smiling face with hearts'
	},
	'😍': {
		'character': '😍',
		'unicode': 'U+1F60D',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-affection',
		'description': 'Smiling face with heart-eyes'
	},
	'🤩': {
		'character': '🤩',
		'unicode': 'U+1F929',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-affection',
		'description': 'Star-struck'
	},
	'😘': {
		'character': '😘',
		'unicode': 'U+1F618',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-affection',
		'description': 'Face blowing a kiss'
	},
	'😗': {
		'character': '😗',
		'unicode': 'U+1F617',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-affection',
		'description': 'Kissing face'
	},
	'☺': {
		'character': '☺',
		'unicode': 'U+263A',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-affection',
		'description': 'Smiling face'
	},
	'😚': {
		'character': '😚',
		'unicode': 'U+1F61A',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-affection',
		'description': 'Kissing face with closed eyes'
	},
	'😙': {
		'character': '😙',
		'unicode': 'U+1F619',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-affection',
		'description': 'Kissing face with smiling eyes'
	},
	'🥲': {
		'character': '🥲',
		'unicode': 'U+1F972',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-affection',
		'description': 'Smiling face with tear'
	},
	'😋': {
		'character': '😋',
		'unicode': 'U+1F60B',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-tongue',
		'description': 'Face savoring food'
	},
	'😛': {
		'character': '😛',
		'unicode': 'U+1F61B',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-tongue',
		'description': 'Face with tongue'
	},
	'😜': {
		'character': '😜',
		'unicode': 'U+1F61C',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-tongue',
		'description': 'Winking face with tongue'
	},
	'🤪': {
		'character': '🤪',
		'unicode': 'U+1F92A',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-tongue',
		'description': 'Zany face'
	},
	'😝': {
		'character': '😝',
		'unicode': 'U+1F61D',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-tongue',
		'description': 'Squinting face with tongue'
	},
	'🤑': {
		'character': '🤑',
		'unicode': 'U+1F911',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-tongue',
		'description': 'Money-mouth face'
	},
	'🤗': {
		'character': '🤗',
		'unicode': 'U+1F917',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-hand',
		'description': 'Smiling face with open hands'
	},
	'🤭': {
		'character': '🤭',
		'unicode': 'U+1F92D',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-hand',
		'description': 'Face with hand over mouth'
	},
	'🫢': {
		'character': '🫢',
		'unicode': 'U+1FAE2',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-hand',
		'description': 'Face with open eyes and hand over mouth'
	},
	'🫣': {
		'character': '🫣',
		'unicode': 'U+1FAE3',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-hand',
		'description': 'Face with peeking eye'
	},
	'🤫': {
		'character': '🤫',
		'unicode': 'U+1F92B',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-hand',
		'description': 'Shushing face'
	},
	'🤔': {
		'character': '🤔',
		'unicode': 'U+1F914',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-hand',
		'description': 'Thinking face'
	},
	'🫡': {
		'character': '🫡',
		'unicode': 'U+1FAE1',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-hand',
		'description': 'Saluting face'
	},
	'🤐': {
		'character': '🤐',
		'unicode': 'U+1F910',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-neutral-skeptical',
		'description': 'Zipper-mouth face'
	},
	'🤨': {
		'character': '🤨',
		'unicode': 'U+1F928',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-neutral-skeptical',
		'description': 'Face with raised eyebrow'
	},
	'😐': {
		'character': '😐',
		'unicode': 'U+1F610',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-neutral-skeptical',
		'description': 'Neutral face'
	},
	'😑': {
		'character': '😑',
		'unicode': 'U+1F611',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-neutral-skeptical',
		'description': 'Expressionless face'
	},
	'😶': {
		'character': '😶',
		'unicode': 'U+1F636',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-neutral-skeptical',
		'description': 'Face without mouth'
	},
	'🫥': {
		'character': '🫥',
		'unicode': 'U+1FAE5',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-neutral-skeptical',
		'description': 'Dotted line face'
	},
	'😶‍🌫️': {
		'character': '😶‍🌫️',
		'unicode': 'U+1F636 U+200D U+1F32B U+FE0F',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-neutral-skeptical',
		'description': 'Face in clouds'
	},
	'😏': {
		'character': '😏',
		'unicode': 'U+1F60F',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-neutral-skeptical',
		'description': 'Smirking face'
	},
	'😒': {
		'character': '😒',
		'unicode': 'U+1F612',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-neutral-skeptical',
		'description': 'Unamused face'
	},
	'🙄': {
		'character': '🙄',
		'unicode': 'U+1F644',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-neutral-skeptical',
		'description': 'Face with rolling eyes'
	},
	'😬': {
		'character': '😬',
		'unicode': 'U+1F62C',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-neutral-skeptical',
		'description': 'Grimacing face'
	},
	'😮‍💨': {
		'character': '😮‍💨',
		'unicode': 'U+1F62E U+200D U+1F4A8',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-neutral-skeptical',
		'description': 'Face exhaling'
	},
	'🤥': {
		'character': '🤥',
		'unicode': 'U+1F925',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-neutral-skeptical',
		'description': 'Lying face'
	},
	'😌': {
		'character': '😌',
		'unicode': 'U+1F60C',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-sleepy',
		'description': 'Relieved face'
	},
	'😔': {
		'character': '😔',
		'unicode': 'U+1F614',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-sleepy',
		'description': 'Pensive face'
	},
	'😪': {
		'character': '😪',
		'unicode': 'U+1F62A',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-sleepy',
		'description': 'Sleepy face'
	},
	'🤤': {
		'character': '🤤',
		'unicode': 'U+1F924',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-sleepy',
		'description': 'Drooling face'
	},
	'😴': {
		'character': '😴',
		'unicode': 'U+1F634',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-sleepy',
		'description': 'Sleeping face'
	},
	'😷': {
		'character': '😷',
		'unicode': 'U+1F637',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-unwell',
		'description': 'Face with medical mask'
	},
	'🤒': {
		'character': '🤒',
		'unicode': 'U+1F912',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-unwell',
		'description': 'Face with thermometer'
	},
	'🤕': {
		'character': '🤕',
		'unicode': 'U+1F915',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-unwell',
		'description': 'Face with head-bandage'
	},
	'🤢': {
		'character': '🤢',
		'unicode': 'U+1F922',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-unwell',
		'description': 'Nauseated face'
	},
	'🤮': {
		'character': '🤮',
		'unicode': 'U+1F92E',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-unwell',
		'description': 'Face vomiting'
	},
	'🤧': {
		'character': '🤧',
		'unicode': 'U+1F927',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-unwell',
		'description': 'Sneezing face'
	},
	'🥵': {
		'character': '🥵',
		'unicode': 'U+1F975',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-unwell',
		'description': 'Hot face'
	},
	'🥶': {
		'character': '🥶',
		'unicode': 'U+1F976',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-unwell',
		'description': 'Cold face'
	},
	'🥴': {
		'character': '🥴',
		'unicode': 'U+1F974',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-unwell',
		'description': 'Woozy face'
	},
	'😵': {
		'character': '😵',
		'unicode': 'U+1F635',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-unwell',
		'description': 'Face with crossed-out eyes'
	},
	'😵‍💫': {
		'character': '😵‍💫',
		'unicode': 'U+1F635 U+200D U+1F4AB',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-unwell',
		'description': 'Face with spiral eyes'
	},
	'🤯': {
		'character': '🤯',
		'unicode': 'U+1F92F',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-unwell',
		'description': 'Exploding head'
	},
	'🤠': {
		'character': '🤠',
		'unicode': 'U+1F920',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-hat',
		'description': 'Cowboy hat face'
	},
	'🥳': {
		'character': '🥳',
		'unicode': 'U+1F973',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-hat',
		'description': 'Partying face'
	},
	'🥸': {
		'character': '🥸',
		'unicode': 'U+1F978',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-hat',
		'description': 'Disguised face'
	},
	'😎': {
		'character': '😎',
		'unicode': 'U+1F60E',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-glasses',
		'description': 'Smiling face with sunglasses'
	},
	'🤓': {
		'character': '🤓',
		'unicode': 'U+1F913',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-glasses',
		'description': 'Nerd face'
	},
	'🧐': {
		'character': '🧐',
		'unicode': 'U+1F9D0',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-glasses',
		'description': 'Face with monocle'
	},
	'😕': {
		'character': '😕',
		'unicode': 'U+1F615',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Confused face'
	},
	'🫤': {
		'character': '🫤',
		'unicode': 'U+1FAE4',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Face with diagonal mouth'
	},
	'😟': {
		'character': '😟',
		'unicode': 'U+1F61F',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Worried face'
	},
	'🙁': {
		'character': '🙁',
		'unicode': 'U+1F641',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Slightly frowning face'
	},
	'☹': {
		'character': '☹',
		'unicode': 'U+2639',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Frowning face'
	},
	'😮': {
		'character': '😮',
		'unicode': 'U+1F62E',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Face with open mouth'
	},
	'😯': {
		'character': '😯',
		'unicode': 'U+1F62F',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Hushed face'
	},
	'😲': {
		'character': '😲',
		'unicode': 'U+1F632',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Astonished face'
	},
	'😳': {
		'character': '😳',
		'unicode': 'U+1F633',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Flushed face'
	},
	'🥺': {
		'character': '🥺',
		'unicode': 'U+1F97A',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Pleading face'
	},
	'🥹': {
		'character': '🥹',
		'unicode': 'U+1F979',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Face holding back tears'
	},
	'😦': {
		'character': '😦',
		'unicode': 'U+1F626',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Frowning face with open mouth'
	},
	'😧': {
		'character': '😧',
		'unicode': 'U+1F627',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Anguished face'
	},
	'😨': {
		'character': '😨',
		'unicode': 'U+1F628',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Fearful face'
	},
	'😰': {
		'character': '😰',
		'unicode': 'U+1F630',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Anxious face with sweat'
	},
	'😥': {
		'character': '😥',
		'unicode': 'U+1F625',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Sad but relieved face'
	},
	'😢': {
		'character': '😢',
		'unicode': 'U+1F622',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Crying face'
	},
	'😭': {
		'character': '😭',
		'unicode': 'U+1F62D',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Loudly crying face'
	},
	'😱': {
		'character': '😱',
		'unicode': 'U+1F631',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Face screaming in fear'
	},
	'😖': {
		'character': '😖',
		'unicode': 'U+1F616',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Confounded face'
	},
	'😣': {
		'character': '😣',
		'unicode': 'U+1F623',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Persevering face'
	},
	'😞': {
		'character': '😞',
		'unicode': 'U+1F61E',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Disappointed face'
	},
	'😓': {
		'character': '😓',
		'unicode': 'U+1F613',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Downcast face with sweat'
	},
	'😩': {
		'character': '😩',
		'unicode': 'U+1F629',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Weary face'
	},
	'😫': {
		'character': '😫',
		'unicode': 'U+1F62B',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Tired face'
	},
	'🥱': {
		'character': '🥱',
		'unicode': 'U+1F971',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-concerned',
		'description': 'Yawning face'
	},
	'😤': {
		'character': '😤',
		'unicode': 'U+1F624',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-negative',
		'description': 'Face with steam from nose'
	},
	'😡': {
		'character': '😡',
		'unicode': 'U+1F621',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-negative',
		'description': 'Enraged face'
	},
	'😠': {
		'character': '😠',
		'unicode': 'U+1F620',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-negative',
		'description': 'Angry face'
	},
	'🤬': {
		'character': '🤬',
		'unicode': 'U+1F92C',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-negative',
		'description': 'Face with symbols on mouth'
	},
	'😈': {
		'character': '😈',
		'unicode': 'U+1F608',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-negative',
		'description': 'Smiling face with horns'
	},
	'👿': {
		'character': '👿',
		'unicode': 'U+1F47F',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-negative',
		'description': 'Angry face with horns'
	},
	'💀': {
		'character': '💀',
		'unicode': 'U+1F480',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-negative',
		'description': 'Skull'
	},
	'☠': {
		'character': '☠',
		'unicode': 'U+2620',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-negative',
		'description': 'Skull and crossbones'
	},
	'💩': {
		'character': '💩',
		'unicode': 'U+1F4A9',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-costume',
		'description': 'Pile of poo'
	},
	'🤡': {
		'character': '🤡',
		'unicode': 'U+1F921',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-costume',
		'description': 'Clown face'
	},
	'👹': {
		'character': '👹',
		'unicode': 'U+1F479',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-costume',
		'description': 'Ogre'
	},
	'👺': {
		'character': '👺',
		'unicode': 'U+1F47A',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-costume',
		'description': 'Goblin'
	},
	'👻': {
		'character': '👻',
		'unicode': 'U+1F47B',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-costume',
		'description': 'Ghost'
	},
	'👽': {
		'character': '👽',
		'unicode': 'U+1F47D',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-costume',
		'description': 'Alien'
	},
	'👾': {
		'character': '👾',
		'unicode': 'U+1F47E',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-costume',
		'description': 'Alien monster'
	},
	'🤖': {
		'character': '🤖',
		'unicode': 'U+1F916',
		'category': 'smileys-and-emotion',
		'subCategory': 'face-costume',
		'description': 'Robot'
	},
	'😺': {
		'character': '😺',
		'unicode': 'U+1F63A',
		'category': 'smileys-and-emotion',
		'subCategory': 'cat-face',
		'description': 'Grinning cat'
	},
	'😸': {
		'character': '😸',
		'unicode': 'U+1F638',
		'category': 'smileys-and-emotion',
		'subCategory': 'cat-face',
		'description': 'Grinning cat with smiling eyes'
	},
	'😹': {
		'character': '😹',
		'unicode': 'U+1F639',
		'category': 'smileys-and-emotion',
		'subCategory': 'cat-face',
		'description': 'Cat with tears of joy'
	},
	'😻': {
		'character': '😻',
		'unicode': 'U+1F63B',
		'category': 'smileys-and-emotion',
		'subCategory': 'cat-face',
		'description': 'Smiling cat with heart-eyes'
	},
	'😼': {
		'character': '😼',
		'unicode': 'U+1F63C',
		'category': 'smileys-and-emotion',
		'subCategory': 'cat-face',
		'description': 'Cat with wry smile'
	},
	'😽': {
		'character': '😽',
		'unicode': 'U+1F63D',
		'category': 'smileys-and-emotion',
		'subCategory': 'cat-face',
		'description': 'Kissing cat'
	},
	'🙀': {
		'character': '🙀',
		'unicode': 'U+1F640',
		'category': 'smileys-and-emotion',
		'subCategory': 'cat-face',
		'description': 'Weary cat'
	},
	'😿': {
		'character': '😿',
		'unicode': 'U+1F63F',
		'category': 'smileys-and-emotion',
		'subCategory': 'cat-face',
		'description': 'Crying cat'
	},
	'😾': {
		'character': '😾',
		'unicode': 'U+1F63E',
		'category': 'smileys-and-emotion',
		'subCategory': 'cat-face',
		'description': 'Pouting cat'
	},
	'🙈': {
		'character': '🙈',
		'unicode': 'U+1F648',
		'category': 'smileys-and-emotion',
		'subCategory': 'monkey-face',
		'description': 'See-no-evil monkey'
	},
	'🙉': {
		'character': '🙉',
		'unicode': 'U+1F649',
		'category': 'smileys-and-emotion',
		'subCategory': 'monkey-face',
		'description': 'Hear-no-evil monkey'
	},
	'🙊': {
		'character': '🙊',
		'unicode': 'U+1F64A',
		'category': 'smileys-and-emotion',
		'subCategory': 'monkey-face',
		'description': 'Speak-no-evil monkey'
	},
	'💌': {
		'character': '💌',
		'unicode': 'U+1F48C',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Love letter'
	},
	'💘': {
		'character': '💘',
		'unicode': 'U+1F498',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Heart with arrow'
	},
	'💝': {
		'character': '💝',
		'unicode': 'U+1F49D',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Heart with ribbon'
	},
	'💖': {
		'character': '💖',
		'unicode': 'U+1F496',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Sparkling heart'
	},
	'💗': {
		'character': '💗',
		'unicode': 'U+1F497',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Growing heart'
	},
	'💓': {
		'character': '💓',
		'unicode': 'U+1F493',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Beating heart'
	},
	'💞': {
		'character': '💞',
		'unicode': 'U+1F49E',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Revolving hearts'
	},
	'💕': {
		'character': '💕',
		'unicode': 'U+1F495',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Two hearts'
	},
	'💟': {
		'character': '💟',
		'unicode': 'U+1F49F',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Heart decoration'
	},
	'❣': {
		'character': '❣',
		'unicode': 'U+2763',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Heart exclamation'
	},
	'💔': {
		'character': '💔',
		'unicode': 'U+1F494',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Broken heart'
	},
	'❤️‍🔥': {
		'character': '❤️‍🔥',
		'unicode': 'U+2764 U+FE0F U+200D U+1F525',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Heart on fire'
	},
	'❤️‍🩹': {
		'character': '❤️‍🩹',
		'unicode': 'U+2764 U+FE0F U+200D U+1FA79',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Mending heart'
	},
	'❤': {
		'character': '❤',
		'unicode': 'U+2764',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Red heart'
	},
	'🧡': {
		'character': '🧡',
		'unicode': 'U+1F9E1',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Orange heart'
	},
	'💛': {
		'character': '💛',
		'unicode': 'U+1F49B',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Yellow heart'
	},
	'💚': {
		'character': '💚',
		'unicode': 'U+1F49A',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Green heart'
	},
	'💙': {
		'character': '💙',
		'unicode': 'U+1F499',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Blue heart'
	},
	'💜': {
		'character': '💜',
		'unicode': 'U+1F49C',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Purple heart'
	},
	'🤎': {
		'character': '🤎',
		'unicode': 'U+1F90E',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Brown heart'
	},
	'🖤': {
		'character': '🖤',
		'unicode': 'U+1F5A4',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'Black heart'
	},
	'🤍': {
		'character': '🤍',
		'unicode': 'U+1F90D',
		'category': 'smileys-and-emotion',
		'subCategory': 'heart',
		'description': 'White heart'
	},
	'💋': {
		'character': '💋',
		'unicode': 'U+1F48B',
		'category': 'smileys-and-emotion',
		'subCategory': 'emotion',
		'description': 'Kiss mark'
	},
	'💯': {
		'character': '💯',
		'unicode': 'U+1F4AF',
		'category': 'smileys-and-emotion',
		'subCategory': 'emotion',
		'description': 'Hundred points'
	},
	'💢': {
		'character': '💢',
		'unicode': 'U+1F4A2',
		'category': 'smileys-and-emotion',
		'subCategory': 'emotion',
		'description': 'Anger symbol'
	},
	'💥': {
		'character': '💥',
		'unicode': 'U+1F4A5',
		'category': 'smileys-and-emotion',
		'subCategory': 'emotion',
		'description': 'Collision'
	},
	'💫': {
		'character': '💫',
		'unicode': 'U+1F4AB',
		'category': 'smileys-and-emotion',
		'subCategory': 'emotion',
		'description': 'Dizzy'
	},
	'💦': {
		'character': '💦',
		'unicode': 'U+1F4A6',
		'category': 'smileys-and-emotion',
		'subCategory': 'emotion',
		'description': 'Sweat droplets'
	},
	'💨': {
		'character': '💨',
		'unicode': 'U+1F4A8',
		'category': 'smileys-and-emotion',
		'subCategory': 'emotion',
		'description': 'Dashing away'
	},
	'🕳': {
		'character': '🕳',
		'unicode': 'U+1F573',
		'category': 'smileys-and-emotion',
		'subCategory': 'emotion',
		'description': 'Hole'
	},
	'💬': {
		'character': '💬',
		'unicode': 'U+1F4AC',
		'category': 'smileys-and-emotion',
		'subCategory': 'emotion',
		'description': 'Speech balloon'
	},
	'👁️‍🗨️': {
		'character': '👁️‍🗨️',
		'unicode': 'U+1F441 U+FE0F U+200D U+1F5E8 U+FE0F',
		'category': 'smileys-and-emotion',
		'subCategory': 'emotion',
		'description': 'Eye in speech bubble'
	},
	'🗨': {
		'character': '🗨',
		'unicode': 'U+1F5E8',
		'category': 'smileys-and-emotion',
		'subCategory': 'emotion',
		'description': 'Left speech bubble'
	},
	'🗯': {
		'character': '🗯',
		'unicode': 'U+1F5EF',
		'category': 'smileys-and-emotion',
		'subCategory': 'emotion',
		'description': 'Right anger bubble'
	},
	'💭': {
		'character': '💭',
		'unicode': 'U+1F4AD',
		'category': 'smileys-and-emotion',
		'subCategory': 'emotion',
		'description': 'Thought balloon'
	},
	'💤': {
		'character': '💤',
		'unicode': 'U+1F4A4',
		'category': 'smileys-and-emotion',
		'subCategory': 'emotion',
		'description': 'Zzz'
	},
	'👋': {
		'character': '👋',
		'unicode': 'U+1F44B',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-open',
		'description': 'Waving hand'
	},
	'🤚': {
		'character': '🤚',
		'unicode': 'U+1F91A',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-open',
		'description': 'Raised back of hand'
	},
	'🖐': {
		'character': '🖐',
		'unicode': 'U+1F590',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-open',
		'description': 'Hand with fingers splayed'
	},
	'✋': {
		'character': '✋',
		'unicode': 'U+270B',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-open',
		'description': 'Raised hand'
	},
	'🖖': {
		'character': '🖖',
		'unicode': 'U+1F596',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-open',
		'description': 'Vulcan salute'
	},
	'🫱': {
		'character': '🫱',
		'unicode': 'U+1FAF1',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-open',
		'description': 'Rightwards hand'
	},
	'🫲': {
		'character': '🫲',
		'unicode': 'U+1FAF2',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-open',
		'description': 'Leftwards hand'
	},
	'🫳': {
		'character': '🫳',
		'unicode': 'U+1FAF3',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-open',
		'description': 'Palm down hand'
	},
	'🫴': {
		'character': '🫴',
		'unicode': 'U+1FAF4',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-open',
		'description': 'Palm up hand'
	},
	'👌': {
		'character': '👌',
		'unicode': 'U+1F44C',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-partial',
		'description': 'Ok hand'
	},
	'🤌': {
		'character': '🤌',
		'unicode': 'U+1F90C',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-partial',
		'description': 'Pinched fingers'
	},
	'🤏': {
		'character': '🤏',
		'unicode': 'U+1F90F',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-partial',
		'description': 'Pinching hand'
	},
	'✌': {
		'character': '✌',
		'unicode': 'U+270C',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-partial',
		'description': 'Victory hand'
	},
	'🤞': {
		'character': '🤞',
		'unicode': 'U+1F91E',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-partial',
		'description': 'Crossed fingers'
	},
	'🫰': {
		'character': '🫰',
		'unicode': 'U+1FAF0',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-partial',
		'description': 'Hand with index finger and thumb crossed'
	},
	'🤟': {
		'character': '🤟',
		'unicode': 'U+1F91F',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-partial',
		'description': 'Love-you gesture'
	},
	'🤘': {
		'character': '🤘',
		'unicode': 'U+1F918',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-partial',
		'description': 'Sign of the horns'
	},
	'🤙': {
		'character': '🤙',
		'unicode': 'U+1F919',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-partial',
		'description': 'Call me hand'
	},
	'👈': {
		'character': '👈',
		'unicode': 'U+1F448',
		'category': 'people-and-body',
		'subCategory': 'hand-single-finger',
		'description': 'Backhand index pointing left'
	},
	'👉': {
		'character': '👉',
		'unicode': 'U+1F449',
		'category': 'people-and-body',
		'subCategory': 'hand-single-finger',
		'description': 'Backhand index pointing right'
	},
	'👆': {
		'character': '👆',
		'unicode': 'U+1F446',
		'category': 'people-and-body',
		'subCategory': 'hand-single-finger',
		'description': 'Backhand index pointing up'
	},
	'🖕': {
		'character': '🖕',
		'unicode': 'U+1F595',
		'category': 'people-and-body',
		'subCategory': 'hand-single-finger',
		'description': 'Middle finger'
	},
	'👇': {
		'character': '👇',
		'unicode': 'U+1F447',
		'category': 'people-and-body',
		'subCategory': 'hand-single-finger',
		'description': 'Backhand index pointing down'
	},
	'☝': {
		'character': '☝',
		'unicode': 'U+261D',
		'category': 'people-and-body',
		'subCategory': 'hand-single-finger',
		'description': 'Index pointing up'
	},
	'🫵': {
		'character': '🫵',
		'unicode': 'U+1FAF5',
		'category': 'people-and-body',
		'subCategory': 'hand-single-finger',
		'description': 'Index pointing at the viewer'
	},
	'👍': {
		'character': '👍',
		'unicode': 'U+1F44D',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-closed',
		'description': 'Thumbs up'
	},
	'👎': {
		'character': '👎',
		'unicode': 'U+1F44E',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-closed',
		'description': 'Thumbs down'
	},
	'✊': {
		'character': '✊',
		'unicode': 'U+270A',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-closed',
		'description': 'Raised fist'
	},
	'👊': {
		'character': '👊',
		'unicode': 'U+1F44A',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-closed',
		'description': 'Oncoming fist'
	},
	'🤛': {
		'character': '🤛',
		'unicode': 'U+1F91B',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-closed',
		'description': 'Left-facing fist'
	},
	'🤜': {
		'character': '🤜',
		'unicode': 'U+1F91C',
		'category': 'people-and-body',
		'subCategory': 'hand-fingers-closed',
		'description': 'Right-facing fist'
	},
	'👏': {
		'character': '👏',
		'unicode': 'U+1F44F',
		'category': 'people-and-body',
		'subCategory': 'hands',
		'description': 'Clapping hands'
	},
	'🙌': {
		'character': '🙌',
		'unicode': 'U+1F64C',
		'category': 'people-and-body',
		'subCategory': 'hands',
		'description': 'Raising hands'
	},
	'🫶': {
		'character': '🫶',
		'unicode': 'U+1FAF6',
		'category': 'people-and-body',
		'subCategory': 'hands',
		'description': 'Heart hands'
	},
	'👐': {
		'character': '👐',
		'unicode': 'U+1F450',
		'category': 'people-and-body',
		'subCategory': 'hands',
		'description': 'Open hands'
	},
	'🤲': {
		'character': '🤲',
		'unicode': 'U+1F932',
		'category': 'people-and-body',
		'subCategory': 'hands',
		'description': 'Palms up together'
	},
	'🤝': {
		'character': '🤝',
		'unicode': 'U+1F91D',
		'category': 'people-and-body',
		'subCategory': 'hands',
		'description': 'Handshake'
	},
	'🙏': {
		'character': '🙏',
		'unicode': 'U+1F64F',
		'category': 'people-and-body',
		'subCategory': 'hands',
		'description': 'Folded hands'
	},
	'✍': {
		'character': '✍',
		'unicode': 'U+270D',
		'category': 'people-and-body',
		'subCategory': 'hand-prop',
		'description': 'Writing hand'
	},
	'💅': {
		'character': '💅',
		'unicode': 'U+1F485',
		'category': 'people-and-body',
		'subCategory': 'hand-prop',
		'description': 'Nail polish'
	},
	'🤳': {
		'character': '🤳',
		'unicode': 'U+1F933',
		'category': 'people-and-body',
		'subCategory': 'hand-prop',
		'description': 'Selfie'
	},
	'💪': {
		'character': '💪',
		'unicode': 'U+1F4AA',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Flexed biceps'
	},
	'🦾': {
		'character': '🦾',
		'unicode': 'U+1F9BE',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Mechanical arm'
	},
	'🦿': {
		'character': '🦿',
		'unicode': 'U+1F9BF',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Mechanical leg'
	},
	'🦵': {
		'character': '🦵',
		'unicode': 'U+1F9B5',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Leg'
	},
	'🦶': {
		'character': '🦶',
		'unicode': 'U+1F9B6',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Foot'
	},
	'👂': {
		'character': '👂',
		'unicode': 'U+1F442',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Ear'
	},
	'🦻': {
		'character': '🦻',
		'unicode': 'U+1F9BB',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Ear with hearing aid'
	},
	'👃': {
		'character': '👃',
		'unicode': 'U+1F443',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Nose'
	},
	'🧠': {
		'character': '🧠',
		'unicode': 'U+1F9E0',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Brain'
	},
	'🫀': {
		'character': '🫀',
		'unicode': 'U+1FAC0',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Anatomical heart'
	},
	'🫁': {
		'character': '🫁',
		'unicode': 'U+1FAC1',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Lungs'
	},
	'🦷': {
		'character': '🦷',
		'unicode': 'U+1F9B7',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Tooth'
	},
	'🦴': {
		'character': '🦴',
		'unicode': 'U+1F9B4',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Bone'
	},
	'👀': {
		'character': '👀',
		'unicode': 'U+1F440',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Eyes'
	},
	'👁': {
		'character': '👁',
		'unicode': 'U+1F441',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Eye'
	},
	'👅': {
		'character': '👅',
		'unicode': 'U+1F445',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Tongue'
	},
	'👄': {
		'character': '👄',
		'unicode': 'U+1F444',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Mouth'
	},
	'🫦': {
		'character': '🫦',
		'unicode': 'U+1FAE6',
		'category': 'people-and-body',
		'subCategory': 'body-parts',
		'description': 'Biting lip'
	},
	'👶': {
		'character': '👶',
		'unicode': 'U+1F476',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Baby'
	},
	'🧒': {
		'character': '🧒',
		'unicode': 'U+1F9D2',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Child'
	},
	'👦': {
		'character': '👦',
		'unicode': 'U+1F466',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Boy'
	},
	'👧': {
		'character': '👧',
		'unicode': 'U+1F467',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Girl'
	},
	'🧑': {
		'character': '🧑',
		'unicode': 'U+1F9D1',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Person'
	},
	'👱': {
		'character': '👱',
		'unicode': 'U+1F471',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Person: blond hair'
	},
	'👨': {
		'character': '👨',
		'unicode': 'U+1F468',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Man'
	},
	'🧔': {
		'character': '🧔',
		'unicode': 'U+1F9D4',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Person: beard'
	},
	'🧔‍♂️': {
		'character': '🧔‍♂️',
		'unicode': 'U+1F9D4 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Man: beard'
	},
	'🧔‍♀️': {
		'character': '🧔‍♀️',
		'unicode': 'U+1F9D4 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Woman: beard'
	},
	'👨‍🦰': {
		'character': '👨‍🦰',
		'unicode': 'U+1F468 U+200D U+1F9B0',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Man: red hair'
	},
	'👨‍🦱': {
		'character': '👨‍🦱',
		'unicode': 'U+1F468 U+200D U+1F9B1',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Man: curly hair'
	},
	'👨‍🦳': {
		'character': '👨‍🦳',
		'unicode': 'U+1F468 U+200D U+1F9B3',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Man: white hair'
	},
	'👨‍🦲': {
		'character': '👨‍🦲',
		'unicode': 'U+1F468 U+200D U+1F9B2',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Man: bald'
	},
	'👩': {
		'character': '👩',
		'unicode': 'U+1F469',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Woman'
	},
	'👩‍🦰': {
		'character': '👩‍🦰',
		'unicode': 'U+1F469 U+200D U+1F9B0',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Woman: red hair'
	},
	'🧑‍🦰': {
		'character': '🧑‍🦰',
		'unicode': 'U+1F9D1 U+200D U+1F9B0',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Person: red hair'
	},
	'👩‍🦱': {
		'character': '👩‍🦱',
		'unicode': 'U+1F469 U+200D U+1F9B1',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Woman: curly hair'
	},
	'🧑‍🦱': {
		'character': '🧑‍🦱',
		'unicode': 'U+1F9D1 U+200D U+1F9B1',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Person: curly hair'
	},
	'👩‍🦳': {
		'character': '👩‍🦳',
		'unicode': 'U+1F469 U+200D U+1F9B3',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Woman: white hair'
	},
	'🧑‍🦳': {
		'character': '🧑‍🦳',
		'unicode': 'U+1F9D1 U+200D U+1F9B3',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Person: white hair'
	},
	'👩‍🦲': {
		'character': '👩‍🦲',
		'unicode': 'U+1F469 U+200D U+1F9B2',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Woman: bald'
	},
	'🧑‍🦲': {
		'character': '🧑‍🦲',
		'unicode': 'U+1F9D1 U+200D U+1F9B2',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Person: bald'
	},
	'👱‍♀️': {
		'character': '👱‍♀️',
		'unicode': 'U+1F471 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Woman: blond hair'
	},
	'👱‍♂️': {
		'character': '👱‍♂️',
		'unicode': 'U+1F471 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Man: blond hair'
	},
	'🧓': {
		'character': '🧓',
		'unicode': 'U+1F9D3',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Older person'
	},
	'👴': {
		'character': '👴',
		'unicode': 'U+1F474',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Old man'
	},
	'👵': {
		'character': '👵',
		'unicode': 'U+1F475',
		'category': 'people-and-body',
		'subCategory': 'person',
		'description': 'Old woman'
	},
	'🙍': {
		'character': '🙍',
		'unicode': 'U+1F64D',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Person frowning'
	},
	'🙍‍♂️': {
		'character': '🙍‍♂️',
		'unicode': 'U+1F64D U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Man frowning'
	},
	'🙍‍♀️': {
		'character': '🙍‍♀️',
		'unicode': 'U+1F64D U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Woman frowning'
	},
	'🙎': {
		'character': '🙎',
		'unicode': 'U+1F64E',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Person pouting'
	},
	'🙎‍♂️': {
		'character': '🙎‍♂️',
		'unicode': 'U+1F64E U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Man pouting'
	},
	'🙎‍♀️': {
		'character': '🙎‍♀️',
		'unicode': 'U+1F64E U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Woman pouting'
	},
	'🙅': {
		'character': '🙅',
		'unicode': 'U+1F645',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Person gesturing no'
	},
	'🙅‍♂️': {
		'character': '🙅‍♂️',
		'unicode': 'U+1F645 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Man gesturing no'
	},
	'🙅‍♀️': {
		'character': '🙅‍♀️',
		'unicode': 'U+1F645 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Woman gesturing no'
	},
	'🙆': {
		'character': '🙆',
		'unicode': 'U+1F646',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Person gesturing ok'
	},
	'🙆‍♂️': {
		'character': '🙆‍♂️',
		'unicode': 'U+1F646 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Man gesturing ok'
	},
	'🙆‍♀️': {
		'character': '🙆‍♀️',
		'unicode': 'U+1F646 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Woman gesturing ok'
	},
	'💁': {
		'character': '💁',
		'unicode': 'U+1F481',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Person tipping hand'
	},
	'💁‍♂️': {
		'character': '💁‍♂️',
		'unicode': 'U+1F481 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Man tipping hand'
	},
	'💁‍♀️': {
		'character': '💁‍♀️',
		'unicode': 'U+1F481 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Woman tipping hand'
	},
	'🙋': {
		'character': '🙋',
		'unicode': 'U+1F64B',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Person raising hand'
	},
	'🙋‍♂️': {
		'character': '🙋‍♂️',
		'unicode': 'U+1F64B U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Man raising hand'
	},
	'🙋‍♀️': {
		'character': '🙋‍♀️',
		'unicode': 'U+1F64B U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Woman raising hand'
	},
	'🧏': {
		'character': '🧏',
		'unicode': 'U+1F9CF',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Deaf person'
	},
	'🧏‍♂️': {
		'character': '🧏‍♂️',
		'unicode': 'U+1F9CF U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Deaf man'
	},
	'🧏‍♀️': {
		'character': '🧏‍♀️',
		'unicode': 'U+1F9CF U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Deaf woman'
	},
	'🙇': {
		'character': '🙇',
		'unicode': 'U+1F647',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Person bowing'
	},
	'🙇‍♂️': {
		'character': '🙇‍♂️',
		'unicode': 'U+1F647 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Man bowing'
	},
	'🙇‍♀️': {
		'character': '🙇‍♀️',
		'unicode': 'U+1F647 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Woman bowing'
	},
	'🤦': {
		'character': '🤦',
		'unicode': 'U+1F926',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Person facepalming'
	},
	'🤦‍♂️': {
		'character': '🤦‍♂️',
		'unicode': 'U+1F926 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Man facepalming'
	},
	'🤦‍♀️': {
		'character': '🤦‍♀️',
		'unicode': 'U+1F926 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Woman facepalming'
	},
	'🤷': {
		'character': '🤷',
		'unicode': 'U+1F937',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Person shrugging'
	},
	'🤷‍♂️': {
		'character': '🤷‍♂️',
		'unicode': 'U+1F937 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Man shrugging'
	},
	'🤷‍♀️': {
		'character': '🤷‍♀️',
		'unicode': 'U+1F937 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-gesture',
		'description': 'Woman shrugging'
	},
	'🧑‍⚕️': {
		'character': '🧑‍⚕️',
		'unicode': 'U+1F9D1 U+200D U+2695 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Health worker'
	},
	'👨‍⚕️': {
		'character': '👨‍⚕️',
		'unicode': 'U+1F468 U+200D U+2695 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man health worker'
	},
	'👩‍⚕️': {
		'character': '👩‍⚕️',
		'unicode': 'U+1F469 U+200D U+2695 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman health worker'
	},
	'🧑‍🎓': {
		'character': '🧑‍🎓',
		'unicode': 'U+1F9D1 U+200D U+1F393',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Student'
	},
	'👨‍🎓': {
		'character': '👨‍🎓',
		'unicode': 'U+1F468 U+200D U+1F393',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man student'
	},
	'👩‍🎓': {
		'character': '👩‍🎓',
		'unicode': 'U+1F469 U+200D U+1F393',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman student'
	},
	'🧑‍🏫': {
		'character': '🧑‍🏫',
		'unicode': 'U+1F9D1 U+200D U+1F3EB',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Teacher'
	},
	'👨‍🏫': {
		'character': '👨‍🏫',
		'unicode': 'U+1F468 U+200D U+1F3EB',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man teacher'
	},
	'👩‍🏫': {
		'character': '👩‍🏫',
		'unicode': 'U+1F469 U+200D U+1F3EB',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman teacher'
	},
	'🧑‍⚖️': {
		'character': '🧑‍⚖️',
		'unicode': 'U+1F9D1 U+200D U+2696 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Judge'
	},
	'👨‍⚖️': {
		'character': '👨‍⚖️',
		'unicode': 'U+1F468 U+200D U+2696 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man judge'
	},
	'👩‍⚖️': {
		'character': '👩‍⚖️',
		'unicode': 'U+1F469 U+200D U+2696 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman judge'
	},
	'🧑‍🌾': {
		'character': '🧑‍🌾',
		'unicode': 'U+1F9D1 U+200D U+1F33E',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Farmer'
	},
	'👨‍🌾': {
		'character': '👨‍🌾',
		'unicode': 'U+1F468 U+200D U+1F33E',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man farmer'
	},
	'👩‍🌾': {
		'character': '👩‍🌾',
		'unicode': 'U+1F469 U+200D U+1F33E',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman farmer'
	},
	'🧑‍🍳': {
		'character': '🧑‍🍳',
		'unicode': 'U+1F9D1 U+200D U+1F373',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Cook'
	},
	'👨‍🍳': {
		'character': '👨‍🍳',
		'unicode': 'U+1F468 U+200D U+1F373',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man cook'
	},
	'👩‍🍳': {
		'character': '👩‍🍳',
		'unicode': 'U+1F469 U+200D U+1F373',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman cook'
	},
	'🧑‍🔧': {
		'character': '🧑‍🔧',
		'unicode': 'U+1F9D1 U+200D U+1F527',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Mechanic'
	},
	'👨‍🔧': {
		'character': '👨‍🔧',
		'unicode': 'U+1F468 U+200D U+1F527',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man mechanic'
	},
	'👩‍🔧': {
		'character': '👩‍🔧',
		'unicode': 'U+1F469 U+200D U+1F527',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman mechanic'
	},
	'🧑‍🏭': {
		'character': '🧑‍🏭',
		'unicode': 'U+1F9D1 U+200D U+1F3ED',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Factory worker'
	},
	'👨‍🏭': {
		'character': '👨‍🏭',
		'unicode': 'U+1F468 U+200D U+1F3ED',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man factory worker'
	},
	'👩‍🏭': {
		'character': '👩‍🏭',
		'unicode': 'U+1F469 U+200D U+1F3ED',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman factory worker'
	},
	'🧑‍💼': {
		'character': '🧑‍💼',
		'unicode': 'U+1F9D1 U+200D U+1F4BC',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Office worker'
	},
	'👨‍💼': {
		'character': '👨‍💼',
		'unicode': 'U+1F468 U+200D U+1F4BC',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man office worker'
	},
	'👩‍💼': {
		'character': '👩‍💼',
		'unicode': 'U+1F469 U+200D U+1F4BC',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman office worker'
	},
	'🧑‍🔬': {
		'character': '🧑‍🔬',
		'unicode': 'U+1F9D1 U+200D U+1F52C',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Scientist'
	},
	'👨‍🔬': {
		'character': '👨‍🔬',
		'unicode': 'U+1F468 U+200D U+1F52C',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man scientist'
	},
	'👩‍🔬': {
		'character': '👩‍🔬',
		'unicode': 'U+1F469 U+200D U+1F52C',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman scientist'
	},
	'🧑‍💻': {
		'character': '🧑‍💻',
		'unicode': 'U+1F9D1 U+200D U+1F4BB',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Technologist'
	},
	'👨‍💻': {
		'character': '👨‍💻',
		'unicode': 'U+1F468 U+200D U+1F4BB',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man technologist'
	},
	'👩‍💻': {
		'character': '👩‍💻',
		'unicode': 'U+1F469 U+200D U+1F4BB',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman technologist'
	},
	'🧑‍🎤': {
		'character': '🧑‍🎤',
		'unicode': 'U+1F9D1 U+200D U+1F3A4',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Singer'
	},
	'👨‍🎤': {
		'character': '👨‍🎤',
		'unicode': 'U+1F468 U+200D U+1F3A4',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man singer'
	},
	'👩‍🎤': {
		'character': '👩‍🎤',
		'unicode': 'U+1F469 U+200D U+1F3A4',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman singer'
	},
	'🧑‍🎨': {
		'character': '🧑‍🎨',
		'unicode': 'U+1F9D1 U+200D U+1F3A8',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Artist'
	},
	'👨‍🎨': {
		'character': '👨‍🎨',
		'unicode': 'U+1F468 U+200D U+1F3A8',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man artist'
	},
	'👩‍🎨': {
		'character': '👩‍🎨',
		'unicode': 'U+1F469 U+200D U+1F3A8',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman artist'
	},
	'🧑‍✈️': {
		'character': '🧑‍✈️',
		'unicode': 'U+1F9D1 U+200D U+2708 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Pilot'
	},
	'👨‍✈️': {
		'character': '👨‍✈️',
		'unicode': 'U+1F468 U+200D U+2708 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man pilot'
	},
	'👩‍✈️': {
		'character': '👩‍✈️',
		'unicode': 'U+1F469 U+200D U+2708 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman pilot'
	},
	'🧑‍🚀': {
		'character': '🧑‍🚀',
		'unicode': 'U+1F9D1 U+200D U+1F680',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Astronaut'
	},
	'👨‍🚀': {
		'character': '👨‍🚀',
		'unicode': 'U+1F468 U+200D U+1F680',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man astronaut'
	},
	'👩‍🚀': {
		'character': '👩‍🚀',
		'unicode': 'U+1F469 U+200D U+1F680',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman astronaut'
	},
	'🧑‍🚒': {
		'character': '🧑‍🚒',
		'unicode': 'U+1F9D1 U+200D U+1F692',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Firefighter'
	},
	'👨‍🚒': {
		'character': '👨‍🚒',
		'unicode': 'U+1F468 U+200D U+1F692',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man firefighter'
	},
	'👩‍🚒': {
		'character': '👩‍🚒',
		'unicode': 'U+1F469 U+200D U+1F692',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman firefighter'
	},
	'👮': {
		'character': '👮',
		'unicode': 'U+1F46E',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Police officer'
	},
	'👮‍♂️': {
		'character': '👮‍♂️',
		'unicode': 'U+1F46E U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man police officer'
	},
	'👮‍♀️': {
		'character': '👮‍♀️',
		'unicode': 'U+1F46E U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman police officer'
	},
	'🕵': {
		'character': '🕵',
		'unicode': 'U+1F575',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Detective'
	},
	'🕵️‍♂️': {
		'character': '🕵️‍♂️',
		'unicode': 'U+1F575 U+FE0F U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man detective'
	},
	'🕵️‍♀️': {
		'character': '🕵️‍♀️',
		'unicode': 'U+1F575 U+FE0F U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman detective'
	},
	'💂': {
		'character': '💂',
		'unicode': 'U+1F482',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Guard'
	},
	'💂‍♂️': {
		'character': '💂‍♂️',
		'unicode': 'U+1F482 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man guard'
	},
	'💂‍♀️': {
		'character': '💂‍♀️',
		'unicode': 'U+1F482 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman guard'
	},
	'🥷': {
		'character': '🥷',
		'unicode': 'U+1F977',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Ninja'
	},
	'👷': {
		'character': '👷',
		'unicode': 'U+1F477',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Construction worker'
	},
	'👷‍♂️': {
		'character': '👷‍♂️',
		'unicode': 'U+1F477 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man construction worker'
	},
	'👷‍♀️': {
		'character': '👷‍♀️',
		'unicode': 'U+1F477 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman construction worker'
	},
	'🫅': {
		'character': '🫅',
		'unicode': 'U+1FAC5',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Person with crown'
	},
	'🤴': {
		'character': '🤴',
		'unicode': 'U+1F934',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Prince'
	},
	'👸': {
		'character': '👸',
		'unicode': 'U+1F478',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Princess'
	},
	'👳': {
		'character': '👳',
		'unicode': 'U+1F473',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Person wearing turban'
	},
	'👳‍♂️': {
		'character': '👳‍♂️',
		'unicode': 'U+1F473 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man wearing turban'
	},
	'👳‍♀️': {
		'character': '👳‍♀️',
		'unicode': 'U+1F473 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman wearing turban'
	},
	'👲': {
		'character': '👲',
		'unicode': 'U+1F472',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Person with skullcap'
	},
	'🧕': {
		'character': '🧕',
		'unicode': 'U+1F9D5',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman with headscarf'
	},
	'🤵': {
		'character': '🤵',
		'unicode': 'U+1F935',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Person in tuxedo'
	},
	'🤵‍♂️': {
		'character': '🤵‍♂️',
		'unicode': 'U+1F935 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man in tuxedo'
	},
	'🤵‍♀️': {
		'character': '🤵‍♀️',
		'unicode': 'U+1F935 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman in tuxedo'
	},
	'👰': {
		'character': '👰',
		'unicode': 'U+1F470',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Person with veil'
	},
	'👰‍♂️': {
		'character': '👰‍♂️',
		'unicode': 'U+1F470 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man with veil'
	},
	'👰‍♀️': {
		'character': '👰‍♀️',
		'unicode': 'U+1F470 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman with veil'
	},
	'🤰': {
		'character': '🤰',
		'unicode': 'U+1F930',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Pregnant woman'
	},
	'🫃': {
		'character': '🫃',
		'unicode': 'U+1FAC3',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Pregnant man'
	},
	'🫄': {
		'character': '🫄',
		'unicode': 'U+1FAC4',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Pregnant person'
	},
	'🤱': {
		'character': '🤱',
		'unicode': 'U+1F931',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Breast-feeding'
	},
	'👩‍🍼': {
		'character': '👩‍🍼',
		'unicode': 'U+1F469 U+200D U+1F37C',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Woman feeding baby'
	},
	'👨‍🍼': {
		'character': '👨‍🍼',
		'unicode': 'U+1F468 U+200D U+1F37C',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Man feeding baby'
	},
	'🧑‍🍼': {
		'character': '🧑‍🍼',
		'unicode': 'U+1F9D1 U+200D U+1F37C',
		'category': 'people-and-body',
		'subCategory': 'person-role',
		'description': 'Person feeding baby'
	},
	'👼': {
		'character': '👼',
		'unicode': 'U+1F47C',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Baby angel'
	},
	'🎅': {
		'character': '🎅',
		'unicode': 'U+1F385',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Santa claus'
	},
	'🤶': {
		'character': '🤶',
		'unicode': 'U+1F936',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Mrs. claus'
	},
	'🧑‍🎄': {
		'character': '🧑‍🎄',
		'unicode': 'U+1F9D1 U+200D U+1F384',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Mx claus'
	},
	'🦸': {
		'character': '🦸',
		'unicode': 'U+1F9B8',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Superhero'
	},
	'🦸‍♂️': {
		'character': '🦸‍♂️',
		'unicode': 'U+1F9B8 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Man superhero'
	},
	'🦸‍♀️': {
		'character': '🦸‍♀️',
		'unicode': 'U+1F9B8 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Woman superhero'
	},
	'🦹': {
		'character': '🦹',
		'unicode': 'U+1F9B9',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Supervillain'
	},
	'🦹‍♂️': {
		'character': '🦹‍♂️',
		'unicode': 'U+1F9B9 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Man supervillain'
	},
	'🦹‍♀️': {
		'character': '🦹‍♀️',
		'unicode': 'U+1F9B9 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Woman supervillain'
	},
	'🧙': {
		'character': '🧙',
		'unicode': 'U+1F9D9',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Mage'
	},
	'🧙‍♂️': {
		'character': '🧙‍♂️',
		'unicode': 'U+1F9D9 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Man mage'
	},
	'🧙‍♀️': {
		'character': '🧙‍♀️',
		'unicode': 'U+1F9D9 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Woman mage'
	},
	'🧚': {
		'character': '🧚',
		'unicode': 'U+1F9DA',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Fairy'
	},
	'🧚‍♂️': {
		'character': '🧚‍♂️',
		'unicode': 'U+1F9DA U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Man fairy'
	},
	'🧚‍♀️': {
		'character': '🧚‍♀️',
		'unicode': 'U+1F9DA U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Woman fairy'
	},
	'🧛': {
		'character': '🧛',
		'unicode': 'U+1F9DB',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Vampire'
	},
	'🧛‍♂️': {
		'character': '🧛‍♂️',
		'unicode': 'U+1F9DB U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Man vampire'
	},
	'🧛‍♀️': {
		'character': '🧛‍♀️',
		'unicode': 'U+1F9DB U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Woman vampire'
	},
	'🧜': {
		'character': '🧜',
		'unicode': 'U+1F9DC',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Merperson'
	},
	'🧜‍♂️': {
		'character': '🧜‍♂️',
		'unicode': 'U+1F9DC U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Merman'
	},
	'🧜‍♀️': {
		'character': '🧜‍♀️',
		'unicode': 'U+1F9DC U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Mermaid'
	},
	'🧝': {
		'character': '🧝',
		'unicode': 'U+1F9DD',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Elf'
	},
	'🧝‍♂️': {
		'character': '🧝‍♂️',
		'unicode': 'U+1F9DD U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Man elf'
	},
	'🧝‍♀️': {
		'character': '🧝‍♀️',
		'unicode': 'U+1F9DD U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Woman elf'
	},
	'🧞': {
		'character': '🧞',
		'unicode': 'U+1F9DE',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Genie'
	},
	'🧞‍♂️': {
		'character': '🧞‍♂️',
		'unicode': 'U+1F9DE U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Man genie'
	},
	'🧞‍♀️': {
		'character': '🧞‍♀️',
		'unicode': 'U+1F9DE U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Woman genie'
	},
	'🧟': {
		'character': '🧟',
		'unicode': 'U+1F9DF',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Zombie'
	},
	'🧟‍♂️': {
		'character': '🧟‍♂️',
		'unicode': 'U+1F9DF U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Man zombie'
	},
	'🧟‍♀️': {
		'character': '🧟‍♀️',
		'unicode': 'U+1F9DF U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Woman zombie'
	},
	'🧌': {
		'character': '🧌',
		'unicode': 'U+1F9CC',
		'category': 'people-and-body',
		'subCategory': 'person-fantasy',
		'description': 'Troll'
	},
	'💆': {
		'character': '💆',
		'unicode': 'U+1F486',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Person getting massage'
	},
	'💆‍♂️': {
		'character': '💆‍♂️',
		'unicode': 'U+1F486 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Man getting massage'
	},
	'💆‍♀️': {
		'character': '💆‍♀️',
		'unicode': 'U+1F486 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Woman getting massage'
	},
	'💇': {
		'character': '💇',
		'unicode': 'U+1F487',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Person getting haircut'
	},
	'💇‍♂️': {
		'character': '💇‍♂️',
		'unicode': 'U+1F487 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Man getting haircut'
	},
	'💇‍♀️': {
		'character': '💇‍♀️',
		'unicode': 'U+1F487 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Woman getting haircut'
	},
	'🚶': {
		'character': '🚶',
		'unicode': 'U+1F6B6',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Person walking'
	},
	'🚶‍♂️': {
		'character': '🚶‍♂️',
		'unicode': 'U+1F6B6 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Man walking'
	},
	'🚶‍♀️': {
		'character': '🚶‍♀️',
		'unicode': 'U+1F6B6 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Woman walking'
	},
	'🧍': {
		'character': '🧍',
		'unicode': 'U+1F9CD',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Person standing'
	},
	'🧍‍♂️': {
		'character': '🧍‍♂️',
		'unicode': 'U+1F9CD U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Man standing'
	},
	'🧍‍♀️': {
		'character': '🧍‍♀️',
		'unicode': 'U+1F9CD U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Woman standing'
	},
	'🧎': {
		'character': '🧎',
		'unicode': 'U+1F9CE',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Person kneeling'
	},
	'🧎‍♂️': {
		'character': '🧎‍♂️',
		'unicode': 'U+1F9CE U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Man kneeling'
	},
	'🧎‍♀️': {
		'character': '🧎‍♀️',
		'unicode': 'U+1F9CE U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Woman kneeling'
	},
	'🧑‍🦯': {
		'character': '🧑‍🦯',
		'unicode': 'U+1F9D1 U+200D U+1F9AF',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Person with white cane'
	},
	'👨‍🦯': {
		'character': '👨‍🦯',
		'unicode': 'U+1F468 U+200D U+1F9AF',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Man with white cane'
	},
	'👩‍🦯': {
		'character': '👩‍🦯',
		'unicode': 'U+1F469 U+200D U+1F9AF',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Woman with white cane'
	},
	'🧑‍🦼': {
		'character': '🧑‍🦼',
		'unicode': 'U+1F9D1 U+200D U+1F9BC',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Person in motorized wheelchair'
	},
	'👨‍🦼': {
		'character': '👨‍🦼',
		'unicode': 'U+1F468 U+200D U+1F9BC',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Man in motorized wheelchair'
	},
	'👩‍🦼': {
		'character': '👩‍🦼',
		'unicode': 'U+1F469 U+200D U+1F9BC',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Woman in motorized wheelchair'
	},
	'🧑‍🦽': {
		'character': '🧑‍🦽',
		'unicode': 'U+1F9D1 U+200D U+1F9BD',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Person in manual wheelchair'
	},
	'👨‍🦽': {
		'character': '👨‍🦽',
		'unicode': 'U+1F468 U+200D U+1F9BD',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Man in manual wheelchair'
	},
	'👩‍🦽': {
		'character': '👩‍🦽',
		'unicode': 'U+1F469 U+200D U+1F9BD',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Woman in manual wheelchair'
	},
	'🏃': {
		'character': '🏃',
		'unicode': 'U+1F3C3',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Person running'
	},
	'🏃‍♂️': {
		'character': '🏃‍♂️',
		'unicode': 'U+1F3C3 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Man running'
	},
	'🏃‍♀️': {
		'character': '🏃‍♀️',
		'unicode': 'U+1F3C3 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Woman running'
	},
	'💃': {
		'character': '💃',
		'unicode': 'U+1F483',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Woman dancing'
	},
	'🕺': {
		'character': '🕺',
		'unicode': 'U+1F57A',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Man dancing'
	},
	'🕴': {
		'character': '🕴',
		'unicode': 'U+1F574',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Person in suit levitating'
	},
	'👯': {
		'character': '👯',
		'unicode': 'U+1F46F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'People with bunny ears'
	},
	'👯‍♂️': {
		'character': '👯‍♂️',
		'unicode': 'U+1F46F U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Men with bunny ears'
	},
	'👯‍♀️': {
		'character': '👯‍♀️',
		'unicode': 'U+1F46F U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Women with bunny ears'
	},
	'🧖': {
		'character': '🧖',
		'unicode': 'U+1F9D6',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Person in steamy room'
	},
	'🧖‍♂️': {
		'character': '🧖‍♂️',
		'unicode': 'U+1F9D6 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Man in steamy room'
	},
	'🧖‍♀️': {
		'character': '🧖‍♀️',
		'unicode': 'U+1F9D6 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Woman in steamy room'
	},
	'🧗': {
		'character': '🧗',
		'unicode': 'U+1F9D7',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Person climbing'
	},
	'🧗‍♂️': {
		'character': '🧗‍♂️',
		'unicode': 'U+1F9D7 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Man climbing'
	},
	'🧗‍♀️': {
		'character': '🧗‍♀️',
		'unicode': 'U+1F9D7 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-activity',
		'description': 'Woman climbing'
	},
	'🤺': {
		'character': '🤺',
		'unicode': 'U+1F93A',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Person fencing'
	},
	'🏇': {
		'character': '🏇',
		'unicode': 'U+1F3C7',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Horse racing'
	},
	'⛷': {
		'character': '⛷',
		'unicode': 'U+26F7',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Skier'
	},
	'🏂': {
		'character': '🏂',
		'unicode': 'U+1F3C2',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Snowboarder'
	},
	'🏌': {
		'character': '🏌',
		'unicode': 'U+1F3CC',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Person golfing'
	},
	'🏌️‍♂️': {
		'character': '🏌️‍♂️',
		'unicode': 'U+1F3CC U+FE0F U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Man golfing'
	},
	'🏌️‍♀️': {
		'character': '🏌️‍♀️',
		'unicode': 'U+1F3CC U+FE0F U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Woman golfing'
	},
	'🏄': {
		'character': '🏄',
		'unicode': 'U+1F3C4',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Person surfing'
	},
	'🏄‍♂️': {
		'character': '🏄‍♂️',
		'unicode': 'U+1F3C4 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Man surfing'
	},
	'🏄‍♀️': {
		'character': '🏄‍♀️',
		'unicode': 'U+1F3C4 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Woman surfing'
	},
	'🚣': {
		'character': '🚣',
		'unicode': 'U+1F6A3',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Person rowing boat'
	},
	'🚣‍♂️': {
		'character': '🚣‍♂️',
		'unicode': 'U+1F6A3 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Man rowing boat'
	},
	'🚣‍♀️': {
		'character': '🚣‍♀️',
		'unicode': 'U+1F6A3 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Woman rowing boat'
	},
	'🏊': {
		'character': '🏊',
		'unicode': 'U+1F3CA',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Person swimming'
	},
	'🏊‍♂️': {
		'character': '🏊‍♂️',
		'unicode': 'U+1F3CA U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Man swimming'
	},
	'🏊‍♀️': {
		'character': '🏊‍♀️',
		'unicode': 'U+1F3CA U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Woman swimming'
	},
	'⛹': {
		'character': '⛹',
		'unicode': 'U+26F9',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Person bouncing ball'
	},
	'⛹️‍♂️': {
		'character': '⛹️‍♂️',
		'unicode': 'U+26F9 U+FE0F U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Man bouncing ball'
	},
	'⛹️‍♀️': {
		'character': '⛹️‍♀️',
		'unicode': 'U+26F9 U+FE0F U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Woman bouncing ball'
	},
	'🏋': {
		'character': '🏋',
		'unicode': 'U+1F3CB',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Person lifting weights'
	},
	'🏋️‍♂️': {
		'character': '🏋️‍♂️',
		'unicode': 'U+1F3CB U+FE0F U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Man lifting weights'
	},
	'🏋️‍♀️': {
		'character': '🏋️‍♀️',
		'unicode': 'U+1F3CB U+FE0F U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Woman lifting weights'
	},
	'🚴': {
		'character': '🚴',
		'unicode': 'U+1F6B4',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Person biking'
	},
	'🚴‍♂️': {
		'character': '🚴‍♂️',
		'unicode': 'U+1F6B4 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Man biking'
	},
	'🚴‍♀️': {
		'character': '🚴‍♀️',
		'unicode': 'U+1F6B4 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Woman biking'
	},
	'🚵': {
		'character': '🚵',
		'unicode': 'U+1F6B5',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Person mountain biking'
	},
	'🚵‍♂️': {
		'character': '🚵‍♂️',
		'unicode': 'U+1F6B5 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Man mountain biking'
	},
	'🚵‍♀️': {
		'character': '🚵‍♀️',
		'unicode': 'U+1F6B5 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Woman mountain biking'
	},
	'🤸': {
		'character': '🤸',
		'unicode': 'U+1F938',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Person cartwheeling'
	},
	'🤸‍♂️': {
		'character': '🤸‍♂️',
		'unicode': 'U+1F938 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Man cartwheeling'
	},
	'🤸‍♀️': {
		'character': '🤸‍♀️',
		'unicode': 'U+1F938 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Woman cartwheeling'
	},
	'🤼': {
		'character': '🤼',
		'unicode': 'U+1F93C',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'People wrestling'
	},
	'🤼‍♂️': {
		'character': '🤼‍♂️',
		'unicode': 'U+1F93C U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Men wrestling'
	},
	'🤼‍♀️': {
		'character': '🤼‍♀️',
		'unicode': 'U+1F93C U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Women wrestling'
	},
	'🤽': {
		'character': '🤽',
		'unicode': 'U+1F93D',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Person playing water polo'
	},
	'🤽‍♂️': {
		'character': '🤽‍♂️',
		'unicode': 'U+1F93D U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Man playing water polo'
	},
	'🤽‍♀️': {
		'character': '🤽‍♀️',
		'unicode': 'U+1F93D U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Woman playing water polo'
	},
	'🤾': {
		'character': '🤾',
		'unicode': 'U+1F93E',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Person playing handball'
	},
	'🤾‍♂️': {
		'character': '🤾‍♂️',
		'unicode': 'U+1F93E U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Man playing handball'
	},
	'🤾‍♀️': {
		'character': '🤾‍♀️',
		'unicode': 'U+1F93E U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Woman playing handball'
	},
	'🤹': {
		'character': '🤹',
		'unicode': 'U+1F939',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Person juggling'
	},
	'🤹‍♂️': {
		'character': '🤹‍♂️',
		'unicode': 'U+1F939 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Man juggling'
	},
	'🤹‍♀️': {
		'character': '🤹‍♀️',
		'unicode': 'U+1F939 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-sport',
		'description': 'Woman juggling'
	},
	'🧘': {
		'character': '🧘',
		'unicode': 'U+1F9D8',
		'category': 'people-and-body',
		'subCategory': 'person-resting',
		'description': 'Person in lotus position'
	},
	'🧘‍♂️': {
		'character': '🧘‍♂️',
		'unicode': 'U+1F9D8 U+200D U+2642 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-resting',
		'description': 'Man in lotus position'
	},
	'🧘‍♀️': {
		'character': '🧘‍♀️',
		'unicode': 'U+1F9D8 U+200D U+2640 U+FE0F',
		'category': 'people-and-body',
		'subCategory': 'person-resting',
		'description': 'Woman in lotus position'
	},
	'🛀': {
		'character': '🛀',
		'unicode': 'U+1F6C0',
		'category': 'people-and-body',
		'subCategory': 'person-resting',
		'description': 'Person taking bath'
	},
	'🛌': {
		'character': '🛌',
		'unicode': 'U+1F6CC',
		'category': 'people-and-body',
		'subCategory': 'person-resting',
		'description': 'Person in bed'
	},
	'🧑‍🤝‍🧑': {
		'character': '🧑‍🤝‍🧑',
		'unicode': 'U+1F9D1 U+200D U+1F91D U+200D U+1F9D1',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'People holding hands'
	},
	'👭': {
		'character': '👭',
		'unicode': 'U+1F46D',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Women holding hands'
	},
	'👫': {
		'character': '👫',
		'unicode': 'U+1F46B',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Woman and man holding hands'
	},
	'👬': {
		'character': '👬',
		'unicode': 'U+1F46C',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Men holding hands'
	},
	'💏': {
		'character': '💏',
		'unicode': 'U+1F48F',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Kiss'
	},
	'👩‍❤️‍💋‍👨': {
		'character': '👩‍❤️‍💋‍👨',
		'unicode': 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F468',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Kiss: woman, man'
	},
	'👨‍❤️‍💋‍👨': {
		'character': '👨‍❤️‍💋‍👨',
		'unicode': 'U+1F468 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F468',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Kiss: man, man'
	},
	'👩‍❤️‍💋‍👩': {
		'character': '👩‍❤️‍💋‍👩',
		'unicode': 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F469',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Kiss: woman, woman'
	},
	'💑': {
		'character': '💑',
		'unicode': 'U+1F491',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Couple with heart'
	},
	'👩‍❤️‍👨': {
		'character': '👩‍❤️‍👨',
		'unicode': 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F468',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Couple with heart: woman, man'
	},
	'👨‍❤️‍👨': {
		'character': '👨‍❤️‍👨',
		'unicode': 'U+1F468 U+200D U+2764 U+FE0F U+200D U+1F468',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Couple with heart: man, man'
	},
	'👩‍❤️‍👩': {
		'character': '👩‍❤️‍👩',
		'unicode': 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F469',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Couple with heart: woman, woman'
	},
	'👪': {
		'character': '👪',
		'unicode': 'U+1F46A',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family'
	},
	'👨‍👩‍👦': {
		'character': '👨‍👩‍👦',
		'unicode': 'U+1F468 U+200D U+1F469 U+200D U+1F466',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: man, woman, boy'
	},
	'👨‍👩‍👧': {
		'character': '👨‍👩‍👧',
		'unicode': 'U+1F468 U+200D U+1F469 U+200D U+1F467',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: man, woman, girl'
	},
	'👨‍👩‍👧‍👦': {
		'character': '👨‍👩‍👧‍👦',
		'unicode': 'U+1F468 U+200D U+1F469 U+200D U+1F467 U+200D U+1F466',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: man, woman, girl, boy'
	},
	'👨‍👩‍👦‍👦': {
		'character': '👨‍👩‍👦‍👦',
		'unicode': 'U+1F468 U+200D U+1F469 U+200D U+1F466 U+200D U+1F466',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: man, woman, boy, boy'
	},
	'👨‍👩‍👧‍👧': {
		'character': '👨‍👩‍👧‍👧',
		'unicode': 'U+1F468 U+200D U+1F469 U+200D U+1F467 U+200D U+1F467',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: man, woman, girl, girl'
	},
	'👨‍👨‍👦': {
		'character': '👨‍👨‍👦',
		'unicode': 'U+1F468 U+200D U+1F468 U+200D U+1F466',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: man, man, boy'
	},
	'👨‍👨‍👧': {
		'character': '👨‍👨‍👧',
		'unicode': 'U+1F468 U+200D U+1F468 U+200D U+1F467',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: man, man, girl'
	},
	'👨‍👨‍👧‍👦': {
		'character': '👨‍👨‍👧‍👦',
		'unicode': 'U+1F468 U+200D U+1F468 U+200D U+1F467 U+200D U+1F466',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: man, man, girl, boy'
	},
	'👨‍👨‍👦‍👦': {
		'character': '👨‍👨‍👦‍👦',
		'unicode': 'U+1F468 U+200D U+1F468 U+200D U+1F466 U+200D U+1F466',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: man, man, boy, boy'
	},
	'👨‍👨‍👧‍👧': {
		'character': '👨‍👨‍👧‍👧',
		'unicode': 'U+1F468 U+200D U+1F468 U+200D U+1F467 U+200D U+1F467',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: man, man, girl, girl'
	},
	'👩‍👩‍👦': {
		'character': '👩‍👩‍👦',
		'unicode': 'U+1F469 U+200D U+1F469 U+200D U+1F466',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: woman, woman, boy'
	},
	'👩‍👩‍👧': {
		'character': '👩‍👩‍👧',
		'unicode': 'U+1F469 U+200D U+1F469 U+200D U+1F467',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: woman, woman, girl'
	},
	'👩‍👩‍👧‍👦': {
		'character': '👩‍👩‍👧‍👦',
		'unicode': 'U+1F469 U+200D U+1F469 U+200D U+1F467 U+200D U+1F466',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: woman, woman, girl, boy'
	},
	'👩‍👩‍👦‍👦': {
		'character': '👩‍👩‍👦‍👦',
		'unicode': 'U+1F469 U+200D U+1F469 U+200D U+1F466 U+200D U+1F466',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: woman, woman, boy, boy'
	},
	'👩‍👩‍👧‍👧': {
		'character': '👩‍👩‍👧‍👧',
		'unicode': 'U+1F469 U+200D U+1F469 U+200D U+1F467 U+200D U+1F467',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: woman, woman, girl, girl'
	},
	'👨‍👦': {
		'character': '👨‍👦',
		'unicode': 'U+1F468 U+200D U+1F466',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: man, boy'
	},
	'👨‍👦‍👦': {
		'character': '👨‍👦‍👦',
		'unicode': 'U+1F468 U+200D U+1F466 U+200D U+1F466',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: man, boy, boy'
	},
	'👨‍👧': {
		'character': '👨‍👧',
		'unicode': 'U+1F468 U+200D U+1F467',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: man, girl'
	},
	'👨‍👧‍👦': {
		'character': '👨‍👧‍👦',
		'unicode': 'U+1F468 U+200D U+1F467 U+200D U+1F466',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: man, girl, boy'
	},
	'👨‍👧‍👧': {
		'character': '👨‍👧‍👧',
		'unicode': 'U+1F468 U+200D U+1F467 U+200D U+1F467',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: man, girl, girl'
	},
	'👩‍👦': {
		'character': '👩‍👦',
		'unicode': 'U+1F469 U+200D U+1F466',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: woman, boy'
	},
	'👩‍👦‍👦': {
		'character': '👩‍👦‍👦',
		'unicode': 'U+1F469 U+200D U+1F466 U+200D U+1F466',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: woman, boy, boy'
	},
	'👩‍👧': {
		'character': '👩‍👧',
		'unicode': 'U+1F469 U+200D U+1F467',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: woman, girl'
	},
	'👩‍👧‍👦': {
		'character': '👩‍👧‍👦',
		'unicode': 'U+1F469 U+200D U+1F467 U+200D U+1F466',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: woman, girl, boy'
	},
	'👩‍👧‍👧': {
		'character': '👩‍👧‍👧',
		'unicode': 'U+1F469 U+200D U+1F467 U+200D U+1F467',
		'category': 'people-and-body',
		'subCategory': 'family',
		'description': 'Family: woman, girl, girl'
	},
	'🗣': {
		'character': '🗣',
		'unicode': 'U+1F5E3',
		'category': 'people-and-body',
		'subCategory': 'person-symbol',
		'description': 'Speaking head'
	},
	'👤': {
		'character': '👤',
		'unicode': 'U+1F464',
		'category': 'people-and-body',
		'subCategory': 'person-symbol',
		'description': 'Bust in silhouette'
	},
	'👥': {
		'character': '👥',
		'unicode': 'U+1F465',
		'category': 'people-and-body',
		'subCategory': 'person-symbol',
		'description': 'Busts in silhouette'
	},
	'🫂': {
		'character': '🫂',
		'unicode': 'U+1FAC2',
		'category': 'people-and-body',
		'subCategory': 'person-symbol',
		'description': 'People hugging'
	},
	'👣': {
		'character': '👣',
		'unicode': 'U+1F463',
		'category': 'people-and-body',
		'subCategory': 'person-symbol',
		'description': 'Footprints'
	},
	'🦰': {
		'character': '🦰',
		'unicode': 'U+1F9B0',
		'category': 'component',
		'subCategory': 'hair-style',
		'description': 'Red hair'
	},
	'🦱': {
		'character': '🦱',
		'unicode': 'U+1F9B1',
		'category': 'component',
		'subCategory': 'hair-style',
		'description': 'Curly hair'
	},
	'🦳': {
		'character': '🦳',
		'unicode': 'U+1F9B3',
		'category': 'component',
		'subCategory': 'hair-style',
		'description': 'White hair'
	},
	'🦲': {
		'character': '🦲',
		'unicode': 'U+1F9B2',
		'category': 'component',
		'subCategory': 'hair-style',
		'description': 'Bald'
	},
	'🐵': {
		'character': '🐵',
		'unicode': 'U+1F435',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Monkey face'
	},
	'🐒': {
		'character': '🐒',
		'unicode': 'U+1F412',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Monkey'
	},
	'🦍': {
		'character': '🦍',
		'unicode': 'U+1F98D',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Gorilla'
	},
	'🦧': {
		'character': '🦧',
		'unicode': 'U+1F9A7',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Orangutan'
	},
	'🐶': {
		'character': '🐶',
		'unicode': 'U+1F436',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Dog face'
	},
	'🐕': {
		'character': '🐕',
		'unicode': 'U+1F415',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Dog'
	},
	'🦮': {
		'character': '🦮',
		'unicode': 'U+1F9AE',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Guide dog'
	},
	'🐕‍🦺': {
		'character': '🐕‍🦺',
		'unicode': 'U+1F415 U+200D U+1F9BA',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Service dog'
	},
	'🐩': {
		'character': '🐩',
		'unicode': 'U+1F429',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Poodle'
	},
	'🐺': {
		'character': '🐺',
		'unicode': 'U+1F43A',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Wolf'
	},
	'🦊': {
		'character': '🦊',
		'unicode': 'U+1F98A',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Fox'
	},
	'🦝': {
		'character': '🦝',
		'unicode': 'U+1F99D',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Raccoon'
	},
	'🐱': {
		'character': '🐱',
		'unicode': 'U+1F431',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Cat face'
	},
	'🐈': {
		'character': '🐈',
		'unicode': 'U+1F408',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Cat'
	},
	'🐈‍⬛': {
		'character': '🐈‍⬛',
		'unicode': 'U+1F408 U+200D U+2B1B',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Black cat'
	},
	'🦁': {
		'character': '🦁',
		'unicode': 'U+1F981',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Lion'
	},
	'🐯': {
		'character': '🐯',
		'unicode': 'U+1F42F',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Tiger face'
	},
	'🐅': {
		'character': '🐅',
		'unicode': 'U+1F405',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Tiger'
	},
	'🐆': {
		'character': '🐆',
		'unicode': 'U+1F406',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Leopard'
	},
	'🐴': {
		'character': '🐴',
		'unicode': 'U+1F434',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Horse face'
	},
	'🐎': {
		'character': '🐎',
		'unicode': 'U+1F40E',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Horse'
	},
	'🦄': {
		'character': '🦄',
		'unicode': 'U+1F984',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Unicorn'
	},
	'🦓': {
		'character': '🦓',
		'unicode': 'U+1F993',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Zebra'
	},
	'🦌': {
		'character': '🦌',
		'unicode': 'U+1F98C',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Deer'
	},
	'🦬': {
		'character': '🦬',
		'unicode': 'U+1F9AC',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Bison'
	},
	'🐮': {
		'character': '🐮',
		'unicode': 'U+1F42E',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Cow face'
	},
	'🐂': {
		'character': '🐂',
		'unicode': 'U+1F402',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Ox'
	},
	'🐃': {
		'character': '🐃',
		'unicode': 'U+1F403',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Water buffalo'
	},
	'🐄': {
		'character': '🐄',
		'unicode': 'U+1F404',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Cow'
	},
	'🐷': {
		'character': '🐷',
		'unicode': 'U+1F437',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Pig face'
	},
	'🐖': {
		'character': '🐖',
		'unicode': 'U+1F416',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Pig'
	},
	'🐗': {
		'character': '🐗',
		'unicode': 'U+1F417',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Boar'
	},
	'🐽': {
		'character': '🐽',
		'unicode': 'U+1F43D',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Pig nose'
	},
	'🐏': {
		'character': '🐏',
		'unicode': 'U+1F40F',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Ram'
	},
	'🐑': {
		'character': '🐑',
		'unicode': 'U+1F411',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Ewe'
	},
	'🐐': {
		'character': '🐐',
		'unicode': 'U+1F410',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Goat'
	},
	'🐪': {
		'character': '🐪',
		'unicode': 'U+1F42A',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Camel'
	},
	'🐫': {
		'character': '🐫',
		'unicode': 'U+1F42B',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Two-hump camel'
	},
	'🦙': {
		'character': '🦙',
		'unicode': 'U+1F999',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Llama'
	},
	'🦒': {
		'character': '🦒',
		'unicode': 'U+1F992',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Giraffe'
	},
	'🐘': {
		'character': '🐘',
		'unicode': 'U+1F418',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Elephant'
	},
	'🦣': {
		'character': '🦣',
		'unicode': 'U+1F9A3',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Mammoth'
	},
	'🦏': {
		'character': '🦏',
		'unicode': 'U+1F98F',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Rhinoceros'
	},
	'🦛': {
		'character': '🦛',
		'unicode': 'U+1F99B',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Hippopotamus'
	},
	'🐭': {
		'character': '🐭',
		'unicode': 'U+1F42D',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Mouse face'
	},
	'🐁': {
		'character': '🐁',
		'unicode': 'U+1F401',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Mouse'
	},
	'🐀': {
		'character': '🐀',
		'unicode': 'U+1F400',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Rat'
	},
	'🐹': {
		'character': '🐹',
		'unicode': 'U+1F439',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Hamster'
	},
	'🐰': {
		'character': '🐰',
		'unicode': 'U+1F430',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Rabbit face'
	},
	'🐇': {
		'character': '🐇',
		'unicode': 'U+1F407',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Rabbit'
	},
	'🐿': {
		'character': '🐿',
		'unicode': 'U+1F43F',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Chipmunk'
	},
	'🦫': {
		'character': '🦫',
		'unicode': 'U+1F9AB',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Beaver'
	},
	'🦔': {
		'character': '🦔',
		'unicode': 'U+1F994',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Hedgehog'
	},
	'🦇': {
		'character': '🦇',
		'unicode': 'U+1F987',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Bat'
	},
	'🐻': {
		'character': '🐻',
		'unicode': 'U+1F43B',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Bear'
	},
	'🐻‍❄️': {
		'character': '🐻‍❄️',
		'unicode': 'U+1F43B U+200D U+2744 U+FE0F',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Polar bear'
	},
	'🐨': {
		'character': '🐨',
		'unicode': 'U+1F428',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Koala'
	},
	'🐼': {
		'character': '🐼',
		'unicode': 'U+1F43C',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Panda'
	},
	'🦥': {
		'character': '🦥',
		'unicode': 'U+1F9A5',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Sloth'
	},
	'🦦': {
		'character': '🦦',
		'unicode': 'U+1F9A6',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Otter'
	},
	'🦨': {
		'character': '🦨',
		'unicode': 'U+1F9A8',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Skunk'
	},
	'🦘': {
		'character': '🦘',
		'unicode': 'U+1F998',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Kangaroo'
	},
	'🦡': {
		'character': '🦡',
		'unicode': 'U+1F9A1',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Badger'
	},
	'🐾': {
		'character': '🐾',
		'unicode': 'U+1F43E',
		'category': 'animals-and-nature',
		'subCategory': 'animal-mammal',
		'description': 'Paw prints'
	},
	'🦃': {
		'character': '🦃',
		'unicode': 'U+1F983',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Turkey'
	},
	'🐔': {
		'character': '🐔',
		'unicode': 'U+1F414',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Chicken'
	},
	'🐓': {
		'character': '🐓',
		'unicode': 'U+1F413',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Rooster'
	},
	'🐣': {
		'character': '🐣',
		'unicode': 'U+1F423',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Hatching chick'
	},
	'🐤': {
		'character': '🐤',
		'unicode': 'U+1F424',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Baby chick'
	},
	'🐥': {
		'character': '🐥',
		'unicode': 'U+1F425',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Front-facing baby chick'
	},
	'🐦': {
		'character': '🐦',
		'unicode': 'U+1F426',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Bird'
	},
	'🐧': {
		'character': '🐧',
		'unicode': 'U+1F427',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Penguin'
	},
	'🕊': {
		'character': '🕊',
		'unicode': 'U+1F54A',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Dove'
	},
	'🦅': {
		'character': '🦅',
		'unicode': 'U+1F985',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Eagle'
	},
	'🦆': {
		'character': '🦆',
		'unicode': 'U+1F986',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Duck'
	},
	'🦢': {
		'character': '🦢',
		'unicode': 'U+1F9A2',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Swan'
	},
	'🦉': {
		'character': '🦉',
		'unicode': 'U+1F989',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Owl'
	},
	'🦤': {
		'character': '🦤',
		'unicode': 'U+1F9A4',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Dodo'
	},
	'🪶': {
		'character': '🪶',
		'unicode': 'U+1FAB6',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Feather'
	},
	'🦩': {
		'character': '🦩',
		'unicode': 'U+1F9A9',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Flamingo'
	},
	'🦚': {
		'character': '🦚',
		'unicode': 'U+1F99A',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Peacock'
	},
	'🦜': {
		'character': '🦜',
		'unicode': 'U+1F99C',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bird',
		'description': 'Parrot'
	},
	'🐸': {
		'character': '🐸',
		'unicode': 'U+1F438',
		'category': 'animals-and-nature',
		'subCategory': 'animal-amphibian',
		'description': 'Frog'
	},
	'🐊': {
		'character': '🐊',
		'unicode': 'U+1F40A',
		'category': 'animals-and-nature',
		'subCategory': 'animal-reptile',
		'description': 'Crocodile'
	},
	'🐢': {
		'character': '🐢',
		'unicode': 'U+1F422',
		'category': 'animals-and-nature',
		'subCategory': 'animal-reptile',
		'description': 'Turtle'
	},
	'🦎': {
		'character': '🦎',
		'unicode': 'U+1F98E',
		'category': 'animals-and-nature',
		'subCategory': 'animal-reptile',
		'description': 'Lizard'
	},
	'🐍': {
		'character': '🐍',
		'unicode': 'U+1F40D',
		'category': 'animals-and-nature',
		'subCategory': 'animal-reptile',
		'description': 'Snake'
	},
	'🐲': {
		'character': '🐲',
		'unicode': 'U+1F432',
		'category': 'animals-and-nature',
		'subCategory': 'animal-reptile',
		'description': 'Dragon face'
	},
	'🐉': {
		'character': '🐉',
		'unicode': 'U+1F409',
		'category': 'animals-and-nature',
		'subCategory': 'animal-reptile',
		'description': 'Dragon'
	},
	'🦕': {
		'character': '🦕',
		'unicode': 'U+1F995',
		'category': 'animals-and-nature',
		'subCategory': 'animal-reptile',
		'description': 'Sauropod'
	},
	'🦖': {
		'character': '🦖',
		'unicode': 'U+1F996',
		'category': 'animals-and-nature',
		'subCategory': 'animal-reptile',
		'description': 'T-rex'
	},
	'🐳': {
		'character': '🐳',
		'unicode': 'U+1F433',
		'category': 'animals-and-nature',
		'subCategory': 'animal-marine',
		'description': 'Spouting whale'
	},
	'🐋': {
		'character': '🐋',
		'unicode': 'U+1F40B',
		'category': 'animals-and-nature',
		'subCategory': 'animal-marine',
		'description': 'Whale'
	},
	'🐬': {
		'character': '🐬',
		'unicode': 'U+1F42C',
		'category': 'animals-and-nature',
		'subCategory': 'animal-marine',
		'description': 'Dolphin'
	},
	'🦭': {
		'character': '🦭',
		'unicode': 'U+1F9AD',
		'category': 'animals-and-nature',
		'subCategory': 'animal-marine',
		'description': 'Seal'
	},
	'🐟': {
		'character': '🐟',
		'unicode': 'U+1F41F',
		'category': 'animals-and-nature',
		'subCategory': 'animal-marine',
		'description': 'Fish'
	},
	'🐠': {
		'character': '🐠',
		'unicode': 'U+1F420',
		'category': 'animals-and-nature',
		'subCategory': 'animal-marine',
		'description': 'Tropical fish'
	},
	'🐡': {
		'character': '🐡',
		'unicode': 'U+1F421',
		'category': 'animals-and-nature',
		'subCategory': 'animal-marine',
		'description': 'Blowfish'
	},
	'🦈': {
		'character': '🦈',
		'unicode': 'U+1F988',
		'category': 'animals-and-nature',
		'subCategory': 'animal-marine',
		'description': 'Shark'
	},
	'🐙': {
		'character': '🐙',
		'unicode': 'U+1F419',
		'category': 'animals-and-nature',
		'subCategory': 'animal-marine',
		'description': 'Octopus'
	},
	'🐚': {
		'character': '🐚',
		'unicode': 'U+1F41A',
		'category': 'animals-and-nature',
		'subCategory': 'animal-marine',
		'description': 'Spiral shell'
	},
	'🪸': {
		'character': '🪸',
		'unicode': 'U+1FAB8',
		'category': 'animals-and-nature',
		'subCategory': 'animal-marine',
		'description': 'Coral'
	},
	'🐌': {
		'character': '🐌',
		'unicode': 'U+1F40C',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Snail'
	},
	'🦋': {
		'character': '🦋',
		'unicode': 'U+1F98B',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Butterfly'
	},
	'🐛': {
		'character': '🐛',
		'unicode': 'U+1F41B',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Bug'
	},
	'🐜': {
		'character': '🐜',
		'unicode': 'U+1F41C',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Ant'
	},
	'🐝': {
		'character': '🐝',
		'unicode': 'U+1F41D',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Honeybee'
	},
	'🪲': {
		'character': '🪲',
		'unicode': 'U+1FAB2',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Beetle'
	},
	'🐞': {
		'character': '🐞',
		'unicode': 'U+1F41E',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Lady beetle'
	},
	'🦗': {
		'character': '🦗',
		'unicode': 'U+1F997',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Cricket'
	},
	'🪳': {
		'character': '🪳',
		'unicode': 'U+1FAB3',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Cockroach'
	},
	'🕷': {
		'character': '🕷',
		'unicode': 'U+1F577',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Spider'
	},
	'🕸': {
		'character': '🕸',
		'unicode': 'U+1F578',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Spider web'
	},
	'🦂': {
		'character': '🦂',
		'unicode': 'U+1F982',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Scorpion'
	},
	'🦟': {
		'character': '🦟',
		'unicode': 'U+1F99F',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Mosquito'
	},
	'🪰': {
		'character': '🪰',
		'unicode': 'U+1FAB0',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Fly'
	},
	'🪱': {
		'character': '🪱',
		'unicode': 'U+1FAB1',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Worm'
	},
	'🦠': {
		'character': '🦠',
		'unicode': 'U+1F9A0',
		'category': 'animals-and-nature',
		'subCategory': 'animal-bug',
		'description': 'Microbe'
	},
	'💐': {
		'character': '💐',
		'unicode': 'U+1F490',
		'category': 'animals-and-nature',
		'subCategory': 'plant-flower',
		'description': 'Bouquet'
	},
	'🌸': {
		'character': '🌸',
		'unicode': 'U+1F338',
		'category': 'animals-and-nature',
		'subCategory': 'plant-flower',
		'description': 'Cherry blossom'
	},
	'💮': {
		'character': '💮',
		'unicode': 'U+1F4AE',
		'category': 'animals-and-nature',
		'subCategory': 'plant-flower',
		'description': 'White flower'
	},
	'🪷': {
		'character': '🪷',
		'unicode': 'U+1FAB7',
		'category': 'animals-and-nature',
		'subCategory': 'plant-flower',
		'description': 'Lotus'
	},
	'🏵': {
		'character': '🏵',
		'unicode': 'U+1F3F5',
		'category': 'animals-and-nature',
		'subCategory': 'plant-flower',
		'description': 'Rosette'
	},
	'🌹': {
		'character': '🌹',
		'unicode': 'U+1F339',
		'category': 'animals-and-nature',
		'subCategory': 'plant-flower',
		'description': 'Rose'
	},
	'🥀': {
		'character': '🥀',
		'unicode': 'U+1F940',
		'category': 'animals-and-nature',
		'subCategory': 'plant-flower',
		'description': 'Wilted flower'
	},
	'🌺': {
		'character': '🌺',
		'unicode': 'U+1F33A',
		'category': 'animals-and-nature',
		'subCategory': 'plant-flower',
		'description': 'Hibiscus'
	},
	'🌻': {
		'character': '🌻',
		'unicode': 'U+1F33B',
		'category': 'animals-and-nature',
		'subCategory': 'plant-flower',
		'description': 'Sunflower'
	},
	'🌼': {
		'character': '🌼',
		'unicode': 'U+1F33C',
		'category': 'animals-and-nature',
		'subCategory': 'plant-flower',
		'description': 'Blossom'
	},
	'🌷': {
		'character': '🌷',
		'unicode': 'U+1F337',
		'category': 'animals-and-nature',
		'subCategory': 'plant-flower',
		'description': 'Tulip'
	},
	'🌱': {
		'character': '🌱',
		'unicode': 'U+1F331',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Seedling'
	},
	'🪴': {
		'character': '🪴',
		'unicode': 'U+1FAB4',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Potted plant'
	},
	'🌲': {
		'character': '🌲',
		'unicode': 'U+1F332',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Evergreen tree'
	},
	'🌳': {
		'character': '🌳',
		'unicode': 'U+1F333',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Deciduous tree'
	},
	'🌴': {
		'character': '🌴',
		'unicode': 'U+1F334',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Palm tree'
	},
	'🌵': {
		'character': '🌵',
		'unicode': 'U+1F335',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Cactus'
	},
	'🌾': {
		'character': '🌾',
		'unicode': 'U+1F33E',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Sheaf of rice'
	},
	'🌿': {
		'character': '🌿',
		'unicode': 'U+1F33F',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Herb'
	},
	'☘': {
		'character': '☘',
		'unicode': 'U+2618',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Shamrock'
	},
	'🍀': {
		'character': '🍀',
		'unicode': 'U+1F340',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Four leaf clover'
	},
	'🍁': {
		'character': '🍁',
		'unicode': 'U+1F341',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Maple leaf'
	},
	'🍂': {
		'character': '🍂',
		'unicode': 'U+1F342',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Fallen leaf'
	},
	'🍃': {
		'character': '🍃',
		'unicode': 'U+1F343',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Leaf fluttering in wind'
	},
	'🪹': {
		'character': '🪹',
		'unicode': 'U+1FAB9',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Empty nest'
	},
	'🪺': {
		'character': '🪺',
		'unicode': 'U+1FABA',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Nest with eggs'
	},
	'🍄': {
		'character': '🍄',
		'unicode': 'U+1F344',
		'category': 'animals-and-nature',
		'subCategory': 'plant-other',
		'description': 'Mushroom'
	},
	'🍇': {
		'character': '🍇',
		'unicode': 'U+1F347',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Grapes'
	},
	'🍈': {
		'character': '🍈',
		'unicode': 'U+1F348',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Melon'
	},
	'🍉': {
		'character': '🍉',
		'unicode': 'U+1F349',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Watermelon'
	},
	'🍊': {
		'character': '🍊',
		'unicode': 'U+1F34A',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Tangerine'
	},
	'🍋': {
		'character': '🍋',
		'unicode': 'U+1F34B',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Lemon'
	},
	'🍌': {
		'character': '🍌',
		'unicode': 'U+1F34C',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Banana'
	},
	'🍍': {
		'character': '🍍',
		'unicode': 'U+1F34D',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Pineapple'
	},
	'🥭': {
		'character': '🥭',
		'unicode': 'U+1F96D',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Mango'
	},
	'🍎': {
		'character': '🍎',
		'unicode': 'U+1F34E',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Red apple'
	},
	'🍏': {
		'character': '🍏',
		'unicode': 'U+1F34F',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Green apple'
	},
	'🍐': {
		'character': '🍐',
		'unicode': 'U+1F350',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Pear'
	},
	'🍑': {
		'character': '🍑',
		'unicode': 'U+1F351',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Peach'
	},
	'🍒': {
		'character': '🍒',
		'unicode': 'U+1F352',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Cherries'
	},
	'🍓': {
		'character': '🍓',
		'unicode': 'U+1F353',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Strawberry'
	},
	'🫐': {
		'character': '🫐',
		'unicode': 'U+1FAD0',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Blueberries'
	},
	'🥝': {
		'character': '🥝',
		'unicode': 'U+1F95D',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Kiwi fruit'
	},
	'🍅': {
		'character': '🍅',
		'unicode': 'U+1F345',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Tomato'
	},
	'🫒': {
		'character': '🫒',
		'unicode': 'U+1FAD2',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Olive'
	},
	'🥥': {
		'character': '🥥',
		'unicode': 'U+1F965',
		'category': 'food-and-drink',
		'subCategory': 'food-fruit',
		'description': 'Coconut'
	},
	'🥑': {
		'character': '🥑',
		'unicode': 'U+1F951',
		'category': 'food-and-drink',
		'subCategory': 'food-vegetable',
		'description': 'Avocado'
	},
	'🍆': {
		'character': '🍆',
		'unicode': 'U+1F346',
		'category': 'food-and-drink',
		'subCategory': 'food-vegetable',
		'description': 'Eggplant'
	},
	'🥔': {
		'character': '🥔',
		'unicode': 'U+1F954',
		'category': 'food-and-drink',
		'subCategory': 'food-vegetable',
		'description': 'Potato'
	},
	'🥕': {
		'character': '🥕',
		'unicode': 'U+1F955',
		'category': 'food-and-drink',
		'subCategory': 'food-vegetable',
		'description': 'Carrot'
	},
	'🌽': {
		'character': '🌽',
		'unicode': 'U+1F33D',
		'category': 'food-and-drink',
		'subCategory': 'food-vegetable',
		'description': 'Ear of corn'
	},
	'🌶': {
		'character': '🌶',
		'unicode': 'U+1F336',
		'category': 'food-and-drink',
		'subCategory': 'food-vegetable',
		'description': 'Hot pepper'
	},
	'🫑': {
		'character': '🫑',
		'unicode': 'U+1FAD1',
		'category': 'food-and-drink',
		'subCategory': 'food-vegetable',
		'description': 'Bell pepper'
	},
	'🥒': {
		'character': '🥒',
		'unicode': 'U+1F952',
		'category': 'food-and-drink',
		'subCategory': 'food-vegetable',
		'description': 'Cucumber'
	},
	'🥬': {
		'character': '🥬',
		'unicode': 'U+1F96C',
		'category': 'food-and-drink',
		'subCategory': 'food-vegetable',
		'description': 'Leafy green'
	},
	'🥦': {
		'character': '🥦',
		'unicode': 'U+1F966',
		'category': 'food-and-drink',
		'subCategory': 'food-vegetable',
		'description': 'Broccoli'
	},
	'🧄': {
		'character': '🧄',
		'unicode': 'U+1F9C4',
		'category': 'food-and-drink',
		'subCategory': 'food-vegetable',
		'description': 'Garlic'
	},
	'🧅': {
		'character': '🧅',
		'unicode': 'U+1F9C5',
		'category': 'food-and-drink',
		'subCategory': 'food-vegetable',
		'description': 'Onion'
	},
	'🥜': {
		'character': '🥜',
		'unicode': 'U+1F95C',
		'category': 'food-and-drink',
		'subCategory': 'food-vegetable',
		'description': 'Peanuts'
	},
	'🫘': {
		'character': '🫘',
		'unicode': 'U+1FAD8',
		'category': 'food-and-drink',
		'subCategory': 'food-vegetable',
		'description': 'Beans'
	},
	'🌰': {
		'character': '🌰',
		'unicode': 'U+1F330',
		'category': 'food-and-drink',
		'subCategory': 'food-vegetable',
		'description': 'Chestnut'
	},
	'🍞': {
		'character': '🍞',
		'unicode': 'U+1F35E',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Bread'
	},
	'🥐': {
		'character': '🥐',
		'unicode': 'U+1F950',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Croissant'
	},
	'🥖': {
		'character': '🥖',
		'unicode': 'U+1F956',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Baguette bread'
	},
	'🫓': {
		'character': '🫓',
		'unicode': 'U+1FAD3',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Flatbread'
	},
	'🥨': {
		'character': '🥨',
		'unicode': 'U+1F968',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Pretzel'
	},
	'🥯': {
		'character': '🥯',
		'unicode': 'U+1F96F',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Bagel'
	},
	'🥞': {
		'character': '🥞',
		'unicode': 'U+1F95E',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Pancakes'
	},
	'🧇': {
		'character': '🧇',
		'unicode': 'U+1F9C7',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Waffle'
	},
	'🧀': {
		'character': '🧀',
		'unicode': 'U+1F9C0',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Cheese wedge'
	},
	'🍖': {
		'character': '🍖',
		'unicode': 'U+1F356',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Meat on bone'
	},
	'🍗': {
		'character': '🍗',
		'unicode': 'U+1F357',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Poultry leg'
	},
	'🥩': {
		'character': '🥩',
		'unicode': 'U+1F969',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Cut of meat'
	},
	'🥓': {
		'character': '🥓',
		'unicode': 'U+1F953',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Bacon'
	},
	'🍔': {
		'character': '🍔',
		'unicode': 'U+1F354',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Hamburger'
	},
	'🍟': {
		'character': '🍟',
		'unicode': 'U+1F35F',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'French fries'
	},
	'🍕': {
		'character': '🍕',
		'unicode': 'U+1F355',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Pizza'
	},
	'🌭': {
		'character': '🌭',
		'unicode': 'U+1F32D',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Hot dog'
	},
	'🥪': {
		'character': '🥪',
		'unicode': 'U+1F96A',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Sandwich'
	},
	'🌮': {
		'character': '🌮',
		'unicode': 'U+1F32E',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Taco'
	},
	'🌯': {
		'character': '🌯',
		'unicode': 'U+1F32F',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Burrito'
	},
	'🫔': {
		'character': '🫔',
		'unicode': 'U+1FAD4',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Tamale'
	},
	'🥙': {
		'character': '🥙',
		'unicode': 'U+1F959',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Stuffed flatbread'
	},
	'🧆': {
		'character': '🧆',
		'unicode': 'U+1F9C6',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Falafel'
	},
	'🥚': {
		'character': '🥚',
		'unicode': 'U+1F95A',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Egg'
	},
	'🍳': {
		'character': '🍳',
		'unicode': 'U+1F373',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Cooking'
	},
	'🥘': {
		'character': '🥘',
		'unicode': 'U+1F958',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Shallow pan of food'
	},
	'🍲': {
		'character': '🍲',
		'unicode': 'U+1F372',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Pot of food'
	},
	'🫕': {
		'character': '🫕',
		'unicode': 'U+1FAD5',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Fondue'
	},
	'🥣': {
		'character': '🥣',
		'unicode': 'U+1F963',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Bowl with spoon'
	},
	'🥗': {
		'character': '🥗',
		'unicode': 'U+1F957',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Green salad'
	},
	'🍿': {
		'character': '🍿',
		'unicode': 'U+1F37F',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Popcorn'
	},
	'🧈': {
		'character': '🧈',
		'unicode': 'U+1F9C8',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Butter'
	},
	'🧂': {
		'character': '🧂',
		'unicode': 'U+1F9C2',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Salt'
	},
	'🥫': {
		'character': '🥫',
		'unicode': 'U+1F96B',
		'category': 'food-and-drink',
		'subCategory': 'food-prepared',
		'description': 'Canned food'
	},
	'🍱': {
		'character': '🍱',
		'unicode': 'U+1F371',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Bento box'
	},
	'🍘': {
		'character': '🍘',
		'unicode': 'U+1F358',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Rice cracker'
	},
	'🍙': {
		'character': '🍙',
		'unicode': 'U+1F359',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Rice ball'
	},
	'🍚': {
		'character': '🍚',
		'unicode': 'U+1F35A',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Cooked rice'
	},
	'🍛': {
		'character': '🍛',
		'unicode': 'U+1F35B',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Curry rice'
	},
	'🍜': {
		'character': '🍜',
		'unicode': 'U+1F35C',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Steaming bowl'
	},
	'🍝': {
		'character': '🍝',
		'unicode': 'U+1F35D',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Spaghetti'
	},
	'🍠': {
		'character': '🍠',
		'unicode': 'U+1F360',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Roasted sweet potato'
	},
	'🍢': {
		'character': '🍢',
		'unicode': 'U+1F362',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Oden'
	},
	'🍣': {
		'character': '🍣',
		'unicode': 'U+1F363',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Sushi'
	},
	'🍤': {
		'character': '🍤',
		'unicode': 'U+1F364',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Fried shrimp'
	},
	'🍥': {
		'character': '🍥',
		'unicode': 'U+1F365',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Fish cake with swirl'
	},
	'🥮': {
		'character': '🥮',
		'unicode': 'U+1F96E',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Moon cake'
	},
	'🍡': {
		'character': '🍡',
		'unicode': 'U+1F361',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Dango'
	},
	'🥟': {
		'character': '🥟',
		'unicode': 'U+1F95F',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Dumpling'
	},
	'🥠': {
		'character': '🥠',
		'unicode': 'U+1F960',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Fortune cookie'
	},
	'🥡': {
		'character': '🥡',
		'unicode': 'U+1F961',
		'category': 'food-and-drink',
		'subCategory': 'food-asian',
		'description': 'Takeout box'
	},
	'🦀': {
		'character': '🦀',
		'unicode': 'U+1F980',
		'category': 'food-and-drink',
		'subCategory': 'food-marine',
		'description': 'Crab'
	},
	'🦞': {
		'character': '🦞',
		'unicode': 'U+1F99E',
		'category': 'food-and-drink',
		'subCategory': 'food-marine',
		'description': 'Lobster'
	},
	'🦐': {
		'character': '🦐',
		'unicode': 'U+1F990',
		'category': 'food-and-drink',
		'subCategory': 'food-marine',
		'description': 'Shrimp'
	},
	'🦑': {
		'character': '🦑',
		'unicode': 'U+1F991',
		'category': 'food-and-drink',
		'subCategory': 'food-marine',
		'description': 'Squid'
	},
	'🦪': {
		'character': '🦪',
		'unicode': 'U+1F9AA',
		'category': 'food-and-drink',
		'subCategory': 'food-marine',
		'description': 'Oyster'
	},
	'🍦': {
		'character': '🍦',
		'unicode': 'U+1F366',
		'category': 'food-and-drink',
		'subCategory': 'food-sweet',
		'description': 'Soft ice cream'
	},
	'🍧': {
		'character': '🍧',
		'unicode': 'U+1F367',
		'category': 'food-and-drink',
		'subCategory': 'food-sweet',
		'description': 'Shaved ice'
	},
	'🍨': {
		'character': '🍨',
		'unicode': 'U+1F368',
		'category': 'food-and-drink',
		'subCategory': 'food-sweet',
		'description': 'Ice cream'
	},
	'🍩': {
		'character': '🍩',
		'unicode': 'U+1F369',
		'category': 'food-and-drink',
		'subCategory': 'food-sweet',
		'description': 'Doughnut'
	},
	'🍪': {
		'character': '🍪',
		'unicode': 'U+1F36A',
		'category': 'food-and-drink',
		'subCategory': 'food-sweet',
		'description': 'Cookie'
	},
	'🎂': {
		'character': '🎂',
		'unicode': 'U+1F382',
		'category': 'food-and-drink',
		'subCategory': 'food-sweet',
		'description': 'Birthday cake'
	},
	'🍰': {
		'character': '🍰',
		'unicode': 'U+1F370',
		'category': 'food-and-drink',
		'subCategory': 'food-sweet',
		'description': 'Shortcake'
	},
	'🧁': {
		'character': '🧁',
		'unicode': 'U+1F9C1',
		'category': 'food-and-drink',
		'subCategory': 'food-sweet',
		'description': 'Cupcake'
	},
	'🥧': {
		'character': '🥧',
		'unicode': 'U+1F967',
		'category': 'food-and-drink',
		'subCategory': 'food-sweet',
		'description': 'Pie'
	},
	'🍫': {
		'character': '🍫',
		'unicode': 'U+1F36B',
		'category': 'food-and-drink',
		'subCategory': 'food-sweet',
		'description': 'Chocolate bar'
	},
	'🍬': {
		'character': '🍬',
		'unicode': 'U+1F36C',
		'category': 'food-and-drink',
		'subCategory': 'food-sweet',
		'description': 'Candy'
	},
	'🍭': {
		'character': '🍭',
		'unicode': 'U+1F36D',
		'category': 'food-and-drink',
		'subCategory': 'food-sweet',
		'description': 'Lollipop'
	},
	'🍮': {
		'character': '🍮',
		'unicode': 'U+1F36E',
		'category': 'food-and-drink',
		'subCategory': 'food-sweet',
		'description': 'Custard'
	},
	'🍯': {
		'character': '🍯',
		'unicode': 'U+1F36F',
		'category': 'food-and-drink',
		'subCategory': 'food-sweet',
		'description': 'Honey pot'
	},
	'🍼': {
		'character': '🍼',
		'unicode': 'U+1F37C',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Baby bottle'
	},
	'🥛': {
		'character': '🥛',
		'unicode': 'U+1F95B',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Glass of milk'
	},
	'☕': {
		'character': '☕',
		'unicode': 'U+2615',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Hot beverage'
	},
	'🫖': {
		'character': '🫖',
		'unicode': 'U+1FAD6',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Teapot'
	},
	'🍵': {
		'character': '🍵',
		'unicode': 'U+1F375',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Teacup without handle'
	},
	'🍶': {
		'character': '🍶',
		'unicode': 'U+1F376',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Sake'
	},
	'🍾': {
		'character': '🍾',
		'unicode': 'U+1F37E',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Bottle with popping cork'
	},
	'🍷': {
		'character': '🍷',
		'unicode': 'U+1F377',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Wine glass'
	},
	'🍸': {
		'character': '🍸',
		'unicode': 'U+1F378',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Cocktail glass'
	},
	'🍹': {
		'character': '🍹',
		'unicode': 'U+1F379',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Tropical drink'
	},
	'🍺': {
		'character': '🍺',
		'unicode': 'U+1F37A',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Beer mug'
	},
	'🍻': {
		'character': '🍻',
		'unicode': 'U+1F37B',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Clinking beer mugs'
	},
	'🥂': {
		'character': '🥂',
		'unicode': 'U+1F942',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Clinking glasses'
	},
	'🥃': {
		'character': '🥃',
		'unicode': 'U+1F943',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Tumbler glass'
	},
	'🫗': {
		'character': '🫗',
		'unicode': 'U+1FAD7',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Pouring liquid'
	},
	'🥤': {
		'character': '🥤',
		'unicode': 'U+1F964',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Cup with straw'
	},
	'🧋': {
		'character': '🧋',
		'unicode': 'U+1F9CB',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Bubble tea'
	},
	'🧃': {
		'character': '🧃',
		'unicode': 'U+1F9C3',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Beverage box'
	},
	'🧉': {
		'character': '🧉',
		'unicode': 'U+1F9C9',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Mate'
	},
	'🧊': {
		'character': '🧊',
		'unicode': 'U+1F9CA',
		'category': 'food-and-drink',
		'subCategory': 'drink',
		'description': 'Ice'
	},
	'🥢': {
		'character': '🥢',
		'unicode': 'U+1F962',
		'category': 'food-and-drink',
		'subCategory': 'dishware',
		'description': 'Chopsticks'
	},
	'🍽': {
		'character': '🍽',
		'unicode': 'U+1F37D',
		'category': 'food-and-drink',
		'subCategory': 'dishware',
		'description': 'Fork and knife with plate'
	},
	'🍴': {
		'character': '🍴',
		'unicode': 'U+1F374',
		'category': 'food-and-drink',
		'subCategory': 'dishware',
		'description': 'Fork and knife'
	},
	'🥄': {
		'character': '🥄',
		'unicode': 'U+1F944',
		'category': 'food-and-drink',
		'subCategory': 'dishware',
		'description': 'Spoon'
	},
	'🔪': {
		'character': '🔪',
		'unicode': 'U+1F52A',
		'category': 'food-and-drink',
		'subCategory': 'dishware',
		'description': 'Kitchen knife'
	},
	'🫙': {
		'character': '🫙',
		'unicode': 'U+1FAD9',
		'category': 'food-and-drink',
		'subCategory': 'dishware',
		'description': 'Jar'
	},
	'🏺': {
		'character': '🏺',
		'unicode': 'U+1F3FA',
		'category': 'food-and-drink',
		'subCategory': 'dishware',
		'description': 'Amphora'
	},
	'🌍': {
		'character': '🌍',
		'unicode': 'U+1F30D',
		'category': 'travel-and-places',
		'subCategory': 'place-map',
		'description': 'Globe showing europe-africa'
	},
	'🌎': {
		'character': '🌎',
		'unicode': 'U+1F30E',
		'category': 'travel-and-places',
		'subCategory': 'place-map',
		'description': 'Globe showing americas'
	},
	'🌏': {
		'character': '🌏',
		'unicode': 'U+1F30F',
		'category': 'travel-and-places',
		'subCategory': 'place-map',
		'description': 'Globe showing asia-australia'
	},
	'🌐': {
		'character': '🌐',
		'unicode': 'U+1F310',
		'category': 'travel-and-places',
		'subCategory': 'place-map',
		'description': 'Globe with meridians'
	},
	'🗺': {
		'character': '🗺',
		'unicode': 'U+1F5FA',
		'category': 'travel-and-places',
		'subCategory': 'place-map',
		'description': 'World map'
	},
	'🗾': {
		'character': '🗾',
		'unicode': 'U+1F5FE',
		'category': 'travel-and-places',
		'subCategory': 'place-map',
		'description': 'Map of japan'
	},
	'🧭': {
		'character': '🧭',
		'unicode': 'U+1F9ED',
		'category': 'travel-and-places',
		'subCategory': 'place-map',
		'description': 'Compass'
	},
	'🏔': {
		'character': '🏔',
		'unicode': 'U+1F3D4',
		'category': 'travel-and-places',
		'subCategory': 'place-geographic',
		'description': 'Snow-capped mountain'
	},
	'⛰': {
		'character': '⛰',
		'unicode': 'U+26F0',
		'category': 'travel-and-places',
		'subCategory': 'place-geographic',
		'description': 'Mountain'
	},
	'🌋': {
		'character': '🌋',
		'unicode': 'U+1F30B',
		'category': 'travel-and-places',
		'subCategory': 'place-geographic',
		'description': 'Volcano'
	},
	'🗻': {
		'character': '🗻',
		'unicode': 'U+1F5FB',
		'category': 'travel-and-places',
		'subCategory': 'place-geographic',
		'description': 'Mount fuji'
	},
	'🏕': {
		'character': '🏕',
		'unicode': 'U+1F3D5',
		'category': 'travel-and-places',
		'subCategory': 'place-geographic',
		'description': 'Camping'
	},
	'🏖': {
		'character': '🏖',
		'unicode': 'U+1F3D6',
		'category': 'travel-and-places',
		'subCategory': 'place-geographic',
		'description': 'Beach with umbrella'
	},
	'🏜': {
		'character': '🏜',
		'unicode': 'U+1F3DC',
		'category': 'travel-and-places',
		'subCategory': 'place-geographic',
		'description': 'Desert'
	},
	'🏝': {
		'character': '🏝',
		'unicode': 'U+1F3DD',
		'category': 'travel-and-places',
		'subCategory': 'place-geographic',
		'description': 'Desert island'
	},
	'🏞': {
		'character': '🏞',
		'unicode': 'U+1F3DE',
		'category': 'travel-and-places',
		'subCategory': 'place-geographic',
		'description': 'National park'
	},
	'🏟': {
		'character': '🏟',
		'unicode': 'U+1F3DF',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Stadium'
	},
	'🏛': {
		'character': '🏛',
		'unicode': 'U+1F3DB',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Classical building'
	},
	'🏗': {
		'character': '🏗',
		'unicode': 'U+1F3D7',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Building construction'
	},
	'🧱': {
		'character': '🧱',
		'unicode': 'U+1F9F1',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Brick'
	},
	'🪨': {
		'character': '🪨',
		'unicode': 'U+1FAA8',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Rock'
	},
	'🪵': {
		'character': '🪵',
		'unicode': 'U+1FAB5',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Wood'
	},
	'🛖': {
		'character': '🛖',
		'unicode': 'U+1F6D6',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Hut'
	},
	'🏘': {
		'character': '🏘',
		'unicode': 'U+1F3D8',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Houses'
	},
	'🏚': {
		'character': '🏚',
		'unicode': 'U+1F3DA',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Derelict house'
	},
	'🏠': {
		'character': '🏠',
		'unicode': 'U+1F3E0',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'House'
	},
	'🏡': {
		'character': '🏡',
		'unicode': 'U+1F3E1',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'House with garden'
	},
	'🏢': {
		'character': '🏢',
		'unicode': 'U+1F3E2',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Office building'
	},
	'🏣': {
		'character': '🏣',
		'unicode': 'U+1F3E3',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Japanese post office'
	},
	'🏤': {
		'character': '🏤',
		'unicode': 'U+1F3E4',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Post office'
	},
	'🏥': {
		'character': '🏥',
		'unicode': 'U+1F3E5',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Hospital'
	},
	'🏦': {
		'character': '🏦',
		'unicode': 'U+1F3E6',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Bank'
	},
	'🏨': {
		'character': '🏨',
		'unicode': 'U+1F3E8',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Hotel'
	},
	'🏩': {
		'character': '🏩',
		'unicode': 'U+1F3E9',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Love hotel'
	},
	'🏪': {
		'character': '🏪',
		'unicode': 'U+1F3EA',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Convenience store'
	},
	'🏫': {
		'character': '🏫',
		'unicode': 'U+1F3EB',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'School'
	},
	'🏬': {
		'character': '🏬',
		'unicode': 'U+1F3EC',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Department store'
	},
	'🏭': {
		'character': '🏭',
		'unicode': 'U+1F3ED',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Factory'
	},
	'🏯': {
		'character': '🏯',
		'unicode': 'U+1F3EF',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Japanese castle'
	},
	'🏰': {
		'character': '🏰',
		'unicode': 'U+1F3F0',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Castle'
	},
	'💒': {
		'character': '💒',
		'unicode': 'U+1F492',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Wedding'
	},
	'🗼': {
		'character': '🗼',
		'unicode': 'U+1F5FC',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Tokyo tower'
	},
	'🗽': {
		'character': '🗽',
		'unicode': 'U+1F5FD',
		'category': 'travel-and-places',
		'subCategory': 'place-building',
		'description': 'Statue of liberty'
	},
	'⛪': {
		'character': '⛪',
		'unicode': 'U+26EA',
		'category': 'travel-and-places',
		'subCategory': 'place-religious',
		'description': 'Church'
	},
	'🕌': {
		'character': '🕌',
		'unicode': 'U+1F54C',
		'category': 'travel-and-places',
		'subCategory': 'place-religious',
		'description': 'Mosque'
	},
	'🛕': {
		'character': '🛕',
		'unicode': 'U+1F6D5',
		'category': 'travel-and-places',
		'subCategory': 'place-religious',
		'description': 'Hindu temple'
	},
	'🕍': {
		'character': '🕍',
		'unicode': 'U+1F54D',
		'category': 'travel-and-places',
		'subCategory': 'place-religious',
		'description': 'Synagogue'
	},
	'⛩': {
		'character': '⛩',
		'unicode': 'U+26E9',
		'category': 'travel-and-places',
		'subCategory': 'place-religious',
		'description': 'Shinto shrine'
	},
	'🕋': {
		'character': '🕋',
		'unicode': 'U+1F54B',
		'category': 'travel-and-places',
		'subCategory': 'place-religious',
		'description': 'Kaaba'
	},
	'⛲': {
		'character': '⛲',
		'unicode': 'U+26F2',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Fountain'
	},
	'⛺': {
		'character': '⛺',
		'unicode': 'U+26FA',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Tent'
	},
	'🌁': {
		'character': '🌁',
		'unicode': 'U+1F301',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Foggy'
	},
	'🌃': {
		'character': '🌃',
		'unicode': 'U+1F303',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Night with stars'
	},
	'🏙': {
		'character': '🏙',
		'unicode': 'U+1F3D9',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Cityscape'
	},
	'🌄': {
		'character': '🌄',
		'unicode': 'U+1F304',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Sunrise over mountains'
	},
	'🌅': {
		'character': '🌅',
		'unicode': 'U+1F305',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Sunrise'
	},
	'🌆': {
		'character': '🌆',
		'unicode': 'U+1F306',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Cityscape at dusk'
	},
	'🌇': {
		'character': '🌇',
		'unicode': 'U+1F307',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Sunset'
	},
	'🌉': {
		'character': '🌉',
		'unicode': 'U+1F309',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Bridge at night'
	},
	'♨': {
		'character': '♨',
		'unicode': 'U+2668',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Hot springs'
	},
	'🎠': {
		'character': '🎠',
		'unicode': 'U+1F3A0',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Carousel horse'
	},
	'🛝': {
		'character': '🛝',
		'unicode': 'U+1F6DD',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Playground slide'
	},
	'🎡': {
		'character': '🎡',
		'unicode': 'U+1F3A1',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Ferris wheel'
	},
	'🎢': {
		'character': '🎢',
		'unicode': 'U+1F3A2',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Roller coaster'
	},
	'💈': {
		'character': '💈',
		'unicode': 'U+1F488',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Barber pole'
	},
	'🎪': {
		'character': '🎪',
		'unicode': 'U+1F3AA',
		'category': 'travel-and-places',
		'subCategory': 'place-other',
		'description': 'Circus tent'
	},
	'🚂': {
		'character': '🚂',
		'unicode': 'U+1F682',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Locomotive'
	},
	'🚃': {
		'character': '🚃',
		'unicode': 'U+1F683',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Railway car'
	},
	'🚄': {
		'character': '🚄',
		'unicode': 'U+1F684',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'High-speed train'
	},
	'🚅': {
		'character': '🚅',
		'unicode': 'U+1F685',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Bullet train'
	},
	'🚆': {
		'character': '🚆',
		'unicode': 'U+1F686',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Train'
	},
	'🚇': {
		'character': '🚇',
		'unicode': 'U+1F687',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Metro'
	},
	'🚈': {
		'character': '🚈',
		'unicode': 'U+1F688',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Light rail'
	},
	'🚉': {
		'character': '🚉',
		'unicode': 'U+1F689',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Station'
	},
	'🚊': {
		'character': '🚊',
		'unicode': 'U+1F68A',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Tram'
	},
	'🚝': {
		'character': '🚝',
		'unicode': 'U+1F69D',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Monorail'
	},
	'🚞': {
		'character': '🚞',
		'unicode': 'U+1F69E',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Mountain railway'
	},
	'🚋': {
		'character': '🚋',
		'unicode': 'U+1F68B',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Tram car'
	},
	'🚌': {
		'character': '🚌',
		'unicode': 'U+1F68C',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Bus'
	},
	'🚍': {
		'character': '🚍',
		'unicode': 'U+1F68D',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Oncoming bus'
	},
	'🚎': {
		'character': '🚎',
		'unicode': 'U+1F68E',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Trolleybus'
	},
	'🚐': {
		'character': '🚐',
		'unicode': 'U+1F690',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Minibus'
	},
	'🚑': {
		'character': '🚑',
		'unicode': 'U+1F691',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Ambulance'
	},
	'🚒': {
		'character': '🚒',
		'unicode': 'U+1F692',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Fire engine'
	},
	'🚓': {
		'character': '🚓',
		'unicode': 'U+1F693',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Police car'
	},
	'🚔': {
		'character': '🚔',
		'unicode': 'U+1F694',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Oncoming police car'
	},
	'🚕': {
		'character': '🚕',
		'unicode': 'U+1F695',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Taxi'
	},
	'🚖': {
		'character': '🚖',
		'unicode': 'U+1F696',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Oncoming taxi'
	},
	'🚗': {
		'character': '🚗',
		'unicode': 'U+1F697',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Automobile'
	},
	'🚘': {
		'character': '🚘',
		'unicode': 'U+1F698',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Oncoming automobile'
	},
	'🚙': {
		'character': '🚙',
		'unicode': 'U+1F699',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Sport utility vehicle'
	},
	'🛻': {
		'character': '🛻',
		'unicode': 'U+1F6FB',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Pickup truck'
	},
	'🚚': {
		'character': '🚚',
		'unicode': 'U+1F69A',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Delivery truck'
	},
	'🚛': {
		'character': '🚛',
		'unicode': 'U+1F69B',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Articulated lorry'
	},
	'🚜': {
		'character': '🚜',
		'unicode': 'U+1F69C',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Tractor'
	},
	'🏎': {
		'character': '🏎',
		'unicode': 'U+1F3CE',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Racing car'
	},
	'🏍': {
		'character': '🏍',
		'unicode': 'U+1F3CD',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Motorcycle'
	},
	'🛵': {
		'character': '🛵',
		'unicode': 'U+1F6F5',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Motor scooter'
	},
	'🦽': {
		'character': '🦽',
		'unicode': 'U+1F9BD',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Manual wheelchair'
	},
	'🦼': {
		'character': '🦼',
		'unicode': 'U+1F9BC',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Motorized wheelchair'
	},
	'🛺': {
		'character': '🛺',
		'unicode': 'U+1F6FA',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Auto rickshaw'
	},
	'🚲': {
		'character': '🚲',
		'unicode': 'U+1F6B2',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Bicycle'
	},
	'🛴': {
		'character': '🛴',
		'unicode': 'U+1F6F4',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Kick scooter'
	},
	'🛹': {
		'character': '🛹',
		'unicode': 'U+1F6F9',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Skateboard'
	},
	'🛼': {
		'character': '🛼',
		'unicode': 'U+1F6FC',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Roller skate'
	},
	'🚏': {
		'character': '🚏',
		'unicode': 'U+1F68F',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Bus stop'
	},
	'🛣': {
		'character': '🛣',
		'unicode': 'U+1F6E3',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Motorway'
	},
	'🛤': {
		'character': '🛤',
		'unicode': 'U+1F6E4',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Railway track'
	},
	'🛢': {
		'character': '🛢',
		'unicode': 'U+1F6E2',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Oil drum'
	},
	'⛽': {
		'character': '⛽',
		'unicode': 'U+26FD',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Fuel pump'
	},
	'🛞': {
		'character': '🛞',
		'unicode': 'U+1F6DE',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Wheel'
	},
	'🚨': {
		'character': '🚨',
		'unicode': 'U+1F6A8',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Police car light'
	},
	'🚥': {
		'character': '🚥',
		'unicode': 'U+1F6A5',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Horizontal traffic light'
	},
	'🚦': {
		'character': '🚦',
		'unicode': 'U+1F6A6',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Vertical traffic light'
	},
	'🛑': {
		'character': '🛑',
		'unicode': 'U+1F6D1',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Stop sign'
	},
	'🚧': {
		'character': '🚧',
		'unicode': 'U+1F6A7',
		'category': 'travel-and-places',
		'subCategory': 'transport-ground',
		'description': 'Construction'
	},
	'⚓': {
		'character': '⚓',
		'unicode': 'U+2693',
		'category': 'travel-and-places',
		'subCategory': 'transport-water',
		'description': 'Anchor'
	},
	'🛟': {
		'character': '🛟',
		'unicode': 'U+1F6DF',
		'category': 'travel-and-places',
		'subCategory': 'transport-water',
		'description': 'Ring buoy'
	},
	'⛵': {
		'character': '⛵',
		'unicode': 'U+26F5',
		'category': 'travel-and-places',
		'subCategory': 'transport-water',
		'description': 'Sailboat'
	},
	'🛶': {
		'character': '🛶',
		'unicode': 'U+1F6F6',
		'category': 'travel-and-places',
		'subCategory': 'transport-water',
		'description': 'Canoe'
	},
	'🚤': {
		'character': '🚤',
		'unicode': 'U+1F6A4',
		'category': 'travel-and-places',
		'subCategory': 'transport-water',
		'description': 'Speedboat'
	},
	'🛳': {
		'character': '🛳',
		'unicode': 'U+1F6F3',
		'category': 'travel-and-places',
		'subCategory': 'transport-water',
		'description': 'Passenger ship'
	},
	'⛴': {
		'character': '⛴',
		'unicode': 'U+26F4',
		'category': 'travel-and-places',
		'subCategory': 'transport-water',
		'description': 'Ferry'
	},
	'🛥': {
		'character': '🛥',
		'unicode': 'U+1F6E5',
		'category': 'travel-and-places',
		'subCategory': 'transport-water',
		'description': 'Motor boat'
	},
	'🚢': {
		'character': '🚢',
		'unicode': 'U+1F6A2',
		'category': 'travel-and-places',
		'subCategory': 'transport-water',
		'description': 'Ship'
	},
	'✈': {
		'character': '✈',
		'unicode': 'U+2708',
		'category': 'travel-and-places',
		'subCategory': 'transport-air',
		'description': 'Airplane'
	},
	'🛩': {
		'character': '🛩',
		'unicode': 'U+1F6E9',
		'category': 'travel-and-places',
		'subCategory': 'transport-air',
		'description': 'Small airplane'
	},
	'🛫': {
		'character': '🛫',
		'unicode': 'U+1F6EB',
		'category': 'travel-and-places',
		'subCategory': 'transport-air',
		'description': 'Airplane departure'
	},
	'🛬': {
		'character': '🛬',
		'unicode': 'U+1F6EC',
		'category': 'travel-and-places',
		'subCategory': 'transport-air',
		'description': 'Airplane arrival'
	},
	'🪂': {
		'character': '🪂',
		'unicode': 'U+1FA82',
		'category': 'travel-and-places',
		'subCategory': 'transport-air',
		'description': 'Parachute'
	},
	'💺': {
		'character': '💺',
		'unicode': 'U+1F4BA',
		'category': 'travel-and-places',
		'subCategory': 'transport-air',
		'description': 'Seat'
	},
	'🚁': {
		'character': '🚁',
		'unicode': 'U+1F681',
		'category': 'travel-and-places',
		'subCategory': 'transport-air',
		'description': 'Helicopter'
	},
	'🚟': {
		'character': '🚟',
		'unicode': 'U+1F69F',
		'category': 'travel-and-places',
		'subCategory': 'transport-air',
		'description': 'Suspension railway'
	},
	'🚠': {
		'character': '🚠',
		'unicode': 'U+1F6A0',
		'category': 'travel-and-places',
		'subCategory': 'transport-air',
		'description': 'Mountain cableway'
	},
	'🚡': {
		'character': '🚡',
		'unicode': 'U+1F6A1',
		'category': 'travel-and-places',
		'subCategory': 'transport-air',
		'description': 'Aerial tramway'
	},
	'🛰': {
		'character': '🛰',
		'unicode': 'U+1F6F0',
		'category': 'travel-and-places',
		'subCategory': 'transport-air',
		'description': 'Satellite'
	},
	'🚀': {
		'character': '🚀',
		'unicode': 'U+1F680',
		'category': 'travel-and-places',
		'subCategory': 'transport-air',
		'description': 'Rocket'
	},
	'🛸': {
		'character': '🛸',
		'unicode': 'U+1F6F8',
		'category': 'travel-and-places',
		'subCategory': 'transport-air',
		'description': 'Flying saucer'
	},
	'🛎': {
		'character': '🛎',
		'unicode': 'U+1F6CE',
		'category': 'travel-and-places',
		'subCategory': 'hotel',
		'description': 'Bellhop bell'
	},
	'🧳': {
		'character': '🧳',
		'unicode': 'U+1F9F3',
		'category': 'travel-and-places',
		'subCategory': 'hotel',
		'description': 'Luggage'
	},
	'⌛': {
		'character': '⌛',
		'unicode': 'U+231B',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Hourglass done'
	},
	'⏳': {
		'character': '⏳',
		'unicode': 'U+23F3',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Hourglass not done'
	},
	'⌚': {
		'character': '⌚',
		'unicode': 'U+231A',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Watch'
	},
	'⏰': {
		'character': '⏰',
		'unicode': 'U+23F0',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Alarm clock'
	},
	'⏱': {
		'character': '⏱',
		'unicode': 'U+23F1',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Stopwatch'
	},
	'⏲': {
		'character': '⏲',
		'unicode': 'U+23F2',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Timer clock'
	},
	'🕰': {
		'character': '🕰',
		'unicode': 'U+1F570',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Mantelpiece clock'
	},
	'🕛': {
		'character': '🕛',
		'unicode': 'U+1F55B',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Twelve o’clock'
	},
	'🕧': {
		'character': '🕧',
		'unicode': 'U+1F567',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Twelve-thirty'
	},
	'🕐': {
		'character': '🕐',
		'unicode': 'U+1F550',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'One o’clock'
	},
	'🕜': {
		'character': '🕜',
		'unicode': 'U+1F55C',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'One-thirty'
	},
	'🕑': {
		'character': '🕑',
		'unicode': 'U+1F551',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Two o’clock'
	},
	'🕝': {
		'character': '🕝',
		'unicode': 'U+1F55D',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Two-thirty'
	},
	'🕒': {
		'character': '🕒',
		'unicode': 'U+1F552',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Three o’clock'
	},
	'🕞': {
		'character': '🕞',
		'unicode': 'U+1F55E',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Three-thirty'
	},
	'🕓': {
		'character': '🕓',
		'unicode': 'U+1F553',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Four o’clock'
	},
	'🕟': {
		'character': '🕟',
		'unicode': 'U+1F55F',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Four-thirty'
	},
	'🕔': {
		'character': '🕔',
		'unicode': 'U+1F554',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Five o’clock'
	},
	'🕠': {
		'character': '🕠',
		'unicode': 'U+1F560',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Five-thirty'
	},
	'🕕': {
		'character': '🕕',
		'unicode': 'U+1F555',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Six o’clock'
	},
	'🕡': {
		'character': '🕡',
		'unicode': 'U+1F561',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Six-thirty'
	},
	'🕖': {
		'character': '🕖',
		'unicode': 'U+1F556',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Seven o’clock'
	},
	'🕢': {
		'character': '🕢',
		'unicode': 'U+1F562',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Seven-thirty'
	},
	'🕗': {
		'character': '🕗',
		'unicode': 'U+1F557',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Eight o’clock'
	},
	'🕣': {
		'character': '🕣',
		'unicode': 'U+1F563',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Eight-thirty'
	},
	'🕘': {
		'character': '🕘',
		'unicode': 'U+1F558',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Nine o’clock'
	},
	'🕤': {
		'character': '🕤',
		'unicode': 'U+1F564',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Nine-thirty'
	},
	'🕙': {
		'character': '🕙',
		'unicode': 'U+1F559',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Ten o’clock'
	},
	'🕥': {
		'character': '🕥',
		'unicode': 'U+1F565',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Ten-thirty'
	},
	'🕚': {
		'character': '🕚',
		'unicode': 'U+1F55A',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Eleven o’clock'
	},
	'🕦': {
		'character': '🕦',
		'unicode': 'U+1F566',
		'category': 'travel-and-places',
		'subCategory': 'time',
		'description': 'Eleven-thirty'
	},
	'🌑': {
		'character': '🌑',
		'unicode': 'U+1F311',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'New moon'
	},
	'🌒': {
		'character': '🌒',
		'unicode': 'U+1F312',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Waxing crescent moon'
	},
	'🌓': {
		'character': '🌓',
		'unicode': 'U+1F313',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'First quarter moon'
	},
	'🌔': {
		'character': '🌔',
		'unicode': 'U+1F314',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Waxing gibbous moon'
	},
	'🌕': {
		'character': '🌕',
		'unicode': 'U+1F315',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Full moon'
	},
	'🌖': {
		'character': '🌖',
		'unicode': 'U+1F316',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Waning gibbous moon'
	},
	'🌗': {
		'character': '🌗',
		'unicode': 'U+1F317',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Last quarter moon'
	},
	'🌘': {
		'character': '🌘',
		'unicode': 'U+1F318',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Waning crescent moon'
	},
	'🌙': {
		'character': '🌙',
		'unicode': 'U+1F319',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Crescent moon'
	},
	'🌚': {
		'character': '🌚',
		'unicode': 'U+1F31A',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'New moon face'
	},
	'🌛': {
		'character': '🌛',
		'unicode': 'U+1F31B',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'First quarter moon face'
	},
	'🌜': {
		'character': '🌜',
		'unicode': 'U+1F31C',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Last quarter moon face'
	},
	'🌡': {
		'character': '🌡',
		'unicode': 'U+1F321',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Thermometer'
	},
	'☀': {
		'character': '☀',
		'unicode': 'U+2600',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Sun'
	},
	'🌝': {
		'character': '🌝',
		'unicode': 'U+1F31D',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Full moon face'
	},
	'🌞': {
		'character': '🌞',
		'unicode': 'U+1F31E',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Sun with face'
	},
	'🪐': {
		'character': '🪐',
		'unicode': 'U+1FA90',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Ringed planet'
	},
	'⭐': {
		'character': '⭐',
		'unicode': 'U+2B50',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Star'
	},
	'🌟': {
		'character': '🌟',
		'unicode': 'U+1F31F',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Glowing star'
	},
	'🌠': {
		'character': '🌠',
		'unicode': 'U+1F320',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Shooting star'
	},
	'🌌': {
		'character': '🌌',
		'unicode': 'U+1F30C',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Milky way'
	},
	'☁': {
		'character': '☁',
		'unicode': 'U+2601',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Cloud'
	},
	'⛅': {
		'character': '⛅',
		'unicode': 'U+26C5',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Sun behind cloud'
	},
	'⛈': {
		'character': '⛈',
		'unicode': 'U+26C8',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Cloud with lightning and rain'
	},
	'🌤': {
		'character': '🌤',
		'unicode': 'U+1F324',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Sun behind small cloud'
	},
	'🌥': {
		'character': '🌥',
		'unicode': 'U+1F325',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Sun behind large cloud'
	},
	'🌦': {
		'character': '🌦',
		'unicode': 'U+1F326',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Sun behind rain cloud'
	},
	'🌧': {
		'character': '🌧',
		'unicode': 'U+1F327',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Cloud with rain'
	},
	'🌨': {
		'character': '🌨',
		'unicode': 'U+1F328',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Cloud with snow'
	},
	'🌩': {
		'character': '🌩',
		'unicode': 'U+1F329',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Cloud with lightning'
	},
	'🌪': {
		'character': '🌪',
		'unicode': 'U+1F32A',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Tornado'
	},
	'🌫': {
		'character': '🌫',
		'unicode': 'U+1F32B',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Fog'
	},
	'🌬': {
		'character': '🌬',
		'unicode': 'U+1F32C',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Wind face'
	},
	'🌀': {
		'character': '🌀',
		'unicode': 'U+1F300',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Cyclone'
	},
	'🌈': {
		'character': '🌈',
		'unicode': 'U+1F308',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Rainbow'
	},
	'🌂': {
		'character': '🌂',
		'unicode': 'U+1F302',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Closed umbrella'
	},
	'☂': {
		'character': '☂',
		'unicode': 'U+2602',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Umbrella'
	},
	'☔': {
		'character': '☔',
		'unicode': 'U+2614',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Umbrella with rain drops'
	},
	'⛱': {
		'character': '⛱',
		'unicode': 'U+26F1',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Umbrella on ground'
	},
	'⚡': {
		'character': '⚡',
		'unicode': 'U+26A1',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'High voltage'
	},
	'❄': {
		'character': '❄',
		'unicode': 'U+2744',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Snowflake'
	},
	'☃': {
		'character': '☃',
		'unicode': 'U+2603',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Snowman'
	},
	'⛄': {
		'character': '⛄',
		'unicode': 'U+26C4',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Snowman without snow'
	},
	'☄': {
		'character': '☄',
		'unicode': 'U+2604',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Comet'
	},
	'🔥': {
		'character': '🔥',
		'unicode': 'U+1F525',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Fire'
	},
	'💧': {
		'character': '💧',
		'unicode': 'U+1F4A7',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Droplet'
	},
	'🌊': {
		'character': '🌊',
		'unicode': 'U+1F30A',
		'category': 'travel-and-places',
		'subCategory': 'sky & weather',
		'description': 'Water wave'
	},
	'🎃': {
		'character': '🎃',
		'unicode': 'U+1F383',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Jack-o-lantern'
	},
	'🎄': {
		'character': '🎄',
		'unicode': 'U+1F384',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Christmas tree'
	},
	'🎆': {
		'character': '🎆',
		'unicode': 'U+1F386',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Fireworks'
	},
	'🎇': {
		'character': '🎇',
		'unicode': 'U+1F387',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Sparkler'
	},
	'🧨': {
		'character': '🧨',
		'unicode': 'U+1F9E8',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Firecracker'
	},
	'✨': {
		'character': '✨',
		'unicode': 'U+2728',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Sparkles'
	},
	'🎈': {
		'character': '🎈',
		'unicode': 'U+1F388',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Balloon'
	},
	'🎉': {
		'character': '🎉',
		'unicode': 'U+1F389',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Party popper'
	},
	'🎊': {
		'character': '🎊',
		'unicode': 'U+1F38A',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Confetti ball'
	},
	'🎋': {
		'character': '🎋',
		'unicode': 'U+1F38B',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Tanabata tree'
	},
	'🎍': {
		'character': '🎍',
		'unicode': 'U+1F38D',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Pine decoration'
	},
	'🎎': {
		'character': '🎎',
		'unicode': 'U+1F38E',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Japanese dolls'
	},
	'🎏': {
		'character': '🎏',
		'unicode': 'U+1F38F',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Carp streamer'
	},
	'🎐': {
		'character': '🎐',
		'unicode': 'U+1F390',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Wind chime'
	},
	'🎑': {
		'character': '🎑',
		'unicode': 'U+1F391',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Moon viewing ceremony'
	},
	'🧧': {
		'character': '🧧',
		'unicode': 'U+1F9E7',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Red envelope'
	},
	'🎀': {
		'character': '🎀',
		'unicode': 'U+1F380',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Ribbon'
	},
	'🎁': {
		'character': '🎁',
		'unicode': 'U+1F381',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Wrapped gift'
	},
	'🎗': {
		'character': '🎗',
		'unicode': 'U+1F397',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Reminder ribbon'
	},
	'🎟': {
		'character': '🎟',
		'unicode': 'U+1F39F',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Admission tickets'
	},
	'🎫': {
		'character': '🎫',
		'unicode': 'U+1F3AB',
		'category': 'activities',
		'subCategory': 'event',
		'description': 'Ticket'
	},
	'🎖': {
		'character': '🎖',
		'unicode': 'U+1F396',
		'category': 'activities',
		'subCategory': 'award-medal',
		'description': 'Military medal'
	},
	'🏆': {
		'character': '🏆',
		'unicode': 'U+1F3C6',
		'category': 'activities',
		'subCategory': 'award-medal',
		'description': 'Trophy'
	},
	'🏅': {
		'character': '🏅',
		'unicode': 'U+1F3C5',
		'category': 'activities',
		'subCategory': 'award-medal',
		'description': 'Sports medal'
	},
	'🥇': {
		'character': '🥇',
		'unicode': 'U+1F947',
		'category': 'activities',
		'subCategory': 'award-medal',
		'description': '1st place medal'
	},
	'🥈': {
		'character': '🥈',
		'unicode': 'U+1F948',
		'category': 'activities',
		'subCategory': 'award-medal',
		'description': '2nd place medal'
	},
	'🥉': {
		'character': '🥉',
		'unicode': 'U+1F949',
		'category': 'activities',
		'subCategory': 'award-medal',
		'description': '3rd place medal'
	},
	'⚽': {
		'character': '⚽',
		'unicode': 'U+26BD',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Soccer ball'
	},
	'⚾': {
		'character': '⚾',
		'unicode': 'U+26BE',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Baseball'
	},
	'🥎': {
		'character': '🥎',
		'unicode': 'U+1F94E',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Softball'
	},
	'🏀': {
		'character': '🏀',
		'unicode': 'U+1F3C0',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Basketball'
	},
	'🏐': {
		'character': '🏐',
		'unicode': 'U+1F3D0',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Volleyball'
	},
	'🏈': {
		'character': '🏈',
		'unicode': 'U+1F3C8',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'American football'
	},
	'🏉': {
		'character': '🏉',
		'unicode': 'U+1F3C9',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Rugby football'
	},
	'🎾': {
		'character': '🎾',
		'unicode': 'U+1F3BE',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Tennis'
	},
	'🥏': {
		'character': '🥏',
		'unicode': 'U+1F94F',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Flying disc'
	},
	'🎳': {
		'character': '🎳',
		'unicode': 'U+1F3B3',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Bowling'
	},
	'🏏': {
		'character': '🏏',
		'unicode': 'U+1F3CF',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Cricket game'
	},
	'🏑': {
		'character': '🏑',
		'unicode': 'U+1F3D1',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Field hockey'
	},
	'🏒': {
		'character': '🏒',
		'unicode': 'U+1F3D2',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Ice hockey'
	},
	'🥍': {
		'character': '🥍',
		'unicode': 'U+1F94D',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Lacrosse'
	},
	'🏓': {
		'character': '🏓',
		'unicode': 'U+1F3D3',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Ping pong'
	},
	'🏸': {
		'character': '🏸',
		'unicode': 'U+1F3F8',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Badminton'
	},
	'🥊': {
		'character': '🥊',
		'unicode': 'U+1F94A',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Boxing glove'
	},
	'🥋': {
		'character': '🥋',
		'unicode': 'U+1F94B',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Martial arts uniform'
	},
	'🥅': {
		'character': '🥅',
		'unicode': 'U+1F945',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Goal net'
	},
	'⛳': {
		'character': '⛳',
		'unicode': 'U+26F3',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Flag in hole'
	},
	'⛸': {
		'character': '⛸',
		'unicode': 'U+26F8',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Ice skate'
	},
	'🎣': {
		'character': '🎣',
		'unicode': 'U+1F3A3',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Fishing pole'
	},
	'🤿': {
		'character': '🤿',
		'unicode': 'U+1F93F',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Diving mask'
	},
	'🎽': {
		'character': '🎽',
		'unicode': 'U+1F3BD',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Running shirt'
	},
	'🎿': {
		'character': '🎿',
		'unicode': 'U+1F3BF',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Skis'
	},
	'🛷': {
		'character': '🛷',
		'unicode': 'U+1F6F7',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Sled'
	},
	'🥌': {
		'character': '🥌',
		'unicode': 'U+1F94C',
		'category': 'activities',
		'subCategory': 'sport',
		'description': 'Curling stone'
	},
	'🎯': {
		'character': '🎯',
		'unicode': 'U+1F3AF',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Bullseye'
	},
	'🪀': {
		'character': '🪀',
		'unicode': 'U+1FA80',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Yo-yo'
	},
	'🪁': {
		'character': '🪁',
		'unicode': 'U+1FA81',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Kite'
	},
	'🔫': {
		'character': '🔫',
		'unicode': 'U+1F52B',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Water pistol'
	},
	'🎱': {
		'character': '🎱',
		'unicode': 'U+1F3B1',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Pool 8 ball'
	},
	'🔮': {
		'character': '🔮',
		'unicode': 'U+1F52E',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Crystal ball'
	},
	'🪄': {
		'character': '🪄',
		'unicode': 'U+1FA84',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Magic wand'
	},
	'🎮': {
		'character': '🎮',
		'unicode': 'U+1F3AE',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Video game'
	},
	'🕹': {
		'character': '🕹',
		'unicode': 'U+1F579',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Joystick'
	},
	'🎰': {
		'character': '🎰',
		'unicode': 'U+1F3B0',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Slot machine'
	},
	'🎲': {
		'character': '🎲',
		'unicode': 'U+1F3B2',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Game die'
	},
	'🧩': {
		'character': '🧩',
		'unicode': 'U+1F9E9',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Puzzle piece'
	},
	'🧸': {
		'character': '🧸',
		'unicode': 'U+1F9F8',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Teddy bear'
	},
	'🪅': {
		'character': '🪅',
		'unicode': 'U+1FA85',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Piñata'
	},
	'🪩': {
		'character': '🪩',
		'unicode': 'U+1FAA9',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Mirror ball'
	},
	'🪆': {
		'character': '🪆',
		'unicode': 'U+1FA86',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Nesting dolls'
	},
	'♠': {
		'character': '♠',
		'unicode': 'U+2660',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Spade suit'
	},
	'♥': {
		'character': '♥',
		'unicode': 'U+2665',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Heart suit'
	},
	'♦': {
		'character': '♦',
		'unicode': 'U+2666',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Diamond suit'
	},
	'♣': {
		'character': '♣',
		'unicode': 'U+2663',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Club suit'
	},
	'♟': {
		'character': '♟',
		'unicode': 'U+265F',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Chess pawn'
	},
	'🃏': {
		'character': '🃏',
		'unicode': 'U+1F0CF',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Joker'
	},
	'🀄': {
		'character': '🀄',
		'unicode': 'U+1F004',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Mahjong red dragon'
	},
	'🎴': {
		'character': '🎴',
		'unicode': 'U+1F3B4',
		'category': 'activities',
		'subCategory': 'game',
		'description': 'Flower playing cards'
	},
	'🎭': {
		'character': '🎭',
		'unicode': 'U+1F3AD',
		'category': 'activities',
		'subCategory': 'arts & crafts',
		'description': 'Performing arts'
	},
	'🖼': {
		'character': '🖼',
		'unicode': 'U+1F5BC',
		'category': 'activities',
		'subCategory': 'arts & crafts',
		'description': 'Framed picture'
	},
	'🎨': {
		'character': '🎨',
		'unicode': 'U+1F3A8',
		'category': 'activities',
		'subCategory': 'arts & crafts',
		'description': 'Artist palette'
	},
	'🧵': {
		'character': '🧵',
		'unicode': 'U+1F9F5',
		'category': 'activities',
		'subCategory': 'arts & crafts',
		'description': 'Thread'
	},
	'🪡': {
		'character': '🪡',
		'unicode': 'U+1FAA1',
		'category': 'activities',
		'subCategory': 'arts & crafts',
		'description': 'Sewing needle'
	},
	'🧶': {
		'character': '🧶',
		'unicode': 'U+1F9F6',
		'category': 'activities',
		'subCategory': 'arts & crafts',
		'description': 'Yarn'
	},
	'🪢': {
		'character': '🪢',
		'unicode': 'U+1FAA2',
		'category': 'activities',
		'subCategory': 'arts & crafts',
		'description': 'Knot'
	},
	'👓': {
		'character': '👓',
		'unicode': 'U+1F453',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Glasses'
	},
	'🕶': {
		'character': '🕶',
		'unicode': 'U+1F576',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Sunglasses'
	},
	'🥽': {
		'character': '🥽',
		'unicode': 'U+1F97D',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Goggles'
	},
	'🥼': {
		'character': '🥼',
		'unicode': 'U+1F97C',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Lab coat'
	},
	'🦺': {
		'character': '🦺',
		'unicode': 'U+1F9BA',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Safety vest'
	},
	'👔': {
		'character': '👔',
		'unicode': 'U+1F454',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Necktie'
	},
	'👕': {
		'character': '👕',
		'unicode': 'U+1F455',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'T-shirt'
	},
	'👖': {
		'character': '👖',
		'unicode': 'U+1F456',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Jeans'
	},
	'🧣': {
		'character': '🧣',
		'unicode': 'U+1F9E3',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Scarf'
	},
	'🧤': {
		'character': '🧤',
		'unicode': 'U+1F9E4',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Gloves'
	},
	'🧥': {
		'character': '🧥',
		'unicode': 'U+1F9E5',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Coat'
	},
	'🧦': {
		'character': '🧦',
		'unicode': 'U+1F9E6',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Socks'
	},
	'👗': {
		'character': '👗',
		'unicode': 'U+1F457',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Dress'
	},
	'👘': {
		'character': '👘',
		'unicode': 'U+1F458',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Kimono'
	},
	'🥻': {
		'character': '🥻',
		'unicode': 'U+1F97B',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Sari'
	},
	'🩱': {
		'character': '🩱',
		'unicode': 'U+1FA71',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'One-piece swimsuit'
	},
	'🩲': {
		'character': '🩲',
		'unicode': 'U+1FA72',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Briefs'
	},
	'🩳': {
		'character': '🩳',
		'unicode': 'U+1FA73',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Shorts'
	},
	'👙': {
		'character': '👙',
		'unicode': 'U+1F459',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Bikini'
	},
	'👚': {
		'character': '👚',
		'unicode': 'U+1F45A',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Woman’s clothes'
	},
	'👛': {
		'character': '👛',
		'unicode': 'U+1F45B',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Purse'
	},
	'👜': {
		'character': '👜',
		'unicode': 'U+1F45C',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Handbag'
	},
	'👝': {
		'character': '👝',
		'unicode': 'U+1F45D',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Clutch bag'
	},
	'🛍': {
		'character': '🛍',
		'unicode': 'U+1F6CD',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Shopping bags'
	},
	'🎒': {
		'character': '🎒',
		'unicode': 'U+1F392',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Backpack'
	},
	'🩴': {
		'character': '🩴',
		'unicode': 'U+1FA74',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Thong sandal'
	},
	'👞': {
		'character': '👞',
		'unicode': 'U+1F45E',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Man’s shoe'
	},
	'👟': {
		'character': '👟',
		'unicode': 'U+1F45F',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Running shoe'
	},
	'🥾': {
		'character': '🥾',
		'unicode': 'U+1F97E',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Hiking boot'
	},
	'🥿': {
		'character': '🥿',
		'unicode': 'U+1F97F',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Flat shoe'
	},
	'👠': {
		'character': '👠',
		'unicode': 'U+1F460',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'High-heeled shoe'
	},
	'👡': {
		'character': '👡',
		'unicode': 'U+1F461',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Woman’s sandal'
	},
	'🩰': {
		'character': '🩰',
		'unicode': 'U+1FA70',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Ballet shoes'
	},
	'👢': {
		'character': '👢',
		'unicode': 'U+1F462',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Woman’s boot'
	},
	'👑': {
		'character': '👑',
		'unicode': 'U+1F451',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Crown'
	},
	'👒': {
		'character': '👒',
		'unicode': 'U+1F452',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Woman’s hat'
	},
	'🎩': {
		'character': '🎩',
		'unicode': 'U+1F3A9',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Top hat'
	},
	'🎓': {
		'character': '🎓',
		'unicode': 'U+1F393',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Graduation cap'
	},
	'🧢': {
		'character': '🧢',
		'unicode': 'U+1F9E2',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Billed cap'
	},
	'🪖': {
		'character': '🪖',
		'unicode': 'U+1FA96',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Military helmet'
	},
	'⛑': {
		'character': '⛑',
		'unicode': 'U+26D1',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Rescue worker’s helmet'
	},
	'📿': {
		'character': '📿',
		'unicode': 'U+1F4FF',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Prayer beads'
	},
	'💄': {
		'character': '💄',
		'unicode': 'U+1F484',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Lipstick'
	},
	'💍': {
		'character': '💍',
		'unicode': 'U+1F48D',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Ring'
	},
	'💎': {
		'character': '💎',
		'unicode': 'U+1F48E',
		'category': 'objects',
		'subCategory': 'clothing',
		'description': 'Gem stone'
	},
	'🔇': {
		'character': '🔇',
		'unicode': 'U+1F507',
		'category': 'objects',
		'subCategory': 'sound',
		'description': 'Muted speaker'
	},
	'🔈': {
		'character': '🔈',
		'unicode': 'U+1F508',
		'category': 'objects',
		'subCategory': 'sound',
		'description': 'Speaker low volume'
	},
	'🔉': {
		'character': '🔉',
		'unicode': 'U+1F509',
		'category': 'objects',
		'subCategory': 'sound',
		'description': 'Speaker medium volume'
	},
	'🔊': {
		'character': '🔊',
		'unicode': 'U+1F50A',
		'category': 'objects',
		'subCategory': 'sound',
		'description': 'Speaker high volume'
	},
	'📢': {
		'character': '📢',
		'unicode': 'U+1F4E2',
		'category': 'objects',
		'subCategory': 'sound',
		'description': 'Loudspeaker'
	},
	'📣': {
		'character': '📣',
		'unicode': 'U+1F4E3',
		'category': 'objects',
		'subCategory': 'sound',
		'description': 'Megaphone'
	},
	'📯': {
		'character': '📯',
		'unicode': 'U+1F4EF',
		'category': 'objects',
		'subCategory': 'sound',
		'description': 'Postal horn'
	},
	'🔔': {
		'character': '🔔',
		'unicode': 'U+1F514',
		'category': 'objects',
		'subCategory': 'sound',
		'description': 'Bell'
	},
	'🔕': {
		'character': '🔕',
		'unicode': 'U+1F515',
		'category': 'objects',
		'subCategory': 'sound',
		'description': 'Bell with slash'
	},
	'🎼': {
		'character': '🎼',
		'unicode': 'U+1F3BC',
		'category': 'objects',
		'subCategory': 'music',
		'description': 'Musical score'
	},
	'🎵': {
		'character': '🎵',
		'unicode': 'U+1F3B5',
		'category': 'objects',
		'subCategory': 'music',
		'description': 'Musical note'
	},
	'🎶': {
		'character': '🎶',
		'unicode': 'U+1F3B6',
		'category': 'objects',
		'subCategory': 'music',
		'description': 'Musical notes'
	},
	'🎙': {
		'character': '🎙',
		'unicode': 'U+1F399',
		'category': 'objects',
		'subCategory': 'music',
		'description': 'Studio microphone'
	},
	'🎚': {
		'character': '🎚',
		'unicode': 'U+1F39A',
		'category': 'objects',
		'subCategory': 'music',
		'description': 'Level slider'
	},
	'🎛': {
		'character': '🎛',
		'unicode': 'U+1F39B',
		'category': 'objects',
		'subCategory': 'music',
		'description': 'Control knobs'
	},
	'🎤': {
		'character': '🎤',
		'unicode': 'U+1F3A4',
		'category': 'objects',
		'subCategory': 'music',
		'description': 'Microphone'
	},
	'🎧': {
		'character': '🎧',
		'unicode': 'U+1F3A7',
		'category': 'objects',
		'subCategory': 'music',
		'description': 'Headphone'
	},
	'📻': {
		'character': '📻',
		'unicode': 'U+1F4FB',
		'category': 'objects',
		'subCategory': 'music',
		'description': 'Radio'
	},
	'🎷': {
		'character': '🎷',
		'unicode': 'U+1F3B7',
		'category': 'objects',
		'subCategory': 'musical-instrument',
		'description': 'Saxophone'
	},
	'🪗': {
		'character': '🪗',
		'unicode': 'U+1FA97',
		'category': 'objects',
		'subCategory': 'musical-instrument',
		'description': 'Accordion'
	},
	'🎸': {
		'character': '🎸',
		'unicode': 'U+1F3B8',
		'category': 'objects',
		'subCategory': 'musical-instrument',
		'description': 'Guitar'
	},
	'🎹': {
		'character': '🎹',
		'unicode': 'U+1F3B9',
		'category': 'objects',
		'subCategory': 'musical-instrument',
		'description': 'Musical keyboard'
	},
	'🎺': {
		'character': '🎺',
		'unicode': 'U+1F3BA',
		'category': 'objects',
		'subCategory': 'musical-instrument',
		'description': 'Trumpet'
	},
	'🎻': {
		'character': '🎻',
		'unicode': 'U+1F3BB',
		'category': 'objects',
		'subCategory': 'musical-instrument',
		'description': 'Violin'
	},
	'🪕': {
		'character': '🪕',
		'unicode': 'U+1FA95',
		'category': 'objects',
		'subCategory': 'musical-instrument',
		'description': 'Banjo'
	},
	'🥁': {
		'character': '🥁',
		'unicode': 'U+1F941',
		'category': 'objects',
		'subCategory': 'musical-instrument',
		'description': 'Drum'
	},
	'🪘': {
		'character': '🪘',
		'unicode': 'U+1FA98',
		'category': 'objects',
		'subCategory': 'musical-instrument',
		'description': 'Long drum'
	},
	'📱': {
		'character': '📱',
		'unicode': 'U+1F4F1',
		'category': 'objects',
		'subCategory': 'phone',
		'description': 'Mobile phone'
	},
	'📲': {
		'character': '📲',
		'unicode': 'U+1F4F2',
		'category': 'objects',
		'subCategory': 'phone',
		'description': 'Mobile phone with arrow'
	},
	'☎': {
		'character': '☎',
		'unicode': 'U+260E',
		'category': 'objects',
		'subCategory': 'phone',
		'description': 'Telephone'
	},
	'📞': {
		'character': '📞',
		'unicode': 'U+1F4DE',
		'category': 'objects',
		'subCategory': 'phone',
		'description': 'Telephone receiver'
	},
	'📟': {
		'character': '📟',
		'unicode': 'U+1F4DF',
		'category': 'objects',
		'subCategory': 'phone',
		'description': 'Pager'
	},
	'📠': {
		'character': '📠',
		'unicode': 'U+1F4E0',
		'category': 'objects',
		'subCategory': 'phone',
		'description': 'Fax machine'
	},
	'🔋': {
		'character': '🔋',
		'unicode': 'U+1F50B',
		'category': 'objects',
		'subCategory': 'computer',
		'description': 'Battery'
	},
	'🪫': {
		'character': '🪫',
		'unicode': 'U+1FAAB',
		'category': 'objects',
		'subCategory': 'computer',
		'description': 'Low battery'
	},
	'🔌': {
		'character': '🔌',
		'unicode': 'U+1F50C',
		'category': 'objects',
		'subCategory': 'computer',
		'description': 'Electric plug'
	},
	'💻': {
		'character': '💻',
		'unicode': 'U+1F4BB',
		'category': 'objects',
		'subCategory': 'computer',
		'description': 'Laptop'
	},
	'🖥': {
		'character': '🖥',
		'unicode': 'U+1F5A5',
		'category': 'objects',
		'subCategory': 'computer',
		'description': 'Desktop computer'
	},
	'🖨': {
		'character': '🖨',
		'unicode': 'U+1F5A8',
		'category': 'objects',
		'subCategory': 'computer',
		'description': 'Printer'
	},
	'⌨': {
		'character': '⌨',
		'unicode': 'U+2328',
		'category': 'objects',
		'subCategory': 'computer',
		'description': 'Keyboard'
	},
	'🖱': {
		'character': '🖱',
		'unicode': 'U+1F5B1',
		'category': 'objects',
		'subCategory': 'computer',
		'description': 'Computer mouse'
	},
	'🖲': {
		'character': '🖲',
		'unicode': 'U+1F5B2',
		'category': 'objects',
		'subCategory': 'computer',
		'description': 'Trackball'
	},
	'💽': {
		'character': '💽',
		'unicode': 'U+1F4BD',
		'category': 'objects',
		'subCategory': 'computer',
		'description': 'Computer disk'
	},
	'💾': {
		'character': '💾',
		'unicode': 'U+1F4BE',
		'category': 'objects',
		'subCategory': 'computer',
		'description': 'Floppy disk'
	},
	'💿': {
		'character': '💿',
		'unicode': 'U+1F4BF',
		'category': 'objects',
		'subCategory': 'computer',
		'description': 'Optical disk'
	},
	'📀': {
		'character': '📀',
		'unicode': 'U+1F4C0',
		'category': 'objects',
		'subCategory': 'computer',
		'description': 'Dvd'
	},
	'🧮': {
		'character': '🧮',
		'unicode': 'U+1F9EE',
		'category': 'objects',
		'subCategory': 'computer',
		'description': 'Abacus'
	},
	'🎥': {
		'character': '🎥',
		'unicode': 'U+1F3A5',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Movie camera'
	},
	'🎞': {
		'character': '🎞',
		'unicode': 'U+1F39E',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Film frames'
	},
	'📽': {
		'character': '📽',
		'unicode': 'U+1F4FD',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Film projector'
	},
	'🎬': {
		'character': '🎬',
		'unicode': 'U+1F3AC',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Clapper board'
	},
	'📺': {
		'character': '📺',
		'unicode': 'U+1F4FA',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Television'
	},
	'📷': {
		'character': '📷',
		'unicode': 'U+1F4F7',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Camera'
	},
	'📸': {
		'character': '📸',
		'unicode': 'U+1F4F8',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Camera with flash'
	},
	'📹': {
		'character': '📹',
		'unicode': 'U+1F4F9',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Video camera'
	},
	'📼': {
		'character': '📼',
		'unicode': 'U+1F4FC',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Videocassette'
	},
	'🔍': {
		'character': '🔍',
		'unicode': 'U+1F50D',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Magnifying glass tilted left'
	},
	'🔎': {
		'character': '🔎',
		'unicode': 'U+1F50E',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Magnifying glass tilted right'
	},
	'🕯': {
		'character': '🕯',
		'unicode': 'U+1F56F',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Candle'
	},
	'💡': {
		'character': '💡',
		'unicode': 'U+1F4A1',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Light bulb'
	},
	'🔦': {
		'character': '🔦',
		'unicode': 'U+1F526',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Flashlight'
	},
	'🏮': {
		'character': '🏮',
		'unicode': 'U+1F3EE',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Red paper lantern'
	},
	'🪔': {
		'character': '🪔',
		'unicode': 'U+1FA94',
		'category': 'objects',
		'subCategory': 'light & video',
		'description': 'Diya lamp'
	},
	'📔': {
		'character': '📔',
		'unicode': 'U+1F4D4',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Notebook with decorative cover'
	},
	'📕': {
		'character': '📕',
		'unicode': 'U+1F4D5',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Closed book'
	},
	'📖': {
		'character': '📖',
		'unicode': 'U+1F4D6',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Open book'
	},
	'📗': {
		'character': '📗',
		'unicode': 'U+1F4D7',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Green book'
	},
	'📘': {
		'character': '📘',
		'unicode': 'U+1F4D8',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Blue book'
	},
	'📙': {
		'character': '📙',
		'unicode': 'U+1F4D9',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Orange book'
	},
	'📚': {
		'character': '📚',
		'unicode': 'U+1F4DA',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Books'
	},
	'📓': {
		'character': '📓',
		'unicode': 'U+1F4D3',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Notebook'
	},
	'📒': {
		'character': '📒',
		'unicode': 'U+1F4D2',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Ledger'
	},
	'📃': {
		'character': '📃',
		'unicode': 'U+1F4C3',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Page with curl'
	},
	'📜': {
		'character': '📜',
		'unicode': 'U+1F4DC',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Scroll'
	},
	'📄': {
		'character': '📄',
		'unicode': 'U+1F4C4',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Page facing up'
	},
	'📰': {
		'character': '📰',
		'unicode': 'U+1F4F0',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Newspaper'
	},
	'🗞': {
		'character': '🗞',
		'unicode': 'U+1F5DE',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Rolled-up newspaper'
	},
	'📑': {
		'character': '📑',
		'unicode': 'U+1F4D1',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Bookmark tabs'
	},
	'🔖': {
		'character': '🔖',
		'unicode': 'U+1F516',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Bookmark'
	},
	'🏷': {
		'character': '🏷',
		'unicode': 'U+1F3F7',
		'category': 'objects',
		'subCategory': 'book-paper',
		'description': 'Label'
	},
	'💰': {
		'character': '💰',
		'unicode': 'U+1F4B0',
		'category': 'objects',
		'subCategory': 'money',
		'description': 'Money bag'
	},
	'🪙': {
		'character': '🪙',
		'unicode': 'U+1FA99',
		'category': 'objects',
		'subCategory': 'money',
		'description': 'Coin'
	},
	'💴': {
		'character': '💴',
		'unicode': 'U+1F4B4',
		'category': 'objects',
		'subCategory': 'money',
		'description': 'Yen banknote'
	},
	'💵': {
		'character': '💵',
		'unicode': 'U+1F4B5',
		'category': 'objects',
		'subCategory': 'money',
		'description': 'Dollar banknote'
	},
	'💶': {
		'character': '💶',
		'unicode': 'U+1F4B6',
		'category': 'objects',
		'subCategory': 'money',
		'description': 'Euro banknote'
	},
	'💷': {
		'character': '💷',
		'unicode': 'U+1F4B7',
		'category': 'objects',
		'subCategory': 'money',
		'description': 'Pound banknote'
	},
	'💸': {
		'character': '💸',
		'unicode': 'U+1F4B8',
		'category': 'objects',
		'subCategory': 'money',
		'description': 'Money with wings'
	},
	'💳': {
		'character': '💳',
		'unicode': 'U+1F4B3',
		'category': 'objects',
		'subCategory': 'money',
		'description': 'Credit card'
	},
	'🧾': {
		'character': '🧾',
		'unicode': 'U+1F9FE',
		'category': 'objects',
		'subCategory': 'money',
		'description': 'Receipt'
	},
	'💹': {
		'character': '💹',
		'unicode': 'U+1F4B9',
		'category': 'objects',
		'subCategory': 'money',
		'description': 'Chart increasing with yen'
	},
	'✉': {
		'character': '✉',
		'unicode': 'U+2709',
		'category': 'objects',
		'subCategory': 'mail',
		'description': 'Envelope'
	},
	'📧': {
		'character': '📧',
		'unicode': 'U+1F4E7',
		'category': 'objects',
		'subCategory': 'mail',
		'description': 'E-mail'
	},
	'📨': {
		'character': '📨',
		'unicode': 'U+1F4E8',
		'category': 'objects',
		'subCategory': 'mail',
		'description': 'Incoming envelope'
	},
	'📩': {
		'character': '📩',
		'unicode': 'U+1F4E9',
		'category': 'objects',
		'subCategory': 'mail',
		'description': 'Envelope with arrow'
	},
	'📤': {
		'character': '📤',
		'unicode': 'U+1F4E4',
		'category': 'objects',
		'subCategory': 'mail',
		'description': 'Outbox tray'
	},
	'📥': {
		'character': '📥',
		'unicode': 'U+1F4E5',
		'category': 'objects',
		'subCategory': 'mail',
		'description': 'Inbox tray'
	},
	'📦': {
		'character': '📦',
		'unicode': 'U+1F4E6',
		'category': 'objects',
		'subCategory': 'mail',
		'description': 'Package'
	},
	'📫': {
		'character': '📫',
		'unicode': 'U+1F4EB',
		'category': 'objects',
		'subCategory': 'mail',
		'description': 'Closed mailbox with raised flag'
	},
	'📪': {
		'character': '📪',
		'unicode': 'U+1F4EA',
		'category': 'objects',
		'subCategory': 'mail',
		'description': 'Closed mailbox with lowered flag'
	},
	'📬': {
		'character': '📬',
		'unicode': 'U+1F4EC',
		'category': 'objects',
		'subCategory': 'mail',
		'description': 'Open mailbox with raised flag'
	},
	'📭': {
		'character': '📭',
		'unicode': 'U+1F4ED',
		'category': 'objects',
		'subCategory': 'mail',
		'description': 'Open mailbox with lowered flag'
	},
	'📮': {
		'character': '📮',
		'unicode': 'U+1F4EE',
		'category': 'objects',
		'subCategory': 'mail',
		'description': 'Postbox'
	},
	'🗳': {
		'character': '🗳',
		'unicode': 'U+1F5F3',
		'category': 'objects',
		'subCategory': 'mail',
		'description': 'Ballot box with ballot'
	},
	'✏': {
		'character': '✏',
		'unicode': 'U+270F',
		'category': 'objects',
		'subCategory': 'writing',
		'description': 'Pencil'
	},
	'✒': {
		'character': '✒',
		'unicode': 'U+2712',
		'category': 'objects',
		'subCategory': 'writing',
		'description': 'Black nib'
	},
	'🖋': {
		'character': '🖋',
		'unicode': 'U+1F58B',
		'category': 'objects',
		'subCategory': 'writing',
		'description': 'Fountain pen'
	},
	'🖊': {
		'character': '🖊',
		'unicode': 'U+1F58A',
		'category': 'objects',
		'subCategory': 'writing',
		'description': 'Pen'
	},
	'🖌': {
		'character': '🖌',
		'unicode': 'U+1F58C',
		'category': 'objects',
		'subCategory': 'writing',
		'description': 'Paintbrush'
	},
	'🖍': {
		'character': '🖍',
		'unicode': 'U+1F58D',
		'category': 'objects',
		'subCategory': 'writing',
		'description': 'Crayon'
	},
	'📝': {
		'character': '📝',
		'unicode': 'U+1F4DD',
		'category': 'objects',
		'subCategory': 'writing',
		'description': 'Memo'
	},
	'💼': {
		'character': '💼',
		'unicode': 'U+1F4BC',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Briefcase'
	},
	'📁': {
		'character': '📁',
		'unicode': 'U+1F4C1',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'File folder'
	},
	'📂': {
		'character': '📂',
		'unicode': 'U+1F4C2',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Open file folder'
	},
	'🗂': {
		'character': '🗂',
		'unicode': 'U+1F5C2',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Card index dividers'
	},
	'📅': {
		'character': '📅',
		'unicode': 'U+1F4C5',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Calendar'
	},
	'📆': {
		'character': '📆',
		'unicode': 'U+1F4C6',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Tear-off calendar'
	},
	'🗒': {
		'character': '🗒',
		'unicode': 'U+1F5D2',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Spiral notepad'
	},
	'🗓': {
		'character': '🗓',
		'unicode': 'U+1F5D3',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Spiral calendar'
	},
	'📇': {
		'character': '📇',
		'unicode': 'U+1F4C7',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Card index'
	},
	'📈': {
		'character': '📈',
		'unicode': 'U+1F4C8',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Chart increasing'
	},
	'📉': {
		'character': '📉',
		'unicode': 'U+1F4C9',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Chart decreasing'
	},
	'📊': {
		'character': '📊',
		'unicode': 'U+1F4CA',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Bar chart'
	},
	'📋': {
		'character': '📋',
		'unicode': 'U+1F4CB',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Clipboard'
	},
	'📌': {
		'character': '📌',
		'unicode': 'U+1F4CC',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Pushpin'
	},
	'📍': {
		'character': '📍',
		'unicode': 'U+1F4CD',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Round pushpin'
	},
	'📎': {
		'character': '📎',
		'unicode': 'U+1F4CE',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Paperclip'
	},
	'🖇': {
		'character': '🖇',
		'unicode': 'U+1F587',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Linked paperclips'
	},
	'📏': {
		'character': '📏',
		'unicode': 'U+1F4CF',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Straight ruler'
	},
	'📐': {
		'character': '📐',
		'unicode': 'U+1F4D0',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Triangular ruler'
	},
	'✂': {
		'character': '✂',
		'unicode': 'U+2702',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Scissors'
	},
	'🗃': {
		'character': '🗃',
		'unicode': 'U+1F5C3',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Card file box'
	},
	'🗄': {
		'character': '🗄',
		'unicode': 'U+1F5C4',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'File cabinet'
	},
	'🗑': {
		'character': '🗑',
		'unicode': 'U+1F5D1',
		'category': 'objects',
		'subCategory': 'office',
		'description': 'Wastebasket'
	},
	'🔒': {
		'character': '🔒',
		'unicode': 'U+1F512',
		'category': 'objects',
		'subCategory': 'lock',
		'description': 'Locked'
	},
	'🔓': {
		'character': '🔓',
		'unicode': 'U+1F513',
		'category': 'objects',
		'subCategory': 'lock',
		'description': 'Unlocked'
	},
	'🔏': {
		'character': '🔏',
		'unicode': 'U+1F50F',
		'category': 'objects',
		'subCategory': 'lock',
		'description': 'Locked with pen'
	},
	'🔐': {
		'character': '🔐',
		'unicode': 'U+1F510',
		'category': 'objects',
		'subCategory': 'lock',
		'description': 'Locked with key'
	},
	'🔑': {
		'character': '🔑',
		'unicode': 'U+1F511',
		'category': 'objects',
		'subCategory': 'lock',
		'description': 'Key'
	},
	'🗝': {
		'character': '🗝',
		'unicode': 'U+1F5DD',
		'category': 'objects',
		'subCategory': 'lock',
		'description': 'Old key'
	},
	'🔨': {
		'character': '🔨',
		'unicode': 'U+1F528',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Hammer'
	},
	'🪓': {
		'character': '🪓',
		'unicode': 'U+1FA93',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Axe'
	},
	'⛏': {
		'character': '⛏',
		'unicode': 'U+26CF',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Pick'
	},
	'⚒': {
		'character': '⚒',
		'unicode': 'U+2692',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Hammer and pick'
	},
	'🛠': {
		'character': '🛠',
		'unicode': 'U+1F6E0',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Hammer and wrench'
	},
	'🗡': {
		'character': '🗡',
		'unicode': 'U+1F5E1',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Dagger'
	},
	'⚔': {
		'character': '⚔',
		'unicode': 'U+2694',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Crossed swords'
	},
	'💣': {
		'character': '💣',
		'unicode': 'U+1F4A3',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Bomb'
	},
	'🪃': {
		'character': '🪃',
		'unicode': 'U+1FA83',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Boomerang'
	},
	'🏹': {
		'character': '🏹',
		'unicode': 'U+1F3F9',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Bow and arrow'
	},
	'🛡': {
		'character': '🛡',
		'unicode': 'U+1F6E1',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Shield'
	},
	'🪚': {
		'character': '🪚',
		'unicode': 'U+1FA9A',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Carpentry saw'
	},
	'🔧': {
		'character': '🔧',
		'unicode': 'U+1F527',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Wrench'
	},
	'🪛': {
		'character': '🪛',
		'unicode': 'U+1FA9B',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Screwdriver'
	},
	'🔩': {
		'character': '🔩',
		'unicode': 'U+1F529',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Nut and bolt'
	},
	'⚙': {
		'character': '⚙',
		'unicode': 'U+2699',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Gear'
	},
	'🗜': {
		'character': '🗜',
		'unicode': 'U+1F5DC',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Clamp'
	},
	'⚖': {
		'character': '⚖',
		'unicode': 'U+2696',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Balance scale'
	},
	'🦯': {
		'character': '🦯',
		'unicode': 'U+1F9AF',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'White cane'
	},
	'🔗': {
		'character': '🔗',
		'unicode': 'U+1F517',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Link'
	},
	'⛓': {
		'character': '⛓',
		'unicode': 'U+26D3',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Chains'
	},
	'🪝': {
		'character': '🪝',
		'unicode': 'U+1FA9D',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Hook'
	},
	'🧰': {
		'character': '🧰',
		'unicode': 'U+1F9F0',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Toolbox'
	},
	'🧲': {
		'character': '🧲',
		'unicode': 'U+1F9F2',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Magnet'
	},
	'🪜': {
		'character': '🪜',
		'unicode': 'U+1FA9C',
		'category': 'objects',
		'subCategory': 'tool',
		'description': 'Ladder'
	},
	'⚗': {
		'character': '⚗',
		'unicode': 'U+2697',
		'category': 'objects',
		'subCategory': 'science',
		'description': 'Alembic'
	},
	'🧪': {
		'character': '🧪',
		'unicode': 'U+1F9EA',
		'category': 'objects',
		'subCategory': 'science',
		'description': 'Test tube'
	},
	'🧫': {
		'character': '🧫',
		'unicode': 'U+1F9EB',
		'category': 'objects',
		'subCategory': 'science',
		'description': 'Petri dish'
	},
	'🧬': {
		'character': '🧬',
		'unicode': 'U+1F9EC',
		'category': 'objects',
		'subCategory': 'science',
		'description': 'Dna'
	},
	'🔬': {
		'character': '🔬',
		'unicode': 'U+1F52C',
		'category': 'objects',
		'subCategory': 'science',
		'description': 'Microscope'
	},
	'🔭': {
		'character': '🔭',
		'unicode': 'U+1F52D',
		'category': 'objects',
		'subCategory': 'science',
		'description': 'Telescope'
	},
	'📡': {
		'character': '📡',
		'unicode': 'U+1F4E1',
		'category': 'objects',
		'subCategory': 'science',
		'description': 'Satellite antenna'
	},
	'💉': {
		'character': '💉',
		'unicode': 'U+1F489',
		'category': 'objects',
		'subCategory': 'medical',
		'description': 'Syringe'
	},
	'🩸': {
		'character': '🩸',
		'unicode': 'U+1FA78',
		'category': 'objects',
		'subCategory': 'medical',
		'description': 'Drop of blood'
	},
	'💊': {
		'character': '💊',
		'unicode': 'U+1F48A',
		'category': 'objects',
		'subCategory': 'medical',
		'description': 'Pill'
	},
	'🩹': {
		'character': '🩹',
		'unicode': 'U+1FA79',
		'category': 'objects',
		'subCategory': 'medical',
		'description': 'Adhesive bandage'
	},
	'🩼': {
		'character': '🩼',
		'unicode': 'U+1FA7C',
		'category': 'objects',
		'subCategory': 'medical',
		'description': 'Crutch'
	},
	'🩺': {
		'character': '🩺',
		'unicode': 'U+1FA7A',
		'category': 'objects',
		'subCategory': 'medical',
		'description': 'Stethoscope'
	},
	'🩻': {
		'character': '🩻',
		'unicode': 'U+1FA7B',
		'category': 'objects',
		'subCategory': 'medical',
		'description': 'X-ray'
	},
	'🚪': {
		'character': '🚪',
		'unicode': 'U+1F6AA',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Door'
	},
	'🛗': {
		'character': '🛗',
		'unicode': 'U+1F6D7',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Elevator'
	},
	'🪞': {
		'character': '🪞',
		'unicode': 'U+1FA9E',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Mirror'
	},
	'🪟': {
		'character': '🪟',
		'unicode': 'U+1FA9F',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Window'
	},
	'🛏': {
		'character': '🛏',
		'unicode': 'U+1F6CF',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Bed'
	},
	'🛋': {
		'character': '🛋',
		'unicode': 'U+1F6CB',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Couch and lamp'
	},
	'🪑': {
		'character': '🪑',
		'unicode': 'U+1FA91',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Chair'
	},
	'🚽': {
		'character': '🚽',
		'unicode': 'U+1F6BD',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Toilet'
	},
	'🪠': {
		'character': '🪠',
		'unicode': 'U+1FAA0',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Plunger'
	},
	'🚿': {
		'character': '🚿',
		'unicode': 'U+1F6BF',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Shower'
	},
	'🛁': {
		'character': '🛁',
		'unicode': 'U+1F6C1',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Bathtub'
	},
	'🪤': {
		'character': '🪤',
		'unicode': 'U+1FAA4',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Mouse trap'
	},
	'🪒': {
		'character': '🪒',
		'unicode': 'U+1FA92',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Razor'
	},
	'🧴': {
		'character': '🧴',
		'unicode': 'U+1F9F4',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Lotion bottle'
	},
	'🧷': {
		'character': '🧷',
		'unicode': 'U+1F9F7',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Safety pin'
	},
	'🧹': {
		'character': '🧹',
		'unicode': 'U+1F9F9',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Broom'
	},
	'🧺': {
		'character': '🧺',
		'unicode': 'U+1F9FA',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Basket'
	},
	'🧻': {
		'character': '🧻',
		'unicode': 'U+1F9FB',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Roll of paper'
	},
	'🪣': {
		'character': '🪣',
		'unicode': 'U+1FAA3',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Bucket'
	},
	'🧼': {
		'character': '🧼',
		'unicode': 'U+1F9FC',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Soap'
	},
	'🫧': {
		'character': '🫧',
		'unicode': 'U+1FAE7',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Bubbles'
	},
	'🪥': {
		'character': '🪥',
		'unicode': 'U+1FAA5',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Toothbrush'
	},
	'🧽': {
		'character': '🧽',
		'unicode': 'U+1F9FD',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Sponge'
	},
	'🧯': {
		'character': '🧯',
		'unicode': 'U+1F9EF',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Fire extinguisher'
	},
	'🛒': {
		'character': '🛒',
		'unicode': 'U+1F6D2',
		'category': 'objects',
		'subCategory': 'household',
		'description': 'Shopping cart'
	},
	'🚬': {
		'character': '🚬',
		'unicode': 'U+1F6AC',
		'category': 'objects',
		'subCategory': 'other-object',
		'description': 'Cigarette'
	},
	'⚰': {
		'character': '⚰',
		'unicode': 'U+26B0',
		'category': 'objects',
		'subCategory': 'other-object',
		'description': 'Coffin'
	},
	'🪦': {
		'character': '🪦',
		'unicode': 'U+1FAA6',
		'category': 'objects',
		'subCategory': 'other-object',
		'description': 'Headstone'
	},
	'⚱': {
		'character': '⚱',
		'unicode': 'U+26B1',
		'category': 'objects',
		'subCategory': 'other-object',
		'description': 'Funeral urn'
	},
	'🧿': {
		'character': '🧿',
		'unicode': 'U+1F9FF',
		'category': 'objects',
		'subCategory': 'other-object',
		'description': 'Nazar amulet'
	},
	'🪬': {
		'character': '🪬',
		'unicode': 'U+1FAAC',
		'category': 'objects',
		'subCategory': 'other-object',
		'description': 'Hamsa'
	},
	'🗿': {
		'character': '🗿',
		'unicode': 'U+1F5FF',
		'category': 'objects',
		'subCategory': 'other-object',
		'description': 'Moai'
	},
	'🪧': {
		'character': '🪧',
		'unicode': 'U+1FAA7',
		'category': 'objects',
		'subCategory': 'other-object',
		'description': 'Placard'
	},
	'🪪': {
		'character': '🪪',
		'unicode': 'U+1FAAA',
		'category': 'objects',
		'subCategory': 'other-object',
		'description': 'Identification card'
	},
	'🏧': {
		'character': '🏧',
		'unicode': 'U+1F3E7',
		'category': 'symbols',
		'subCategory': 'transport-sign',
		'description': 'Atm sign'
	},
	'🚮': {
		'character': '🚮',
		'unicode': 'U+1F6AE',
		'category': 'symbols',
		'subCategory': 'transport-sign',
		'description': 'Litter in bin sign'
	},
	'🚰': {
		'character': '🚰',
		'unicode': 'U+1F6B0',
		'category': 'symbols',
		'subCategory': 'transport-sign',
		'description': 'Potable water'
	},
	'♿': {
		'character': '♿',
		'unicode': 'U+267F',
		'category': 'symbols',
		'subCategory': 'transport-sign',
		'description': 'Wheelchair symbol'
	},
	'🚹': {
		'character': '🚹',
		'unicode': 'U+1F6B9',
		'category': 'symbols',
		'subCategory': 'transport-sign',
		'description': 'Men’s room'
	},
	'🚺': {
		'character': '🚺',
		'unicode': 'U+1F6BA',
		'category': 'symbols',
		'subCategory': 'transport-sign',
		'description': 'Women’s room'
	},
	'🚻': {
		'character': '🚻',
		'unicode': 'U+1F6BB',
		'category': 'symbols',
		'subCategory': 'transport-sign',
		'description': 'Restroom'
	},
	'🚼': {
		'character': '🚼',
		'unicode': 'U+1F6BC',
		'category': 'symbols',
		'subCategory': 'transport-sign',
		'description': 'Baby symbol'
	},
	'🚾': {
		'character': '🚾',
		'unicode': 'U+1F6BE',
		'category': 'symbols',
		'subCategory': 'transport-sign',
		'description': 'Water closet'
	},
	'🛂': {
		'character': '🛂',
		'unicode': 'U+1F6C2',
		'category': 'symbols',
		'subCategory': 'transport-sign',
		'description': 'Passport control'
	},
	'🛃': {
		'character': '🛃',
		'unicode': 'U+1F6C3',
		'category': 'symbols',
		'subCategory': 'transport-sign',
		'description': 'Customs'
	},
	'🛄': {
		'character': '🛄',
		'unicode': 'U+1F6C4',
		'category': 'symbols',
		'subCategory': 'transport-sign',
		'description': 'Baggage claim'
	},
	'🛅': {
		'character': '🛅',
		'unicode': 'U+1F6C5',
		'category': 'symbols',
		'subCategory': 'transport-sign',
		'description': 'Left luggage'
	},
	'⚠': {
		'character': '⚠',
		'unicode': 'U+26A0',
		'category': 'symbols',
		'subCategory': 'warning',
		'description': 'Warning'
	},
	'🚸': {
		'character': '🚸',
		'unicode': 'U+1F6B8',
		'category': 'symbols',
		'subCategory': 'warning',
		'description': 'Children crossing'
	},
	'⛔': {
		'character': '⛔',
		'unicode': 'U+26D4',
		'category': 'symbols',
		'subCategory': 'warning',
		'description': 'No entry'
	},
	'🚫': {
		'character': '🚫',
		'unicode': 'U+1F6AB',
		'category': 'symbols',
		'subCategory': 'warning',
		'description': 'Prohibited'
	},
	'🚳': {
		'character': '🚳',
		'unicode': 'U+1F6B3',
		'category': 'symbols',
		'subCategory': 'warning',
		'description': 'No bicycles'
	},
	'🚭': {
		'character': '🚭',
		'unicode': 'U+1F6AD',
		'category': 'symbols',
		'subCategory': 'warning',
		'description': 'No smoking'
	},
	'🚯': {
		'character': '🚯',
		'unicode': 'U+1F6AF',
		'category': 'symbols',
		'subCategory': 'warning',
		'description': 'No littering'
	},
	'🚱': {
		'character': '🚱',
		'unicode': 'U+1F6B1',
		'category': 'symbols',
		'subCategory': 'warning',
		'description': 'Non-potable water'
	},
	'🚷': {
		'character': '🚷',
		'unicode': 'U+1F6B7',
		'category': 'symbols',
		'subCategory': 'warning',
		'description': 'No pedestrians'
	},
	'📵': {
		'character': '📵',
		'unicode': 'U+1F4F5',
		'category': 'symbols',
		'subCategory': 'warning',
		'description': 'No mobile phones'
	},
	'🔞': {
		'character': '🔞',
		'unicode': 'U+1F51E',
		'category': 'symbols',
		'subCategory': 'warning',
		'description': 'No one under eighteen'
	},
	'☢': {
		'character': '☢',
		'unicode': 'U+2622',
		'category': 'symbols',
		'subCategory': 'warning',
		'description': 'Radioactive'
	},
	'☣': {
		'character': '☣',
		'unicode': 'U+2623',
		'category': 'symbols',
		'subCategory': 'warning',
		'description': 'Biohazard'
	},
	'⬆': {
		'character': '⬆',
		'unicode': 'U+2B06',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Up arrow'
	},
	'↗': {
		'character': '↗',
		'unicode': 'U+2197',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Up-right arrow'
	},
	'➡': {
		'character': '➡',
		'unicode': 'U+27A1',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Right arrow'
	},
	'↘': {
		'character': '↘',
		'unicode': 'U+2198',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Down-right arrow'
	},
	'⬇': {
		'character': '⬇',
		'unicode': 'U+2B07',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Down arrow'
	},
	'↙': {
		'character': '↙',
		'unicode': 'U+2199',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Down-left arrow'
	},
	'⬅': {
		'character': '⬅',
		'unicode': 'U+2B05',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Left arrow'
	},
	'↖': {
		'character': '↖',
		'unicode': 'U+2196',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Up-left arrow'
	},
	'↕': {
		'character': '↕',
		'unicode': 'U+2195',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Up-down arrow'
	},
	'↔': {
		'character': '↔',
		'unicode': 'U+2194',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Left-right arrow'
	},
	'↩': {
		'character': '↩',
		'unicode': 'U+21A9',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Right arrow curving left'
	},
	'↪': {
		'character': '↪',
		'unicode': 'U+21AA',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Left arrow curving right'
	},
	'⤴': {
		'character': '⤴',
		'unicode': 'U+2934',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Right arrow curving up'
	},
	'⤵': {
		'character': '⤵',
		'unicode': 'U+2935',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Right arrow curving down'
	},
	'🔃': {
		'character': '🔃',
		'unicode': 'U+1F503',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Clockwise vertical arrows'
	},
	'🔄': {
		'character': '🔄',
		'unicode': 'U+1F504',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Counterclockwise arrows button'
	},
	'🔙': {
		'character': '🔙',
		'unicode': 'U+1F519',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Back arrow'
	},
	'🔚': {
		'character': '🔚',
		'unicode': 'U+1F51A',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'End arrow'
	},
	'🔛': {
		'character': '🔛',
		'unicode': 'U+1F51B',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'On! arrow'
	},
	'🔜': {
		'character': '🔜',
		'unicode': 'U+1F51C',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Soon arrow'
	},
	'🔝': {
		'character': '🔝',
		'unicode': 'U+1F51D',
		'category': 'symbols',
		'subCategory': 'arrow',
		'description': 'Top arrow'
	},
	'🛐': {
		'character': '🛐',
		'unicode': 'U+1F6D0',
		'category': 'symbols',
		'subCategory': 'religion',
		'description': 'Place of worship'
	},
	'⚛': {
		'character': '⚛',
		'unicode': 'U+269B',
		'category': 'symbols',
		'subCategory': 'religion',
		'description': 'Atom symbol'
	},
	'🕉': {
		'character': '🕉',
		'unicode': 'U+1F549',
		'category': 'symbols',
		'subCategory': 'religion',
		'description': 'Om'
	},
	'✡': {
		'character': '✡',
		'unicode': 'U+2721',
		'category': 'symbols',
		'subCategory': 'religion',
		'description': 'Star of david'
	},
	'☸': {
		'character': '☸',
		'unicode': 'U+2638',
		'category': 'symbols',
		'subCategory': 'religion',
		'description': 'Wheel of dharma'
	},
	'☯': {
		'character': '☯',
		'unicode': 'U+262F',
		'category': 'symbols',
		'subCategory': 'religion',
		'description': 'Yin yang'
	},
	'✝': {
		'character': '✝',
		'unicode': 'U+271D',
		'category': 'symbols',
		'subCategory': 'religion',
		'description': 'Latin cross'
	},
	'☦': {
		'character': '☦',
		'unicode': 'U+2626',
		'category': 'symbols',
		'subCategory': 'religion',
		'description': 'Orthodox cross'
	},
	'☪': {
		'character': '☪',
		'unicode': 'U+262A',
		'category': 'symbols',
		'subCategory': 'religion',
		'description': 'Star and crescent'
	},
	'☮': {
		'character': '☮',
		'unicode': 'U+262E',
		'category': 'symbols',
		'subCategory': 'religion',
		'description': 'Peace symbol'
	},
	'🕎': {
		'character': '🕎',
		'unicode': 'U+1F54E',
		'category': 'symbols',
		'subCategory': 'religion',
		'description': 'Menorah'
	},
	'🔯': {
		'character': '🔯',
		'unicode': 'U+1F52F',
		'category': 'symbols',
		'subCategory': 'religion',
		'description': 'Dotted six-pointed star'
	},
	'♈': {
		'character': '♈',
		'unicode': 'U+2648',
		'category': 'symbols',
		'subCategory': 'zodiac',
		'description': 'Aries'
	},
	'♉': {
		'character': '♉',
		'unicode': 'U+2649',
		'category': 'symbols',
		'subCategory': 'zodiac',
		'description': 'Taurus'
	},
	'♊': {
		'character': '♊',
		'unicode': 'U+264A',
		'category': 'symbols',
		'subCategory': 'zodiac',
		'description': 'Gemini'
	},
	'♋': {
		'character': '♋',
		'unicode': 'U+264B',
		'category': 'symbols',
		'subCategory': 'zodiac',
		'description': 'Cancer'
	},
	'♌': {
		'character': '♌',
		'unicode': 'U+264C',
		'category': 'symbols',
		'subCategory': 'zodiac',
		'description': 'Leo'
	},
	'♍': {
		'character': '♍',
		'unicode': 'U+264D',
		'category': 'symbols',
		'subCategory': 'zodiac',
		'description': 'Virgo'
	},
	'♎': {
		'character': '♎',
		'unicode': 'U+264E',
		'category': 'symbols',
		'subCategory': 'zodiac',
		'description': 'Libra'
	},
	'♏': {
		'character': '♏',
		'unicode': 'U+264F',
		'category': 'symbols',
		'subCategory': 'zodiac',
		'description': 'Scorpio'
	},
	'♐': {
		'character': '♐',
		'unicode': 'U+2650',
		'category': 'symbols',
		'subCategory': 'zodiac',
		'description': 'Sagittarius'
	},
	'♑': {
		'character': '♑',
		'unicode': 'U+2651',
		'category': 'symbols',
		'subCategory': 'zodiac',
		'description': 'Capricorn'
	},
	'♒': {
		'character': '♒',
		'unicode': 'U+2652',
		'category': 'symbols',
		'subCategory': 'zodiac',
		'description': 'Aquarius'
	},
	'♓': {
		'character': '♓',
		'unicode': 'U+2653',
		'category': 'symbols',
		'subCategory': 'zodiac',
		'description': 'Pisces'
	},
	'⛎': {
		'character': '⛎',
		'unicode': 'U+26CE',
		'category': 'symbols',
		'subCategory': 'zodiac',
		'description': 'Ophiuchus'
	},
	'🔀': {
		'character': '🔀',
		'unicode': 'U+1F500',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Shuffle tracks button'
	},
	'🔁': {
		'character': '🔁',
		'unicode': 'U+1F501',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Repeat button'
	},
	'🔂': {
		'character': '🔂',
		'unicode': 'U+1F502',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Repeat single button'
	},
	'▶': {
		'character': '▶',
		'unicode': 'U+25B6',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Play button'
	},
	'⏩': {
		'character': '⏩',
		'unicode': 'U+23E9',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Fast-forward button'
	},
	'⏭': {
		'character': '⏭',
		'unicode': 'U+23ED',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Next track button'
	},
	'⏯': {
		'character': '⏯',
		'unicode': 'U+23EF',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Play or pause button'
	},
	'◀': {
		'character': '◀',
		'unicode': 'U+25C0',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Reverse button'
	},
	'⏪': {
		'character': '⏪',
		'unicode': 'U+23EA',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Fast reverse button'
	},
	'⏮': {
		'character': '⏮',
		'unicode': 'U+23EE',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Last track button'
	},
	'🔼': {
		'character': '🔼',
		'unicode': 'U+1F53C',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Upwards button'
	},
	'⏫': {
		'character': '⏫',
		'unicode': 'U+23EB',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Fast up button'
	},
	'🔽': {
		'character': '🔽',
		'unicode': 'U+1F53D',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Downwards button'
	},
	'⏬': {
		'character': '⏬',
		'unicode': 'U+23EC',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Fast down button'
	},
	'⏸': {
		'character': '⏸',
		'unicode': 'U+23F8',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Pause button'
	},
	'⏹': {
		'character': '⏹',
		'unicode': 'U+23F9',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Stop button'
	},
	'⏺': {
		'character': '⏺',
		'unicode': 'U+23FA',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Record button'
	},
	'⏏': {
		'character': '⏏',
		'unicode': 'U+23CF',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Eject button'
	},
	'🎦': {
		'character': '🎦',
		'unicode': 'U+1F3A6',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Cinema'
	},
	'🔅': {
		'character': '🔅',
		'unicode': 'U+1F505',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Dim button'
	},
	'🔆': {
		'character': '🔆',
		'unicode': 'U+1F506',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Bright button'
	},
	'📶': {
		'character': '📶',
		'unicode': 'U+1F4F6',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Antenna bars'
	},
	'📳': {
		'character': '📳',
		'unicode': 'U+1F4F3',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Vibration mode'
	},
	'📴': {
		'character': '📴',
		'unicode': 'U+1F4F4',
		'category': 'symbols',
		'subCategory': 'av-symbol',
		'description': 'Mobile phone off'
	},
	'♀': {
		'character': '♀',
		'unicode': 'U+2640',
		'category': 'symbols',
		'subCategory': 'gender',
		'description': 'Female sign'
	},
	'♂': {
		'character': '♂',
		'unicode': 'U+2642',
		'category': 'symbols',
		'subCategory': 'gender',
		'description': 'Male sign'
	},
	'⚧': {
		'character': '⚧',
		'unicode': 'U+26A7',
		'category': 'symbols',
		'subCategory': 'gender',
		'description': 'Transgender symbol'
	},
	'✖': {
		'character': '✖',
		'unicode': 'U+2716',
		'category': 'symbols',
		'subCategory': 'math',
		'description': 'Multiply'
	},
	'➕': {
		'character': '➕',
		'unicode': 'U+2795',
		'category': 'symbols',
		'subCategory': 'math',
		'description': 'Plus'
	},
	'➖': {
		'character': '➖',
		'unicode': 'U+2796',
		'category': 'symbols',
		'subCategory': 'math',
		'description': 'Minus'
	},
	'➗': {
		'character': '➗',
		'unicode': 'U+2797',
		'category': 'symbols',
		'subCategory': 'math',
		'description': 'Divide'
	},
	'🟰': {
		'character': '🟰',
		'unicode': 'U+1F7F0',
		'category': 'symbols',
		'subCategory': 'math',
		'description': 'Heavy equals sign'
	},
	'♾': {
		'character': '♾',
		'unicode': 'U+267E',
		'category': 'symbols',
		'subCategory': 'math',
		'description': 'Infinity'
	},
	'‼': {
		'character': '‼',
		'unicode': 'U+203C',
		'category': 'symbols',
		'subCategory': 'punctuation',
		'description': 'Double exclamation mark'
	},
	'⁉': {
		'character': '⁉',
		'unicode': 'U+2049',
		'category': 'symbols',
		'subCategory': 'punctuation',
		'description': 'Exclamation question mark'
	},
	'❓': {
		'character': '❓',
		'unicode': 'U+2753',
		'category': 'symbols',
		'subCategory': 'punctuation',
		'description': 'Red question mark'
	},
	'❔': {
		'character': '❔',
		'unicode': 'U+2754',
		'category': 'symbols',
		'subCategory': 'punctuation',
		'description': 'White question mark'
	},
	'❕': {
		'character': '❕',
		'unicode': 'U+2755',
		'category': 'symbols',
		'subCategory': 'punctuation',
		'description': 'White exclamation mark'
	},
	'❗': {
		'character': '❗',
		'unicode': 'U+2757',
		'category': 'symbols',
		'subCategory': 'punctuation',
		'description': 'Red exclamation mark'
	},
	'〰': {
		'character': '〰',
		'unicode': 'U+3030',
		'category': 'symbols',
		'subCategory': 'punctuation',
		'description': 'Wavy dash'
	},
	'💱': {
		'character': '💱',
		'unicode': 'U+1F4B1',
		'category': 'symbols',
		'subCategory': 'currency',
		'description': 'Currency exchange'
	},
	'💲': {
		'character': '💲',
		'unicode': 'U+1F4B2',
		'category': 'symbols',
		'subCategory': 'currency',
		'description': 'Heavy dollar sign'
	},
	'⚕': {
		'character': '⚕',
		'unicode': 'U+2695',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Medical symbol'
	},
	'♻': {
		'character': '♻',
		'unicode': 'U+267B',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Recycling symbol'
	},
	'⚜': {
		'character': '⚜',
		'unicode': 'U+269C',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Fleur-de-lis'
	},
	'🔱': {
		'character': '🔱',
		'unicode': 'U+1F531',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Trident emblem'
	},
	'📛': {
		'character': '📛',
		'unicode': 'U+1F4DB',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Name badge'
	},
	'🔰': {
		'character': '🔰',
		'unicode': 'U+1F530',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Japanese symbol for beginner'
	},
	'⭕': {
		'character': '⭕',
		'unicode': 'U+2B55',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Hollow red circle'
	},
	'✅': {
		'character': '✅',
		'unicode': 'U+2705',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Check mark button'
	},
	'☑': {
		'character': '☑',
		'unicode': 'U+2611',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Check box with check'
	},
	'✔': {
		'character': '✔',
		'unicode': 'U+2714',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Check mark'
	},
	'❌': {
		'character': '❌',
		'unicode': 'U+274C',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Cross mark'
	},
	'❎': {
		'character': '❎',
		'unicode': 'U+274E',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Cross mark button'
	},
	'➰': {
		'character': '➰',
		'unicode': 'U+27B0',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Curly loop'
	},
	'➿': {
		'character': '➿',
		'unicode': 'U+27BF',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Double curly loop'
	},
	'〽': {
		'character': '〽',
		'unicode': 'U+303D',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Part alternation mark'
	},
	'✳': {
		'character': '✳',
		'unicode': 'U+2733',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Eight-spoked asterisk'
	},
	'✴': {
		'character': '✴',
		'unicode': 'U+2734',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Eight-pointed star'
	},
	'❇': {
		'character': '❇',
		'unicode': 'U+2747',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Sparkle'
	},
	'©': {
		'character': '©',
		'unicode': 'U+00A9',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Copyright'
	},
	'®': {
		'character': '®',
		'unicode': 'U+00AE',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Registered'
	},
	'™': {
		'character': '™',
		'unicode': 'U+2122',
		'category': 'symbols',
		'subCategory': 'other-symbol',
		'description': 'Trade mark'
	},
	'#️⃣': {
		'character': '#️⃣',
		'unicode': 'U+0023 U+FE0F U+20E3',
		'category': 'symbols',
		'subCategory': 'keycap',
		'description': 'Keycap: #'
	},
	'*️⃣': {
		'character': '*️⃣',
		'unicode': 'U+002A U+FE0F U+20E3',
		'category': 'symbols',
		'subCategory': 'keycap',
		'description': 'Keycap: *'
	},
	'0️⃣': {
		'character': '0️⃣',
		'unicode': 'U+0030 U+FE0F U+20E3',
		'category': 'symbols',
		'subCategory': 'keycap',
		'description': 'Keycap: 0'
	},
	'1️⃣': {
		'character': '1️⃣',
		'unicode': 'U+0031 U+FE0F U+20E3',
		'category': 'symbols',
		'subCategory': 'keycap',
		'description': 'Keycap: 1'
	},
	'2️⃣': {
		'character': '2️⃣',
		'unicode': 'U+0032 U+FE0F U+20E3',
		'category': 'symbols',
		'subCategory': 'keycap',
		'description': 'Keycap: 2'
	},
	'3️⃣': {
		'character': '3️⃣',
		'unicode': 'U+0033 U+FE0F U+20E3',
		'category': 'symbols',
		'subCategory': 'keycap',
		'description': 'Keycap: 3'
	},
	'4️⃣': {
		'character': '4️⃣',
		'unicode': 'U+0034 U+FE0F U+20E3',
		'category': 'symbols',
		'subCategory': 'keycap',
		'description': 'Keycap: 4'
	},
	'5️⃣': {
		'character': '5️⃣',
		'unicode': 'U+0035 U+FE0F U+20E3',
		'category': 'symbols',
		'subCategory': 'keycap',
		'description': 'Keycap: 5'
	},
	'6️⃣': {
		'character': '6️⃣',
		'unicode': 'U+0036 U+FE0F U+20E3',
		'category': 'symbols',
		'subCategory': 'keycap',
		'description': 'Keycap: 6'
	},
	'7️⃣': {
		'character': '7️⃣',
		'unicode': 'U+0037 U+FE0F U+20E3',
		'category': 'symbols',
		'subCategory': 'keycap',
		'description': 'Keycap: 7'
	},
	'8️⃣': {
		'character': '8️⃣',
		'unicode': 'U+0038 U+FE0F U+20E3',
		'category': 'symbols',
		'subCategory': 'keycap',
		'description': 'Keycap: 8'
	},
	'9️⃣': {
		'character': '9️⃣',
		'unicode': 'U+0039 U+FE0F U+20E3',
		'category': 'symbols',
		'subCategory': 'keycap',
		'description': 'Keycap: 9'
	},
	'🔟': {
		'character': '🔟',
		'unicode': 'U+1F51F',
		'category': 'symbols',
		'subCategory': 'keycap',
		'description': 'Keycap: 10'
	},
	'🔠': {
		'character': '🔠',
		'unicode': 'U+1F520',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Input latin uppercase'
	},
	'🔡': {
		'character': '🔡',
		'unicode': 'U+1F521',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Input latin lowercase'
	},
	'🔢': {
		'character': '🔢',
		'unicode': 'U+1F522',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Input numbers'
	},
	'🔣': {
		'character': '🔣',
		'unicode': 'U+1F523',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Input symbols'
	},
	'🔤': {
		'character': '🔤',
		'unicode': 'U+1F524',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Input latin letters'
	},
	'🅰': {
		'character': '🅰',
		'unicode': 'U+1F170',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'A button (blood type)'
	},
	'🆎': {
		'character': '🆎',
		'unicode': 'U+1F18E',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Ab button (blood type)'
	},
	'🅱': {
		'character': '🅱',
		'unicode': 'U+1F171',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'B button (blood type)'
	},
	'🆑': {
		'character': '🆑',
		'unicode': 'U+1F191',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Cl button'
	},
	'🆒': {
		'character': '🆒',
		'unicode': 'U+1F192',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Cool button'
	},
	'🆓': {
		'character': '🆓',
		'unicode': 'U+1F193',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Free button'
	},
	'ℹ': {
		'character': 'ℹ',
		'unicode': 'U+2139',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Information'
	},
	'🆔': {
		'character': '🆔',
		'unicode': 'U+1F194',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Id button'
	},
	'Ⓜ': {
		'character': 'Ⓜ',
		'unicode': 'U+24C2',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Circled m'
	},
	'🆕': {
		'character': '🆕',
		'unicode': 'U+1F195',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'New button'
	},
	'🆖': {
		'character': '🆖',
		'unicode': 'U+1F196',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Ng button'
	},
	'🅾': {
		'character': '🅾',
		'unicode': 'U+1F17E',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'O button (blood type)'
	},
	'🆗': {
		'character': '🆗',
		'unicode': 'U+1F197',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Ok button'
	},
	'🅿': {
		'character': '🅿',
		'unicode': 'U+1F17F',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'P button'
	},
	'🆘': {
		'character': '🆘',
		'unicode': 'U+1F198',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Sos button'
	},
	'🆙': {
		'character': '🆙',
		'unicode': 'U+1F199',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Up! button'
	},
	'🆚': {
		'character': '🆚',
		'unicode': 'U+1F19A',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Vs button'
	},
	'🈁': {
		'character': '🈁',
		'unicode': 'U+1F201',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “here” button'
	},
	'🈂': {
		'character': '🈂',
		'unicode': 'U+1F202',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “service charge” button'
	},
	'🈷': {
		'character': '🈷',
		'unicode': 'U+1F237',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “monthly amount” button'
	},
	'🈶': {
		'character': '🈶',
		'unicode': 'U+1F236',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “not free of charge” button'
	},
	'🈯': {
		'character': '🈯',
		'unicode': 'U+1F22F',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “reserved” button'
	},
	'🉐': {
		'character': '🉐',
		'unicode': 'U+1F250',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “bargain” button'
	},
	'🈹': {
		'character': '🈹',
		'unicode': 'U+1F239',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “discount” button'
	},
	'🈚': {
		'character': '🈚',
		'unicode': 'U+1F21A',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “free of charge” button'
	},
	'🈲': {
		'character': '🈲',
		'unicode': 'U+1F232',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “prohibited” button'
	},
	'🉑': {
		'character': '🉑',
		'unicode': 'U+1F251',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “acceptable” button'
	},
	'🈸': {
		'character': '🈸',
		'unicode': 'U+1F238',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “application” button'
	},
	'🈴': {
		'character': '🈴',
		'unicode': 'U+1F234',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “passing grade” button'
	},
	'🈳': {
		'character': '🈳',
		'unicode': 'U+1F233',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “vacancy” button'
	},
	'㊗': {
		'character': '㊗',
		'unicode': 'U+3297',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “congratulations” button'
	},
	'㊙': {
		'character': '㊙',
		'unicode': 'U+3299',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “secret” button'
	},
	'🈺': {
		'character': '🈺',
		'unicode': 'U+1F23A',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “open for business” button'
	},
	'🈵': {
		'character': '🈵',
		'unicode': 'U+1F235',
		'category': 'symbols',
		'subCategory': 'alphanum',
		'description': 'Japanese “no vacancy” button'
	},
	'🔴': {
		'character': '🔴',
		'unicode': 'U+1F534',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Red circle'
	},
	'🟠': {
		'character': '🟠',
		'unicode': 'U+1F7E0',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Orange circle'
	},
	'🟡': {
		'character': '🟡',
		'unicode': 'U+1F7E1',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Yellow circle'
	},
	'🟢': {
		'character': '🟢',
		'unicode': 'U+1F7E2',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Green circle'
	},
	'🔵': {
		'character': '🔵',
		'unicode': 'U+1F535',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Blue circle'
	},
	'🟣': {
		'character': '🟣',
		'unicode': 'U+1F7E3',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Purple circle'
	},
	'🟤': {
		'character': '🟤',
		'unicode': 'U+1F7E4',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Brown circle'
	},
	'⚫': {
		'character': '⚫',
		'unicode': 'U+26AB',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Black circle'
	},
	'⚪': {
		'character': '⚪',
		'unicode': 'U+26AA',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'White circle'
	},
	'🟥': {
		'character': '🟥',
		'unicode': 'U+1F7E5',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Red square'
	},
	'🟧': {
		'character': '🟧',
		'unicode': 'U+1F7E7',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Orange square'
	},
	'🟨': {
		'character': '🟨',
		'unicode': 'U+1F7E8',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Yellow square'
	},
	'🟩': {
		'character': '🟩',
		'unicode': 'U+1F7E9',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Green square'
	},
	'🟦': {
		'character': '🟦',
		'unicode': 'U+1F7E6',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Blue square'
	},
	'🟪': {
		'character': '🟪',
		'unicode': 'U+1F7EA',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Purple square'
	},
	'🟫': {
		'character': '🟫',
		'unicode': 'U+1F7EB',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Brown square'
	},
	'⬛': {
		'character': '⬛',
		'unicode': 'U+2B1B',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Black large square'
	},
	'⬜': {
		'character': '⬜',
		'unicode': 'U+2B1C',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'White large square'
	},
	'◼': {
		'character': '◼',
		'unicode': 'U+25FC',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Black medium square'
	},
	'◻': {
		'character': '◻',
		'unicode': 'U+25FB',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'White medium square'
	},
	'◾': {
		'character': '◾',
		'unicode': 'U+25FE',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Black medium-small square'
	},
	'◽': {
		'character': '◽',
		'unicode': 'U+25FD',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'White medium-small square'
	},
	'▪': {
		'character': '▪',
		'unicode': 'U+25AA',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Black small square'
	},
	'▫': {
		'character': '▫',
		'unicode': 'U+25AB',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'White small square'
	},
	'🔶': {
		'character': '🔶',
		'unicode': 'U+1F536',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Large orange diamond'
	},
	'🔷': {
		'character': '🔷',
		'unicode': 'U+1F537',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Large blue diamond'
	},
	'🔸': {
		'character': '🔸',
		'unicode': 'U+1F538',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Small orange diamond'
	},
	'🔹': {
		'character': '🔹',
		'unicode': 'U+1F539',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Small blue diamond'
	},
	'🔺': {
		'character': '🔺',
		'unicode': 'U+1F53A',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Red triangle pointed up'
	},
	'🔻': {
		'character': '🔻',
		'unicode': 'U+1F53B',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Red triangle pointed down'
	},
	'💠': {
		'character': '💠',
		'unicode': 'U+1F4A0',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Diamond with a dot'
	},
	'🔘': {
		'character': '🔘',
		'unicode': 'U+1F518',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Radio button'
	},
	'🔳': {
		'character': '🔳',
		'unicode': 'U+1F533',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'White square button'
	},
	'🔲': {
		'character': '🔲',
		'unicode': 'U+1F532',
		'category': 'symbols',
		'subCategory': 'geometric',
		'description': 'Black square button'
	},
	'🏁': {
		'character': '🏁',
		'unicode': 'U+1F3C1',
		'category': 'flags',
		'subCategory': 'flag',
		'description': 'Chequered flag'
	},
	'🚩': {
		'character': '🚩',
		'unicode': 'U+1F6A9',
		'category': 'flags',
		'subCategory': 'flag',
		'description': 'Triangular flag'
	},
	'🎌': {
		'character': '🎌',
		'unicode': 'U+1F38C',
		'category': 'flags',
		'subCategory': 'flag',
		'description': 'Crossed flags'
	},
	'🏴': {
		'character': '🏴',
		'unicode': 'U+1F3F4',
		'category': 'flags',
		'subCategory': 'flag',
		'description': 'Black flag'
	},
	'🏳': {
		'character': '🏳',
		'unicode': 'U+1F3F3',
		'category': 'flags',
		'subCategory': 'flag',
		'description': 'White flag'
	},
	'🏳️‍🌈': {
		'character': '🏳️‍🌈',
		'unicode': 'U+1F3F3 U+FE0F U+200D U+1F308',
		'category': 'flags',
		'subCategory': 'flag',
		'description': 'Rainbow flag'
	},
	'🏳️‍⚧️': {
		'character': '🏳️‍⚧️',
		'unicode': 'U+1F3F3 U+FE0F U+200D U+26A7 U+FE0F',
		'category': 'flags',
		'subCategory': 'flag',
		'description': 'Transgender flag'
	},
	'🏴‍☠️': {
		'character': '🏴‍☠️',
		'unicode': 'U+1F3F4 U+200D U+2620 U+FE0F',
		'category': 'flags',
		'subCategory': 'flag',
		'description': 'Pirate flag'
	},
	'🇦🇨': {
		'character': '🇦🇨',
		'unicode': 'U+1F1E6 U+1F1E8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: ascension island'
	},
	'🇦🇩': {
		'character': '🇦🇩',
		'unicode': 'U+1F1E6 U+1F1E9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: andorra'
	},
	'🇦🇪': {
		'character': '🇦🇪',
		'unicode': 'U+1F1E6 U+1F1EA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: united arab emirates'
	},
	'🇦🇫': {
		'character': '🇦🇫',
		'unicode': 'U+1F1E6 U+1F1EB',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: afghanistan'
	},
	'🇦🇬': {
		'character': '🇦🇬',
		'unicode': 'U+1F1E6 U+1F1EC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: antigua & barbuda'
	},
	'🇦🇮': {
		'character': '🇦🇮',
		'unicode': 'U+1F1E6 U+1F1EE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: anguilla'
	},
	'🇦🇱': {
		'character': '🇦🇱',
		'unicode': 'U+1F1E6 U+1F1F1',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: albania'
	},
	'🇦🇲': {
		'character': '🇦🇲',
		'unicode': 'U+1F1E6 U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: armenia'
	},
	'🇦🇴': {
		'character': '🇦🇴',
		'unicode': 'U+1F1E6 U+1F1F4',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: angola'
	},
	'🇦🇶': {
		'character': '🇦🇶',
		'unicode': 'U+1F1E6 U+1F1F6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: antarctica'
	},
	'🇦🇷': {
		'character': '🇦🇷',
		'unicode': 'U+1F1E6 U+1F1F7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: argentina'
	},
	'🇦🇸': {
		'character': '🇦🇸',
		'unicode': 'U+1F1E6 U+1F1F8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: american samoa'
	},
	'🇦🇹': {
		'character': '🇦🇹',
		'unicode': 'U+1F1E6 U+1F1F9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: austria'
	},
	'🇦🇺': {
		'character': '🇦🇺',
		'unicode': 'U+1F1E6 U+1F1FA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: australia'
	},
	'🇦🇼': {
		'character': '🇦🇼',
		'unicode': 'U+1F1E6 U+1F1FC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: aruba'
	},
	'🇦🇽': {
		'character': '🇦🇽',
		'unicode': 'U+1F1E6 U+1F1FD',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: åland islands'
	},
	'🇦🇿': {
		'character': '🇦🇿',
		'unicode': 'U+1F1E6 U+1F1FF',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: azerbaijan'
	},
	'🇧🇦': {
		'character': '🇧🇦',
		'unicode': 'U+1F1E7 U+1F1E6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: bosnia & herzegovina'
	},
	'🇧🇧': {
		'character': '🇧🇧',
		'unicode': 'U+1F1E7 U+1F1E7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: barbados'
	},
	'🇧🇩': {
		'character': '🇧🇩',
		'unicode': 'U+1F1E7 U+1F1E9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: bangladesh'
	},
	'🇧🇪': {
		'character': '🇧🇪',
		'unicode': 'U+1F1E7 U+1F1EA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: belgium'
	},
	'🇧🇫': {
		'character': '🇧🇫',
		'unicode': 'U+1F1E7 U+1F1EB',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: burkina faso'
	},
	'🇧🇬': {
		'character': '🇧🇬',
		'unicode': 'U+1F1E7 U+1F1EC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: bulgaria'
	},
	'🇧🇭': {
		'character': '🇧🇭',
		'unicode': 'U+1F1E7 U+1F1ED',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: bahrain'
	},
	'🇧🇮': {
		'character': '🇧🇮',
		'unicode': 'U+1F1E7 U+1F1EE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: burundi'
	},
	'🇧🇯': {
		'character': '🇧🇯',
		'unicode': 'U+1F1E7 U+1F1EF',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: benin'
	},
	'🇧🇱': {
		'character': '🇧🇱',
		'unicode': 'U+1F1E7 U+1F1F1',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: st. barthélemy'
	},
	'🇧🇲': {
		'character': '🇧🇲',
		'unicode': 'U+1F1E7 U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: bermuda'
	},
	'🇧🇳': {
		'character': '🇧🇳',
		'unicode': 'U+1F1E7 U+1F1F3',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: brunei'
	},
	'🇧🇴': {
		'character': '🇧🇴',
		'unicode': 'U+1F1E7 U+1F1F4',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: bolivia'
	},
	'🇧🇶': {
		'character': '🇧🇶',
		'unicode': 'U+1F1E7 U+1F1F6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: caribbean netherlands'
	},
	'🇧🇷': {
		'character': '🇧🇷',
		'unicode': 'U+1F1E7 U+1F1F7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: brazil'
	},
	'🇧🇸': {
		'character': '🇧🇸',
		'unicode': 'U+1F1E7 U+1F1F8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: bahamas'
	},
	'🇧🇹': {
		'character': '🇧🇹',
		'unicode': 'U+1F1E7 U+1F1F9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: bhutan'
	},
	'🇧🇻': {
		'character': '🇧🇻',
		'unicode': 'U+1F1E7 U+1F1FB',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: bouvet island'
	},
	'🇧🇼': {
		'character': '🇧🇼',
		'unicode': 'U+1F1E7 U+1F1FC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: botswana'
	},
	'🇧🇾': {
		'character': '🇧🇾',
		'unicode': 'U+1F1E7 U+1F1FE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: belarus'
	},
	'🇧🇿': {
		'character': '🇧🇿',
		'unicode': 'U+1F1E7 U+1F1FF',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: belize'
	},
	'🇨🇦': {
		'character': '🇨🇦',
		'unicode': 'U+1F1E8 U+1F1E6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: canada'
	},
	'🇨🇨': {
		'character': '🇨🇨',
		'unicode': 'U+1F1E8 U+1F1E8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: cocos (keeling) islands'
	},
	'🇨🇩': {
		'character': '🇨🇩',
		'unicode': 'U+1F1E8 U+1F1E9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: congo - kinshasa'
	},
	'🇨🇫': {
		'character': '🇨🇫',
		'unicode': 'U+1F1E8 U+1F1EB',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: central african republic'
	},
	'🇨🇬': {
		'character': '🇨🇬',
		'unicode': 'U+1F1E8 U+1F1EC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: congo - brazzaville'
	},
	'🇨🇭': {
		'character': '🇨🇭',
		'unicode': 'U+1F1E8 U+1F1ED',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: switzerland'
	},
	'🇨🇮': {
		'character': '🇨🇮',
		'unicode': 'U+1F1E8 U+1F1EE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: côte d’ivoire'
	},
	'🇨🇰': {
		'character': '🇨🇰',
		'unicode': 'U+1F1E8 U+1F1F0',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: cook islands'
	},
	'🇨🇱': {
		'character': '🇨🇱',
		'unicode': 'U+1F1E8 U+1F1F1',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: chile'
	},
	'🇨🇲': {
		'character': '🇨🇲',
		'unicode': 'U+1F1E8 U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: cameroon'
	},
	'🇨🇳': {
		'character': '🇨🇳',
		'unicode': 'U+1F1E8 U+1F1F3',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: china'
	},
	'🇨🇴': {
		'character': '🇨🇴',
		'unicode': 'U+1F1E8 U+1F1F4',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: colombia'
	},
	'🇨🇵': {
		'character': '🇨🇵',
		'unicode': 'U+1F1E8 U+1F1F5',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: clipperton island'
	},
	'🇨🇷': {
		'character': '🇨🇷',
		'unicode': 'U+1F1E8 U+1F1F7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: costa rica'
	},
	'🇨🇺': {
		'character': '🇨🇺',
		'unicode': 'U+1F1E8 U+1F1FA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: cuba'
	},
	'🇨🇻': {
		'character': '🇨🇻',
		'unicode': 'U+1F1E8 U+1F1FB',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: cape verde'
	},
	'🇨🇼': {
		'character': '🇨🇼',
		'unicode': 'U+1F1E8 U+1F1FC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: curaçao'
	},
	'🇨🇽': {
		'character': '🇨🇽',
		'unicode': 'U+1F1E8 U+1F1FD',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: christmas island'
	},
	'🇨🇾': {
		'character': '🇨🇾',
		'unicode': 'U+1F1E8 U+1F1FE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: cyprus'
	},
	'🇨🇿': {
		'character': '🇨🇿',
		'unicode': 'U+1F1E8 U+1F1FF',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: czechia'
	},
	'🇩🇪': {
		'character': '🇩🇪',
		'unicode': 'U+1F1E9 U+1F1EA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: germany'
	},
	'🇩🇬': {
		'character': '🇩🇬',
		'unicode': 'U+1F1E9 U+1F1EC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: diego garcia'
	},
	'🇩🇯': {
		'character': '🇩🇯',
		'unicode': 'U+1F1E9 U+1F1EF',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: djibouti'
	},
	'🇩🇰': {
		'character': '🇩🇰',
		'unicode': 'U+1F1E9 U+1F1F0',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: denmark'
	},
	'🇩🇲': {
		'character': '🇩🇲',
		'unicode': 'U+1F1E9 U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: dominica'
	},
	'🇩🇴': {
		'character': '🇩🇴',
		'unicode': 'U+1F1E9 U+1F1F4',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: dominican republic'
	},
	'🇩🇿': {
		'character': '🇩🇿',
		'unicode': 'U+1F1E9 U+1F1FF',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: algeria'
	},
	'🇪🇦': {
		'character': '🇪🇦',
		'unicode': 'U+1F1EA U+1F1E6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: ceuta & melilla'
	},
	'🇪🇨': {
		'character': '🇪🇨',
		'unicode': 'U+1F1EA U+1F1E8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: ecuador'
	},
	'🇪🇪': {
		'character': '🇪🇪',
		'unicode': 'U+1F1EA U+1F1EA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: estonia'
	},
	'🇪🇬': {
		'character': '🇪🇬',
		'unicode': 'U+1F1EA U+1F1EC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: egypt'
	},
	'🇪🇭': {
		'character': '🇪🇭',
		'unicode': 'U+1F1EA U+1F1ED',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: western sahara'
	},
	'🇪🇷': {
		'character': '🇪🇷',
		'unicode': 'U+1F1EA U+1F1F7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: eritrea'
	},
	'🇪🇸': {
		'character': '🇪🇸',
		'unicode': 'U+1F1EA U+1F1F8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: spain'
	},
	'🇪🇹': {
		'character': '🇪🇹',
		'unicode': 'U+1F1EA U+1F1F9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: ethiopia'
	},
	'🇪🇺': {
		'character': '🇪🇺',
		'unicode': 'U+1F1EA U+1F1FA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: european union'
	},
	'🇫🇮': {
		'character': '🇫🇮',
		'unicode': 'U+1F1EB U+1F1EE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: finland'
	},
	'🇫🇯': {
		'character': '🇫🇯',
		'unicode': 'U+1F1EB U+1F1EF',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: fiji'
	},
	'🇫🇰': {
		'character': '🇫🇰',
		'unicode': 'U+1F1EB U+1F1F0',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: falkland islands'
	},
	'🇫🇲': {
		'character': '🇫🇲',
		'unicode': 'U+1F1EB U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: micronesia'
	},
	'🇫🇴': {
		'character': '🇫🇴',
		'unicode': 'U+1F1EB U+1F1F4',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: faroe islands'
	},
	'🇫🇷': {
		'character': '🇫🇷',
		'unicode': 'U+1F1EB U+1F1F7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: france'
	},
	'🇬🇦': {
		'character': '🇬🇦',
		'unicode': 'U+1F1EC U+1F1E6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: gabon'
	},
	'🇬🇧': {
		'character': '🇬🇧',
		'unicode': 'U+1F1EC U+1F1E7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: united kingdom'
	},
	'🇬🇩': {
		'character': '🇬🇩',
		'unicode': 'U+1F1EC U+1F1E9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: grenada'
	},
	'🇬🇪': {
		'character': '🇬🇪',
		'unicode': 'U+1F1EC U+1F1EA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: georgia'
	},
	'🇬🇫': {
		'character': '🇬🇫',
		'unicode': 'U+1F1EC U+1F1EB',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: french guiana'
	},
	'🇬🇬': {
		'character': '🇬🇬',
		'unicode': 'U+1F1EC U+1F1EC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: guernsey'
	},
	'🇬🇭': {
		'character': '🇬🇭',
		'unicode': 'U+1F1EC U+1F1ED',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: ghana'
	},
	'🇬🇮': {
		'character': '🇬🇮',
		'unicode': 'U+1F1EC U+1F1EE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: gibraltar'
	},
	'🇬🇱': {
		'character': '🇬🇱',
		'unicode': 'U+1F1EC U+1F1F1',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: greenland'
	},
	'🇬🇲': {
		'character': '🇬🇲',
		'unicode': 'U+1F1EC U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: gambia'
	},
	'🇬🇳': {
		'character': '🇬🇳',
		'unicode': 'U+1F1EC U+1F1F3',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: guinea'
	},
	'🇬🇵': {
		'character': '🇬🇵',
		'unicode': 'U+1F1EC U+1F1F5',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: guadeloupe'
	},
	'🇬🇶': {
		'character': '🇬🇶',
		'unicode': 'U+1F1EC U+1F1F6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: equatorial guinea'
	},
	'🇬🇷': {
		'character': '🇬🇷',
		'unicode': 'U+1F1EC U+1F1F7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: greece'
	},
	'🇬🇸': {
		'character': '🇬🇸',
		'unicode': 'U+1F1EC U+1F1F8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: south georgia & south sandwich islands'
	},
	'🇬🇹': {
		'character': '🇬🇹',
		'unicode': 'U+1F1EC U+1F1F9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: guatemala'
	},
	'🇬🇺': {
		'character': '🇬🇺',
		'unicode': 'U+1F1EC U+1F1FA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: guam'
	},
	'🇬🇼': {
		'character': '🇬🇼',
		'unicode': 'U+1F1EC U+1F1FC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: guinea-bissau'
	},
	'🇬🇾': {
		'character': '🇬🇾',
		'unicode': 'U+1F1EC U+1F1FE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: guyana'
	},
	'🇭🇰': {
		'character': '🇭🇰',
		'unicode': 'U+1F1ED U+1F1F0',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: hong kong sar china'
	},
	'🇭🇲': {
		'character': '🇭🇲',
		'unicode': 'U+1F1ED U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: heard & mcdonald islands'
	},
	'🇭🇳': {
		'character': '🇭🇳',
		'unicode': 'U+1F1ED U+1F1F3',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: honduras'
	},
	'🇭🇷': {
		'character': '🇭🇷',
		'unicode': 'U+1F1ED U+1F1F7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: croatia'
	},
	'🇭🇹': {
		'character': '🇭🇹',
		'unicode': 'U+1F1ED U+1F1F9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: haiti'
	},
	'🇭🇺': {
		'character': '🇭🇺',
		'unicode': 'U+1F1ED U+1F1FA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: hungary'
	},
	'🇮🇨': {
		'character': '🇮🇨',
		'unicode': 'U+1F1EE U+1F1E8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: canary islands'
	},
	'🇮🇩': {
		'character': '🇮🇩',
		'unicode': 'U+1F1EE U+1F1E9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: indonesia'
	},
	'🇮🇪': {
		'character': '🇮🇪',
		'unicode': 'U+1F1EE U+1F1EA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: ireland'
	},
	'🇮🇱': {
		'character': '🇮🇱',
		'unicode': 'U+1F1EE U+1F1F1',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: israel'
	},
	'🇮🇲': {
		'character': '🇮🇲',
		'unicode': 'U+1F1EE U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: isle of man'
	},
	'🇮🇳': {
		'character': '🇮🇳',
		'unicode': 'U+1F1EE U+1F1F3',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: india'
	},
	'🇮🇴': {
		'character': '🇮🇴',
		'unicode': 'U+1F1EE U+1F1F4',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: british indian ocean territory'
	},
	'🇮🇶': {
		'character': '🇮🇶',
		'unicode': 'U+1F1EE U+1F1F6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: iraq'
	},
	'🇮🇷': {
		'character': '🇮🇷',
		'unicode': 'U+1F1EE U+1F1F7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: iran'
	},
	'🇮🇸': {
		'character': '🇮🇸',
		'unicode': 'U+1F1EE U+1F1F8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: iceland'
	},
	'🇮🇹': {
		'character': '🇮🇹',
		'unicode': 'U+1F1EE U+1F1F9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: italy'
	},
	'🇯🇪': {
		'character': '🇯🇪',
		'unicode': 'U+1F1EF U+1F1EA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: jersey'
	},
	'🇯🇲': {
		'character': '🇯🇲',
		'unicode': 'U+1F1EF U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: jamaica'
	},
	'🇯🇴': {
		'character': '🇯🇴',
		'unicode': 'U+1F1EF U+1F1F4',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: jordan'
	},
	'🇯🇵': {
		'character': '🇯🇵',
		'unicode': 'U+1F1EF U+1F1F5',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: japan'
	},
	'🇰🇪': {
		'character': '🇰🇪',
		'unicode': 'U+1F1F0 U+1F1EA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: kenya'
	},
	'🇰🇬': {
		'character': '🇰🇬',
		'unicode': 'U+1F1F0 U+1F1EC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: kyrgyzstan'
	},
	'🇰🇭': {
		'character': '🇰🇭',
		'unicode': 'U+1F1F0 U+1F1ED',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: cambodia'
	},
	'🇰🇮': {
		'character': '🇰🇮',
		'unicode': 'U+1F1F0 U+1F1EE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: kiribati'
	},
	'🇰🇲': {
		'character': '🇰🇲',
		'unicode': 'U+1F1F0 U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: comoros'
	},
	'🇰🇳': {
		'character': '🇰🇳',
		'unicode': 'U+1F1F0 U+1F1F3',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: st. kitts & nevis'
	},
	'🇰🇵': {
		'character': '🇰🇵',
		'unicode': 'U+1F1F0 U+1F1F5',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: north korea'
	},
	'🇰🇷': {
		'character': '🇰🇷',
		'unicode': 'U+1F1F0 U+1F1F7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: south korea'
	},
	'🇰🇼': {
		'character': '🇰🇼',
		'unicode': 'U+1F1F0 U+1F1FC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: kuwait'
	},
	'🇰🇾': {
		'character': '🇰🇾',
		'unicode': 'U+1F1F0 U+1F1FE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: cayman islands'
	},
	'🇰🇿': {
		'character': '🇰🇿',
		'unicode': 'U+1F1F0 U+1F1FF',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: kazakhstan'
	},
	'🇱🇦': {
		'character': '🇱🇦',
		'unicode': 'U+1F1F1 U+1F1E6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: laos'
	},
	'🇱🇧': {
		'character': '🇱🇧',
		'unicode': 'U+1F1F1 U+1F1E7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: lebanon'
	},
	'🇱🇨': {
		'character': '🇱🇨',
		'unicode': 'U+1F1F1 U+1F1E8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: st. lucia'
	},
	'🇱🇮': {
		'character': '🇱🇮',
		'unicode': 'U+1F1F1 U+1F1EE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: liechtenstein'
	},
	'🇱🇰': {
		'character': '🇱🇰',
		'unicode': 'U+1F1F1 U+1F1F0',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: sri lanka'
	},
	'🇱🇷': {
		'character': '🇱🇷',
		'unicode': 'U+1F1F1 U+1F1F7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: liberia'
	},
	'🇱🇸': {
		'character': '🇱🇸',
		'unicode': 'U+1F1F1 U+1F1F8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: lesotho'
	},
	'🇱🇹': {
		'character': '🇱🇹',
		'unicode': 'U+1F1F1 U+1F1F9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: lithuania'
	},
	'🇱🇺': {
		'character': '🇱🇺',
		'unicode': 'U+1F1F1 U+1F1FA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: luxembourg'
	},
	'🇱🇻': {
		'character': '🇱🇻',
		'unicode': 'U+1F1F1 U+1F1FB',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: latvia'
	},
	'🇱🇾': {
		'character': '🇱🇾',
		'unicode': 'U+1F1F1 U+1F1FE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: libya'
	},
	'🇲🇦': {
		'character': '🇲🇦',
		'unicode': 'U+1F1F2 U+1F1E6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: morocco'
	},
	'🇲🇨': {
		'character': '🇲🇨',
		'unicode': 'U+1F1F2 U+1F1E8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: monaco'
	},
	'🇲🇩': {
		'character': '🇲🇩',
		'unicode': 'U+1F1F2 U+1F1E9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: moldova'
	},
	'🇲🇪': {
		'character': '🇲🇪',
		'unicode': 'U+1F1F2 U+1F1EA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: montenegro'
	},
	'🇲🇫': {
		'character': '🇲🇫',
		'unicode': 'U+1F1F2 U+1F1EB',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: st. martin'
	},
	'🇲🇬': {
		'character': '🇲🇬',
		'unicode': 'U+1F1F2 U+1F1EC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: madagascar'
	},
	'🇲🇭': {
		'character': '🇲🇭',
		'unicode': 'U+1F1F2 U+1F1ED',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: marshall islands'
	},
	'🇲🇰': {
		'character': '🇲🇰',
		'unicode': 'U+1F1F2 U+1F1F0',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: north macedonia'
	},
	'🇲🇱': {
		'character': '🇲🇱',
		'unicode': 'U+1F1F2 U+1F1F1',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: mali'
	},
	'🇲🇲': {
		'character': '🇲🇲',
		'unicode': 'U+1F1F2 U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: myanmar (burma)'
	},
	'🇲🇳': {
		'character': '🇲🇳',
		'unicode': 'U+1F1F2 U+1F1F3',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: mongolia'
	},
	'🇲🇴': {
		'character': '🇲🇴',
		'unicode': 'U+1F1F2 U+1F1F4',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: macao sar china'
	},
	'🇲🇵': {
		'character': '🇲🇵',
		'unicode': 'U+1F1F2 U+1F1F5',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: northern mariana islands'
	},
	'🇲🇶': {
		'character': '🇲🇶',
		'unicode': 'U+1F1F2 U+1F1F6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: martinique'
	},
	'🇲🇷': {
		'character': '🇲🇷',
		'unicode': 'U+1F1F2 U+1F1F7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: mauritania'
	},
	'🇲🇸': {
		'character': '🇲🇸',
		'unicode': 'U+1F1F2 U+1F1F8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: montserrat'
	},
	'🇲🇹': {
		'character': '🇲🇹',
		'unicode': 'U+1F1F2 U+1F1F9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: malta'
	},
	'🇲🇺': {
		'character': '🇲🇺',
		'unicode': 'U+1F1F2 U+1F1FA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: mauritius'
	},
	'🇲🇻': {
		'character': '🇲🇻',
		'unicode': 'U+1F1F2 U+1F1FB',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: maldives'
	},
	'🇲🇼': {
		'character': '🇲🇼',
		'unicode': 'U+1F1F2 U+1F1FC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: malawi'
	},
	'🇲🇽': {
		'character': '🇲🇽',
		'unicode': 'U+1F1F2 U+1F1FD',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: mexico'
	},
	'🇲🇾': {
		'character': '🇲🇾',
		'unicode': 'U+1F1F2 U+1F1FE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: malaysia'
	},
	'🇲🇿': {
		'character': '🇲🇿',
		'unicode': 'U+1F1F2 U+1F1FF',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: mozambique'
	},
	'🇳🇦': {
		'character': '🇳🇦',
		'unicode': 'U+1F1F3 U+1F1E6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: namibia'
	},
	'🇳🇨': {
		'character': '🇳🇨',
		'unicode': 'U+1F1F3 U+1F1E8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: new caledonia'
	},
	'🇳🇪': {
		'character': '🇳🇪',
		'unicode': 'U+1F1F3 U+1F1EA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: niger'
	},
	'🇳🇫': {
		'character': '🇳🇫',
		'unicode': 'U+1F1F3 U+1F1EB',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: norfolk island'
	},
	'🇳🇬': {
		'character': '🇳🇬',
		'unicode': 'U+1F1F3 U+1F1EC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: nigeria'
	},
	'🇳🇮': {
		'character': '🇳🇮',
		'unicode': 'U+1F1F3 U+1F1EE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: nicaragua'
	},
	'🇳🇱': {
		'character': '🇳🇱',
		'unicode': 'U+1F1F3 U+1F1F1',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: netherlands'
	},
	'🇳🇴': {
		'character': '🇳🇴',
		'unicode': 'U+1F1F3 U+1F1F4',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: norway'
	},
	'🇳🇵': {
		'character': '🇳🇵',
		'unicode': 'U+1F1F3 U+1F1F5',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: nepal'
	},
	'🇳🇷': {
		'character': '🇳🇷',
		'unicode': 'U+1F1F3 U+1F1F7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: nauru'
	},
	'🇳🇺': {
		'character': '🇳🇺',
		'unicode': 'U+1F1F3 U+1F1FA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: niue'
	},
	'🇳🇿': {
		'character': '🇳🇿',
		'unicode': 'U+1F1F3 U+1F1FF',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: new zealand'
	},
	'🇴🇲': {
		'character': '🇴🇲',
		'unicode': 'U+1F1F4 U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: oman'
	},
	'🇵🇦': {
		'character': '🇵🇦',
		'unicode': 'U+1F1F5 U+1F1E6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: panama'
	},
	'🇵🇪': {
		'character': '🇵🇪',
		'unicode': 'U+1F1F5 U+1F1EA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: peru'
	},
	'🇵🇫': {
		'character': '🇵🇫',
		'unicode': 'U+1F1F5 U+1F1EB',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: french polynesia'
	},
	'🇵🇬': {
		'character': '🇵🇬',
		'unicode': 'U+1F1F5 U+1F1EC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: papua new guinea'
	},
	'🇵🇭': {
		'character': '🇵🇭',
		'unicode': 'U+1F1F5 U+1F1ED',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: philippines'
	},
	'🇵🇰': {
		'character': '🇵🇰',
		'unicode': 'U+1F1F5 U+1F1F0',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: pakistan'
	},
	'🇵🇱': {
		'character': '🇵🇱',
		'unicode': 'U+1F1F5 U+1F1F1',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: poland'
	},
	'🇵🇲': {
		'character': '🇵🇲',
		'unicode': 'U+1F1F5 U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: st. pierre & miquelon'
	},
	'🇵🇳': {
		'character': '🇵🇳',
		'unicode': 'U+1F1F5 U+1F1F3',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: pitcairn islands'
	},
	'🇵🇷': {
		'character': '🇵🇷',
		'unicode': 'U+1F1F5 U+1F1F7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: puerto rico'
	},
	'🇵🇸': {
		'character': '🇵🇸',
		'unicode': 'U+1F1F5 U+1F1F8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: palestinian territories'
	},
	'🇵🇹': {
		'character': '🇵🇹',
		'unicode': 'U+1F1F5 U+1F1F9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: portugal'
	},
	'🇵🇼': {
		'character': '🇵🇼',
		'unicode': 'U+1F1F5 U+1F1FC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: palau'
	},
	'🇵🇾': {
		'character': '🇵🇾',
		'unicode': 'U+1F1F5 U+1F1FE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: paraguay'
	},
	'🇶🇦': {
		'character': '🇶🇦',
		'unicode': 'U+1F1F6 U+1F1E6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: qatar'
	},
	'🇷🇪': {
		'character': '🇷🇪',
		'unicode': 'U+1F1F7 U+1F1EA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: réunion'
	},
	'🇷🇴': {
		'character': '🇷🇴',
		'unicode': 'U+1F1F7 U+1F1F4',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: romania'
	},
	'🇷🇸': {
		'character': '🇷🇸',
		'unicode': 'U+1F1F7 U+1F1F8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: serbia'
	},
	'🇷🇺': {
		'character': '🇷🇺',
		'unicode': 'U+1F1F7 U+1F1FA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: russia'
	},
	'🇷🇼': {
		'character': '🇷🇼',
		'unicode': 'U+1F1F7 U+1F1FC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: rwanda'
	},
	'🇸🇦': {
		'character': '🇸🇦',
		'unicode': 'U+1F1F8 U+1F1E6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: saudi arabia'
	},
	'🇸🇧': {
		'character': '🇸🇧',
		'unicode': 'U+1F1F8 U+1F1E7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: solomon islands'
	},
	'🇸🇨': {
		'character': '🇸🇨',
		'unicode': 'U+1F1F8 U+1F1E8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: seychelles'
	},
	'🇸🇩': {
		'character': '🇸🇩',
		'unicode': 'U+1F1F8 U+1F1E9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: sudan'
	},
	'🇸🇪': {
		'character': '🇸🇪',
		'unicode': 'U+1F1F8 U+1F1EA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: sweden'
	},
	'🇸🇬': {
		'character': '🇸🇬',
		'unicode': 'U+1F1F8 U+1F1EC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: singapore'
	},
	'🇸🇭': {
		'character': '🇸🇭',
		'unicode': 'U+1F1F8 U+1F1ED',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: st. helena'
	},
	'🇸🇮': {
		'character': '🇸🇮',
		'unicode': 'U+1F1F8 U+1F1EE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: slovenia'
	},
	'🇸🇯': {
		'character': '🇸🇯',
		'unicode': 'U+1F1F8 U+1F1EF',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: svalbard & jan mayen'
	},
	'🇸🇰': {
		'character': '🇸🇰',
		'unicode': 'U+1F1F8 U+1F1F0',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: slovakia'
	},
	'🇸🇱': {
		'character': '🇸🇱',
		'unicode': 'U+1F1F8 U+1F1F1',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: sierra leone'
	},
	'🇸🇲': {
		'character': '🇸🇲',
		'unicode': 'U+1F1F8 U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: san marino'
	},
	'🇸🇳': {
		'character': '🇸🇳',
		'unicode': 'U+1F1F8 U+1F1F3',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: senegal'
	},
	'🇸🇴': {
		'character': '🇸🇴',
		'unicode': 'U+1F1F8 U+1F1F4',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: somalia'
	},
	'🇸🇷': {
		'character': '🇸🇷',
		'unicode': 'U+1F1F8 U+1F1F7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: suriname'
	},
	'🇸🇸': {
		'character': '🇸🇸',
		'unicode': 'U+1F1F8 U+1F1F8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: south sudan'
	},
	'🇸🇹': {
		'character': '🇸🇹',
		'unicode': 'U+1F1F8 U+1F1F9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: são tomé & príncipe'
	},
	'🇸🇻': {
		'character': '🇸🇻',
		'unicode': 'U+1F1F8 U+1F1FB',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: el salvador'
	},
	'🇸🇽': {
		'character': '🇸🇽',
		'unicode': 'U+1F1F8 U+1F1FD',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: sint maarten'
	},
	'🇸🇾': {
		'character': '🇸🇾',
		'unicode': 'U+1F1F8 U+1F1FE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: syria'
	},
	'🇸🇿': {
		'character': '🇸🇿',
		'unicode': 'U+1F1F8 U+1F1FF',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: eswatini'
	},
	'🇹🇦': {
		'character': '🇹🇦',
		'unicode': 'U+1F1F9 U+1F1E6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: tristan da cunha'
	},
	'🇹🇨': {
		'character': '🇹🇨',
		'unicode': 'U+1F1F9 U+1F1E8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: turks & caicos islands'
	},
	'🇹🇩': {
		'character': '🇹🇩',
		'unicode': 'U+1F1F9 U+1F1E9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: chad'
	},
	'🇹🇫': {
		'character': '🇹🇫',
		'unicode': 'U+1F1F9 U+1F1EB',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: french southern territories'
	},
	'🇹🇬': {
		'character': '🇹🇬',
		'unicode': 'U+1F1F9 U+1F1EC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: togo'
	},
	'🇹🇭': {
		'character': '🇹🇭',
		'unicode': 'U+1F1F9 U+1F1ED',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: thailand'
	},
	'🇹🇯': {
		'character': '🇹🇯',
		'unicode': 'U+1F1F9 U+1F1EF',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: tajikistan'
	},
	'🇹🇰': {
		'character': '🇹🇰',
		'unicode': 'U+1F1F9 U+1F1F0',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: tokelau'
	},
	'🇹🇱': {
		'character': '🇹🇱',
		'unicode': 'U+1F1F9 U+1F1F1',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: timor-leste'
	},
	'🇹🇲': {
		'character': '🇹🇲',
		'unicode': 'U+1F1F9 U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: turkmenistan'
	},
	'🇹🇳': {
		'character': '🇹🇳',
		'unicode': 'U+1F1F9 U+1F1F3',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: tunisia'
	},
	'🇹🇴': {
		'character': '🇹🇴',
		'unicode': 'U+1F1F9 U+1F1F4',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: tonga'
	},
	'🇹🇷': {
		'character': '🇹🇷',
		'unicode': 'U+1F1F9 U+1F1F7',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: turkey'
	},
	'🇹🇹': {
		'character': '🇹🇹',
		'unicode': 'U+1F1F9 U+1F1F9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: trinidad & tobago'
	},
	'🇹🇻': {
		'character': '🇹🇻',
		'unicode': 'U+1F1F9 U+1F1FB',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: tuvalu'
	},
	'🇹🇼': {
		'character': '🇹🇼',
		'unicode': 'U+1F1F9 U+1F1FC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: taiwan'
	},
	'🇹🇿': {
		'character': '🇹🇿',
		'unicode': 'U+1F1F9 U+1F1FF',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: tanzania'
	},
	'🇺🇦': {
		'character': '🇺🇦',
		'unicode': 'U+1F1FA U+1F1E6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: ukraine'
	},
	'🇺🇬': {
		'character': '🇺🇬',
		'unicode': 'U+1F1FA U+1F1EC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: uganda'
	},
	'🇺🇲': {
		'character': '🇺🇲',
		'unicode': 'U+1F1FA U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: u.s. outlying islands'
	},
	'🇺🇳': {
		'character': '🇺🇳',
		'unicode': 'U+1F1FA U+1F1F3',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: united nations'
	},
	'🇺🇸': {
		'character': '🇺🇸',
		'unicode': 'U+1F1FA U+1F1F8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: united states'
	},
	'🇺🇾': {
		'character': '🇺🇾',
		'unicode': 'U+1F1FA U+1F1FE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: uruguay'
	},
	'🇺🇿': {
		'character': '🇺🇿',
		'unicode': 'U+1F1FA U+1F1FF',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: uzbekistan'
	},
	'🇻🇦': {
		'character': '🇻🇦',
		'unicode': 'U+1F1FB U+1F1E6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: vatican city'
	},
	'🇻🇨': {
		'character': '🇻🇨',
		'unicode': 'U+1F1FB U+1F1E8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: st. vincent & grenadines'
	},
	'🇻🇪': {
		'character': '🇻🇪',
		'unicode': 'U+1F1FB U+1F1EA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: venezuela'
	},
	'🇻🇬': {
		'character': '🇻🇬',
		'unicode': 'U+1F1FB U+1F1EC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: british virgin islands'
	},
	'🇻🇮': {
		'character': '🇻🇮',
		'unicode': 'U+1F1FB U+1F1EE',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: u.s. virgin islands'
	},
	'🇻🇳': {
		'character': '🇻🇳',
		'unicode': 'U+1F1FB U+1F1F3',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: vietnam'
	},
	'🇻🇺': {
		'character': '🇻🇺',
		'unicode': 'U+1F1FB U+1F1FA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: vanuatu'
	},
	'🇼🇫': {
		'character': '🇼🇫',
		'unicode': 'U+1F1FC U+1F1EB',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: wallis & futuna'
	},
	'🇼🇸': {
		'character': '🇼🇸',
		'unicode': 'U+1F1FC U+1F1F8',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: samoa'
	},
	'🇽🇰': {
		'character': '🇽🇰',
		'unicode': 'U+1F1FD U+1F1F0',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: kosovo'
	},
	'🇾🇪': {
		'character': '🇾🇪',
		'unicode': 'U+1F1FE U+1F1EA',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: yemen'
	},
	'🇾🇹': {
		'character': '🇾🇹',
		'unicode': 'U+1F1FE U+1F1F9',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: mayotte'
	},
	'🇿🇦': {
		'character': '🇿🇦',
		'unicode': 'U+1F1FF U+1F1E6',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: south africa'
	},
	'🇿🇲': {
		'character': '🇿🇲',
		'unicode': 'U+1F1FF U+1F1F2',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: zambia'
	},
	'🇿🇼': {
		'character': '🇿🇼',
		'unicode': 'U+1F1FF U+1F1FC',
		'category': 'flags',
		'subCategory': 'country-flag',
		'description': 'Flag: zimbabwe'
	},
	'🏴󠁧󠁢󠁥󠁮󠁧󠁿': {
		'character': '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
		'unicode': 'U+1F3F4 U+E0067 U+E0062 U+E0065 U+E006E U+E0067 U+E007F',
		'category': 'flags',
		'subCategory': 'subdivision-flag',
		'description': 'Flag: england'
	},
	'🏴󠁧󠁢󠁳󠁣󠁴󠁿': {
		'character': '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
		'unicode': 'U+1F3F4 U+E0067 U+E0062 U+E0073 U+E0063 U+E0074 U+E007F',
		'category': 'flags',
		'subCategory': 'subdivision-flag',
		'description': 'Flag: scotland'
	},
	'🏴󠁧󠁢󠁷󠁬󠁳󠁿': {
		'character': '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
		'unicode': 'U+1F3F4 U+E0067 U+E0062 U+E0077 U+E006C U+E0073 U+E007F',
		'category': 'flags',
		'subCategory': 'subdivision-flag',
		'description': 'Flag: wales'
	}
}

// Filter out certain categories, and only show the supported emojis for this browser.
const skipCategory = ['component']

//	TODO: WARNING: The call to checkEmojiSupport is expensive/unoptimised... We should simply check this across browsers, and have a hard-coded set of emojis instead...
emojis = Object.keys(emojis).filter(key => (
	skipCategory.indexOf(emojis[key].category) === -1 &&
	checkEmojiSupport(emojis[key].character) &&
	emojis[key].unicode.length === 7
)).map(key => emojis[key]).reduce((acc, emo) => {
	acc[emo.character] = emo
	return acc
}, {})

export default emojis
