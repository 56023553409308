import { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import HomeIcon from '@mui/icons-material/Home'
import { Link, To } from 'react-router-dom'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { IconType } from 'kitfit-community-common'
import { SvgIconTypeMap } from '@mui/material'

//	Returns what colour coding we want based on value - TODO: configurable per user and category in their profile, eg: 5 tickets might not be many for one user, but a lot for another user, so the colours should reflect that.
const getNumberColour = (number?: number) => {
	if (number === undefined) return 'black'
	if (number < 4) {
		return 'green'
	}
	if (number < 8) {
		return 'blue'
	}
	return number > 0 ? 'red' : 'black'
}

export interface ItemType {
	href: To,
	number?: number,
	isSelected: boolean,
	label: string
}
export interface SidebarButtonPropsType {
	Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> | IconType,
	isSelected?: boolean,
	isCollapsed?: boolean,
	label: string,
	number?: number,
	href: To,
	items?: Array<ItemType>
}

const SidebarButton: React.FC<SidebarButtonPropsType> = (props) => {
	const { Icon = HomeIcon, isSelected, isCollapsed, label, number, href, items } = props
	const [surroundHeight, setSurroundHeight] = useState<number>()
	const colour = getNumberColour(number)
	const surroundRef = useRef<HTMLElement>(null)

	useEffect(() => {
		if (surroundRef.current) {
			//	A little hacky - we calculate the height, (using hard-coded values), so we can animate it.
			let height = 72
			if (!isCollapsed && items && isSelected) {
				height += items.length * 46
			}
			setSurroundHeight(height)
		}
	}, [surroundRef, isSelected, isCollapsed, items])

	return (
		<Box sx={{
			display: 'flex',
			justifyContent: 'center',
			...(isSelected ? {
				backgroundColor: 'primary.contrastText',
				border: '1px solid transparent',
				transition: 'all 0.2s'
			} : {
				backgroundColor: 'inherit',
				border: '1px solid transparent',
				transition: 'all 0.2s'
			}),
			...(isSelected && isCollapsed ? {
				backgroundColor: 'inherit',
				border: '1px solid transparent'
			} : []),
			...(surroundHeight ? { height: surroundHeight } : [])
		}} ref={surroundRef} title={`${label}${number !== undefined ? ' (' + (number || 0) + ' items)' : ''}`}>
			<Button component={Link} to={href} sx={{
				'textTransform': 'none',
				'margin': '0',
				'marginTop': '0.66rem',
				'marginBottom': '0.66rem',
				'color': 'text.primary',
				'border': '1px solid transparent',
				'display': 'flex',
				'justifyContent': 'space-between',
				'backgroundColor': 'inherit',
				'transition': 'all 0.2s',
				':hover': {
					cursor: 'pointer',
					backgroundColor: '#44444f',
					border: '1px solid inherit',
					fontWeight: 'bold'
				},
				...(isSelected ? {
					'fontWeight': 'bold',
					'backgroundColor': '#ffffff',
					':hover': {
						cursor: 'pointer',
						backgroundColor: '#ffffff',
						border: '1px solid transparent',
						fontWeight: 'bold'
					}
				} : []),
				...(isCollapsed ? {
					width: '3rem',
					minWidth: '3rem',
					justifyContent: 'center',
					pl: 0,
					pr: 0
				} : [])
			}}>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '2rem' }}>
					{/* <Icon {...(!isCollapsed ? { sx: { mr: 1.5 } } : {})} {...(!isSelected ? { fill: '#888888' } : { fill: '#0a65d7' })} /> */}
					<Icon {...(!isCollapsed ? { sx: { mr: 1.5 } } : {})} {...(!isSelected ? { fill: '#ffffff' } : { fill: '#02a2a2f' })} />
					{!isCollapsed && <Box>{label}</Box>}
				</Box>
				{!isCollapsed && number !== undefined && <Box sx={{
					display: 'flex',
					height: '1.25rem',
					width: '1.25rem',
					borderRadius: '100%',
					justifyContent: 'center',
					alignItems: 'center',
					color: '#ffffff',
					fontSize: '0.7rem',
					fontWeight: 'normal',
					backgroundColor: colour
				}}>{number}</Box>}
				{isCollapsed && number !== undefined && <Box sx={{
					display: 'flex',
					position: 'absolute',
					top: '0.2rem',
					right: '0.1rem',
					height: '0.5rem',
					width: '0.5rem',
					borderRadius: '100%',
					backgroundColor: colour
				}}></Box>}
			</Button>
			{!isCollapsed && isSelected && items && items.map((item) => {
				const itemColour = getNumberColour(item.number)
				return (
					<Button key={`${item.href}`} component={Link} to={item.href} sx={{
						'textTransform': 'none',
						'm': 0.25,
						'color': 'text.primary',
						'border': '1px solid transparent',
						'display': 'flex',
						'justifyContent': 'space-between',
						'backgroundColor': 'inherit',
						'p': 0,
						'pr': 1,
						'pl': 2,
						'transition': 'all 0.2s',
						':hover': {
							cursor: 'pointer',
							backgroundColor: 'inherit',
							border: '1px solid inherit',
							fontWeight: 'bold'
						},
						...(item.isSelected ? {
							'fontWeight': 'bold',
							':hover': {
								cursor: 'pointer',
								// backgroundColor: 'inherit',
								border: '1px solid transparent',
								fontWeight: 'bold'
							}
						} : [])
					}}>
						<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '2rem' }}>
							<Box sx={{ width: '1rem' }}></Box>
							<Box>{item.label}</Box>
						</Box>
						{item.number !== undefined && <Box sx={{
							display: 'flex',
							height: '1.25rem',
							width: '1.25rem',
							borderRadius: '100%',
							justifyContent: 'center',
							alignItems: 'center',
							color: '#ffffff',
							fontSize: '0.7rem',
							fontWeight: 'normal',
							backgroundColor: itemColour
						}}>{item.number}</Box>}
					</Button>)
			})}
		</Box>
	)
}

export default SidebarButton
