import { useEffect, useContext, useRef } from 'react'
import { Link } from 'react-router-dom'

import './Home.css'

import HeaderContext from './context/headerContext'

const Home = () => {
	const headerContext = useRef(useContext(HeaderContext))
	useEffect(() => {
		headerContext.current.setHeader('Home')
	}, [])

	return (
		<div>
			<div>Welcome</div>
			<div>
				<div>Demos:</div>
				<div><Link to='/editordemoblock'>block editor</Link></div>
				<div><Link to='/editordemochat'>chat editor</Link></div>
				<div><Link to='/insertproductsdemo'>insert products</Link></div>
				<div><Link to='/insertfilterdemo'>insert filter</Link></div>
			</div>
		</div>
	)
}

export default Home
