import Typography, { TypographyProps } from '@mui/material/Typography'

const Copyright: React.FC<TypographyProps> = (props) => {
	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			{`Copyright © Pointful ${new Date().getFullYear()}`}
		</Typography>
	)
}

export default Copyright
