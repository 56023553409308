import { useRef, useContext, useEffect } from 'react'
import { Box, Paper } from '@mui/material'

import HeaderContext from './context/headerContext'
import ArticleEditor from './components/ArticleEditor'

const Article = () => {
	const headerContext = useRef(useContext(HeaderContext))
	useEffect(() => {
		//	TODO: Add filters, etc.
		headerContext.current.setHeader(<Box>Article</Box>)
	}, [])

	return (
		<Box sx={{ display: 'block', width: '100%' }} component={Paper}>
			<ArticleEditor />
		</Box>
	)
}

export default Article
