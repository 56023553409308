import { useRef, useState } from 'react'
import { Box, Paper, IconButton, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { NodeHtmlMarkdown } from 'node-html-markdown'
import ReactDOMServer from 'react-dom/server'

import EditorBase from '../EditorBase'
import EmojiSelector from '../EditorBase/EmojiSelector'
import Markdown from '../utilities/Markdown'
import { useOutsideClick } from '../../../hooks'
import { ImageIcon, NewspaperIcon, PaperclipIcon, SmileIcon } from '../../icons'

//	TODO: Move to ... somewhere.
type UserType = {
	userName: string,
	image: string,
	fullName: string
}

const ChatEditor = (props: any) => {
	const tagName = props.tagName || 'p'
	const { title, chatMessage, setChatMessage, handleSend, addAttachment, removeAttachment, ...rest } = props
	const { text, attachments } = chatMessage
	const [showAttachmentDialog, setShowAttachmentDialog] = useState<boolean>()
	const [showEmojis, setShowEmojis] = useState<boolean>(false)

	const handleCloseAttachmentDialog = () => setShowAttachmentDialog(false)
	const removeAttachmentBinder = (attachment: any) => () => removeAttachment(attachment)

	const [editorApi, setEditorApi] = useState<any>()

	const apiBinder = (editorApi: any) => {
		setEditorApi(editorApi)
	}

	const handleToggleEmojis = () => {
		setShowEmojis(() => !showEmojis)
	}

	const handleSelectEmoji = (emo: any) => {
		const { character } = emo
		setShowEmojis(false)
		editorApi.setSelectionOffset(editorApi.element, editorApi.selectionStart, editorApi.selectionEnd)
		editorApi.insertText(character)
	}

	const emojiSelectorRef = useRef(null)
	useOutsideClick(emojiSelectorRef, () => setShowEmojis(false))

	const handleOnChange = ({ doc }: { doc: string }) => {
		const text = NodeHtmlMarkdown.translate(doc)
		setChatMessage({ ...chatMessage, text })
	}

	const [mentionUsers, setMentionUsers] = useState<UserType[] | null>(null)

	const handleOnAtMentionUserSearch = ({ query }: { query: string }) => {
		console.log('AT search for users', query)
		//	TODO: Use real data
		setMentionUsers([
			{ userName: 'Kitty1', image: 'https://placekitten.com/32/32', fullName: 'Kitty Flanaghan' },
			{ userName: 'Kitty2', image: 'https://placekitten.com/32/32', fullName: 'Kitty Flanaghan' },
			{ userName: 'Kitty3', image: 'https://placekitten.com/32/32', fullName: 'Kitty Flanaghan' }
		])
	}

	return (
		<Box sx={{ display: 'block', width: '100%', pt: 1, pb: 1, pr: 2, pl: 2 }} component={Paper}>
			{title && <Box sx={{ fontSize: '0.8rem', textTransform: 'uppercase' }}>{title}</Box>}
			<EditorBase
				sx={{
					'marginTop': '0.5rem',
					'marginBottom': '1rem',
					'borderRadius': '0.5rem',
					'boxShadow': 'inset 1px 1px 5px #eee',
					'paddingLeft': '0.5rem',
					'paddingRight': '0.5rem',
					'paddingTop': '0.5rem',
					'paddingBottom': '0.5rem',
					'& *': {
						'paddingTop': 'initial',
						'paddingBottom': 'initial',
						'marginTop': 'initial',
						'marginBottom': 'initial'
					}
				}}
				//	Convert from Markdown to HTML
				content={ReactDOMServer.renderToStaticMarkup(<Markdown children={text} />)}
				minHeight='80px'
				apiBinder={apiBinder}
				//	Convert from HTML to Markdown
				onChange={handleOnChange}
				onAtMentionUserSearch={handleOnAtMentionUserSearch}
				mentionUsers={mentionUsers}
				tagName={tagName}
				{...rest}
			/>
			{attachments && (
				<Box>
					{attachments.map((att: any) => (
						<Box
							key={JSON.stringify(att)}
							sx={{
								'display': 'inline-flex',
								'borderRadius': 1,
								'position': 'relative',
								':hover .closeButton': {
									display: 'flex'
								}
							}}
						>
							<Box
								component='img'
								sx={{
									'borderRadius': 1,
									':hover': {
										cursor: 'pointer',
										opacity: 0.75
									}
								}}
								src={att.thumbNail}
								title={att.title}
							/>
							<Box className='closeButton' sx={{
								backgroundColor: 'white',
								borderRadius: '100%',
								border: '0.25rem solid white',
								width: '32px',
								height: '32px',
								display: 'none',
								justifyContent: 'center',
								alignItems: 'center',
								position: 'absolute',
								top: '-1rem',
								right: '-1rem'
							}}>
								<IconButton
									sx={{
										backgroundColor: '#white',
										width: '28px',
										height: '28px'
									}}
									onClick={removeAttachmentBinder(att)}
								>
									<CloseIcon />
								</IconButton>
							</Box>
						</Box>
					))}
				</Box>
			)}
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Box>
					<IconButton color="primary" aria-label="emoteicon" component="label" onClick={handleToggleEmojis}><SmileIcon /></IconButton>
					<IconButton color="primary" aria-label="attach" component="label" onClick={() => setShowAttachmentDialog(true)}><PaperclipIcon /></IconButton>
				</Box>
				<Box>
					<Button variant='contained' onClick={handleSend}>Send</Button>
				</Box>
			</Box>
			{showEmojis && (
				<Box ref={emojiSelectorRef} sx={{ position: 'absolute' }}>
					<EmojiSelector
						onSelect={handleSelectEmoji}
					/>
				</Box>
			)}
			{showAttachmentDialog && (
				<Dialog
					open={showAttachmentDialog}
					onClose={handleCloseAttachmentDialog}
				>
					<DialogTitle id="responsive-dialog-title">
						Attach product, article or file
					</DialogTitle>
					<DialogContent>
						<Box>
							<Box><NewspaperIcon /> Select article</Box>
							<Box>Here we see previous recent articles and a search area</Box>
						</Box>
						<Box>
							<Box><ImageIcon /> Select image</Box>
							<Box>Here we see previous recent images and an upload area</Box>
						</Box>
					</DialogContent>
					<DialogActions sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }}>
						<Button variant='contained' color='secondary' onClick={handleCloseAttachmentDialog} autoFocus>
							Close
						</Button>
						<Button variant='contained' onClick={handleCloseAttachmentDialog} autoFocus>
							Attach
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Box>
	)
}

export default ChatEditor
