/*
	These are the shortcuts we use for the emojis, obtained by:

	* Grabbing this file: https://github.com/rickstaa/github-emoji-picker/blob/main/src/data/github_emojis.json
	* Parsing out the bits we want:

	```Javascript
	var result = {}
	var usedKeywords = {}
	Object.keys(github_emojis.emojis).map(key => {
		var native = github_emojis.emojis[key].skins[0].native
		var keywords = github_emojis.emojis[key].keywords
		const words = []
		keywords.forEach(kw => {
			if (!usedKeywords[kw] && kw !== key) {
				words.push(kw)
				usedKeywords[kw] = kw
			}
		})
		result[native] = [key, ...words]
	})
	console.log(JSON.stringify(result, null, 4))
	```

*/

const emojiShortcuts: { [key: string]: any } = {
	'💯': {
		'keywords': [
			'100',
			'score',
			'perfect',
			'numbers',
			'century',
			'exam',
			'quiz',
			'test',
			'pass'
		],
		'emoticons': []
	},
	'🔢': {
		'keywords': [
			'1234',
			'blue',
			'square'
		],
		'emoticons': []
	},
	'😀': {
		'keywords': [
			'grinning',
			'smile',
			'happy',
			'joy',
			':D',
			'grin'
		],
		'emoticons': [
			':D'
		]
	},
	'😃': {
		'keywords': [
			'smiley',
			'haha',
			':)',
			'funny'
		],
		'emoticons': [
			':)',
			'=)',
			'=-)'
		]
	},
	'😄': {
		'keywords': [
			'smile',
			'laugh',
			'like'
		],
		'emoticons': [
			':)',
			'C:',
			'c:',
			':D',
			':-D'
		]
	},
	'😁': {
		'keywords': [
			'grin',
			'kawaii'
		],
		'emoticons': []
	},
	'😆': {
		'keywords': [
			'laughing',
			'satisfied',
			'lol',
			'glad',
			'XD'
		],
		'emoticons': [
			':>',
			':->'
		]
	},
	'😅': {
		'keywords': [
			'sweat_smile',
			'hot',
			'relief'
		],
		'emoticons': []
	},
	'🤣': {
		'keywords': [
			'rofl',
			'face'
		],
		'emoticons': []
	},
	'😂': {
		'keywords': [
			'joy',
			'cry',
			'weep',
			'happytears'
		],
		'emoticons': []
	},
	'🙂': {
		'keywords': [
			'slightly_smiling_face'
		],
		'emoticons': [
			':)',
			'(:',
			':-)'
		]
	},
	'🙃': {
		'keywords': [
			'upside_down_face',
			'upside',
			'down',
			'flipped',
			'silly'
		],
		'emoticons': []
	},
	'😉': {
		'keywords': [
			'wink',
			'mischievous',
			'secret',
			';)',
			'eye'
		],
		'emoticons': [
			';)',
			';-)'
		]
	},
	'😊': {
		'keywords': [
			'blush',
			'flushed',
			'crush',
			'embarrassed',
			'shy'
		],
		'emoticons': [
			':)'
		]
	},
	'😇': {
		'keywords': [
			'innocent',
			'angel',
			'heaven'
		],
		'emoticons': []
	},
	'🥰': {
		'keywords': [
			'smiling_face_with_three_hearts',
			'three',
			'3',
			'love',
			'affection',
			'valentines',
			'infatuation',
			'adore'
		],
		'emoticons': []
	},
	'😍': {
		'keywords': [
			'heart_eyes',
			'heart',
			'eyes'
		],
		'emoticons': []
	},
	'🤩': {
		'keywords': [
			'star_struck',
			'star',
			'struck',
			'grinning',
			'with',
			'starry'
		],
		'emoticons': []
	},
	'😘': {
		'keywords': [
			'kissing_heart',
			'kissing'
		],
		'emoticons': [
			':*',
			':-*'
		]
	},
	'😗': {
		'keywords': [
			'kissing',
			'kiss'
		],
		'emoticons': []
	},
	'☺️': {
		'keywords': [
			'relaxed',
			'blush',
			'massage',
			'happiness'
		],
		'emoticons': []
	},
	'😚': {
		'keywords': [
			'kissing_closed_eyes'
		],
		'emoticons': []
	},
	'😙': {
		'keywords': [
			'kissing_smiling_eyes'
		],
		'emoticons': []
	},
	'🥲': {
		'keywords': [
			'smiling_face_with_tear',
			'sad',
			'pretend'
		],
		'emoticons': []
	},
	'😋': {
		'keywords': [
			'yum',
			'tongue',
			'yummy',
			'nom',
			'delicious',
			'savouring'
		],
		'emoticons': []
	},
	'😛': {
		'keywords': [
			'stuck_out_tongue',
			'stuck',
			'out',
			'prank',
			'childish',
			'playful'
		],
		'emoticons': [
			':p',
			':-p',
			':P',
			':-P',
			':b',
			':-b'
		]
	},
	'😜': {
		'keywords': [
			'stuck_out_tongue_winking_eye',
			'wink'
		],
		'emoticons': [
			';p',
			';-p',
			';b',
			';-b',
			';P',
			';-P'
		]
	},
	'🤪': {
		'keywords': [
			'zany_face',
			'one',
			'large',
			'and',
			'small',
			'goofy',
			'crazy'
		],
		'emoticons': []
	},
	'😝': {
		'keywords': [
			'stuck_out_tongue_closed_eyes',
			'closed'
		],
		'emoticons': []
	},
	'🤑': {
		'keywords': [
			'money_mouth_face',
			'money',
			'mouth',
			'rich',
			'dollar'
		],
		'emoticons': []
	},
	'🤗': {
		'keywords': [
			'hugs',
			'hug'
		],
		'emoticons': []
	},
	'🤭': {
		'keywords': [
			'hand_over_mouth',
			'smiling',
			'covering',
			'whoops',
			'shock',
			'surprise'
		],
		'emoticons': []
	},
	'🤫': {
		'keywords': [
			'shushing_face',
			'finger',
			'lips',
			'quiet',
			'shhh'
		],
		'emoticons': []
	},
	'🤔': {
		'keywords': [
			'thinking',
			'hmmm',
			'think',
			'consider'
		],
		'emoticons': []
	},
	'🤐': {
		'keywords': [
			'zipper_mouth_face',
			'zipper',
			'sealed'
		],
		'emoticons': []
	},
	'🤨': {
		'keywords': [
			'raised_eyebrow',
			'distrust',
			'scepticism',
			'disapproval',
			'disbelief'
		],
		'emoticons': []
	},
	'😐': {
		'keywords': [
			'neutral_face',
			'indifference',
			'meh',
			':',
			''
		],
		'emoticons': [
			':|',
			':-|'
		]
	},
	'😑': {
		'keywords': [
			'expressionless',
			'indifferent',
			'-',
			'',
			'deadpan'
		],
		'emoticons': [
			'-_-'
		]
	},
	'😶': {
		'keywords': [
			'no_mouth',
			'no',
			'hellokitty'
		],
		'emoticons': []
	},
	'😶‍🌫️': {
		'keywords': [
			'face_in_clouds',
			'shower',
			'steam',
			'dream'
		],
		'emoticons': []
	},
	'😏': {
		'keywords': [
			'smirk',
			'mean',
			'smug',
			'sarcasm'
		],
		'emoticons': []
	},
	'😒': {
		'keywords': [
			'unamused',
			'bored',
			'straight',
			'serious',
			'unimpressed',
			'skeptical',
			'dubious',
			'side'
		],
		'emoticons': [
			':('
		]
	},
	'🙄': {
		'keywords': [
			'roll_eyes',
			'roll',
			'eyeroll',
			'frustrated'
		],
		'emoticons': []
	},
	'😬': {
		'keywords': [
			'grimacing',
			'grimace',
			'teeth'
		],
		'emoticons': []
	},
	'😮‍💨': {
		'keywords': [
			'face_exhaling',
			'relieve',
			'tired',
			'sigh'
		],
		'emoticons': []
	},
	'🤥': {
		'keywords': [
			'lying_face',
			'lie',
			'pinocchio'
		],
		'emoticons': []
	},
	'😌': {
		'keywords': [
			'relieved',
			'relaxed',
			'phew'
		],
		'emoticons': []
	},
	'😔': {
		'keywords': [
			'pensive',
			'depressed',
			'upset'
		],
		'emoticons': []
	},
	'😪': {
		'keywords': [
			'sleepy',
			'rest',
			'nap'
		],
		'emoticons': []
	},
	'🤤': {
		'keywords': [
			'drooling_face'
		],
		'emoticons': []
	},
	'😴': {
		'keywords': [
			'sleeping',
			'sleepy',
			'night',
			'zzz'
		],
		'emoticons': []
	},
	'😷': {
		'keywords': [
			'mask',
			'sick',
			'ill',
			'disease',
			'covid'
		],
		'emoticons': []
	},
	'🤒': {
		'keywords': [
			'face_with_thermometer',
			'temperature',
			'cold',
			'fever'
		],
		'emoticons': []
	},
	'🤕': {
		'keywords': [
			'face_with_head_bandage',
			'head',
			'bandage',
			'injured',
			'clumsy',
			'hurt'
		],
		'emoticons': []
	},
	'🤢': {
		'keywords': [
			'nauseated_face',
			'vomit',
			'gross',
			'green',
			'throw',
			'up'
		],
		'emoticons': []
	},
	'🤮': {
		'keywords': [
			'vomiting_face',
			'open'
		],
		'emoticons': []
	},
	'🤧': {
		'keywords': [
			'sneezing_face',
			'gesundheit',
			'sneeze',
			'allergy'
		],
		'emoticons': []
	},
	'🥵': {
		'keywords': [
			'hot_face',
			'feverish',
			'heat',
			'red',
			'sweating'
		],
		'emoticons': []
	},
	'🥶': {
		'keywords': [
			'cold_face',
			'freezing',
			'frozen',
			'frostbite',
			'icicles'
		],
		'emoticons': []
	},
	'🥴': {
		'keywords': [
			'woozy_face',
			'dizzy',
			'intoxicated',
			'tipsy',
			'wavy'
		],
		'emoticons': []
	},
	'😵': {
		'keywords': [
			'dizzy_face',
			'spent',
			'unconscious',
			'xox'
		],
		'emoticons': []
	},
	'😵‍💫': {
		'keywords': [
			'face_with_spiral_eyes',
			'confused',
			'nauseous',
			'nausea'
		],
		'emoticons': []
	},
	'🤯': {
		'keywords': [
			'exploding_head',
			'shocked',
			'mind',
			'blown'
		],
		'emoticons': []
	},
	'🤠': {
		'keywords': [
			'cowboy_hat_face',
			'cowgirl'
		],
		'emoticons': []
	},
	'🥳': {
		'keywords': [
			'partying_face',
			'celebration',
			'woohoo'
		],
		'emoticons': []
	},
	'🥸': {
		'keywords': [
			'disguised_face',
			'pretent',
			'brows',
			'glasses',
			'moustache'
		],
		'emoticons': []
	},
	'😎': {
		'keywords': [
			'sunglasses',
			'cool',
			'summer',
			'beach',
			'sunglass'
		],
		'emoticons': [
			'8)'
		]
	},
	'🤓': {
		'keywords': [
			'nerd_face',
			'nerdy',
			'geek',
			'dork'
		],
		'emoticons': []
	},
	'🧐': {
		'keywords': [
			'monocle_face',
			'stuffy',
			'wealthy'
		],
		'emoticons': []
	},
	'😕': {
		'keywords': [
			'confused',
			'huh',
			'weird',
			':/'
		],
		'emoticons': [
			':\\',
			':-\\',
			':/',
			':-/'
		]
	},
	'😟': {
		'keywords': [
			'worried',
			'concern',
			'nervous',
			':('
		],
		'emoticons': []
	},
	'🙁': {
		'keywords': [
			'slightly_frowning_face',
			'disappointed'
		],
		'emoticons': []
	},
	'😮': {
		'keywords': [
			'open_mouth',
			'impressed',
			'wow',
			'whoa',
			':O'
		],
		'emoticons': [
			':o',
			':-o',
			':O',
			':-O'
		]
	},
	'😯': {
		'keywords': [
			'hushed',
			'woo',
			'shh'
		],
		'emoticons': []
	},
	'😲': {
		'keywords': [
			'astonished',
			'surprised',
			'poisoned'
		],
		'emoticons': []
	},
	'😳': {
		'keywords': [
			'flushed',
			'flattered'
		],
		'emoticons': []
	},
	'🥺': {
		'keywords': [
			'pleading_face',
			'begging',
			'mercy'
		],
		'emoticons': []
	},
	'😦': {
		'keywords': [
			'frowning',
			'aw',
			'what'
		],
		'emoticons': []
	},
	'😧': {
		'keywords': [
			'anguished',
			'stunned'
		],
		'emoticons': [
			'D:'
		]
	},
	'😨': {
		'keywords': [
			'fearful',
			'scared',
			'terrified',
			'oops'
		],
		'emoticons': []
	},
	'😰': {
		'keywords': [
			'cold_sweat'
		],
		'emoticons': []
	},
	'😥': {
		'keywords': [
			'disappointed_relieved',
			'sweat'
		],
		'emoticons': []
	},
	'😢': {
		'keywords': [
			'cry',
			'tears',
			':\'('
		],
		'emoticons': [
			':\'('
		]
	},
	'😭': {
		'keywords': [
			'sob'
		],
		'emoticons': [
			':\'('
		]
	},
	'😱': {
		'keywords': [
			'scream',
			'munch',
			'omg'
		],
		'emoticons': []
	},
	'😖': {
		'keywords': [
			'confounded',
			'unwell',
			':S'
		],
		'emoticons': []
	},
	'😣': {
		'keywords': [
			'persevere'
		],
		'emoticons': []
	},
	'😞': {
		'keywords': [
			'disappointed'
		],
		'emoticons': [
			'):',
			':(',
			':-('
		]
	},
	'😓': {
		'keywords': [
			'sweat',
			'downcast',
			'exercise'
		],
		'emoticons': []
	},
	'😩': {
		'keywords': [
			'weary'
		],
		'emoticons': []
	},
	'😫': {
		'keywords': [
			'tired_face',
			'whine'
		],
		'emoticons': []
	},
	'🥱': {
		'keywords': [
			'yawning_face'
		],
		'emoticons': []
	},
	'😤': {
		'keywords': [
			'triumph',
			'from',
			'nose',
			'gas',
			'proud',
			'pride'
		],
		'emoticons': []
	},
	'😡': {
		'keywords': [
			'rage',
			'angry',
			'mad',
			'hate',
			'despise'
		],
		'emoticons': []
	},
	'😠': {
		'keywords': [
			'angry',
			'annoyed'
		],
		'emoticons': [
			'>:(',
			'>:-('
		]
	},
	'🤬': {
		'keywords': [
			'cursing_face',
			'cursing',
			'swearing',
			'cussing',
			'profanity',
			'expletive'
		],
		'emoticons': []
	},
	'😈': {
		'keywords': [
			'smiling_imp',
			'imp',
			'devil'
		],
		'emoticons': []
	},
	'👿': {
		'keywords': [
			'imp',
			'horns'
		],
		'emoticons': []
	},
	'💀': {
		'keywords': [
			'skull',
			'dead',
			'skeleton',
			'creepy',
			'death'
		],
		'emoticons': []
	},
	'☠️': {
		'keywords': [
			'skull_and_crossbones',
			'poison',
			'danger',
			'deadly',
			'scary',
			'pirate',
			'evil'
		],
		'emoticons': []
	},
	'💩': {
		'keywords': [
			'hankey',
			'poop',
			'shit',
			'shitface',
			'fail',
			'turd'
		],
		'emoticons': []
	},
	'🤡': {
		'keywords': [
			'clown_face'
		],
		'emoticons': []
	},
	'👹': {
		'keywords': [
			'japanese_ogre',
			'japanese',
			'monster',
			'mask',
			'halloween',
			'demon'
		],
		'emoticons': []
	},
	'👺': {
		'keywords': [
			'japanese_goblin'
		],
		'emoticons': []
	},
	'👻': {
		'keywords': [
			'ghost',
			'spooky'
		],
		'emoticons': []
	},
	'👽': {
		'keywords': [
			'alien',
			'UFO',
			'paul',
			'outer',
			'space'
		],
		'emoticons': []
	},
	'👾': {
		'keywords': [
			'space_invader',
			'invader',
			'game',
			'arcade',
			'play'
		],
		'emoticons': []
	},
	'🤖': {
		'keywords': [
			'robot',
			'computer',
			'machine',
			'bot'
		],
		'emoticons': []
	},
	'😺': {
		'keywords': [
			'smiley_cat',
			'smiley',
			'animal',
			'cats'
		],
		'emoticons': []
	},
	'😸': {
		'keywords': [
			'smile_cat'
		],
		'emoticons': []
	},
	'😹': {
		'keywords': [
			'joy_cat'
		],
		'emoticons': []
	},
	'😻': {
		'keywords': [
			'heart_eyes_cat'
		],
		'emoticons': []
	},
	'😼': {
		'keywords': [
			'smirk_cat',
			'smirk'
		],
		'emoticons': []
	},
	'😽': {
		'keywords': [
			'kissing_cat'
		],
		'emoticons': []
	},
	'🙀': {
		'keywords': [
			'scream_cat',
			'scream'
		],
		'emoticons': []
	},
	'😿': {
		'keywords': [
			'crying_cat_face'
		],
		'emoticons': []
	},
	'😾': {
		'keywords': [
			'pouting_cat'
		],
		'emoticons': []
	},
	'🙈': {
		'keywords': [
			'see_no_evil',
			'see',
			'nature'
		],
		'emoticons': []
	},
	'🙉': {
		'keywords': [
			'hear_no_evil',
			'hear'
		],
		'emoticons': []
	},
	'🙊': {
		'keywords': [
			'speak_no_evil',
			'speak'
		],
		'emoticons': []
	},
	'💋': {
		'keywords': [
			'kiss'
		],
		'emoticons': []
	},
	'💌': {
		'keywords': [
			'love_letter',
			'email',
			'envelope'
		],
		'emoticons': []
	},
	'💘': {
		'keywords': [
			'cupid'
		],
		'emoticons': []
	},
	'💝': {
		'keywords': [
			'gift_heart',
			'gift'
		],
		'emoticons': []
	},
	'💖': {
		'keywords': [
			'sparkling_heart'
		],
		'emoticons': []
	},
	'💗': {
		'keywords': [
			'heartpulse',
			'pink'
		],
		'emoticons': []
	},
	'💓': {
		'keywords': [
			'heartbeat'
		],
		'emoticons': []
	},
	'💞': {
		'keywords': [
			'revolving_hearts'
		],
		'emoticons': []
	},
	'💕': {
		'keywords': [
			'two_hearts'
		],
		'emoticons': []
	},
	'💟': {
		'keywords': [
			'heart_decoration',
			'purple'
		],
		'emoticons': []
	},
	'💔': {
		'keywords': [
			'broken_heart',
			'sorry',
			'break',
			'heartbreak'
		],
		'emoticons': [
			'</3'
		]
	},
	'❤️‍🔥': {
		'keywords': [
			'heart_on_fire',
			'passionate',
			'enthusiastic'
		],
		'emoticons': []
	},
	'❤️‍🩹': {
		'keywords': [
			'mending_heart',
			'broken',
			'wounded'
		],
		'emoticons': []
	},
	'❤️': {
		'keywords': [
			'heart'
		],
		'emoticons': [
			'<3'
		]
	},
	'🧡': {
		'keywords': [
			'orange_heart'
		],
		'emoticons': []
	},
	'💛': {
		'keywords': [
			'yellow_heart'
		],
		'emoticons': [
			'<3'
		]
	},
	'💚': {
		'keywords': [
			'green_heart'
		],
		'emoticons': [
			'<3'
		]
	},
	'💙': {
		'keywords': [
			'blue_heart'
		],
		'emoticons': [
			'<3'
		]
	},
	'💜': {
		'keywords': [
			'purple_heart'
		],
		'emoticons': [
			'<3'
		]
	},
	'🤎': {
		'keywords': [
			'brown_heart',
			'coffee'
		],
		'emoticons': []
	},
	'🖤': {
		'keywords': [
			'black_heart'
		],
		'emoticons': []
	},
	'🤍': {
		'keywords': [
			'white_heart',
			'pure'
		],
		'emoticons': []
	},
	'💢': {
		'keywords': [
			'anger'
		],
		'emoticons': []
	},
	'💥': {
		'keywords': [
			'boom',
			'bomb',
			'explode',
			'explosion'
		],
		'emoticons': []
	},
	'💫': {
		'keywords': [
			'dizzy',
			'sparkle',
			'shoot',
			'magic'
		],
		'emoticons': []
	},
	'💦': {
		'keywords': [
			'sweat_drops',
			'drops',
			'water',
			'drip'
		],
		'emoticons': []
	},
	'💨': {
		'keywords': [
			'dash',
			'dashing',
			'away',
			'wind',
			'air',
			'fast',
			'shoo',
			'fart',
			'smoke',
			'puff'
		],
		'emoticons': []
	},
	'🕳️': {
		'keywords': [
			'hole',
			'embarrassing'
		],
		'emoticons': []
	},
	'💣': {
		'keywords': [
			'bomb',
			'boom',
			'terrorism'
		],
		'emoticons': []
	},
	'💬': {
		'keywords': [
			'speech_balloon',
			'bubble',
			'words',
			'message',
			'talk',
			'chatting'
		],
		'emoticons': []
	},
	'🗨️': {
		'keywords': [
			'left_speech_bubble'
		],
		'emoticons': []
	},
	'🗯️': {
		'keywords': [
			'right_anger_bubble',
			'caption',
			'speech',
			'thinking'
		],
		'emoticons': []
	},
	'💭': {
		'keywords': [
			'thought_balloon',
			'cloud'
		],
		'emoticons': []
	},
	'💤': {
		'keywords': [
			'zzz'
		],
		'emoticons': []
	},
	'👋': {
		'keywords': [
			'wave',
			'hands',
			'gesture',
			'goodbye',
			'solong',
			'farewell',
			'hello',
			'hi',
			'palm'
		],
		'emoticons': []
	},
	'🤚': {
		'keywords': [
			'raised_back_of_hand',
			'fingers',
			'backhand'
		],
		'emoticons': []
	},
	'🖐️': {
		'keywords': [
			'raised_hand_with_fingers_splayed',
			'raised'
		],
		'emoticons': []
	},
	'✋': {
		'keywords': [
			'hand',
			'stop',
			'highfive',
			'high',
			'five',
			'ban'
		],
		'emoticons': []
	},
	'🖖': {
		'keywords': [
			'vulcan_salute',
			'spock',
			'hand',
			'trek'
		],
		'emoticons': []
	},
	'👌': {
		'keywords': [
			'ok_hand',
			'limbs',
			'okay'
		],
		'emoticons': []
	},
	'🤌': {
		'keywords': [
			'pinched_fingers',
			'size',
			'tiny'
		],
		'emoticons': []
	},
	'🤏': {
		'keywords': [
			'pinching_hand'
		],
		'emoticons': []
	},
	'✌️': {
		'keywords': [
			'v',
			'ohyeah',
			'peace',
			'two'
		],
		'emoticons': []
	},
	'🤞': {
		'keywords': [
			'crossed_fingers',
			'index',
			'middle',
			'good',
			'lucky'
		],
		'emoticons': []
	},
	'🤟': {
		'keywords': [
			'love_you_gesture',
			'you',
			'i',
			'sign'
		],
		'emoticons': []
	},
	'🤘': {
		'keywords': [
			'metal',
			'rock',
			'on'
		],
		'emoticons': []
	},
	'🤙': {
		'keywords': [
			'call_me_hand',
			'shaka'
		],
		'emoticons': []
	},
	'👈': {
		'keywords': [
			'point_left',
			'point',
			'direction'
		],
		'emoticons': []
	},
	'👉': {
		'keywords': [
			'point_right'
		],
		'emoticons': []
	},
	'👆': {
		'keywords': [
			'point_up_2',
			'2'
		],
		'emoticons': []
	},
	'🖕': {
		'keywords': [
			'middle_finger',
			'reversed',
			'extended',
			'rude',
			'flipping'
		],
		'emoticons': []
	},
	'👇': {
		'keywords': [
			'point_down'
		],
		'emoticons': []
	},
	'☝️': {
		'keywords': [
			'point_up'
		],
		'emoticons': []
	},
	'👍': {
		'keywords': [
			'+1',
			'thumbsup',
			'yes',
			'awesome',
			'agree',
			'accept'
		],
		'emoticons': []
	},
	'👎': {
		'keywords': [
			'-1',
			'thumbsdown',
			'dislike'
		],
		'emoticons': []
	},
	'✊': {
		'keywords': [
			'fist',
			'grasp'
		],
		'emoticons': []
	},
	'👊': {
		'keywords': [
			'facepunch',
			'punch',
			'violence',
			'hit',
			'attack'
		],
		'emoticons': []
	},
	'🤛': {
		'keywords': [
			'fist_left',
			'left',
			'facing',
			'fistbump'
		],
		'emoticons': []
	},
	'🤜': {
		'keywords': [
			'fist_right',
			'right'
		],
		'emoticons': []
	},
	'👏': {
		'keywords': [
			'clap',
			'praise',
			'applause',
			'congrats',
			'yay'
		],
		'emoticons': []
	},
	'🙌': {
		'keywords': [
			'raised_hands',
			'hooray',
			'yea'
		],
		'emoticons': []
	},
	'👐': {
		'keywords': [
			'open_hands',
			'butterfly'
		],
		'emoticons': []
	},
	'🤲': {
		'keywords': [
			'palms_up_together',
			'cupped',
			'prayer'
		],
		'emoticons': []
	},
	'🤝': {
		'keywords': [
			'handshake',
			'agreement',
			'shake'
		],
		'emoticons': []
	},
	'🙏': {
		'keywords': [
			'pray',
			'please',
			'hope',
			'wish',
			'namaste',
			'thank',
			'thanks',
			'appreciate'
		],
		'emoticons': []
	},
	'✍️': {
		'keywords': [
			'writing_hand',
			'lower',
			'ballpoint',
			'pen',
			'stationery',
			'write',
			'compose'
		],
		'emoticons': []
	},
	'💅': {
		'keywords': [
			'nail_care',
			'care',
			'beauty',
			'manicure',
			'fashion'
		],
		'emoticons': []
	},
	'🤳': {
		'keywords': [
			'selfie',
			'camera',
			'phone'
		],
		'emoticons': []
	},
	'💪': {
		'keywords': [
			'muscle',
			'arm',
			'flex',
			'strong'
		],
		'emoticons': []
	},
	'🦾': {
		'keywords': [
			'mechanical_arm',
			'accessibility'
		],
		'emoticons': []
	},
	'🦿': {
		'keywords': [
			'mechanical_leg'
		],
		'emoticons': []
	},
	'🦵': {
		'keywords': [
			'leg',
			'kick',
			'limb'
		],
		'emoticons': []
	},
	'🦶': {
		'keywords': [
			'foot',
			'stomp'
		],
		'emoticons': []
	},
	'👂': {
		'keywords': [
			'ear',
			'sound',
			'listen'
		],
		'emoticons': []
	},
	'🦻': {
		'keywords': [
			'ear_with_hearing_aid'
		],
		'emoticons': []
	},
	'👃': {
		'keywords': [
			'nose',
			'smell',
			'sniff'
		],
		'emoticons': []
	},
	'🧠': {
		'keywords': [
			'brain',
			'smart',
			'intelligent'
		],
		'emoticons': []
	},
	'🫀': {
		'keywords': [
			'anatomical_heart',
			'health',
			'heartbeat'
		],
		'emoticons': []
	},
	'🫁': {
		'keywords': [
			'lungs',
			'breathe'
		],
		'emoticons': []
	},
	'🦷': {
		'keywords': [
			'tooth',
			'dentist'
		],
		'emoticons': []
	},
	'🦴': {
		'keywords': [
			'bone'
		],
		'emoticons': []
	},
	'👀': {
		'keywords': [
			'eyes',
			'look',
			'watch',
			'stalk',
			'peek'
		],
		'emoticons': []
	},
	'👁️': {
		'keywords': [
			'eye',
			'stare'
		],
		'emoticons': []
	},
	'👅': {
		'keywords': [
			'tongue'
		],
		'emoticons': []
	},
	'👄': {
		'keywords': [
			'lips'
		],
		'emoticons': []
	},
	'👶': {
		'keywords': [
			'baby',
			'child',
			'boy',
			'girl',
			'toddler'
		],
		'emoticons': []
	},
	'🧒': {
		'keywords': [
			'child',
			'gender',
			'neutral',
			'young'
		],
		'emoticons': []
	},
	'👦': {
		'keywords': [
			'boy',
			'man',
			'male',
			'guy',
			'teenager'
		],
		'emoticons': []
	},
	'👧': {
		'keywords': [
			'girl',
			'female',
			'woman'
		],
		'emoticons': []
	},
	'🧑': {
		'keywords': [
			'adult',
			'person'
		],
		'emoticons': []
	},
	'👱': {
		'keywords': [
			'blond_haired_person',
			'haired',
			'hairstyle'
		],
		'emoticons': []
	},
	'👨': {
		'keywords': [
			'man',
			'mustache',
			'father',
			'dad',
			'classy',
			'sir'
		],
		'emoticons': []
	},
	'🧔': {
		'keywords': [
			'bearded_person',
			'bearded',
			'bewhiskered'
		],
		'emoticons': []
	},
	'👨‍🦰': {
		'keywords': [
			'red_haired_man'
		],
		'emoticons': []
	},
	'👨‍🦱': {
		'keywords': [
			'curly_haired_man'
		],
		'emoticons': []
	},
	'👨‍🦳': {
		'keywords': [
			'white_haired_man',
			'old',
			'elder'
		],
		'emoticons': []
	},
	'👨‍🦲': {
		'keywords': [
			'bald_man',
			'hairless'
		],
		'emoticons': []
	},
	'👩': {
		'keywords': [
			'woman',
			'girls',
			'lady'
		],
		'emoticons': []
	},
	'👩‍🦰': {
		'keywords': [
			'red_haired_woman'
		],
		'emoticons': []
	},
	'👩‍🦱': {
		'keywords': [
			'curly_haired_woman'
		],
		'emoticons': []
	},
	'👩‍🦳': {
		'keywords': [
			'white_haired_woman'
		],
		'emoticons': []
	},
	'👩‍🦲': {
		'keywords': [
			'bald_woman'
		],
		'emoticons': []
	},
	'🧓': {
		'keywords': [
			'older_adult',
			'human',
			'senior'
		],
		'emoticons': []
	},
	'👴': {
		'keywords': [
			'older_man',
			'older',
			'men'
		],
		'emoticons': []
	},
	'👵': {
		'keywords': [
			'older_woman',
			'women'
		],
		'emoticons': []
	},
	'🙍': {
		'keywords': [
			'frowning_person',
			'worried'
		],
		'emoticons': []
	},
	'🙎': {
		'keywords': [
			'pouting_face'
		],
		'emoticons': []
	},
	'🙅': {
		'keywords': [
			'no_good',
			'decline'
		],
		'emoticons': []
	},
	'🙆': {
		'keywords': [
			'ok_woman'
		],
		'emoticons': []
	},
	'💁': {
		'keywords': [
			'information_desk_person',
			'information',
			'desk'
		],
		'emoticons': []
	},
	'🙋': {
		'keywords': [
			'raising_hand',
			'question'
		],
		'emoticons': []
	},
	'🧏': {
		'keywords': [
			'deaf_person'
		],
		'emoticons': []
	},
	'🧏‍♂️': {
		'keywords': [
			'deaf_man'
		],
		'emoticons': []
	},
	'🧏‍♀️': {
		'keywords': [
			'deaf_woman'
		],
		'emoticons': []
	},
	'🙇': {
		'keywords': [
			'bow',
			'respectiful'
		],
		'emoticons': []
	},
	'🤦': {
		'keywords': [
			'facepalm',
			'facepalming'
		],
		'emoticons': []
	},
	'🤷': {
		'keywords': [
			'shrug',
			'shrugging',
			'regardless'
		],
		'emoticons': []
	},
	'🧑‍⚕️': {
		'keywords': [
			'health_worker',
			'hospital'
		],
		'emoticons': []
	},
	'🧑‍🎓': {
		'keywords': [
			'student',
			'learn'
		],
		'emoticons': []
	},
	'🧑‍🏫': {
		'keywords': [
			'teacher',
			'professor'
		],
		'emoticons': []
	},
	'🧑‍⚖️': {
		'keywords': [
			'judge',
			'law'
		],
		'emoticons': []
	},
	'🧑‍🌾': {
		'keywords': [
			'farmer',
			'crops'
		],
		'emoticons': []
	},
	'🧑‍🍳': {
		'keywords': [
			'cook',
			'food',
			'kitchen',
			'culinary'
		],
		'emoticons': []
	},
	'🧑‍🔧': {
		'keywords': [
			'mechanic',
			'worker',
			'technician'
		],
		'emoticons': []
	},
	'🧑‍🏭': {
		'keywords': [
			'factory_worker',
			'labor'
		],
		'emoticons': []
	},
	'🧑‍💼': {
		'keywords': [
			'office_worker',
			'business'
		],
		'emoticons': []
	},
	'🧑‍🔬': {
		'keywords': [
			'scientist',
			'chemistry'
		],
		'emoticons': []
	},
	'🧑‍💻': {
		'keywords': [
			'technologist'
		],
		'emoticons': []
	},
	'🧑‍🎤': {
		'keywords': [
			'singer',
			'song',
			'artist',
			'performer'
		],
		'emoticons': []
	},
	'🧑‍🎨': {
		'keywords': [
			'artist',
			'painting',
			'draw',
			'creativity'
		],
		'emoticons': []
	},
	'🧑‍✈️': {
		'keywords': [
			'pilot',
			'fly',
			'plane',
			'airplane'
		],
		'emoticons': []
	},
	'🧑‍🚀': {
		'keywords': [
			'astronaut',
			'outerspace'
		],
		'emoticons': []
	},
	'🧑‍🚒': {
		'keywords': [
			'firefighter',
			'fire'
		],
		'emoticons': []
	},
	'👮': {
		'keywords': [
			'cop'
		],
		'emoticons': []
	},
	'💂': {
		'keywords': [
			'guardsman',
			'protect'
		],
		'emoticons': []
	},
	'🥷': {
		'keywords': [
			'ninja',
			'ninjutsu',
			'skills'
		],
		'emoticons': []
	},
	'👷': {
		'keywords': [
			'construction_worker',
			'build'
		],
		'emoticons': []
	},
	'🤴': {
		'keywords': [
			'prince',
			'crown',
			'royal',
			'king'
		],
		'emoticons': []
	},
	'👸': {
		'keywords': [
			'princess',
			'blond',
			'queen'
		],
		'emoticons': []
	},
	'👳': {
		'keywords': [
			'man_with_turban',
			'wearing',
			'headdress'
		],
		'emoticons': []
	},
	'👲': {
		'keywords': [
			'man_with_gua_pi_mao',
			'skullcap',
			'chinese'
		],
		'emoticons': []
	},
	'🧕': {
		'keywords': [
			'woman_with_headscarf',
			'hijab',
			'mantilla',
			'tichel'
		],
		'emoticons': []
	},
	'🤵': {
		'keywords': [
			'person_in_tuxedo',
			'couple',
			'marriage',
			'wedding',
			'groom'
		],
		'emoticons': []
	},
	'🤵‍♂️': {
		'keywords': [
			'man_in_tuxedo',
			'formal'
		],
		'emoticons': []
	},
	'🤵‍♀️': {
		'keywords': [
			'woman_in_tuxedo'
		],
		'emoticons': []
	},
	'👰': {
		'keywords': [
			'bride_with_veil'
		],
		'emoticons': []
	},
	'👰‍♂️': {
		'keywords': [
			'man_with_veil'
		],
		'emoticons': []
	},
	'👰‍♀️': {
		'keywords': [
			'woman_with_veil'
		],
		'emoticons': []
	},
	'🤰': {
		'keywords': [
			'pregnant_woman',
			'baby'
		],
		'emoticons': []
	},
	'🤱': {
		'keywords': [
			'breast_feeding',
			'breast',
			'feeding',
			'nursing'
		],
		'emoticons': []
	},
	'👩‍🍼': {
		'keywords': [
			'woman_feeding_baby',
			'birth'
		],
		'emoticons': []
	},
	'👨‍🍼': {
		'keywords': [
			'man_feeding_baby'
		],
		'emoticons': []
	},
	'🧑‍🍼': {
		'keywords': [
			'person_feeding_baby'
		],
		'emoticons': []
	},
	'👼': {
		'keywords': [
			'angel',
			'wings',
			'halo'
		],
		'emoticons': []
	},
	'🎅': {
		'keywords': [
			'santa',
			'festival',
			'xmas',
			'christmas'
		],
		'emoticons': []
	},
	'🤶': {
		'keywords': [
			'mrs_claus',
			'mrs',
			'mother'
		],
		'emoticons': []
	},
	'🧑‍🎄': {
		'keywords': [
			'mx_claus'
		],
		'emoticons': []
	},
	'🦸': {
		'keywords': [
			'superhero',
			'marvel'
		],
		'emoticons': []
	},
	'🦹': {
		'keywords': [
			'supervillain'
		],
		'emoticons': []
	},
	'🧙': {
		'keywords': [
			'mage'
		],
		'emoticons': []
	},
	'🧚': {
		'keywords': [
			'fairy',
			'magical'
		],
		'emoticons': []
	},
	'🧛': {
		'keywords': [
			'vampire',
			'blood',
			'twilight'
		],
		'emoticons': []
	},
	'🧜': {
		'keywords': [
			'merperson',
			'sea'
		],
		'emoticons': []
	},
	'🧜‍♂️': {
		'keywords': [
			'merman',
			'triton'
		],
		'emoticons': []
	},
	'🧜‍♀️': {
		'keywords': [
			'mermaid',
			'merwoman',
			'ariel'
		],
		'emoticons': []
	},
	'🧝': {
		'keywords': [
			'elf'
		],
		'emoticons': []
	},
	'🧞': {
		'keywords': [
			'genie',
			'wishes'
		],
		'emoticons': []
	},
	'🧟': {
		'keywords': [
			'zombie'
		],
		'emoticons': []
	},
	'💆': {
		'keywords': [
			'massage',
			'getting',
			'relax'
		],
		'emoticons': []
	},
	'💇': {
		'keywords': [
			'haircut'
		],
		'emoticons': []
	},
	'🚶': {
		'keywords': [
			'walking',
			'move'
		],
		'emoticons': []
	},
	'🧍': {
		'keywords': [
			'standing_person',
			'still'
		],
		'emoticons': []
	},
	'🧎': {
		'keywords': [
			'kneeling_person',
			'pray',
			'respectful'
		],
		'emoticons': []
	},
	'🧑‍🦯': {
		'keywords': [
			'person_with_probing_cane',
			'probing',
			'blind'
		],
		'emoticons': []
	},
	'👨‍🦯': {
		'keywords': [
			'man_with_probing_cane'
		],
		'emoticons': []
	},
	'👩‍🦯': {
		'keywords': [
			'woman_with_probing_cane'
		],
		'emoticons': []
	},
	'🧑‍🦼': {
		'keywords': [
			'person_in_motorized_wheelchair',
			'disability'
		],
		'emoticons': []
	},
	'👨‍🦼': {
		'keywords': [
			'man_in_motorized_wheelchair'
		],
		'emoticons': []
	},
	'👩‍🦼': {
		'keywords': [
			'woman_in_motorized_wheelchair'
		],
		'emoticons': []
	},
	'🧑‍🦽': {
		'keywords': [
			'person_in_manual_wheelchair'
		],
		'emoticons': []
	},
	'👨‍🦽': {
		'keywords': [
			'man_in_manual_wheelchair'
		],
		'emoticons': []
	},
	'👩‍🦽': {
		'keywords': [
			'woman_in_manual_wheelchair'
		],
		'emoticons': []
	},
	'🏃': {
		'keywords': [
			'runner',
			'running'
		],
		'emoticons': []
	},
	'💃': {
		'keywords': [
			'dancer',
			'dancing',
			'fun'
		],
		'emoticons': []
	},
	'🕺': {
		'keywords': [
			'man_dancing',
			'dancer'
		],
		'emoticons': []
	},
	'👯': {
		'keywords': [
			'dancers',
			'people',
			'perform',
			'costume'
		],
		'emoticons': []
	},
	'🧖': {
		'keywords': [
			'sauna_person',
			'sauna',
			'spa'
		],
		'emoticons': []
	},
	'🧗': {
		'keywords': [
			'climbing',
			'sport'
		],
		'emoticons': []
	},
	'🤺': {
		'keywords': [
			'person_fencing',
			'fencing',
			'sports',
			'sword'
		],
		'emoticons': []
	},
	'🏇': {
		'keywords': [
			'horse_racing',
			'betting',
			'competition',
			'gambling',
			'luck'
		],
		'emoticons': []
	},
	'⛷️': {
		'keywords': [
			'skier',
			'winter',
			'snow'
		],
		'emoticons': []
	},
	'🏂': {
		'keywords': [
			'snowboarder'
		],
		'emoticons': []
	},
	'🏄': {
		'keywords': [
			'surfer',
			'surfing'
		],
		'emoticons': []
	},
	'🚣': {
		'keywords': [
			'rowboat',
			'rowing',
			'boat'
		],
		'emoticons': []
	},
	'🏊': {
		'keywords': [
			'swimmer',
			'swimming',
			'pool'
		],
		'emoticons': []
	},
	'🚴': {
		'keywords': [
			'bicyclist',
			'biking'
		],
		'emoticons': []
	},
	'🚵': {
		'keywords': [
			'mountain_bicyclist'
		],
		'emoticons': []
	},
	'🤸': {
		'keywords': [
			'cartwheeling',
			'doing',
			'cartwheel',
			'gymnastic'
		],
		'emoticons': []
	},
	'🤼': {
		'keywords': [
			'wrestling'
		],
		'emoticons': []
	},
	'🤽': {
		'keywords': [
			'water_polo',
			'playing'
		],
		'emoticons': []
	},
	'🤾': {
		'keywords': [
			'handball_person'
		],
		'emoticons': []
	},
	'🤹': {
		'keywords': [
			'juggling_person',
			'performance',
			'balance'
		],
		'emoticons': []
	},
	'🧘': {
		'keywords': [
			'lotus_position',
			'meditate'
		],
		'emoticons': []
	},
	'🛀': {
		'keywords': [
			'bath',
			'taking',
			'clean',
			'bathroom'
		],
		'emoticons': []
	},
	'🛌': {
		'keywords': [
			'sleeping_bed',
			'sleeping',
			'accommodation'
		],
		'emoticons': []
	},
	'🧑‍🤝‍🧑': {
		'keywords': [
			'people_holding_hands',
			'friendship'
		],
		'emoticons': []
	},
	'👭': {
		'keywords': [
			'two_women_holding_hands',
			'pair'
		],
		'emoticons': []
	},
	'👫': {
		'keywords': [
			'man_and_woman_holding_hands',
			'date',
			'dating'
		],
		'emoticons': []
	},
	'👬': {
		'keywords': [
			'two_men_holding_hands',
			'bromance'
		],
		'emoticons': []
	},
	'💏': {
		'keywords': [
			'couplekiss'
		],
		'emoticons': []
	},
	'💑': {
		'keywords': [
			'couple_with_heart'
		],
		'emoticons': []
	},
	'👪': {
		'keywords': [
			'family',
			'home',
			'parents',
			'mom'
		],
		'emoticons': []
	},
	'👤': {
		'keywords': [
			'bust_in_silhouette',
			'user'
		],
		'emoticons': []
	},
	'👥': {
		'keywords': [
			'busts_in_silhouette',
			'group',
			'team'
		],
		'emoticons': []
	},
	'🫂': {
		'keywords': [
			'people_hugging'
		],
		'emoticons': []
	},
	'👣': {
		'keywords': [
			'footprints',
			'feet',
			'tracking',
			'walking'
		],
		'emoticons': []
	},
	'🐵': {
		'keywords': [
			'monkey_face',
			'circus'
		],
		'emoticons': [
			':o)'
		]
	},
	'🐒': {
		'keywords': [
			'monkey',
			'banana'
		],
		'emoticons': []
	},
	'🦍': {
		'keywords': [
			'gorilla'
		],
		'emoticons': []
	},
	'🦧': {
		'keywords': [
			'orangutan'
		],
		'emoticons': []
	},
	'🐶': {
		'keywords': [
			'dog',
			'friend',
			'woof',
			'puppy',
			'pet',
			'faithful'
		],
		'emoticons': []
	},
	'🐕': {
		'keywords': [
			'dog2',
			'doge'
		],
		'emoticons': []
	},
	'🦮': {
		'keywords': [
			'guide_dog'
		],
		'emoticons': []
	},
	'🐕‍🦺': {
		'keywords': [
			'service_dog'
		],
		'emoticons': []
	},
	'🐩': {
		'keywords': [
			'poodle',
			'dog',
			'101'
		],
		'emoticons': []
	},
	'🐺': {
		'keywords': [
			'wolf',
			'wild'
		],
		'emoticons': []
	},
	'🦊': {
		'keywords': [
			'fox_face'
		],
		'emoticons': []
	},
	'🦝': {
		'keywords': [
			'raccoon'
		],
		'emoticons': []
	},
	'🐱': {
		'keywords': [
			'cat',
			'meow',
			'kitten'
		],
		'emoticons': []
	},
	'🐈': {
		'keywords': [
			'cat2'
		],
		'emoticons': []
	},
	'🐈‍⬛': {
		'keywords': [
			'black_cat',
			'superstition'
		],
		'emoticons': []
	},
	'🦁': {
		'keywords': [
			'lion'
		],
		'emoticons': []
	},
	'🐯': {
		'keywords': [
			'tiger',
			'cat',
			'roar'
		],
		'emoticons': []
	},
	'🐅': {
		'keywords': [
			'tiger2'
		],
		'emoticons': []
	},
	'🐆': {
		'keywords': [
			'leopard'
		],
		'emoticons': []
	},
	'🐴': {
		'keywords': [
			'horse',
			'brown'
		],
		'emoticons': []
	},
	'🐎': {
		'keywords': [
			'racehorse',
			'gamble'
		],
		'emoticons': []
	},
	'🦄': {
		'keywords': [
			'unicorn',
			'mystical'
		],
		'emoticons': []
	},
	'🦓': {
		'keywords': [
			'zebra',
			'stripes',
			'safari'
		],
		'emoticons': []
	},
	'🦌': {
		'keywords': [
			'deer',
			'venison'
		],
		'emoticons': []
	},
	'🦬': {
		'keywords': [
			'bison',
			'ox'
		],
		'emoticons': []
	},
	'🐮': {
		'keywords': [
			'cow',
			'beef',
			'moo',
			'milk'
		],
		'emoticons': []
	},
	'🐂': {
		'keywords': [
			'ox',
			'cow'
		],
		'emoticons': []
	},
	'🐃': {
		'keywords': [
			'water_buffalo'
		],
		'emoticons': []
	},
	'🐄': {
		'keywords': [
			'cow2'
		],
		'emoticons': []
	},
	'🐷': {
		'keywords': [
			'pig',
			'oink'
		],
		'emoticons': []
	},
	'🐖': {
		'keywords': [
			'pig2'
		],
		'emoticons': []
	},
	'🐗': {
		'keywords': [
			'boar'
		],
		'emoticons': []
	},
	'🐽': {
		'keywords': [
			'pig_nose'
		],
		'emoticons': []
	},
	'🐏': {
		'keywords': [
			'ram',
			'sheep'
		],
		'emoticons': []
	},
	'🐑': {
		'keywords': [
			'sheep',
			'wool',
			'shipit'
		],
		'emoticons': []
	},
	'🐐': {
		'keywords': [
			'goat'
		],
		'emoticons': []
	},
	'🐪': {
		'keywords': [
			'dromedary_camel',
			'dromedary',
			'desert',
			'hump'
		],
		'emoticons': []
	},
	'🐫': {
		'keywords': [
			'camel'
		],
		'emoticons': []
	},
	'🦙': {
		'keywords': [
			'llama',
			'alpaca'
		],
		'emoticons': []
	},
	'🦒': {
		'keywords': [
			'giraffe',
			'spots'
		],
		'emoticons': []
	},
	'🐘': {
		'keywords': [
			'elephant',
			'th'
		],
		'emoticons': []
	},
	'🦣': {
		'keywords': [
			'mammoth',
			'elephant',
			'tusks'
		],
		'emoticons': []
	},
	'🦏': {
		'keywords': [
			'rhinoceros',
			'horn'
		],
		'emoticons': []
	},
	'🦛': {
		'keywords': [
			'hippopotamus'
		],
		'emoticons': []
	},
	'🐭': {
		'keywords': [
			'mouse',
			'cheese',
			'wedge',
			'rodent'
		],
		'emoticons': []
	},
	'🐁': {
		'keywords': [
			'mouse2'
		],
		'emoticons': []
	},
	'🐀': {
		'keywords': [
			'rat',
			'mouse'
		],
		'emoticons': []
	},
	'🐹': {
		'keywords': [
			'hamster'
		],
		'emoticons': []
	},
	'🐰': {
		'keywords': [
			'rabbit',
			'spring',
			'bunny'
		],
		'emoticons': []
	},
	'🐇': {
		'keywords': [
			'rabbit2'
		],
		'emoticons': []
	},
	'🐿️': {
		'keywords': [
			'chipmunk',
			'squirrel'
		],
		'emoticons': []
	},
	'🦫': {
		'keywords': [
			'beaver'
		],
		'emoticons': []
	},
	'🦔': {
		'keywords': [
			'hedgehog',
			'spiny'
		],
		'emoticons': []
	},
	'🦇': {
		'keywords': [
			'bat',
			'vampire'
		],
		'emoticons': []
	},
	'🐻': {
		'keywords': [
			'bear'
		],
		'emoticons': []
	},
	'🐻‍❄️': {
		'keywords': [
			'polar_bear',
			'arctic'
		],
		'emoticons': []
	},
	'🐨': {
		'keywords': [
			'koala'
		],
		'emoticons': []
	},
	'🐼': {
		'keywords': [
			'panda_face'
		],
		'emoticons': []
	},
	'🦥': {
		'keywords': [
			'sloth'
		],
		'emoticons': []
	},
	'🦦': {
		'keywords': [
			'otter'
		],
		'emoticons': []
	},
	'🦨': {
		'keywords': [
			'skunk'
		],
		'emoticons': []
	},
	'🦘': {
		'keywords': [
			'kangaroo',
			'australia',
			'joey',
			'hop',
			'marsupial'
		],
		'emoticons': []
	},
	'🦡': {
		'keywords': [
			'badger',
			'honey'
		],
		'emoticons': []
	},
	'🐾': {
		'keywords': [
			'feet',
			'footprints'
		],
		'emoticons': []
	},
	'🦃': {
		'keywords': [
			'turkey',
			'bird'
		],
		'emoticons': []
	},
	'🐔': {
		'keywords': [
			'chicken',
			'cluck'
		],
		'emoticons': []
	},
	'🐓': {
		'keywords': [
			'rooster',
			'chicken'
		],
		'emoticons': []
	},
	'🐣': {
		'keywords': [
			'hatching_chick',
			'egg',
			'born'
		],
		'emoticons': []
	},
	'🐤': {
		'keywords': [
			'baby_chick'
		],
		'emoticons': []
	},
	'🐥': {
		'keywords': [
			'hatched_chick',
			'hatched',
			'front'
		],
		'emoticons': []
	},
	'🐦': {
		'keywords': [
			'bird',
			'tweet'
		],
		'emoticons': []
	},
	'🐧': {
		'keywords': [
			'penguin'
		],
		'emoticons': []
	},
	'🦅': {
		'keywords': [
			'eagle'
		],
		'emoticons': []
	},
	'🦆': {
		'keywords': [
			'duck',
			'mallard'
		],
		'emoticons': []
	},
	'🦢': {
		'keywords': [
			'swan'
		],
		'emoticons': []
	},
	'🦉': {
		'keywords': [
			'owl',
			'hoot'
		],
		'emoticons': []
	},
	'🦤': {
		'keywords': [
			'dodo'
		],
		'emoticons': []
	},
	'🪶': {
		'keywords': [
			'feather'
		],
		'emoticons': []
	},
	'🦩': {
		'keywords': [
			'flamingo'
		],
		'emoticons': []
	},
	'🦚': {
		'keywords': [
			'peacock',
			'peahen'
		],
		'emoticons': []
	},
	'🦜': {
		'keywords': [
			'parrot'
		],
		'emoticons': []
	},
	'🐸': {
		'keywords': [
			'frog',
			'croak',
			'toad'
		],
		'emoticons': []
	},
	'🐊': {
		'keywords': [
			'crocodile',
			'reptile',
			'lizard',
			'alligator'
		],
		'emoticons': []
	},
	'🐢': {
		'keywords': [
			'turtle',
			'slow',
			'tortoise'
		],
		'emoticons': []
	},
	'🦎': {
		'keywords': [
			'lizard'
		],
		'emoticons': []
	},
	'🐍': {
		'keywords': [
			'snake',
			'hiss',
			'python'
		],
		'emoticons': []
	},
	'🐲': {
		'keywords': [
			'dragon_face',
			'myth'
		],
		'emoticons': []
	},
	'🐉': {
		'keywords': [
			'dragon'
		],
		'emoticons': []
	},
	'🦕': {
		'keywords': [
			'sauropod',
			'dinosaur',
			'brachiosaurus',
			'brontosaurus',
			'diplodocus',
			'extinct'
		],
		'emoticons': []
	},
	'🦖': {
		'keywords': [
			't-rex',
			't',
			'rex',
			'tyrannosaurus'
		],
		'emoticons': []
	},
	'🐳': {
		'keywords': [
			'whale',
			'ocean'
		],
		'emoticons': []
	},
	'🐋': {
		'keywords': [
			'whale2'
		],
		'emoticons': []
	},
	'🐬': {
		'keywords': [
			'dolphin',
			'flipper',
			'fish',
			'fins'
		],
		'emoticons': []
	},
	'🦭': {
		'keywords': [
			'seal',
			'creature'
		],
		'emoticons': []
	},
	'🐟': {
		'keywords': [
			'fish'
		],
		'emoticons': []
	},
	'🐠': {
		'keywords': [
			'tropical_fish',
			'swim',
			'nemo'
		],
		'emoticons': []
	},
	'🐡': {
		'keywords': [
			'blowfish'
		],
		'emoticons': []
	},
	'🦈': {
		'keywords': [
			'shark',
			'jaws'
		],
		'emoticons': []
	},
	'🐙': {
		'keywords': [
			'octopus'
		],
		'emoticons': []
	},
	'🐚': {
		'keywords': [
			'shell'
		],
		'emoticons': []
	},
	'🐌': {
		'keywords': [
			'snail',
			'shell'
		],
		'emoticons': []
	},
	'🦋': {
		'keywords': [
			'butterfly',
			'insect',
			'caterpillar'
		],
		'emoticons': []
	},
	'🐛': {
		'keywords': [
			'bug',
			'worm'
		],
		'emoticons': []
	},
	'🐜': {
		'keywords': [
			'ant',
			'bug'
		],
		'emoticons': []
	},
	'🐝': {
		'keywords': [
			'bee'
		],
		'emoticons': []
	},
	'🪲': {
		'keywords': [
			'beetle'
		],
		'emoticons': []
	},
	'🐞': {
		'keywords': [
			'ladybug'
		],
		'emoticons': []
	},
	'🦗': {
		'keywords': [
			'cricket',
			'chirp'
		],
		'emoticons': []
	},
	'🪳': {
		'keywords': [
			'cockroach',
			'pests'
		],
		'emoticons': []
	},
	'🕷️': {
		'keywords': [
			'spider',
			'arachnid'
		],
		'emoticons': []
	},
	'🕸️': {
		'keywords': [
			'spider_web',
			'silk'
		],
		'emoticons': []
	},
	'🦂': {
		'keywords': [
			'scorpion'
		],
		'emoticons': []
	},
	'🦟': {
		'keywords': [
			'mosquito',
			'malaria'
		],
		'emoticons': []
	},
	'🪰': {
		'keywords': [
			'fly'
		],
		'emoticons': []
	},
	'🪱': {
		'keywords': [
			'worm'
		],
		'emoticons': []
	},
	'🦠': {
		'keywords': [
			'microbe',
			'amoeba',
			'bacteria',
			'germs',
			'virus'
		],
		'emoticons': []
	},
	'💐': {
		'keywords': [
			'bouquet',
			'flowers'
		],
		'emoticons': []
	},
	'🌸': {
		'keywords': [
			'cherry_blossom',
			'plant',
			'flower'
		],
		'emoticons': []
	},
	'💮': {
		'keywords': [
			'white_flower'
		],
		'emoticons': []
	},
	'🏵️': {
		'keywords': [
			'rosette',
			'decoration',
			'military'
		],
		'emoticons': []
	},
	'🌹': {
		'keywords': [
			'rose'
		],
		'emoticons': []
	},
	'🥀': {
		'keywords': [
			'wilted_flower'
		],
		'emoticons': []
	},
	'🌺': {
		'keywords': [
			'hibiscus',
			'vegetable'
		],
		'emoticons': []
	},
	'🌻': {
		'keywords': [
			'sunflower',
			'fall'
		],
		'emoticons': []
	},
	'🌼': {
		'keywords': [
			'blossom',
			'yellow'
		],
		'emoticons': []
	},
	'🌷': {
		'keywords': [
			'tulip'
		],
		'emoticons': []
	},
	'🌱': {
		'keywords': [
			'seedling',
			'grass',
			'lawn'
		],
		'emoticons': []
	},
	'🪴': {
		'keywords': [
			'potted_plant',
			'greenery',
			'house'
		],
		'emoticons': []
	},
	'🌲': {
		'keywords': [
			'evergreen_tree'
		],
		'emoticons': []
	},
	'🌳': {
		'keywords': [
			'deciduous_tree'
		],
		'emoticons': []
	},
	'🌴': {
		'keywords': [
			'palm_tree',
			'mojito',
			'tropical'
		],
		'emoticons': []
	},
	'🌵': {
		'keywords': [
			'cactus'
		],
		'emoticons': []
	},
	'🌾': {
		'keywords': [
			'ear_of_rice',
			'sheaf'
		],
		'emoticons': []
	},
	'🌿': {
		'keywords': [
			'herb',
			'medicine',
			'weed'
		],
		'emoticons': []
	},
	'☘️': {
		'keywords': [
			'shamrock',
			'irish',
			'clover'
		],
		'emoticons': []
	},
	'🍀': {
		'keywords': [
			'four_leaf_clover'
		],
		'emoticons': []
	},
	'🍁': {
		'keywords': [
			'maple_leaf',
			'ca'
		],
		'emoticons': []
	},
	'🍂': {
		'keywords': [
			'fallen_leaf',
			'leaves'
		],
		'emoticons': []
	},
	'🍃': {
		'keywords': [
			'leaves',
			'tree'
		],
		'emoticons': []
	},
	'🍇': {
		'keywords': [
			'grapes',
			'fruit',
			'wine'
		],
		'emoticons': []
	},
	'🍈': {
		'keywords': [
			'melon'
		],
		'emoticons': []
	},
	'🍉': {
		'keywords': [
			'watermelon',
			'picnic'
		],
		'emoticons': []
	},
	'🍊': {
		'keywords': [
			'tangerine',
			'orange'
		],
		'emoticons': []
	},
	'🍋': {
		'keywords': [
			'lemon'
		],
		'emoticons': []
	},
	'🍌': {
		'keywords': [
			'banana',
			'monkey'
		],
		'emoticons': []
	},
	'🍍': {
		'keywords': [
			'pineapple'
		],
		'emoticons': []
	},
	'🥭': {
		'keywords': [
			'mango'
		],
		'emoticons': []
	},
	'🍎': {
		'keywords': [
			'apple',
			'mac',
			'school'
		],
		'emoticons': []
	},
	'🍏': {
		'keywords': [
			'green_apple'
		],
		'emoticons': []
	},
	'🍐': {
		'keywords': [
			'pear'
		],
		'emoticons': []
	},
	'🍑': {
		'keywords': [
			'peach'
		],
		'emoticons': []
	},
	'🍒': {
		'keywords': [
			'cherries'
		],
		'emoticons': []
	},
	'🍓': {
		'keywords': [
			'strawberry'
		],
		'emoticons': []
	},
	'🫐': {
		'keywords': [
			'blueberries'
		],
		'emoticons': []
	},
	'🥝': {
		'keywords': [
			'kiwi_fruit',
			'kiwi'
		],
		'emoticons': []
	},
	'🍅': {
		'keywords': [
			'tomato'
		],
		'emoticons': []
	},
	'🫒': {
		'keywords': [
			'olive'
		],
		'emoticons': []
	},
	'🥥': {
		'keywords': [
			'coconut'
		],
		'emoticons': []
	},
	'🥑': {
		'keywords': [
			'avocado'
		],
		'emoticons': []
	},
	'🍆': {
		'keywords': [
			'eggplant',
			'aubergine'
		],
		'emoticons': []
	},
	'🥔': {
		'keywords': [
			'potato',
			'tuber',
			'vegatable',
			'starch'
		],
		'emoticons': []
	},
	'🥕': {
		'keywords': [
			'carrot'
		],
		'emoticons': []
	},
	'🌽': {
		'keywords': [
			'corn'
		],
		'emoticons': []
	},
	'🌶️': {
		'keywords': [
			'hot_pepper',
			'spicy',
			'chilli',
			'chili'
		],
		'emoticons': []
	},
	'🫑': {
		'keywords': [
			'bell_pepper'
		],
		'emoticons': []
	},
	'🥒': {
		'keywords': [
			'cucumber',
			'pickle'
		],
		'emoticons': []
	},
	'🥬': {
		'keywords': [
			'leafy_green',
			'bok',
			'choy',
			'cabbage',
			'kale',
			'lettuce'
		],
		'emoticons': []
	},
	'🥦': {
		'keywords': [
			'broccoli'
		],
		'emoticons': []
	},
	'🧄': {
		'keywords': [
			'garlic',
			'spice',
			'cook'
		],
		'emoticons': []
	},
	'🧅': {
		'keywords': [
			'onion'
		],
		'emoticons': []
	},
	'🍄': {
		'keywords': [
			'mushroom'
		],
		'emoticons': []
	},
	'🥜': {
		'keywords': [
			'peanuts',
			'nut'
		],
		'emoticons': []
	},
	'🌰': {
		'keywords': [
			'chestnut'
		],
		'emoticons': []
	},
	'🍞': {
		'keywords': [
			'bread',
			'wheat',
			'breakfast',
			'toast'
		],
		'emoticons': []
	},
	'🥐': {
		'keywords': [
			'croissant',
			'bread',
			'french'
		],
		'emoticons': []
	},
	'🥖': {
		'keywords': [
			'baguette_bread',
			'france',
			'bakery'
		],
		'emoticons': []
	},
	'🫓': {
		'keywords': [
			'flatbread',
			'flour'
		],
		'emoticons': []
	},
	'🥨': {
		'keywords': [
			'pretzel',
			'twisted',
			'germany'
		],
		'emoticons': []
	},
	'🥯': {
		'keywords': [
			'bagel',
			'schmear',
			'jewish'
		],
		'emoticons': []
	},
	'🥞': {
		'keywords': [
			'pancakes',
			'flapjacks',
			'hotcakes',
			'brunch'
		],
		'emoticons': []
	},
	'🧇': {
		'keywords': [
			'waffle'
		],
		'emoticons': []
	},
	'🧀': {
		'keywords': [
			'cheese',
			'chadder',
			'swiss'
		],
		'emoticons': []
	},
	'🍖': {
		'keywords': [
			'meat_on_bone',
			'drumstick'
		],
		'emoticons': []
	},
	'🍗': {
		'keywords': [
			'poultry_leg',
			'meat',
			'turkey'
		],
		'emoticons': []
	},
	'🥩': {
		'keywords': [
			'cut_of_meat',
			'chop',
			'lambchop',
			'porkchop'
		],
		'emoticons': []
	},
	'🥓': {
		'keywords': [
			'bacon',
			'pork',
			'pig'
		],
		'emoticons': []
	},
	'🍔': {
		'keywords': [
			'hamburger',
			'cheeseburger',
			'mcdonalds',
			'burger'
		],
		'emoticons': []
	},
	'🍟': {
		'keywords': [
			'fries',
			'chips',
			'snack',
			'potato'
		],
		'emoticons': []
	},
	'🍕': {
		'keywords': [
			'pizza',
			'party',
			'italy'
		],
		'emoticons': []
	},
	'🌭': {
		'keywords': [
			'hotdog',
			'frankfurter',
			'america'
		],
		'emoticons': []
	},
	'🥪': {
		'keywords': [
			'sandwich',
			'lunch'
		],
		'emoticons': []
	},
	'🌮': {
		'keywords': [
			'taco',
			'mexican'
		],
		'emoticons': []
	},
	'🌯': {
		'keywords': [
			'burrito'
		],
		'emoticons': []
	},
	'🫔': {
		'keywords': [
			'tamale',
			'masa'
		],
		'emoticons': []
	},
	'🥙': {
		'keywords': [
			'stuffed_flatbread',
			'gyro',
			'mediterranean'
		],
		'emoticons': []
	},
	'🧆': {
		'keywords': [
			'falafel'
		],
		'emoticons': []
	},
	'🥚': {
		'keywords': [
			'egg'
		],
		'emoticons': []
	},
	'🍳': {
		'keywords': [
			'fried_egg',
			'fried',
			'skillet'
		],
		'emoticons': []
	},
	'🥘': {
		'keywords': [
			'shallow_pan_of_food',
			'cooking',
			'casserole',
			'paella'
		],
		'emoticons': []
	},
	'🍲': {
		'keywords': [
			'stew',
			'soup'
		],
		'emoticons': []
	},
	'🫕': {
		'keywords': [
			'fondue',
			'pot'
		],
		'emoticons': []
	},
	'🥣': {
		'keywords': [
			'bowl_with_spoon',
			'cereal',
			'oatmeal',
			'porridge'
		],
		'emoticons': []
	},
	'🥗': {
		'keywords': [
			'green_salad',
			'healthy'
		],
		'emoticons': []
	},
	'🍿': {
		'keywords': [
			'popcorn',
			'movie',
			'theater',
			'films',
			'drama'
		],
		'emoticons': []
	},
	'🧈': {
		'keywords': [
			'butter'
		],
		'emoticons': []
	},
	'🧂': {
		'keywords': [
			'salt',
			'condiment',
			'shaker'
		],
		'emoticons': []
	},
	'🥫': {
		'keywords': [
			'canned_food',
			'tomatoes'
		],
		'emoticons': []
	},
	'🍱': {
		'keywords': [
			'bento'
		],
		'emoticons': []
	},
	'🍘': {
		'keywords': [
			'rice_cracker'
		],
		'emoticons': []
	},
	'🍙': {
		'keywords': [
			'rice_ball'
		],
		'emoticons': []
	},
	'🍚': {
		'keywords': [
			'rice',
			'asian'
		],
		'emoticons': []
	},
	'🍛': {
		'keywords': [
			'curry',
			'indian'
		],
		'emoticons': []
	},
	'🍜': {
		'keywords': [
			'ramen',
			'noodle',
			'chopsticks'
		],
		'emoticons': []
	},
	'🍝': {
		'keywords': [
			'spaghetti',
			'italian',
			'pasta'
		],
		'emoticons': []
	},
	'🍠': {
		'keywords': [
			'sweet_potato'
		],
		'emoticons': []
	},
	'🍢': {
		'keywords': [
			'oden'
		],
		'emoticons': []
	},
	'🍣': {
		'keywords': [
			'sushi',
			'rice'
		],
		'emoticons': []
	},
	'🍤': {
		'keywords': [
			'fried_shrimp',
			'appetizer'
		],
		'emoticons': []
	},
	'🍥': {
		'keywords': [
			'fish_cake',
			'japan',
			'narutomaki',
			'kamaboko',
			'surimi',
			'ramen'
		],
		'emoticons': []
	},
	'🥮': {
		'keywords': [
			'moon_cake',
			'autumn',
			'dessert'
		],
		'emoticons': []
	},
	'🍡': {
		'keywords': [
			'dango',
			'sweet',
			'barbecue'
		],
		'emoticons': []
	},
	'🥟': {
		'keywords': [
			'dumpling',
			'empanada',
			'pierogi',
			'potsticker',
			'gyoza'
		],
		'emoticons': []
	},
	'🥠': {
		'keywords': [
			'fortune_cookie',
			'prophecy'
		],
		'emoticons': []
	},
	'🥡': {
		'keywords': [
			'takeout_box',
			'leftovers'
		],
		'emoticons': []
	},
	'🦀': {
		'keywords': [
			'crab',
			'crustacean'
		],
		'emoticons': []
	},
	'🦞': {
		'keywords': [
			'lobster',
			'bisque',
			'claws',
			'seafood'
		],
		'emoticons': []
	},
	'🦐': {
		'keywords': [
			'shrimp'
		],
		'emoticons': []
	},
	'🦑': {
		'keywords': [
			'squid'
		],
		'emoticons': []
	},
	'🦪': {
		'keywords': [
			'oyster'
		],
		'emoticons': []
	},
	'🍦': {
		'keywords': [
			'icecream'
		],
		'emoticons': []
	},
	'🍧': {
		'keywords': [
			'shaved_ice'
		],
		'emoticons': []
	},
	'🍨': {
		'keywords': [
			'ice_cream'
		],
		'emoticons': []
	},
	'🍩': {
		'keywords': [
			'doughnut',
			'donut'
		],
		'emoticons': []
	},
	'🍪': {
		'keywords': [
			'cookie',
			'oreo',
			'chocolate'
		],
		'emoticons': []
	},
	'🎂': {
		'keywords': [
			'birthday'
		],
		'emoticons': []
	},
	'🍰': {
		'keywords': [
			'cake'
		],
		'emoticons': []
	},
	'🧁': {
		'keywords': [
			'cupcake'
		],
		'emoticons': []
	},
	'🥧': {
		'keywords': [
			'pie',
			'pastry'
		],
		'emoticons': []
	},
	'🍫': {
		'keywords': [
			'chocolate_bar'
		],
		'emoticons': []
	},
	'🍬': {
		'keywords': [
			'candy',
			'lolly'
		],
		'emoticons': []
	},
	'🍭': {
		'keywords': [
			'lollipop',
			'candy'
		],
		'emoticons': []
	},
	'🍮': {
		'keywords': [
			'custard'
		],
		'emoticons': []
	},
	'🍯': {
		'keywords': [
			'honey_pot',
			'bees'
		],
		'emoticons': []
	},
	'🍼': {
		'keywords': [
			'baby_bottle',
			'container'
		],
		'emoticons': []
	},
	'🥛': {
		'keywords': [
			'milk_glass',
			'beverage',
			'drink'
		],
		'emoticons': []
	},
	'☕': {
		'keywords': [
			'coffee',
			'caffeine',
			'latte',
			'espresso'
		],
		'emoticons': []
	},
	'🫖': {
		'keywords': [
			'teapot'
		],
		'emoticons': []
	},
	'🍵': {
		'keywords': [
			'tea',
			'bowl',
			'british'
		],
		'emoticons': []
	},
	'🍶': {
		'keywords': [
			'sake',
			'drunk',
			'alcohol',
			'booze'
		],
		'emoticons': []
	},
	'🍾': {
		'keywords': [
			'champagne'
		],
		'emoticons': []
	},
	'🍷': {
		'keywords': [
			'wine_glass'
		],
		'emoticons': []
	},
	'🍸': {
		'keywords': [
			'cocktail'
		],
		'emoticons': []
	},
	'🍹': {
		'keywords': [
			'tropical_drink',
			'cocktail'
		],
		'emoticons': []
	},
	'🍺': {
		'keywords': [
			'beer',
			'pub'
		],
		'emoticons': []
	},
	'🍻': {
		'keywords': [
			'beers'
		],
		'emoticons': []
	},
	'🥂': {
		'keywords': [
			'clinking_glasses',
			'celebrate',
			'cheers',
			'champagne'
		],
		'emoticons': []
	},
	'🥃': {
		'keywords': [
			'tumbler_glass',
			'liquor',
			'bourbon',
			'scotch',
			'whisky',
			'shot'
		],
		'emoticons': []
	},
	'🥤': {
		'keywords': [
			'cup_with_straw',
			'soda'
		],
		'emoticons': []
	},
	'🧋': {
		'keywords': [
			'bubble_tea',
			'taiwan',
			'boba',
			'straw'
		],
		'emoticons': []
	},
	'🧃': {
		'keywords': [
			'beverage_box'
		],
		'emoticons': []
	},
	'🧉': {
		'keywords': [
			'mate',
			'tea'
		],
		'emoticons': []
	},
	'🧊': {
		'keywords': [
			'ice_cube',
			'cube'
		],
		'emoticons': []
	},
	'🥢': {
		'keywords': [
			'chopsticks'
		],
		'emoticons': []
	},
	'🍴': {
		'keywords': [
			'fork_and_knife',
			'cutlery'
		],
		'emoticons': []
	},
	'🥄': {
		'keywords': [
			'spoon',
			'tableware'
		],
		'emoticons': []
	},
	'🔪': {
		'keywords': [
			'hocho',
			'knife',
			'blade',
			'weapon'
		],
		'emoticons': []
	},
	'🏺': {
		'keywords': [
			'amphora',
			'vase',
			'jar'
		],
		'emoticons': []
	},
	'🌍': {
		'keywords': [
			'earth_africa',
			'africa',
			'showing',
			'europe',
			'world',
			'international'
		],
		'emoticons': []
	},
	'🌎': {
		'keywords': [
			'earth_americas',
			'USA'
		],
		'emoticons': []
	},
	'🌏': {
		'keywords': [
			'earth_asia',
			'asia',
			'east'
		],
		'emoticons': []
	},
	'🌐': {
		'keywords': [
			'globe_with_meridians',
			'earth',
			'internet',
			'interweb',
			'i18n'
		],
		'emoticons': []
	},
	'🗺️': {
		'keywords': [
			'world_map',
			'location'
		],
		'emoticons': []
	},
	'🗾': {
		'keywords': [
			'japan',
			'nation',
			'country'
		],
		'emoticons': []
	},
	'🧭': {
		'keywords': [
			'compass',
			'magnetic',
			'navigation',
			'orienteering'
		],
		'emoticons': []
	},
	'⛰️': {
		'keywords': [
			'mountain',
			'photo',
			'environment'
		],
		'emoticons': []
	},
	'🌋': {
		'keywords': [
			'volcano',
			'disaster'
		],
		'emoticons': []
	},
	'🗻': {
		'keywords': [
			'mount_fuji',
			'mountain'
		],
		'emoticons': []
	},
	'🏕️': {
		'keywords': [
			'camping',
			'outdoors',
			'tent'
		],
		'emoticons': []
	},
	'🏜️': {
		'keywords': [
			'desert',
			'warm',
			'saharah'
		],
		'emoticons': []
	},
	'🏝️': {
		'keywords': [
			'desert_island'
		],
		'emoticons': []
	},
	'🏞️': {
		'keywords': [
			'national_park'
		],
		'emoticons': []
	},
	'🏟️': {
		'keywords': [
			'stadium',
			'place',
			'concert',
			'venue'
		],
		'emoticons': []
	},
	'🏛️': {
		'keywords': [
			'classical_building',
			'art',
			'culture',
			'history'
		],
		'emoticons': []
	},
	'🏗️': {
		'keywords': [
			'building_construction',
			'wip',
			'working',
			'progress'
		],
		'emoticons': []
	},
	'🧱': {
		'keywords': [
			'bricks'
		],
		'emoticons': []
	},
	'🪨': {
		'keywords': [
			'rock',
			'stone'
		],
		'emoticons': []
	},
	'🪵': {
		'keywords': [
			'wood',
			'timber',
			'trunk'
		],
		'emoticons': []
	},
	'🛖': {
		'keywords': [
			'hut',
			'structure'
		],
		'emoticons': []
	},
	'🏠': {
		'keywords': [
			'house',
			'building'
		],
		'emoticons': []
	},
	'🏡': {
		'keywords': [
			'house_with_garden'
		],
		'emoticons': []
	},
	'🏢': {
		'keywords': [
			'office',
			'bureau',
			'work'
		],
		'emoticons': []
	},
	'🏣': {
		'keywords': [
			'post_office',
			'communication'
		],
		'emoticons': []
	},
	'🏤': {
		'keywords': [
			'european_post_office',
			'european'
		],
		'emoticons': []
	},
	'🏥': {
		'keywords': [
			'hospital',
			'surgery',
			'doctor'
		],
		'emoticons': []
	},
	'🏦': {
		'keywords': [
			'bank',
			'sales',
			'cash',
			'enterprise'
		],
		'emoticons': []
	},
	'🏨': {
		'keywords': [
			'hotel',
			'accomodation',
			'checkin'
		],
		'emoticons': []
	},
	'🏩': {
		'keywords': [
			'love_hotel'
		],
		'emoticons': []
	},
	'🏪': {
		'keywords': [
			'convenience_store',
			'shopping',
			'groceries'
		],
		'emoticons': []
	},
	'🏫': {
		'keywords': [
			'school',
			'student',
			'education',
			'teach'
		],
		'emoticons': []
	},
	'🏬': {
		'keywords': [
			'department_store',
			'mall'
		],
		'emoticons': []
	},
	'🏭': {
		'keywords': [
			'factory',
			'industry',
			'pollution'
		],
		'emoticons': []
	},
	'🏯': {
		'keywords': [
			'japanese_castle'
		],
		'emoticons': []
	},
	'🏰': {
		'keywords': [
			'european_castle',
			'royalty'
		],
		'emoticons': []
	},
	'💒': {
		'keywords': [
			'wedding',
			'bride'
		],
		'emoticons': []
	},
	'🗼': {
		'keywords': [
			'tokyo_tower'
		],
		'emoticons': []
	},
	'🗽': {
		'keywords': [
			'statue_of_liberty',
			'american',
			'newyork'
		],
		'emoticons': []
	},
	'⛪': {
		'keywords': [
			'church',
			'religion',
			'christ'
		],
		'emoticons': []
	},
	'🕌': {
		'keywords': [
			'mosque',
			'islam',
			'worship',
			'minaret'
		],
		'emoticons': []
	},
	'🛕': {
		'keywords': [
			'hindu_temple'
		],
		'emoticons': []
	},
	'🕍': {
		'keywords': [
			'synagogue',
			'judaism',
			'temple'
		],
		'emoticons': []
	},
	'⛩️': {
		'keywords': [
			'shinto_shrine',
			'kyoto'
		],
		'emoticons': []
	},
	'🕋': {
		'keywords': [
			'kaaba',
			'mecca',
			'mosque'
		],
		'emoticons': []
	},
	'⛲': {
		'keywords': [
			'fountain',
			'fresh'
		],
		'emoticons': []
	},
	'⛺': {
		'keywords': [
			'tent',
			'camping'
		],
		'emoticons': []
	},
	'🌁': {
		'keywords': [
			'foggy'
		],
		'emoticons': []
	},
	'🌃': {
		'keywords': [
			'night_with_stars',
			'evening',
			'city',
			'downtown'
		],
		'emoticons': []
	},
	'🏙️': {
		'keywords': [
			'cityscape',
			'life',
			'urban'
		],
		'emoticons': []
	},
	'🌄': {
		'keywords': [
			'sunrise_over_mountains',
			'view',
			'vacation'
		],
		'emoticons': []
	},
	'🌅': {
		'keywords': [
			'sunrise',
			'morning'
		],
		'emoticons': []
	},
	'🌆': {
		'keywords': [
			'city_sunset',
			'sunset',
			'sky',
			'buildings'
		],
		'emoticons': []
	},
	'🌇': {
		'keywords': [
			'city_sunrise',
			'sunrise',
			'dawn'
		],
		'emoticons': []
	},
	'🌉': {
		'keywords': [
			'bridge_at_night',
			'sanfrancisco'
		],
		'emoticons': []
	},
	'♨️': {
		'keywords': [
			'hotsprings',
			'bath'
		],
		'emoticons': []
	},
	'🎠': {
		'keywords': [
			'carousel_horse',
			'carnival'
		],
		'emoticons': []
	},
	'🎡': {
		'keywords': [
			'ferris_wheel',
			'londoneye'
		],
		'emoticons': []
	},
	'🎢': {
		'keywords': [
			'roller_coaster',
			'playground'
		],
		'emoticons': []
	},
	'💈': {
		'keywords': [
			'barber',
			'hair',
			'salon',
			'style'
		],
		'emoticons': []
	},
	'🎪': {
		'keywords': [
			'circus_tent'
		],
		'emoticons': []
	},
	'🚂': {
		'keywords': [
			'steam_locomotive',
			'transportation',
			'vehicle',
			'train'
		],
		'emoticons': []
	},
	'🚃': {
		'keywords': [
			'railway_car'
		],
		'emoticons': []
	},
	'🚄': {
		'keywords': [
			'bullettrain_side',
			'bullettrain',
			'speed'
		],
		'emoticons': []
	},
	'🚅': {
		'keywords': [
			'bullettrain_front',
			'public',
			'travel'
		],
		'emoticons': []
	},
	'🚆': {
		'keywords': [
			'train2'
		],
		'emoticons': []
	},
	'🚇': {
		'keywords': [
			'metro',
			'mrt',
			'underground',
			'tube'
		],
		'emoticons': []
	},
	'🚈': {
		'keywords': [
			'light_rail'
		],
		'emoticons': []
	},
	'🚉': {
		'keywords': [
			'station'
		],
		'emoticons': []
	},
	'🚊': {
		'keywords': [
			'tram'
		],
		'emoticons': []
	},
	'🚝': {
		'keywords': [
			'monorail'
		],
		'emoticons': []
	},
	'🚞': {
		'keywords': [
			'mountain_railway'
		],
		'emoticons': []
	},
	'🚋': {
		'keywords': [
			'train',
			'carriage'
		],
		'emoticons': []
	},
	'🚌': {
		'keywords': [
			'bus',
			'car'
		],
		'emoticons': []
	},
	'🚍': {
		'keywords': [
			'oncoming_bus'
		],
		'emoticons': []
	},
	'🚎': {
		'keywords': [
			'trolleybus',
			'bart'
		],
		'emoticons': []
	},
	'🚐': {
		'keywords': [
			'minibus'
		],
		'emoticons': []
	},
	'🚑': {
		'keywords': [
			'ambulance',
			'911'
		],
		'emoticons': []
	},
	'🚒': {
		'keywords': [
			'fire_engine',
			'cars'
		],
		'emoticons': []
	},
	'🚓': {
		'keywords': [
			'police_car',
			'legal',
			'enforcement'
		],
		'emoticons': []
	},
	'🚔': {
		'keywords': [
			'oncoming_police_car'
		],
		'emoticons': []
	},
	'🚕': {
		'keywords': [
			'taxi',
			'uber'
		],
		'emoticons': []
	},
	'🚖': {
		'keywords': [
			'oncoming_taxi'
		],
		'emoticons': []
	},
	'🚗': {
		'keywords': [
			'car'
		],
		'emoticons': []
	},
	'🚘': {
		'keywords': [
			'oncoming_automobile'
		],
		'emoticons': []
	},
	'🚙': {
		'keywords': [
			'blue_car',
			'utility'
		],
		'emoticons': []
	},
	'🛻': {
		'keywords': [
			'pickup_truck'
		],
		'emoticons': []
	},
	'🚚': {
		'keywords': [
			'truck'
		],
		'emoticons': []
	},
	'🚛': {
		'keywords': [
			'articulated_lorry',
			'express'
		],
		'emoticons': []
	},
	'🚜': {
		'keywords': [
			'tractor',
			'farming',
			'agriculture'
		],
		'emoticons': []
	},
	'🏎️': {
		'keywords': [
			'racing_car',
			'race',
			'formula',
			'f1'
		],
		'emoticons': []
	},
	'🛵': {
		'keywords': [
			'motor_scooter',
			'vespa',
			'sasha'
		],
		'emoticons': []
	},
	'🦽': {
		'keywords': [
			'manual_wheelchair'
		],
		'emoticons': []
	},
	'🦼': {
		'keywords': [
			'motorized_wheelchair'
		],
		'emoticons': []
	},
	'🛺': {
		'keywords': [
			'auto_rickshaw'
		],
		'emoticons': []
	},
	'🚲': {
		'keywords': [
			'bike',
			'hipster'
		],
		'emoticons': []
	},
	'🛴': {
		'keywords': [
			'kick_scooter',
			'razor'
		],
		'emoticons': []
	},
	'🛹': {
		'keywords': [
			'skateboard',
			'board'
		],
		'emoticons': []
	},
	'🛼': {
		'keywords': [
			'roller_skate',
			'footwear'
		],
		'emoticons': []
	},
	'🚏': {
		'keywords': [
			'busstop',
			'wait'
		],
		'emoticons': []
	},
	'🛣️': {
		'keywords': [
			'motorway',
			'road',
			'cupertino',
			'interstate',
			'highway'
		],
		'emoticons': []
	},
	'🛤️': {
		'keywords': [
			'railway_track'
		],
		'emoticons': []
	},
	'🛢️': {
		'keywords': [
			'oil_drum',
			'barrell'
		],
		'emoticons': []
	},
	'⛽': {
		'keywords': [
			'fuelpump',
			'station',
			'petroleum'
		],
		'emoticons': []
	},
	'🚨': {
		'keywords': [
			'rotating_light',
			'rotating',
			'ambulance',
			'emergency',
			'alert',
			'error',
			'pinged'
		],
		'emoticons': []
	},
	'🚥': {
		'keywords': [
			'traffic_light',
			'signal'
		],
		'emoticons': []
	},
	'🚦': {
		'keywords': [
			'vertical_traffic_light',
			'driving'
		],
		'emoticons': []
	},
	'🛑': {
		'keywords': [
			'stop_sign',
			'octagonal'
		],
		'emoticons': []
	},
	'🚧': {
		'keywords': [
			'construction',
			'caution',
			'warning'
		],
		'emoticons': []
	},
	'⚓': {
		'keywords': [
			'anchor',
			'ship',
			'ferry'
		],
		'emoticons': []
	},
	'⛵': {
		'keywords': [
			'boat',
			'sailing'
		],
		'emoticons': []
	},
	'🛶': {
		'keywords': [
			'canoe',
			'paddle'
		],
		'emoticons': []
	},
	'🚤': {
		'keywords': [
			'speedboat'
		],
		'emoticons': []
	},
	'🛳️': {
		'keywords': [
			'passenger_ship',
			'yacht',
			'cruise'
		],
		'emoticons': []
	},
	'⛴️': {
		'keywords': [
			'ferry'
		],
		'emoticons': []
	},
	'🛥️': {
		'keywords': [
			'motor_boat'
		],
		'emoticons': []
	},
	'🚢': {
		'keywords': [
			'ship',
			'titanic',
			'deploy'
		],
		'emoticons': []
	},
	'✈️': {
		'keywords': [
			'airplane',
			'flight'
		],
		'emoticons': []
	},
	'🛩️': {
		'keywords': [
			'small_airplane'
		],
		'emoticons': []
	},
	'🛫': {
		'keywords': [
			'flight_departure',
			'airport',
			'landing'
		],
		'emoticons': []
	},
	'🛬': {
		'keywords': [
			'flight_arrival',
			'arriving',
			'boarding'
		],
		'emoticons': []
	},
	'🪂': {
		'keywords': [
			'parachute',
			'glide'
		],
		'emoticons': []
	},
	'💺': {
		'keywords': [
			'seat',
			'sit',
			'transport',
			'bus'
		],
		'emoticons': []
	},
	'🚁': {
		'keywords': [
			'helicopter'
		],
		'emoticons': []
	},
	'🚟': {
		'keywords': [
			'suspension_railway'
		],
		'emoticons': []
	},
	'🚠': {
		'keywords': [
			'mountain_cableway',
			'ski'
		],
		'emoticons': []
	},
	'🚡': {
		'keywords': [
			'aerial_tramway'
		],
		'emoticons': []
	},
	'📡': {
		'keywords': [
			'satellite',
			'future',
			'radio'
		],
		'emoticons': []
	},
	'🚀': {
		'keywords': [
			'rocket',
			'launch',
			'staffmode',
			'NASA'
		],
		'emoticons': []
	},
	'🛸': {
		'keywords': [
			'flying_saucer',
			'ufo'
		],
		'emoticons': []
	},
	'🛎️': {
		'keywords': [
			'bellhop_bell',
			'service'
		],
		'emoticons': []
	},
	'🧳': {
		'keywords': [
			'luggage',
			'packing'
		],
		'emoticons': []
	},
	'⌛': {
		'keywords': [
			'hourglass',
			'done',
			'time',
			'clock',
			'oldschool',
			'limit'
		],
		'emoticons': []
	},
	'⏳': {
		'keywords': [
			'hourglass_flowing_sand',
			'flowing',
			'sand',
			'countdown'
		],
		'emoticons': []
	},
	'⌚': {
		'keywords': [
			'watch',
			'accessories'
		],
		'emoticons': []
	},
	'⏰': {
		'keywords': [
			'alarm_clock',
			'wake'
		],
		'emoticons': []
	},
	'⏱️': {
		'keywords': [
			'stopwatch',
			'deadline'
		],
		'emoticons': []
	},
	'⏲️': {
		'keywords': [
			'timer_clock',
			'alarm'
		],
		'emoticons': []
	},
	'🕰️': {
		'keywords': [
			'mantelpiece_clock'
		],
		'emoticons': []
	},
	'🕛': {
		'keywords': [
			'clock12',
			'o',
			'noon',
			'midnight',
			'midday',
			'late',
			'early',
			'schedule'
		],
		'emoticons': []
	},
	'🕧': {
		'keywords': [
			'clock1230',
			'twelve',
			'thirty'
		],
		'emoticons': []
	},
	'🕐': {
		'keywords': [
			'clock1'
		],
		'emoticons': []
	},
	'🕜': {
		'keywords': [
			'clock130'
		],
		'emoticons': []
	},
	'🕑': {
		'keywords': [
			'clock2'
		],
		'emoticons': []
	},
	'🕝': {
		'keywords': [
			'clock230'
		],
		'emoticons': []
	},
	'🕒': {
		'keywords': [
			'clock3'
		],
		'emoticons': []
	},
	'🕞': {
		'keywords': [
			'clock330'
		],
		'emoticons': []
	},
	'🕓': {
		'keywords': [
			'clock4'
		],
		'emoticons': []
	},
	'🕟': {
		'keywords': [
			'clock430',
			'four'
		],
		'emoticons': []
	},
	'🕔': {
		'keywords': [
			'clock5'
		],
		'emoticons': []
	},
	'🕠': {
		'keywords': [
			'clock530'
		],
		'emoticons': []
	},
	'🕕': {
		'keywords': [
			'clock6',
			'dusk'
		],
		'emoticons': []
	},
	'🕡': {
		'keywords': [
			'clock630',
			'six'
		],
		'emoticons': []
	},
	'🕖': {
		'keywords': [
			'clock7'
		],
		'emoticons': []
	},
	'🕢': {
		'keywords': [
			'clock730',
			'seven'
		],
		'emoticons': []
	},
	'🕗': {
		'keywords': [
			'clock8'
		],
		'emoticons': []
	},
	'🕣': {
		'keywords': [
			'clock830',
			'eight'
		],
		'emoticons': []
	},
	'🕘': {
		'keywords': [
			'clock9'
		],
		'emoticons': []
	},
	'🕤': {
		'keywords': [
			'clock930',
			'nine'
		],
		'emoticons': []
	},
	'🕙': {
		'keywords': [
			'clock10'
		],
		'emoticons': []
	},
	'🕥': {
		'keywords': [
			'clock1030',
			'ten'
		],
		'emoticons': []
	},
	'🕚': {
		'keywords': [
			'clock11'
		],
		'emoticons': []
	},
	'🕦': {
		'keywords': [
			'clock1130',
			'eleven'
		],
		'emoticons': []
	},
	'🌑': {
		'keywords': [
			'new_moon',
			'planet',
			'sleep'
		],
		'emoticons': []
	},
	'🌒': {
		'keywords': [
			'waxing_crescent_moon'
		],
		'emoticons': []
	},
	'🌓': {
		'keywords': [
			'first_quarter_moon'
		],
		'emoticons': []
	},
	'🌔': {
		'keywords': [
			'moon',
			'gray'
		],
		'emoticons': []
	},
	'🌕': {
		'keywords': [
			'full_moon'
		],
		'emoticons': []
	},
	'🌖': {
		'keywords': [
			'waning_gibbous_moon',
			'waxing'
		],
		'emoticons': []
	},
	'🌗': {
		'keywords': [
			'last_quarter_moon'
		],
		'emoticons': []
	},
	'🌘': {
		'keywords': [
			'waning_crescent_moon'
		],
		'emoticons': []
	},
	'🌙': {
		'keywords': [
			'crescent_moon'
		],
		'emoticons': []
	},
	'🌚': {
		'keywords': [
			'new_moon_with_face'
		],
		'emoticons': []
	},
	'🌛': {
		'keywords': [
			'first_quarter_moon_with_face'
		],
		'emoticons': []
	},
	'🌜': {
		'keywords': [
			'last_quarter_moon_with_face'
		],
		'emoticons': []
	},
	'🌡️': {
		'keywords': [
			'thermometer',
			'weather'
		],
		'emoticons': []
	},
	'☀️': {
		'keywords': [
			'sunny',
			'brightness'
		],
		'emoticons': []
	},
	'🌝': {
		'keywords': [
			'full_moon_with_face'
		],
		'emoticons': []
	},
	'🌞': {
		'keywords': [
			'sun_with_face'
		],
		'emoticons': []
	},
	'🪐': {
		'keywords': [
			'ringed_planet'
		],
		'emoticons': []
	},
	'⭐': {
		'keywords': [
			'star'
		],
		'emoticons': []
	},
	'🌟': {
		'keywords': [
			'star2'
		],
		'emoticons': []
	},
	'🌠': {
		'keywords': [
			'stars'
		],
		'emoticons': []
	},
	'🌌': {
		'keywords': [
			'milky_way',
			'stars'
		],
		'emoticons': []
	},
	'☁️': {
		'keywords': [
			'cloud'
		],
		'emoticons': []
	},
	'⛅': {
		'keywords': [
			'partly_sunny',
			'partly',
			'sunny',
			'cloudy'
		],
		'emoticons': []
	},
	'🌪️': {
		'keywords': [
			'tornado',
			'cyclone',
			'twister'
		],
		'emoticons': []
	},
	'🌫️': {
		'keywords': [
			'fog'
		],
		'emoticons': []
	},
	'🌀': {
		'keywords': [
			'cyclone',
			'swirl',
			'vortex',
			'spiral',
			'whirlpool',
			'spin',
			'tornado',
			'hurricane',
			'typhoon'
		],
		'emoticons': []
	},
	'🌈': {
		'keywords': [
			'rainbow',
			'unicorn'
		],
		'emoticons': []
	},
	'🌂': {
		'keywords': [
			'closed_umbrella',
			'rain',
			'drizzle'
		],
		'emoticons': []
	},
	'☔': {
		'keywords': [
			'umbrella',
			'rainy'
		],
		'emoticons': []
	},
	'⚡': {
		'keywords': [
			'zap',
			'thunder',
			'lightning',
			'bolt'
		],
		'emoticons': []
	},
	'❄️': {
		'keywords': [
			'snowflake',
			'season'
		],
		'emoticons': []
	},
	'⛄': {
		'keywords': [
			'snowman'
		],
		'emoticons': []
	},
	'☄️': {
		'keywords': [
			'comet'
		],
		'emoticons': []
	},
	'🔥': {
		'keywords': [
			'fire',
			'flame'
		],
		'emoticons': []
	},
	'💧': {
		'keywords': [
			'droplet',
			'faucet'
		],
		'emoticons': []
	},
	'🌊': {
		'keywords': [
			'ocean',
			'tsunami'
		],
		'emoticons': []
	},
	'🎃': {
		'keywords': [
			'jack_o_lantern',
			'jack',
			'lantern',
			'light',
			'pumpkin'
		],
		'emoticons': []
	},
	'🎄': {
		'keywords': [
			'christmas_tree',
			'december'
		],
		'emoticons': []
	},
	'🎆': {
		'keywords': [
			'fireworks',
			'congratulations'
		],
		'emoticons': []
	},
	'🎇': {
		'keywords': [
			'sparkler',
			'shine'
		],
		'emoticons': []
	},
	'🧨': {
		'keywords': [
			'firecracker',
			'dynamite',
			'explosive'
		],
		'emoticons': []
	},
	'✨': {
		'keywords': [
			'sparkles',
			'shiny'
		],
		'emoticons': []
	},
	'🎈': {
		'keywords': [
			'balloon',
			'birthday'
		],
		'emoticons': []
	},
	'🎉': {
		'keywords': [
			'tada'
		],
		'emoticons': []
	},
	'🎊': {
		'keywords': [
			'confetti_ball'
		],
		'emoticons': []
	},
	'🎋': {
		'keywords': [
			'tanabata_tree',
			'branch'
		],
		'emoticons': []
	},
	'🎍': {
		'keywords': [
			'bamboo',
			'panda'
		],
		'emoticons': []
	},
	'🎎': {
		'keywords': [
			'dolls',
			'toy',
			'kimono'
		],
		'emoticons': []
	},
	'🎏': {
		'keywords': [
			'flags',
			'koinobori',
			'banner'
		],
		'emoticons': []
	},
	'🎐': {
		'keywords': [
			'wind_chime',
			'ding',
			'bell'
		],
		'emoticons': []
	},
	'🎑': {
		'keywords': [
			'rice_scene',
			'scene',
			'tsukimi'
		],
		'emoticons': []
	},
	'🧧': {
		'keywords': [
			'red_envelope'
		],
		'emoticons': []
	},
	'🎀': {
		'keywords': [
			'ribbon',
			'bowtie'
		],
		'emoticons': []
	},
	'🎁': {
		'keywords': [
			'gift',
			'present'
		],
		'emoticons': []
	},
	'🎗️': {
		'keywords': [
			'reminder_ribbon',
			'cause',
			'support',
			'awareness'
		],
		'emoticons': []
	},
	'🎫': {
		'keywords': [
			'ticket',
			'event'
		],
		'emoticons': []
	},
	'🏆': {
		'keywords': [
			'trophy',
			'win',
			'award',
			'contest',
			'ftw',
			'ceremony'
		],
		'emoticons': []
	},
	'🏅': {
		'keywords': [
			'medal_sports',
			'winning'
		],
		'emoticons': []
	},
	'🥇': {
		'keywords': [
			'1st_place_medal',
			'first'
		],
		'emoticons': []
	},
	'🥈': {
		'keywords': [
			'2nd_place_medal',
			'second'
		],
		'emoticons': []
	},
	'🥉': {
		'keywords': [
			'3rd_place_medal',
			'third'
		],
		'emoticons': []
	},
	'⚽': {
		'keywords': [
			'soccer',
			'football'
		],
		'emoticons': []
	},
	'⚾': {
		'keywords': [
			'baseball',
			'balls'
		],
		'emoticons': []
	},
	'🥎': {
		'keywords': [
			'softball'
		],
		'emoticons': []
	},
	'🏀': {
		'keywords': [
			'basketball',
			'NBA'
		],
		'emoticons': []
	},
	'🏐': {
		'keywords': [
			'volleyball'
		],
		'emoticons': []
	},
	'🏈': {
		'keywords': [
			'football',
			'NFL'
		],
		'emoticons': []
	},
	'🏉': {
		'keywords': [
			'rugby_football'
		],
		'emoticons': []
	},
	'🎾': {
		'keywords': [
			'tennis'
		],
		'emoticons': []
	},
	'🥏': {
		'keywords': [
			'flying_disc',
			'frisbee',
			'ultimate'
		],
		'emoticons': []
	},
	'🎳': {
		'keywords': [
			'bowling'
		],
		'emoticons': []
	},
	'🏏': {
		'keywords': [
			'cricket_game',
			'bat',
			'ball'
		],
		'emoticons': []
	},
	'🏑': {
		'keywords': [
			'field_hockey',
			'stick'
		],
		'emoticons': []
	},
	'🏒': {
		'keywords': [
			'ice_hockey',
			'puck'
		],
		'emoticons': []
	},
	'🥍': {
		'keywords': [
			'lacrosse'
		],
		'emoticons': []
	},
	'🏓': {
		'keywords': [
			'ping_pong',
			'table',
			'tennis',
			'pingpong'
		],
		'emoticons': []
	},
	'🏸': {
		'keywords': [
			'badminton',
			'racquet',
			'shuttlecock'
		],
		'emoticons': []
	},
	'🥊': {
		'keywords': [
			'boxing_glove',
			'fighting'
		],
		'emoticons': []
	},
	'🥋': {
		'keywords': [
			'martial_arts_uniform',
			'judo',
			'karate',
			'taekwondo'
		],
		'emoticons': []
	},
	'🥅': {
		'keywords': [
			'goal_net'
		],
		'emoticons': []
	},
	'⛳': {
		'keywords': [
			'golf'
		],
		'emoticons': []
	},
	'⛸️': {
		'keywords': [
			'ice_skate'
		],
		'emoticons': []
	},
	'🎣': {
		'keywords': [
			'fishing_pole_and_fish',
			'hobby'
		],
		'emoticons': []
	},
	'🤿': {
		'keywords': [
			'diving_mask'
		],
		'emoticons': []
	},
	'🎽': {
		'keywords': [
			'running_shirt_with_sash',
			'sash',
			'pageant'
		],
		'emoticons': []
	},
	'🎿': {
		'keywords': [
			'ski'
		],
		'emoticons': []
	},
	'🛷': {
		'keywords': [
			'sled',
			'sleigh',
			'luge',
			'toboggan'
		],
		'emoticons': []
	},
	'🥌': {
		'keywords': [
			'curling_stone'
		],
		'emoticons': []
	},
	'🎯': {
		'keywords': [
			'dart',
			'direct',
			'bar',
			'target'
		],
		'emoticons': []
	},
	'🪀': {
		'keywords': [
			'yo_yo',
			'yo'
		],
		'emoticons': []
	},
	'🪁': {
		'keywords': [
			'kite'
		],
		'emoticons': []
	},
	'🎱': {
		'keywords': [
			'8ball',
			'8'
		],
		'emoticons': []
	},
	'🔮': {
		'keywords': [
			'crystal_ball',
			'disco',
			'fortune',
			'teller'
		],
		'emoticons': []
	},
	'🪄': {
		'keywords': [
			'magic_wand',
			'supernature',
			'power'
		],
		'emoticons': []
	},
	'🧿': {
		'keywords': [
			'nazar_amulet',
			'bead',
			'charm'
		],
		'emoticons': []
	},
	'🎮': {
		'keywords': [
			'video_game',
			'console',
			'PS4',
			'controller'
		],
		'emoticons': []
	},
	'🕹️': {
		'keywords': [
			'joystick'
		],
		'emoticons': []
	},
	'🎰': {
		'keywords': [
			'slot_machine',
			'bet',
			'vegas',
			'casino'
		],
		'emoticons': []
	},
	'🎲': {
		'keywords': [
			'game_die',
			'dice',
			'random',
			'tabletop'
		],
		'emoticons': []
	},
	'🧩': {
		'keywords': [
			'jigsaw',
			'interlocking'
		],
		'emoticons': []
	},
	'🧸': {
		'keywords': [
			'teddy_bear',
			'plush',
			'stuffed'
		],
		'emoticons': []
	},
	'🪅': {
		'keywords': [
			'pinata',
			'mexico'
		],
		'emoticons': []
	},
	'🪆': {
		'keywords': [
			'nesting_dolls',
			'matryoshka'
		],
		'emoticons': []
	},
	'♠️': {
		'keywords': [
			'spades',
			'poker',
			'cards',
			'suits'
		],
		'emoticons': []
	},
	'♥️': {
		'keywords': [
			'hearts'
		],
		'emoticons': []
	},
	'♦️': {
		'keywords': [
			'diamonds'
		],
		'emoticons': []
	},
	'♣️': {
		'keywords': [
			'clubs'
		],
		'emoticons': []
	},
	'♟️': {
		'keywords': [
			'chess_pawn',
			'expendable'
		],
		'emoticons': []
	},
	'🃏': {
		'keywords': [
			'black_joker',
			'black'
		],
		'emoticons': []
	},
	'🀄': {
		'keywords': [
			'mahjong',
			'kanji'
		],
		'emoticons': []
	},
	'🎴': {
		'keywords': [
			'flower_playing_cards'
		],
		'emoticons': []
	},
	'🎭': {
		'keywords': [
			'performing_arts',
			'acting'
		],
		'emoticons': []
	},
	'🎨': {
		'keywords': [
			'art',
			'design',
			'paint',
			'colors'
		],
		'emoticons': []
	},
	'🧵': {
		'keywords': [
			'thread',
			'needle',
			'sewing',
			'spool',
			'string'
		],
		'emoticons': []
	},
	'🪡': {
		'keywords': [
			'sewing_needle',
			'stitches'
		],
		'emoticons': []
	},
	'🧶': {
		'keywords': [
			'yarn',
			'crochet',
			'knit'
		],
		'emoticons': []
	},
	'🪢': {
		'keywords': [
			'knot',
			'rope',
			'scout'
		],
		'emoticons': []
	},
	'👓': {
		'keywords': [
			'eyeglasses',
			'eyesight'
		],
		'emoticons': []
	},
	'🕶️': {
		'keywords': [
			'dark_sunglasses',
			'dark'
		],
		'emoticons': []
	},
	'🥽': {
		'keywords': [
			'goggles',
			'protection',
			'safety'
		],
		'emoticons': []
	},
	'🥼': {
		'keywords': [
			'lab_coat',
			'experiment',
			'scientist',
			'chemist'
		],
		'emoticons': []
	},
	'🦺': {
		'keywords': [
			'safety_vest'
		],
		'emoticons': []
	},
	'👔': {
		'keywords': [
			'necktie',
			'shirt',
			'suitup',
			'cloth'
		],
		'emoticons': []
	},
	'👕': {
		'keywords': [
			'shirt',
			'tshirt',
			'casual',
			'tee'
		],
		'emoticons': []
	},
	'👖': {
		'keywords': [
			'jeans'
		],
		'emoticons': []
	},
	'🧣': {
		'keywords': [
			'scarf',
			'neck',
			'clothes'
		],
		'emoticons': []
	},
	'🧤': {
		'keywords': [
			'gloves'
		],
		'emoticons': []
	},
	'🧥': {
		'keywords': [
			'coat',
			'jacket'
		],
		'emoticons': []
	},
	'🧦': {
		'keywords': [
			'socks',
			'stockings'
		],
		'emoticons': []
	},
	'👗': {
		'keywords': [
			'dress'
		],
		'emoticons': []
	},
	'👘': {
		'keywords': [
			'kimono',
			'dress'
		],
		'emoticons': []
	},
	'🥻': {
		'keywords': [
			'sari'
		],
		'emoticons': []
	},
	'🩱': {
		'keywords': [
			'one_piece_swimsuit',
			'piece'
		],
		'emoticons': []
	},
	'🩲': {
		'keywords': [
			'swim_brief',
			'brief',
			'clothing'
		],
		'emoticons': []
	},
	'🩳': {
		'keywords': [
			'shorts'
		],
		'emoticons': []
	},
	'👙': {
		'keywords': [
			'bikini'
		],
		'emoticons': []
	},
	'👚': {
		'keywords': [
			'womans_clothes',
			's',
			'bags'
		],
		'emoticons': []
	},
	'👛': {
		'keywords': [
			'purse'
		],
		'emoticons': []
	},
	'👜': {
		'keywords': [
			'handbag',
			'accessory'
		],
		'emoticons': []
	},
	'👝': {
		'keywords': [
			'pouch',
			'clutch',
			'bag'
		],
		'emoticons': []
	},
	'🎒': {
		'keywords': [
			'school_satchel',
			'satchel'
		],
		'emoticons': []
	},
	'🩴': {
		'keywords': [
			'thong_sandal'
		],
		'emoticons': []
	},
	'👞': {
		'keywords': [
			'mans_shoe'
		],
		'emoticons': []
	},
	'👟': {
		'keywords': [
			'athletic_shoe',
			'athletic',
			'shoes',
			'sneakers'
		],
		'emoticons': []
	},
	'🥾': {
		'keywords': [
			'hiking_boot',
			'backpacking'
		],
		'emoticons': []
	},
	'🥿': {
		'keywords': [
			'flat_shoe',
			'womans',
			'ballet',
			'slip',
			'slipper'
		],
		'emoticons': []
	},
	'👠': {
		'keywords': [
			'high_heel',
			'heel',
			'heeled',
			'pumps',
			'stiletto'
		],
		'emoticons': []
	},
	'👡': {
		'keywords': [
			'sandal',
			'flip',
			'flops'
		],
		'emoticons': []
	},
	'🩰': {
		'keywords': [
			'ballet_shoes',
			'dance'
		],
		'emoticons': []
	},
	'👢': {
		'keywords': [
			'boot'
		],
		'emoticons': []
	},
	'👑': {
		'keywords': [
			'crown',
			'kod',
			'leader',
			'lord'
		],
		'emoticons': []
	},
	'👒': {
		'keywords': [
			'womans_hat'
		],
		'emoticons': []
	},
	'🎩': {
		'keywords': [
			'tophat',
			'gentleman'
		],
		'emoticons': []
	},
	'🎓': {
		'keywords': [
			'mortar_board',
			'mortar',
			'college',
			'degree',
			'university',
			'hat'
		],
		'emoticons': []
	},
	'🧢': {
		'keywords': [
			'billed_cap',
			'baseball'
		],
		'emoticons': []
	},
	'🪖': {
		'keywords': [
			'military_helmet',
			'army'
		],
		'emoticons': []
	},
	'📿': {
		'keywords': [
			'prayer_beads',
			'dhikr',
			'religious'
		],
		'emoticons': []
	},
	'💄': {
		'keywords': [
			'lipstick'
		],
		'emoticons': []
	},
	'💍': {
		'keywords': [
			'ring',
			'propose',
			'diamond',
			'jewelry',
			'gem',
			'engagement'
		],
		'emoticons': []
	},
	'💎': {
		'keywords': [
			'gem',
			'ruby'
		],
		'emoticons': []
	},
	'🔇': {
		'keywords': [
			'mute',
			'volume',
			'silence'
		],
		'emoticons': []
	},
	'🔈': {
		'keywords': [
			'speaker',
			'low',
			'broadcast'
		],
		'emoticons': []
	},
	'🔉': {
		'keywords': [
			'sound'
		],
		'emoticons': []
	},
	'🔊': {
		'keywords': [
			'loud_sound',
			'loud',
			'noise',
			'noisy'
		],
		'emoticons': []
	},
	'📢': {
		'keywords': [
			'loudspeaker'
		],
		'emoticons': []
	},
	'📣': {
		'keywords': [
			'mega',
			'speaker'
		],
		'emoticons': []
	},
	'📯': {
		'keywords': [
			'postal_horn',
			'instrument',
			'music'
		],
		'emoticons': []
	},
	'🔔': {
		'keywords': [
			'bell',
			'notification',
			'chime'
		],
		'emoticons': []
	},
	'🔕': {
		'keywords': [
			'no_bell',
			'mute',
			'silent'
		],
		'emoticons': []
	},
	'🎼': {
		'keywords': [
			'musical_score',
			'treble',
			'clef'
		],
		'emoticons': []
	},
	'🎵': {
		'keywords': [
			'musical_note',
			'tone'
		],
		'emoticons': []
	},
	'🎶': {
		'keywords': [
			'notes'
		],
		'emoticons': []
	},
	'🎙️': {
		'keywords': [
			'studio_microphone',
			'sing',
			'recording',
			'talkshow'
		],
		'emoticons': []
	},
	'🎚️': {
		'keywords': [
			'level_slider',
			'scale'
		],
		'emoticons': []
	},
	'🎛️': {
		'keywords': [
			'control_knobs',
			'dial'
		],
		'emoticons': []
	},
	'🎤': {
		'keywords': [
			'microphone',
			'PA'
		],
		'emoticons': []
	},
	'🎧': {
		'keywords': [
			'headphones',
			'gadgets'
		],
		'emoticons': []
	},
	'📻': {
		'keywords': [
			'radio',
			'podcast',
			'program'
		],
		'emoticons': []
	},
	'🎷': {
		'keywords': [
			'saxophone',
			'jazz',
			'blues'
		],
		'emoticons': []
	},
	'🪗': {
		'keywords': [
			'accordion'
		],
		'emoticons': []
	},
	'🎸': {
		'keywords': [
			'guitar'
		],
		'emoticons': []
	},
	'🎹': {
		'keywords': [
			'musical_keyboard',
			'piano'
		],
		'emoticons': []
	},
	'🎺': {
		'keywords': [
			'trumpet',
			'brass'
		],
		'emoticons': []
	},
	'🎻': {
		'keywords': [
			'violin',
			'orchestra',
			'symphony'
		],
		'emoticons': []
	},
	'🪕': {
		'keywords': [
			'banjo',
			'instructment'
		],
		'emoticons': []
	},
	'🥁': {
		'keywords': [
			'drum',
			'drumsticks',
			'snare'
		],
		'emoticons': []
	},
	'🪘': {
		'keywords': [
			'long_drum'
		],
		'emoticons': []
	},
	'📱': {
		'keywords': [
			'iphone',
			'technology',
			'apple'
		],
		'emoticons': []
	},
	'📲': {
		'keywords': [
			'calling',
			'iphone',
			'incoming'
		],
		'emoticons': []
	},
	'☎️': {
		'keywords': [
			'phone'
		],
		'emoticons': []
	},
	'📞': {
		'keywords': [
			'telephone_receiver'
		],
		'emoticons': []
	},
	'📟': {
		'keywords': [
			'pager',
			'bbcall',
			'90s'
		],
		'emoticons': []
	},
	'📠': {
		'keywords': [
			'fax'
		],
		'emoticons': []
	},
	'🔋': {
		'keywords': [
			'battery',
			'energy',
			'sustain'
		],
		'emoticons': []
	},
	'🔌': {
		'keywords': [
			'electric_plug',
			'charger'
		],
		'emoticons': []
	},
	'💻': {
		'keywords': [
			'computer',
			'screen',
			'display',
			'monitor'
		],
		'emoticons': []
	},
	'🖥️': {
		'keywords': [
			'desktop_computer',
			'computing'
		],
		'emoticons': []
	},
	'🖨️': {
		'keywords': [
			'printer',
			'paper',
			'ink'
		],
		'emoticons': []
	},
	'⌨️': {
		'keywords': [
			'keyboard',
			'type',
			'input',
			'text'
		],
		'emoticons': []
	},
	'🖲️': {
		'keywords': [
			'trackball',
			'trackpad'
		],
		'emoticons': []
	},
	'💽': {
		'keywords': [
			'minidisc',
			'disk',
			'record',
			'data'
		],
		'emoticons': []
	},
	'💾': {
		'keywords': [
			'floppy_disk',
			'save',
			'80s'
		],
		'emoticons': []
	},
	'💿': {
		'keywords': [
			'cd',
			'dvd'
		],
		'emoticons': []
	},
	'📀': {
		'keywords': [
			'dvd',
			'cd',
			'disc'
		],
		'emoticons': []
	},
	'🧮': {
		'keywords': [
			'abacus',
			'calculation'
		],
		'emoticons': []
	},
	'🎥': {
		'keywords': [
			'movie_camera',
			'film'
		],
		'emoticons': []
	},
	'📽️': {
		'keywords': [
			'film_projector',
			'video',
			'tape'
		],
		'emoticons': []
	},
	'🎬': {
		'keywords': [
			'clapper'
		],
		'emoticons': []
	},
	'📺': {
		'keywords': [
			'tv',
			'show'
		],
		'emoticons': []
	},
	'📷': {
		'keywords': [
			'camera',
			'photography'
		],
		'emoticons': []
	},
	'📸': {
		'keywords': [
			'camera_flash'
		],
		'emoticons': []
	},
	'📹': {
		'keywords': [
			'video_camera'
		],
		'emoticons': []
	},
	'📼': {
		'keywords': [
			'vhs'
		],
		'emoticons': []
	},
	'🔍': {
		'keywords': [
			'mag',
			'search',
			'zoom',
			'find',
			'detective'
		],
		'emoticons': []
	},
	'🔎': {
		'keywords': [
			'mag_right',
			'mag'
		],
		'emoticons': []
	},
	'🕯️': {
		'keywords': [
			'candle',
			'wax'
		],
		'emoticons': []
	},
	'💡': {
		'keywords': [
			'bulb',
			'electricity',
			'idea'
		],
		'emoticons': []
	},
	'🔦': {
		'keywords': [
			'flashlight',
			'sight'
		],
		'emoticons': []
	},
	'🏮': {
		'keywords': [
			'izakaya_lantern'
		],
		'emoticons': []
	},
	'🪔': {
		'keywords': [
			'diya_lamp',
			'lighting'
		],
		'emoticons': []
	},
	'📔': {
		'keywords': [
			'notebook_with_decorative_cover',
			'classroom',
			'notes',
			'study'
		],
		'emoticons': []
	},
	'📕': {
		'keywords': [
			'closed_book',
			'read',
			'library',
			'knowledge',
			'textbook'
		],
		'emoticons': []
	},
	'📖': {
		'keywords': [
			'book',
			'literature'
		],
		'emoticons': []
	},
	'📗': {
		'keywords': [
			'green_book'
		],
		'emoticons': []
	},
	'📘': {
		'keywords': [
			'blue_book'
		],
		'emoticons': []
	},
	'📙': {
		'keywords': [
			'orange_book'
		],
		'emoticons': []
	},
	'📚': {
		'keywords': [
			'books'
		],
		'emoticons': []
	},
	'📓': {
		'keywords': [
			'notebook'
		],
		'emoticons': []
	},
	'📒': {
		'keywords': [
			'ledger'
		],
		'emoticons': []
	},
	'📃': {
		'keywords': [
			'page_with_curl',
			'documents',
			'office'
		],
		'emoticons': []
	},
	'📜': {
		'keywords': [
			'scroll',
			'ancient'
		],
		'emoticons': []
	},
	'📄': {
		'keywords': [
			'page_facing_up'
		],
		'emoticons': []
	},
	'📰': {
		'keywords': [
			'newspaper',
			'press',
			'headline'
		],
		'emoticons': []
	},
	'📑': {
		'keywords': [
			'bookmark_tabs',
			'favorite',
			'order',
			'tidy'
		],
		'emoticons': []
	},
	'🔖': {
		'keywords': [
			'bookmark',
			'label'
		],
		'emoticons': []
	},
	'🏷️': {
		'keywords': [
			'label',
			'sale',
			'tag'
		],
		'emoticons': []
	},
	'💰': {
		'keywords': [
			'moneybag',
			'payment',
			'coins'
		],
		'emoticons': []
	},
	'🪙': {
		'keywords': [
			'coin',
			'currency'
		],
		'emoticons': []
	},
	'💴': {
		'keywords': [
			'yen'
		],
		'emoticons': []
	},
	'💵': {
		'keywords': [
			'dollar',
			'bill'
		],
		'emoticons': []
	},
	'💶': {
		'keywords': [
			'euro'
		],
		'emoticons': []
	},
	'💷': {
		'keywords': [
			'pound',
			'sterling',
			'bills',
			'uk',
			'england'
		],
		'emoticons': []
	},
	'💸': {
		'keywords': [
			'money_with_wings'
		],
		'emoticons': []
	},
	'💳': {
		'keywords': [
			'credit_card'
		],
		'emoticons': []
	},
	'🧾': {
		'keywords': [
			'receipt',
			'accounting',
			'expenses'
		],
		'emoticons': []
	},
	'💹': {
		'keywords': [
			'chart',
			'graph',
			'presentation',
			'stats'
		],
		'emoticons': []
	},
	'✉️': {
		'keywords': [
			'email',
			'letter',
			'postal',
			'inbox'
		],
		'emoticons': []
	},
	'📧': {
		'keywords': [
			'e-mail',
			'e',
			'mail'
		],
		'emoticons': []
	},
	'📨': {
		'keywords': [
			'incoming_envelope'
		],
		'emoticons': []
	},
	'📩': {
		'keywords': [
			'envelope_with_arrow'
		],
		'emoticons': []
	},
	'📤': {
		'keywords': [
			'outbox_tray'
		],
		'emoticons': []
	},
	'📥': {
		'keywords': [
			'inbox_tray'
		],
		'emoticons': []
	},
	'📦': {
		'keywords': [
			'package',
			'cardboard',
			'box',
			'moving'
		],
		'emoticons': []
	},
	'📫': {
		'keywords': [
			'mailbox'
		],
		'emoticons': []
	},
	'📪': {
		'keywords': [
			'mailbox_closed'
		],
		'emoticons': []
	},
	'📬': {
		'keywords': [
			'mailbox_with_mail'
		],
		'emoticons': []
	},
	'📭': {
		'keywords': [
			'mailbox_with_no_mail'
		],
		'emoticons': []
	},
	'📮': {
		'keywords': [
			'postbox'
		],
		'emoticons': []
	},
	'✏️': {
		'keywords': [
			'pencil2',
			'writing'
		],
		'emoticons': []
	},
	'✒️': {
		'keywords': [
			'black_nib'
		],
		'emoticons': []
	},
	'📝': {
		'keywords': [
			'memo',
			'pencil'
		],
		'emoticons': []
	},
	'💼': {
		'keywords': [
			'briefcase',
			'job',
			'career'
		],
		'emoticons': []
	},
	'📁': {
		'keywords': [
			'file_folder'
		],
		'emoticons': []
	},
	'📂': {
		'keywords': [
			'open_file_folder',
			'load'
		],
		'emoticons': []
	},
	'🗂️': {
		'keywords': [
			'card_index_dividers',
			'organizing'
		],
		'emoticons': []
	},
	'📅': {
		'keywords': [
			'date'
		],
		'emoticons': []
	},
	'📆': {
		'keywords': [
			'calendar',
			'tear',
			'off',
			'planning'
		],
		'emoticons': []
	},
	'📇': {
		'keywords': [
			'card_index'
		],
		'emoticons': []
	},
	'📈': {
		'keywords': [
			'chart_with_upwards_trend',
			'upwards',
			'trend',
			'recovery',
			'economics',
			'success'
		],
		'emoticons': []
	},
	'📉': {
		'keywords': [
			'chart_with_downwards_trend',
			'downwards',
			'recession',
			'bad',
			'failure'
		],
		'emoticons': []
	},
	'📊': {
		'keywords': [
			'bar_chart'
		],
		'emoticons': []
	},
	'📋': {
		'keywords': [
			'clipboard'
		],
		'emoticons': []
	},
	'📌': {
		'keywords': [
			'pushpin',
			'mark',
			'here'
		],
		'emoticons': []
	},
	'📍': {
		'keywords': [
			'round_pushpin',
			'map'
		],
		'emoticons': []
	},
	'📎': {
		'keywords': [
			'paperclip'
		],
		'emoticons': []
	},
	'📏': {
		'keywords': [
			'straight_ruler',
			'calculate',
			'length',
			'math',
			'drawing',
			'architect',
			'sketch'
		],
		'emoticons': []
	},
	'📐': {
		'keywords': [
			'triangular_ruler'
		],
		'emoticons': []
	},
	'✂️': {
		'keywords': [
			'scissors',
			'cut'
		],
		'emoticons': []
	},
	'🗃️': {
		'keywords': [
			'card_file_box'
		],
		'emoticons': []
	},
	'🗄️': {
		'keywords': [
			'file_cabinet',
			'filing'
		],
		'emoticons': []
	},
	'🗑️': {
		'keywords': [
			'wastebasket',
			'bin',
			'trash',
			'rubbish',
			'garbage',
			'toss'
		],
		'emoticons': []
	},
	'🔒': {
		'keywords': [
			'lock',
			'locked',
			'security',
			'password',
			'padlock'
		],
		'emoticons': []
	},
	'🔓': {
		'keywords': [
			'unlock',
			'privacy'
		],
		'emoticons': []
	},
	'🔏': {
		'keywords': [
			'lock_with_ink_pen',
			'lock'
		],
		'emoticons': []
	},
	'🔐': {
		'keywords': [
			'closed_lock_with_key'
		],
		'emoticons': []
	},
	'🔑': {
		'keywords': [
			'key',
			'door'
		],
		'emoticons': []
	},
	'🗝️': {
		'keywords': [
			'old_key'
		],
		'emoticons': []
	},
	'🔨': {
		'keywords': [
			'hammer',
			'tools',
			'create'
		],
		'emoticons': []
	},
	'🪓': {
		'keywords': [
			'axe',
			'tool'
		],
		'emoticons': []
	},
	'⛏️': {
		'keywords': [
			'pick',
			'dig'
		],
		'emoticons': []
	},
	'⚒️': {
		'keywords': [
			'hammer_and_pick'
		],
		'emoticons': []
	},
	'🛠️': {
		'keywords': [
			'hammer_and_wrench'
		],
		'emoticons': []
	},
	'⚔️': {
		'keywords': [
			'crossed_swords'
		],
		'emoticons': []
	},
	'🔫': {
		'keywords': [
			'gun',
			'revolver'
		],
		'emoticons': []
	},
	'🪃': {
		'keywords': [
			'boomerang'
		],
		'emoticons': []
	},
	'🏹': {
		'keywords': [
			'bow_and_arrow'
		],
		'emoticons': []
	},
	'🛡️': {
		'keywords': [
			'shield'
		],
		'emoticons': []
	},
	'🪚': {
		'keywords': [
			'carpentry_saw'
		],
		'emoticons': []
	},
	'🔧': {
		'keywords': [
			'wrench',
			'diy',
			'ikea',
			'fix',
			'maintainer'
		],
		'emoticons': []
	},
	'🪛': {
		'keywords': [
			'screwdriver'
		],
		'emoticons': []
	},
	'🔩': {
		'keywords': [
			'nut_and_bolt',
			'handy'
		],
		'emoticons': []
	},
	'⚙️': {
		'keywords': [
			'gear',
			'cog'
		],
		'emoticons': []
	},
	'🦯': {
		'keywords': [
			'probing_cane'
		],
		'emoticons': []
	},
	'🔗': {
		'keywords': [
			'link',
			'rings',
			'url'
		],
		'emoticons': []
	},
	'⛓️': {
		'keywords': [
			'chains',
			'arrest'
		],
		'emoticons': []
	},
	'🪝': {
		'keywords': [
			'hook'
		],
		'emoticons': []
	},
	'🧰': {
		'keywords': [
			'toolbox',
			'mechanic'
		],
		'emoticons': []
	},
	'🧲': {
		'keywords': [
			'magnet',
			'attraction'
		],
		'emoticons': []
	},
	'🪜': {
		'keywords': [
			'ladder'
		],
		'emoticons': []
	},
	'⚗️': {
		'keywords': [
			'alembic',
			'distilling',
			'science'
		],
		'emoticons': []
	},
	'🧪': {
		'keywords': [
			'test_tube',
			'lab'
		],
		'emoticons': []
	},
	'🧫': {
		'keywords': [
			'petri_dish',
			'biology'
		],
		'emoticons': []
	},
	'🧬': {
		'keywords': [
			'dna',
			'biologist',
			'genetics'
		],
		'emoticons': []
	},
	'🔬': {
		'keywords': [
			'microscope',
			'laboratory',
			'zoomin'
		],
		'emoticons': []
	},
	'🔭': {
		'keywords': [
			'telescope',
			'astronomy'
		],
		'emoticons': []
	},
	'💉': {
		'keywords': [
			'syringe',
			'drugs',
			'nurse'
		],
		'emoticons': []
	},
	'🩸': {
		'keywords': [
			'drop_of_blood',
			'period',
			'harm',
			'wound'
		],
		'emoticons': []
	},
	'💊': {
		'keywords': [
			'pill',
			'pharmacy',
			'drug'
		],
		'emoticons': []
	},
	'🩹': {
		'keywords': [
			'adhesive_bandage',
			'heal'
		],
		'emoticons': []
	},
	'🩺': {
		'keywords': [
			'stethoscope'
		],
		'emoticons': []
	},
	'🚪': {
		'keywords': [
			'door',
			'entry',
			'exit'
		],
		'emoticons': []
	},
	'🛗': {
		'keywords': [
			'elevator',
			'lift'
		],
		'emoticons': []
	},
	'🪞': {
		'keywords': [
			'mirror',
			'reflection'
		],
		'emoticons': []
	},
	'🪟': {
		'keywords': [
			'window',
			'scenery'
		],
		'emoticons': []
	},
	'🛏️': {
		'keywords': [
			'bed'
		],
		'emoticons': []
	},
	'🛋️': {
		'keywords': [
			'couch_and_lamp',
			'chill'
		],
		'emoticons': []
	},
	'🪑': {
		'keywords': [
			'chair',
			'furniture'
		],
		'emoticons': []
	},
	'🚽': {
		'keywords': [
			'toilet',
			'restroom',
			'wc',
			'washroom',
			'potty'
		],
		'emoticons': []
	},
	'🪠': {
		'keywords': [
			'plunger',
			'toilet'
		],
		'emoticons': []
	},
	'🚿': {
		'keywords': [
			'shower'
		],
		'emoticons': []
	},
	'🛁': {
		'keywords': [
			'bathtub'
		],
		'emoticons': []
	},
	'🪤': {
		'keywords': [
			'mouse_trap'
		],
		'emoticons': []
	},
	'🪒': {
		'keywords': [
			'razor'
		],
		'emoticons': []
	},
	'🧴': {
		'keywords': [
			'lotion_bottle',
			'moisturizer',
			'sunscreen'
		],
		'emoticons': []
	},
	'🧷': {
		'keywords': [
			'safety_pin',
			'diaper'
		],
		'emoticons': []
	},
	'🧹': {
		'keywords': [
			'broom',
			'cleaning',
			'sweeping',
			'witch'
		],
		'emoticons': []
	},
	'🧺': {
		'keywords': [
			'basket',
			'laundry'
		],
		'emoticons': []
	},
	'🧻': {
		'keywords': [
			'roll_of_paper'
		],
		'emoticons': []
	},
	'🪣': {
		'keywords': [
			'bucket'
		],
		'emoticons': []
	},
	'🧼': {
		'keywords': [
			'soap',
			'bathing',
			'lather'
		],
		'emoticons': []
	},
	'🪥': {
		'keywords': [
			'toothbrush',
			'hygiene',
			'dental'
		],
		'emoticons': []
	},
	'🧽': {
		'keywords': [
			'sponge',
			'absorbing',
			'porous'
		],
		'emoticons': []
	},
	'🧯': {
		'keywords': [
			'fire_extinguisher',
			'quench'
		],
		'emoticons': []
	},
	'🛒': {
		'keywords': [
			'shopping_cart',
			'trolley'
		],
		'emoticons': []
	},
	'🚬': {
		'keywords': [
			'smoking',
			'kills',
			'tobacco',
			'joint'
		],
		'emoticons': []
	},
	'⚰️': {
		'keywords': [
			'coffin',
			'die',
			'rip',
			'graveyard',
			'cemetery',
			'casket',
			'funeral'
		],
		'emoticons': []
	},
	'🪦': {
		'keywords': [
			'headstone',
			'grave'
		],
		'emoticons': []
	},
	'⚱️': {
		'keywords': [
			'funeral_urn',
			'ashes'
		],
		'emoticons': []
	},
	'🗿': {
		'keywords': [
			'moyai',
			'easter',
			'island'
		],
		'emoticons': []
	},
	'🪧': {
		'keywords': [
			'placard',
			'announcement'
		],
		'emoticons': []
	},
	'🏧': {
		'keywords': [
			'atm',
			'bank'
		],
		'emoticons': []
	},
	'🚮': {
		'keywords': [
			'put_litter_in_its_place',
			'put',
			'its',
			'info'
		],
		'emoticons': []
	},
	'🚰': {
		'keywords': [
			'potable_water',
			'liquid'
		],
		'emoticons': []
	},
	'♿': {
		'keywords': [
			'wheelchair',
			'disabled'
		],
		'emoticons': []
	},
	'🚹': {
		'keywords': [
			'mens'
		],
		'emoticons': []
	},
	'🚺': {
		'keywords': [
			'womens',
			'loo'
		],
		'emoticons': []
	},
	'🚻': {
		'keywords': [
			'restroom',
			'refresh'
		],
		'emoticons': []
	},
	'🚼': {
		'keywords': [
			'baby_symbol'
		],
		'emoticons': []
	},
	'🚾': {
		'keywords': [
			'wc'
		],
		'emoticons': []
	},
	'🛂': {
		'keywords': [
			'passport_control',
			'custom'
		],
		'emoticons': []
	},
	'🛃': {
		'keywords': [
			'customs',
			'passport',
			'border'
		],
		'emoticons': []
	},
	'🛄': {
		'keywords': [
			'baggage_claim'
		],
		'emoticons': []
	},
	'🛅': {
		'keywords': [
			'left_luggage'
		],
		'emoticons': []
	},
	'⚠️': {
		'keywords': [
			'warning',
			'exclamation',
			'problem',
			'issue'
		],
		'emoticons': []
	},
	'🚸': {
		'keywords': [
			'children_crossing'
		],
		'emoticons': []
	},
	'⛔': {
		'keywords': [
			'no_entry',
			'denied',
			'circle'
		],
		'emoticons': []
	},
	'🚫': {
		'keywords': [
			'no_entry_sign',
			'forbid',
			'disallow'
		],
		'emoticons': []
	},
	'🚳': {
		'keywords': [
			'no_bicycles',
			'cyclist',
			'prohibited'
		],
		'emoticons': []
	},
	'🚭': {
		'keywords': [
			'no_smoking',
			'cigarette'
		],
		'emoticons': []
	},
	'🚯': {
		'keywords': [
			'do_not_litter',
			'do',
			'not',
			'litter'
		],
		'emoticons': []
	},
	'🚱': {
		'keywords': [
			'non-potable_water',
			'non',
			'potable',
			'tap'
		],
		'emoticons': []
	},
	'🚷': {
		'keywords': [
			'no_pedestrians',
			'rules',
			'crossing'
		],
		'emoticons': []
	},
	'📵': {
		'keywords': [
			'no_mobile_phones'
		],
		'emoticons': []
	},
	'🔞': {
		'keywords': [
			'underage',
			'18',
			'minor'
		],
		'emoticons': []
	},
	'⬆️': {
		'keywords': [
			'arrow_up',
			'continue',
			'top'
		],
		'emoticons': []
	},
	'↗️': {
		'keywords': [
			'arrow_upper_right',
			'upper',
			'diagonal',
			'northeast'
		],
		'emoticons': []
	},
	'➡️': {
		'keywords': [
			'arrow_right',
			'next'
		],
		'emoticons': []
	},
	'↘️': {
		'keywords': [
			'arrow_lower_right',
			'southeast'
		],
		'emoticons': []
	},
	'⬇️': {
		'keywords': [
			'arrow_down',
			'bottom'
		],
		'emoticons': []
	},
	'↙️': {
		'keywords': [
			'arrow_lower_left',
			'southwest'
		],
		'emoticons': []
	},
	'⬅️': {
		'keywords': [
			'arrow_left',
			'previous',
			'back'
		],
		'emoticons': []
	},
	'↖️': {
		'keywords': [
			'arrow_upper_left',
			'northwest'
		],
		'emoticons': []
	},
	'↕️': {
		'keywords': [
			'arrow_up_down',
			'way',
			'vertical'
		],
		'emoticons': []
	},
	'↔️': {
		'keywords': [
			'left_right_arrow',
			'shape',
			'horizontal',
			'sideways'
		],
		'emoticons': []
	},
	'↩️': {
		'keywords': [
			'leftwards_arrow_with_hook',
			'leftwards',
			'hook',
			'return',
			'undo',
			'enter'
		],
		'emoticons': []
	},
	'↪️': {
		'keywords': [
			'arrow_right_hook',
			'rotate'
		],
		'emoticons': []
	},
	'⤴️': {
		'keywords': [
			'arrow_heading_up',
			'heading'
		],
		'emoticons': []
	},
	'⤵️': {
		'keywords': [
			'arrow_heading_down'
		],
		'emoticons': []
	},
	'🔃': {
		'keywords': [
			'arrows_clockwise',
			'sync',
			'cycle',
			'round',
			'repeat'
		],
		'emoticons': []
	},
	'🔄': {
		'keywords': [
			'arrows_counterclockwise'
		],
		'emoticons': []
	},
	'🔙': {
		'keywords': [
			'back'
		],
		'emoticons': []
	},
	'🔚': {
		'keywords': [
			'end'
		],
		'emoticons': []
	},
	'🔛': {
		'keywords': [
			'on'
		],
		'emoticons': []
	},
	'🔜': {
		'keywords': [
			'soon'
		],
		'emoticons': []
	},
	'🔝': {
		'keywords': [
			'top'
		],
		'emoticons': []
	},
	'🛐': {
		'keywords': [
			'place_of_worship',
			'church'
		],
		'emoticons': []
	},
	'⚛️': {
		'keywords': [
			'atom_symbol',
			'physics'
		],
		'emoticons': []
	},
	'✡️': {
		'keywords': [
			'star_of_david'
		],
		'emoticons': []
	},
	'☸️': {
		'keywords': [
			'wheel_of_dharma',
			'hinduism',
			'buddhism',
			'sikhism',
			'jainism'
		],
		'emoticons': []
	},
	'☯️': {
		'keywords': [
			'yin_yang'
		],
		'emoticons': []
	},
	'✝️': {
		'keywords': [
			'latin_cross',
			'christianity'
		],
		'emoticons': []
	},
	'☦️': {
		'keywords': [
			'orthodox_cross',
			'suppedaneum'
		],
		'emoticons': []
	},
	'☪️': {
		'keywords': [
			'star_and_crescent'
		],
		'emoticons': []
	},
	'☮️': {
		'keywords': [
			'peace_symbol',
			'hippie'
		],
		'emoticons': []
	},
	'🕎': {
		'keywords': [
			'menorah',
			'branches',
			'hanukkah',
			'candles'
		],
		'emoticons': []
	},
	'🔯': {
		'keywords': [
			'six_pointed_star',
			'pointed',
			'hexagram'
		],
		'emoticons': []
	},
	'♈': {
		'keywords': [
			'aries',
			'zodiac',
			'astrology'
		],
		'emoticons': []
	},
	'♉': {
		'keywords': [
			'taurus'
		],
		'emoticons': []
	},
	'♊': {
		'keywords': [
			'gemini'
		],
		'emoticons': []
	},
	'♋': {
		'keywords': [
			'cancer'
		],
		'emoticons': []
	},
	'♌': {
		'keywords': [
			'leo'
		],
		'emoticons': []
	},
	'♍': {
		'keywords': [
			'virgo'
		],
		'emoticons': []
	},
	'♎': {
		'keywords': [
			'libra'
		],
		'emoticons': []
	},
	'♏': {
		'keywords': [
			'scorpius'
		],
		'emoticons': []
	},
	'♐': {
		'keywords': [
			'sagittarius'
		],
		'emoticons': []
	},
	'♑': {
		'keywords': [
			'capricorn'
		],
		'emoticons': []
	},
	'♒': {
		'keywords': [
			'aquarius'
		],
		'emoticons': []
	},
	'♓': {
		'keywords': [
			'pisces'
		],
		'emoticons': []
	},
	'⛎': {
		'keywords': [
			'ophiuchus',
			'constellation'
		],
		'emoticons': []
	},
	'🔀': {
		'keywords': [
			'twisted_rightwards_arrows',
			'rightwards',
			'arrows'
		],
		'emoticons': []
	},
	'🔁': {
		'keywords': [
			'repeat',
			'loop'
		],
		'emoticons': []
	},
	'🔂': {
		'keywords': [
			'repeat_one'
		],
		'emoticons': []
	},
	'▶️': {
		'keywords': [
			'arrow_forward',
			'arrow',
			'forward'
		],
		'emoticons': []
	},
	'⏩': {
		'keywords': [
			'fast_forward'
		],
		'emoticons': []
	},
	'◀️': {
		'keywords': [
			'arrow_backward',
			'backward'
		],
		'emoticons': []
	},
	'⏪': {
		'keywords': [
			'rewind'
		],
		'emoticons': []
	},
	'🔼': {
		'keywords': [
			'arrow_up_small',
			'triangle'
		],
		'emoticons': []
	},
	'⏫': {
		'keywords': [
			'arrow_double_up',
			'double'
		],
		'emoticons': []
	},
	'🔽': {
		'keywords': [
			'arrow_down_small'
		],
		'emoticons': []
	},
	'⏬': {
		'keywords': [
			'arrow_double_down'
		],
		'emoticons': []
	},
	'🎦': {
		'keywords': [
			'cinema',
			'curtain',
			'stage'
		],
		'emoticons': []
	},
	'🔅': {
		'keywords': [
			'low_brightness',
			'sun',
			'afternoon'
		],
		'emoticons': []
	},
	'🔆': {
		'keywords': [
			'high_brightness'
		],
		'emoticons': []
	},
	'📶': {
		'keywords': [
			'signal_strength',
			'strength',
			'reception',
			'connection',
			'wifi',
			'bluetooth'
		],
		'emoticons': []
	},
	'📳': {
		'keywords': [
			'vibration_mode'
		],
		'emoticons': []
	},
	'📴': {
		'keywords': [
			'mobile_phone_off'
		],
		'emoticons': []
	},
	'♀️': {
		'keywords': [
			'female_sign'
		],
		'emoticons': []
	},
	'♂️': {
		'keywords': [
			'male_sign'
		],
		'emoticons': []
	},
	'⚧️': {
		'keywords': [
			'transgender_symbol',
			'lgbtq'
		],
		'emoticons': []
	},
	'✖️': {
		'keywords': [
			'heavy_multiplication_x',
			'heavy',
			'multiplication',
			'x'
		],
		'emoticons': []
	},
	'➕': {
		'keywords': [
			'heavy_plus_sign',
			'addition',
			'more',
			'increase'
		],
		'emoticons': []
	},
	'➖': {
		'keywords': [
			'heavy_minus_sign',
			'subtract',
			'less'
		],
		'emoticons': []
	},
	'➗': {
		'keywords': [
			'heavy_division_sign',
			'division'
		],
		'emoticons': []
	},
	'♾️': {
		'keywords': [
			'infinity',
			'forever'
		],
		'emoticons': []
	},
	'‼️': {
		'keywords': [
			'bangbang'
		],
		'emoticons': []
	},
	'⁉️': {
		'keywords': [
			'interrobang',
			'wat',
			'punctuation'
		],
		'emoticons': []
	},
	'❓': {
		'keywords': [
			'question',
			'doubt'
		],
		'emoticons': []
	},
	'❔': {
		'keywords': [
			'grey_question',
			'grey',
			'doubts'
		],
		'emoticons': []
	},
	'❕': {
		'keywords': [
			'grey_exclamation'
		],
		'emoticons': []
	},
	'❗': {
		'keywords': [
			'exclamation'
		],
		'emoticons': []
	},
	'〰️': {
		'keywords': [
			'wavy_dash',
			'line',
			'squiggle',
			'scribble'
		],
		'emoticons': []
	},
	'💱': {
		'keywords': [
			'currency_exchange'
		],
		'emoticons': []
	},
	'💲': {
		'keywords': [
			'heavy_dollar_sign',
			'buck'
		],
		'emoticons': []
	},
	'⚕️': {
		'keywords': [
			'medical_symbol',
			'staff',
			'of',
			'aesculapius'
		],
		'emoticons': []
	},
	'♻️': {
		'keywords': [
			'recycle'
		],
		'emoticons': []
	},
	'⚜️': {
		'keywords': [
			'fleur_de_lis',
			'fleur',
			'de',
			'lis',
			'decorative'
		],
		'emoticons': []
	},
	'🔱': {
		'keywords': [
			'trident',
			'spear'
		],
		'emoticons': []
	},
	'📛': {
		'keywords': [
			'name_badge'
		],
		'emoticons': []
	},
	'🔰': {
		'keywords': [
			'beginner',
			'badge',
			'shield'
		],
		'emoticons': []
	},
	'⭕': {
		'keywords': [
			'o'
		],
		'emoticons': []
	},
	'✅': {
		'keywords': [
			'white_check_mark',
			'white',
			'ok',
			'vote',
			'election',
			'answer',
			'tick'
		],
		'emoticons': []
	},
	'☑️': {
		'keywords': [
			'ballot_box_with_check',
			'ballot',
			'confirm'
		],
		'emoticons': []
	},
	'✔️': {
		'keywords': [
			'heavy_check_mark',
			'nike'
		],
		'emoticons': []
	},
	'❌': {
		'keywords': [
			'x',
			'delete',
			'remove',
			'cancel'
		],
		'emoticons': []
	},
	'❎': {
		'keywords': [
			'negative_squared_cross_mark',
			'negative',
			'squared',
			'deny'
		],
		'emoticons': []
	},
	'➰': {
		'keywords': [
			'curly_loop'
		],
		'emoticons': []
	},
	'➿': {
		'keywords': [
			'loop',
			'cassette'
		],
		'emoticons': []
	},
	'〽️': {
		'keywords': [
			'part_alternation_mark'
		],
		'emoticons': []
	},
	'✳️': {
		'keywords': [
			'eight_spoked_asterisk'
		],
		'emoticons': []
	},
	'✴️': {
		'keywords': [
			'eight_pointed_black_star',
			'polygon'
		],
		'emoticons': []
	},
	'❇️': {
		'keywords': [
			'sparkle',
			'fireworks'
		],
		'emoticons': []
	},
	'©️': {
		'keywords': [
			'copyright',
			'ip',
			'license'
		],
		'emoticons': []
	},
	'®️': {
		'keywords': [
			'registered',
			'alphabet'
		],
		'emoticons': []
	},
	'™️': {
		'keywords': [
			'tm',
			'trademark',
			'brand'
		],
		'emoticons': []
	},
	'#️⃣': {
		'keywords': [
			'hash',
			'keycap',
			'',
			'symbol',
			'twitter'
		],
		'emoticons': []
	},
	'0️⃣': {
		'keywords': [
			'zero',
			'null'
		],
		'emoticons': []
	},
	'1️⃣': {
		'keywords': [
			'one'
		],
		'emoticons': []
	},
	'2️⃣': {
		'keywords': [
			'two',
			'prime'
		],
		'emoticons': []
	},
	'3️⃣': {
		'keywords': [
			'three'
		],
		'emoticons': []
	},
	'4️⃣': {
		'keywords': [
			'four'
		],
		'emoticons': []
	},
	'5️⃣': {
		'keywords': [
			'five'
		],
		'emoticons': []
	},
	'6️⃣': {
		'keywords': [
			'six'
		],
		'emoticons': []
	},
	'7️⃣': {
		'keywords': [
			'seven'
		],
		'emoticons': []
	},
	'8️⃣': {
		'keywords': [
			'eight'
		],
		'emoticons': []
	},
	'9️⃣': {
		'keywords': [
			'nine'
		],
		'emoticons': []
	},
	'🔟': {
		'keywords': [
			'keycap_ten'
		],
		'emoticons': []
	},
	'🔠': {
		'keywords': [
			'capital_abcd',
			'capital',
			'abcd'
		],
		'emoticons': []
	},
	'🔡': {
		'keywords': [
			'abcd'
		],
		'emoticons': []
	},
	'🔣': {
		'keywords': [
			'symbols',
			'note',
			'ampersand',
			'percent',
			'glyphs',
			'characters'
		],
		'emoticons': []
	},
	'🔤': {
		'keywords': [
			'abc'
		],
		'emoticons': []
	},
	'🅰️': {
		'keywords': [
			'a'
		],
		'emoticons': []
	},
	'🆎': {
		'keywords': [
			'ab',
			'button'
		],
		'emoticons': []
	},
	'🅱️': {
		'keywords': [
			'b'
		],
		'emoticons': []
	},
	'🆑': {
		'keywords': [
			'cl'
		],
		'emoticons': []
	},
	'🆒': {
		'keywords': [
			'cool'
		],
		'emoticons': []
	},
	'🆓': {
		'keywords': [
			'free'
		],
		'emoticons': []
	},
	'ℹ️': {
		'keywords': [
			'information_source',
			'source'
		],
		'emoticons': []
	},
	'🆔': {
		'keywords': [
			'id'
		],
		'emoticons': []
	},
	'Ⓜ️': {
		'keywords': [
			'm'
		],
		'emoticons': []
	},
	'🆕': {
		'keywords': [
			'new',
			'start'
		],
		'emoticons': []
	},
	'🆖': {
		'keywords': [
			'ng',
			'icon'
		],
		'emoticons': []
	},
	'🅾️': {
		'keywords': [
			'o2'
		],
		'emoticons': []
	},
	'🆗': {
		'keywords': [
			'ok'
		],
		'emoticons': []
	},
	'🅿️': {
		'keywords': [
			'parking'
		],
		'emoticons': []
	},
	'🆘': {
		'keywords': [
			'sos',
			'help'
		],
		'emoticons': []
	},
	'🆙': {
		'keywords': [
			'up',
			'above'
		],
		'emoticons': []
	},
	'🆚': {
		'keywords': [
			'vs'
		],
		'emoticons': []
	},
	'🈁': {
		'keywords': [
			'koko',
			'destination'
		],
		'emoticons': []
	},
	'🈂️': {
		'keywords': [
			'sa',
			'charge'
		],
		'emoticons': []
	},
	'🈷️': {
		'keywords': [
			'u6708',
			'monthly',
			'amount',
			'month',
			'moon'
		],
		'emoticons': []
	},
	'🈶': {
		'keywords': [
			'u6709',
			'free',
			'have'
		],
		'emoticons': []
	},
	'🈯': {
		'keywords': [
			'u6307',
			'reserved'
		],
		'emoticons': []
	},
	'🉐': {
		'keywords': [
			'ideograph_advantage',
			'ideograph',
			'advantage',
			'bargain',
			'obtain',
			'get'
		],
		'emoticons': []
	},
	'🈹': {
		'keywords': [
			'u5272',
			'discount',
			'divide'
		],
		'emoticons': []
	},
	'🈚': {
		'keywords': [
			'u7121',
			'nothing'
		],
		'emoticons': []
	},
	'🈲': {
		'keywords': [
			'u7981',
			'forbidden',
			'restricted'
		],
		'emoticons': []
	},
	'🉑': {
		'keywords': [
			'accept',
			'acceptable'
		],
		'emoticons': []
	},
	'🈸': {
		'keywords': [
			'u7533',
			'application'
		],
		'emoticons': []
	},
	'🈴': {
		'keywords': [
			'u5408',
			'passing',
			'grade',
			'join'
		],
		'emoticons': []
	},
	'🈳': {
		'keywords': [
			'u7a7a',
			'vacancy',
			'empty'
		],
		'emoticons': []
	},
	'㊗️': {
		'keywords': [
			'congratulations'
		],
		'emoticons': []
	},
	'㊙️': {
		'keywords': [
			'secret',
			'sshh'
		],
		'emoticons': []
	},
	'🈺': {
		'keywords': [
			'u55b6',
			'for',
			'opening',
			'hours'
		],
		'emoticons': []
	},
	'🈵': {
		'keywords': [
			'u6e80',
			'full'
		],
		'emoticons': []
	},
	'🔴': {
		'keywords': [
			'red_circle'
		],
		'emoticons': []
	},
	'🟠': {
		'keywords': [
			'orange_circle'
		],
		'emoticons': []
	},
	'🟡': {
		'keywords': [
			'yellow_circle'
		],
		'emoticons': []
	},
	'🟢': {
		'keywords': [
			'green_circle'
		],
		'emoticons': []
	},
	'🔵': {
		'keywords': [
			'large_blue_circle'
		],
		'emoticons': []
	},
	'🟣': {
		'keywords': [
			'purple_circle'
		],
		'emoticons': []
	},
	'🟤': {
		'keywords': [
			'brown_circle'
		],
		'emoticons': []
	},
	'⚫': {
		'keywords': [
			'black_circle'
		],
		'emoticons': []
	},
	'⚪': {
		'keywords': [
			'white_circle'
		],
		'emoticons': []
	},
	'🟥': {
		'keywords': [
			'red_square'
		],
		'emoticons': []
	},
	'🟧': {
		'keywords': [
			'orange_square'
		],
		'emoticons': []
	},
	'🟨': {
		'keywords': [
			'yellow_square'
		],
		'emoticons': []
	},
	'🟩': {
		'keywords': [
			'green_square'
		],
		'emoticons': []
	},
	'🟦': {
		'keywords': [
			'blue_square'
		],
		'emoticons': []
	},
	'🟪': {
		'keywords': [
			'purple_square'
		],
		'emoticons': []
	},
	'🟫': {
		'keywords': [
			'brown_square'
		],
		'emoticons': []
	},
	'⬛': {
		'keywords': [
			'black_large_square'
		],
		'emoticons': []
	},
	'⬜': {
		'keywords': [
			'white_large_square'
		],
		'emoticons': []
	},
	'◼️': {
		'keywords': [
			'black_medium_square'
		],
		'emoticons': []
	},
	'◻️': {
		'keywords': [
			'white_medium_square'
		],
		'emoticons': []
	},
	'◾': {
		'keywords': [
			'black_medium_small_square'
		],
		'emoticons': []
	},
	'◽': {
		'keywords': [
			'white_medium_small_square'
		],
		'emoticons': []
	},
	'▪️': {
		'keywords': [
			'black_small_square'
		],
		'emoticons': []
	},
	'▫️': {
		'keywords': [
			'white_small_square'
		],
		'emoticons': []
	},
	'🔶': {
		'keywords': [
			'large_orange_diamond',
			'jewel'
		],
		'emoticons': []
	},
	'🔷': {
		'keywords': [
			'large_blue_diamond'
		],
		'emoticons': []
	},
	'🔸': {
		'keywords': [
			'small_orange_diamond'
		],
		'emoticons': []
	},
	'🔹': {
		'keywords': [
			'small_blue_diamond'
		],
		'emoticons': []
	},
	'🔺': {
		'keywords': [
			'small_red_triangle'
		],
		'emoticons': []
	},
	'🔻': {
		'keywords': [
			'small_red_triangle_down'
		],
		'emoticons': []
	},
	'💠': {
		'keywords': [
			'diamond_shape_with_a_dot_inside',
			'inside',
			'crystal',
			'fancy'
		],
		'emoticons': []
	},
	'🔘': {
		'keywords': [
			'radio_button'
		],
		'emoticons': []
	},
	'🔳': {
		'keywords': [
			'white_square_button'
		],
		'emoticons': []
	},
	'🔲': {
		'keywords': [
			'black_square_button',
			'frame'
		],
		'emoticons': []
	},
	'🏁': {
		'keywords': [
			'checkered_flag',
			'checkered',
			'finishline',
			'gokart'
		],
		'emoticons': []
	},
	'🚩': {
		'keywords': [
			'triangular_flag_on_post',
			'post',
			'milestone'
		],
		'emoticons': []
	},
	'🎌': {
		'keywords': [
			'crossed_flags'
		],
		'emoticons': []
	},
	'🏴': {
		'keywords': [
			'black_flag',
			'waving'
		],
		'emoticons': []
	},
	'🏳️‍⚧️': {
		'keywords': [
			'transgender_flag'
		],
		'emoticons': []
	},
	'🏴‍☠️': {
		'keywords': [
			'pirate_flag',
			'skull',
			'crossbones'
		],
		'emoticons': []
	},
	'🇦🇨': {
		'keywords': [
			'ascension_island',
			'ac'
		],
		'emoticons': []
	},
	'🇦🇩': {
		'keywords': [
			'andorra',
			'ad'
		],
		'emoticons': []
	},
	'🇦🇪': {
		'keywords': [
			'united_arab_emirates',
			'ae'
		],
		'emoticons': []
	},
	'🇦🇫': {
		'keywords': [
			'afghanistan',
			'af'
		],
		'emoticons': []
	},
	'🇦🇬': {
		'keywords': [
			'antigua_barbuda',
			'ag'
		],
		'emoticons': []
	},
	'🇦🇮': {
		'keywords': [
			'anguilla',
			'ai'
		],
		'emoticons': []
	},
	'🇦🇱': {
		'keywords': [
			'albania',
			'al'
		],
		'emoticons': []
	},
	'🇦🇲': {
		'keywords': [
			'armenia',
			'am'
		],
		'emoticons': []
	},
	'🇦🇴': {
		'keywords': [
			'angola',
			'ao'
		],
		'emoticons': []
	},
	'🇦🇶': {
		'keywords': [
			'antarctica',
			'aq'
		],
		'emoticons': []
	},
	'🇦🇷': {
		'keywords': [
			'argentina',
			'ar'
		],
		'emoticons': []
	},
	'🇦🇸': {
		'keywords': [
			'american_samoa',
			'as',
			'ws'
		],
		'emoticons': []
	},
	'🇦🇹': {
		'keywords': [
			'austria',
			'at'
		],
		'emoticons': []
	},
	'🇦🇺': {
		'keywords': [
			'australia',
			'au'
		],
		'emoticons': []
	},
	'🇦🇼': {
		'keywords': [
			'aruba'
		],
		'emoticons': []
	},
	'🇦🇽': {
		'keywords': [
			'aland_islands',
			'aland',
			'ax',
			'Aland'
		],
		'emoticons': []
	},
	'🇦🇿': {
		'keywords': [
			'azerbaijan',
			'az'
		],
		'emoticons': []
	},
	'🇧🇦': {
		'keywords': [
			'bosnia_herzegovina',
			'ba'
		],
		'emoticons': []
	},
	'🇧🇧': {
		'keywords': [
			'barbados',
			'bb'
		],
		'emoticons': []
	},
	'🇧🇩': {
		'keywords': [
			'bangladesh',
			'bd'
		],
		'emoticons': []
	},
	'🇧🇪': {
		'keywords': [
			'belgium',
			'be'
		],
		'emoticons': []
	},
	'🇧🇫': {
		'keywords': [
			'burkina_faso',
			'bf'
		],
		'emoticons': []
	},
	'🇧🇬': {
		'keywords': [
			'bulgaria',
			'bg'
		],
		'emoticons': []
	},
	'🇧🇭': {
		'keywords': [
			'bahrain',
			'bh'
		],
		'emoticons': []
	},
	'🇧🇮': {
		'keywords': [
			'burundi',
			'bi'
		],
		'emoticons': []
	},
	'🇧🇯': {
		'keywords': [
			'benin',
			'bj'
		],
		'emoticons': []
	},
	'🇧🇱': {
		'keywords': [
			'st_barthelemy',
			'st',
			'barthelemy',
			'bl',
			'saint'
		],
		'emoticons': []
	},
	'🇧🇲': {
		'keywords': [
			'bermuda',
			'bm'
		],
		'emoticons': []
	},
	'🇧🇳': {
		'keywords': [
			'brunei',
			'bn',
			'darussalam'
		],
		'emoticons': []
	},
	'🇧🇴': {
		'keywords': [
			'bolivia',
			'bo'
		],
		'emoticons': []
	},
	'🇧🇶': {
		'keywords': [
			'caribbean_netherlands',
			'bq',
			'bonaire'
		],
		'emoticons': []
	},
	'🇧🇷': {
		'keywords': [
			'brazil',
			'br'
		],
		'emoticons': []
	},
	'🇧🇸': {
		'keywords': [
			'bahamas',
			'bs'
		],
		'emoticons': []
	},
	'🇧🇹': {
		'keywords': [
			'bhutan',
			'bt'
		],
		'emoticons': []
	},
	'🇧🇻': {
		'keywords': [
			'bouvet_island',
			'bv',
			'norway'
		],
		'emoticons': []
	},
	'🇧🇼': {
		'keywords': [
			'botswana',
			'bw'
		],
		'emoticons': []
	},
	'🇧🇾': {
		'keywords': [
			'belarus',
			'by'
		],
		'emoticons': []
	},
	'🇧🇿': {
		'keywords': [
			'belize',
			'bz'
		],
		'emoticons': []
	},
	'🇨🇦': {
		'keywords': [
			'canada'
		],
		'emoticons': []
	},
	'🇨🇨': {
		'keywords': [
			'cocos_islands',
			'cc',
			'keeling'
		],
		'emoticons': []
	},
	'🇨🇩': {
		'keywords': [
			'congo_kinshasa',
			'democratic',
			'republic'
		],
		'emoticons': []
	},
	'🇨🇫': {
		'keywords': [
			'central_african_republic',
			'cf'
		],
		'emoticons': []
	},
	'🇨🇬': {
		'keywords': [
			'congo_brazzaville',
			'cg'
		],
		'emoticons': []
	},
	'🇨🇭': {
		'keywords': [
			'switzerland',
			'ch'
		],
		'emoticons': []
	},
	'🇨🇮': {
		'keywords': [
			'cote_divoire',
			'cote',
			'divoire',
			'ci',
			'd',
			'ivoire',
			'ivory',
			'coast'
		],
		'emoticons': []
	},
	'🇨🇰': {
		'keywords': [
			'cook_islands',
			'ck'
		],
		'emoticons': []
	},
	'🇨🇱': {
		'keywords': [
			'chile',
			'cl'
		],
		'emoticons': []
	},
	'🇨🇲': {
		'keywords': [
			'cameroon',
			'cm'
		],
		'emoticons': []
	},
	'🇨🇳': {
		'keywords': [
			'cn',
			'prc'
		],
		'emoticons': []
	},
	'🇨🇴': {
		'keywords': [
			'colombia',
			'co'
		],
		'emoticons': []
	},
	'🇨🇵': {
		'keywords': [
			'clipperton_island',
			'cp'
		],
		'emoticons': []
	},
	'🇨🇷': {
		'keywords': [
			'costa_rica',
			'cr'
		],
		'emoticons': []
	},
	'🇨🇺': {
		'keywords': [
			'cuba',
			'cu'
		],
		'emoticons': []
	},
	'🇨🇻': {
		'keywords': [
			'cape_verde',
			'cv',
			'cabo'
		],
		'emoticons': []
	},
	'🇨🇼': {
		'keywords': [
			'curacao',
			'cw'
		],
		'emoticons': []
	},
	'🇨🇽': {
		'keywords': [
			'christmas_island',
			'cx'
		],
		'emoticons': []
	},
	'🇨🇾': {
		'keywords': [
			'cyprus',
			'cy'
		],
		'emoticons': []
	},
	'🇨🇿': {
		'keywords': [
			'czech_republic',
			'czech',
			'cz'
		],
		'emoticons': []
	},
	'🇩🇪': {
		'keywords': [
			'de',
			'german'
		],
		'emoticons': []
	},
	'🇩🇬': {
		'keywords': [
			'diego_garcia',
			'dg'
		],
		'emoticons': []
	},
	'🇩🇯': {
		'keywords': [
			'djibouti',
			'dj'
		],
		'emoticons': []
	},
	'🇩🇰': {
		'keywords': [
			'denmark',
			'dk'
		],
		'emoticons': []
	},
	'🇩🇲': {
		'keywords': [
			'dominica',
			'dm'
		],
		'emoticons': []
	},
	'🇩🇴': {
		'keywords': [
			'dominican_republic'
		],
		'emoticons': []
	},
	'🇩🇿': {
		'keywords': [
			'algeria',
			'dz'
		],
		'emoticons': []
	},
	'🇪🇦': {
		'keywords': [
			'ceuta_melilla',
			'ea'
		],
		'emoticons': []
	},
	'🇪🇨': {
		'keywords': [
			'ecuador',
			'ec'
		],
		'emoticons': []
	},
	'🇪🇪': {
		'keywords': [
			'estonia',
			'ee'
		],
		'emoticons': []
	},
	'🇪🇬': {
		'keywords': [
			'egypt',
			'eg'
		],
		'emoticons': []
	},
	'🇪🇭': {
		'keywords': [
			'western_sahara',
			'eh'
		],
		'emoticons': []
	},
	'🇪🇷': {
		'keywords': [
			'eritrea',
			'er'
		],
		'emoticons': []
	},
	'🇪🇸': {
		'keywords': [
			'es'
		],
		'emoticons': []
	},
	'🇪🇹': {
		'keywords': [
			'ethiopia',
			'et'
		],
		'emoticons': []
	},
	'🇪🇺': {
		'keywords': [
			'european_union',
			'eu'
		],
		'emoticons': []
	},
	'🇫🇮': {
		'keywords': [
			'finland',
			'fi'
		],
		'emoticons': []
	},
	'🇫🇯': {
		'keywords': [
			'fiji',
			'fj'
		],
		'emoticons': []
	},
	'🇫🇰': {
		'keywords': [
			'falkland_islands',
			'fk',
			'malvinas'
		],
		'emoticons': []
	},
	'🇫🇲': {
		'keywords': [
			'micronesia',
			'fm',
			'federated',
			'states'
		],
		'emoticons': []
	},
	'🇫🇴': {
		'keywords': [
			'faroe_islands',
			'fo'
		],
		'emoticons': []
	},
	'🇫🇷': {
		'keywords': [
			'fr'
		],
		'emoticons': []
	},
	'🇬🇦': {
		'keywords': [
			'gabon',
			'ga'
		],
		'emoticons': []
	},
	'🇬🇧': {
		'keywords': [
			'gb',
			'great',
			'britain',
			'northern',
			'ireland',
			'UK',
			'english',
			'union'
		],
		'emoticons': []
	},
	'🇬🇩': {
		'keywords': [
			'grenada',
			'gd'
		],
		'emoticons': []
	},
	'🇬🇪': {
		'keywords': [
			'georgia',
			'ge'
		],
		'emoticons': []
	},
	'🇬🇫': {
		'keywords': [
			'french_guiana',
			'gf'
		],
		'emoticons': []
	},
	'🇬🇬': {
		'keywords': [
			'guernsey',
			'gg'
		],
		'emoticons': []
	},
	'🇬🇭': {
		'keywords': [
			'ghana',
			'gh'
		],
		'emoticons': []
	},
	'🇬🇮': {
		'keywords': [
			'gibraltar',
			'gi'
		],
		'emoticons': []
	},
	'🇬🇱': {
		'keywords': [
			'greenland',
			'gl'
		],
		'emoticons': []
	},
	'🇬🇲': {
		'keywords': [
			'gambia',
			'gm'
		],
		'emoticons': []
	},
	'🇬🇳': {
		'keywords': [
			'guinea',
			'gn'
		],
		'emoticons': []
	},
	'🇬🇵': {
		'keywords': [
			'guadeloupe',
			'gp'
		],
		'emoticons': []
	},
	'🇬🇶': {
		'keywords': [
			'equatorial_guinea',
			'gq'
		],
		'emoticons': []
	},
	'🇬🇷': {
		'keywords': [
			'greece',
			'gr'
		],
		'emoticons': []
	},
	'🇬🇸': {
		'keywords': [
			'south_georgia_south_sandwich_islands',
			'gs'
		],
		'emoticons': []
	},
	'🇬🇹': {
		'keywords': [
			'guatemala',
			'gt'
		],
		'emoticons': []
	},
	'🇬🇺': {
		'keywords': [
			'guam',
			'gu'
		],
		'emoticons': []
	},
	'🇬🇼': {
		'keywords': [
			'guinea_bissau',
			'guinea',
			'bissau',
			'gw'
		],
		'emoticons': []
	},
	'🇬🇾': {
		'keywords': [
			'guyana',
			'gy'
		],
		'emoticons': []
	},
	'🇭🇰': {
		'keywords': [
			'hong_kong',
			'hk'
		],
		'emoticons': []
	},
	'🇭🇲': {
		'keywords': [
			'heard_mcdonald_islands',
			'hm'
		],
		'emoticons': []
	},
	'🇭🇳': {
		'keywords': [
			'honduras',
			'hn'
		],
		'emoticons': []
	},
	'🇭🇷': {
		'keywords': [
			'croatia',
			'hr'
		],
		'emoticons': []
	},
	'🇭🇹': {
		'keywords': [
			'haiti',
			'ht'
		],
		'emoticons': []
	},
	'🇭🇺': {
		'keywords': [
			'hungary',
			'hu'
		],
		'emoticons': []
	},
	'🇮🇨': {
		'keywords': [
			'canary_islands',
			'ic'
		],
		'emoticons': []
	},
	'🇮🇩': {
		'keywords': [
			'indonesia',
			'id'
		],
		'emoticons': []
	},
	'🇮🇪': {
		'keywords': [
			'ireland',
			'ie'
		],
		'emoticons': []
	},
	'🇮🇱': {
		'keywords': [
			'israel',
			'il'
		],
		'emoticons': []
	},
	'🇮🇲': {
		'keywords': [
			'isle_of_man',
			'im'
		],
		'emoticons': []
	},
	'🇮🇳': {
		'keywords': [
			'india',
			'in'
		],
		'emoticons': []
	},
	'🇮🇴': {
		'keywords': [
			'british_indian_ocean_territory',
			'io'
		],
		'emoticons': []
	},
	'🇮🇶': {
		'keywords': [
			'iraq',
			'iq'
		],
		'emoticons': []
	},
	'🇮🇷': {
		'keywords': [
			'iran',
			'ir',
			'islamic'
		],
		'emoticons': []
	},
	'🇮🇸': {
		'keywords': [
			'iceland',
			'is'
		],
		'emoticons': []
	},
	'🇮🇹': {
		'keywords': [
			'it'
		],
		'emoticons': []
	},
	'🇯🇪': {
		'keywords': [
			'jersey',
			'je'
		],
		'emoticons': []
	},
	'🇯🇲': {
		'keywords': [
			'jamaica',
			'jm'
		],
		'emoticons': []
	},
	'🇯🇴': {
		'keywords': [
			'jordan',
			'jo'
		],
		'emoticons': []
	},
	'🇯🇵': {
		'keywords': [
			'jp',
			'ja'
		],
		'emoticons': []
	},
	'🇰🇪': {
		'keywords': [
			'kenya',
			'ke'
		],
		'emoticons': []
	},
	'🇰🇬': {
		'keywords': [
			'kyrgyzstan',
			'kg'
		],
		'emoticons': []
	},
	'🇰🇭': {
		'keywords': [
			'cambodia',
			'kh'
		],
		'emoticons': []
	},
	'🇰🇮': {
		'keywords': [
			'kiribati',
			'ki'
		],
		'emoticons': []
	},
	'🇰🇲': {
		'keywords': [
			'comoros',
			'km'
		],
		'emoticons': []
	},
	'🇰🇳': {
		'keywords': [
			'st_kitts_nevis',
			'kn'
		],
		'emoticons': []
	},
	'🇰🇵': {
		'keywords': [
			'north_korea',
			'kp'
		],
		'emoticons': []
	},
	'🇰🇷': {
		'keywords': [
			'kr'
		],
		'emoticons': []
	},
	'🇰🇼': {
		'keywords': [
			'kuwait',
			'kw'
		],
		'emoticons': []
	},
	'🇰🇾': {
		'keywords': [
			'cayman_islands',
			'ky'
		],
		'emoticons': []
	},
	'🇰🇿': {
		'keywords': [
			'kazakhstan',
			'kz'
		],
		'emoticons': []
	},
	'🇱🇦': {
		'keywords': [
			'laos',
			'la',
			'lao'
		],
		'emoticons': []
	},
	'🇱🇧': {
		'keywords': [
			'lebanon',
			'lb'
		],
		'emoticons': []
	},
	'🇱🇨': {
		'keywords': [
			'st_lucia',
			'lc'
		],
		'emoticons': []
	},
	'🇱🇮': {
		'keywords': [
			'liechtenstein',
			'li'
		],
		'emoticons': []
	},
	'🇱🇰': {
		'keywords': [
			'sri_lanka',
			'lk'
		],
		'emoticons': []
	},
	'🇱🇷': {
		'keywords': [
			'liberia',
			'lr'
		],
		'emoticons': []
	},
	'🇱🇸': {
		'keywords': [
			'lesotho',
			'ls'
		],
		'emoticons': []
	},
	'🇱🇹': {
		'keywords': [
			'lithuania',
			'lt'
		],
		'emoticons': []
	},
	'🇱🇺': {
		'keywords': [
			'luxembourg',
			'lu'
		],
		'emoticons': []
	},
	'🇱🇻': {
		'keywords': [
			'latvia',
			'lv'
		],
		'emoticons': []
	},
	'🇱🇾': {
		'keywords': [
			'libya',
			'ly'
		],
		'emoticons': []
	},
	'🇲🇦': {
		'keywords': [
			'morocco',
			'ma'
		],
		'emoticons': []
	},
	'🇲🇨': {
		'keywords': [
			'monaco',
			'mc'
		],
		'emoticons': []
	},
	'🇲🇩': {
		'keywords': [
			'moldova',
			'md'
		],
		'emoticons': []
	},
	'🇲🇪': {
		'keywords': [
			'montenegro',
			'me'
		],
		'emoticons': []
	},
	'🇲🇫': {
		'keywords': [
			'st_martin',
			'mf'
		],
		'emoticons': []
	},
	'🇲🇬': {
		'keywords': [
			'madagascar',
			'mg'
		],
		'emoticons': []
	},
	'🇲🇭': {
		'keywords': [
			'marshall_islands',
			'mh'
		],
		'emoticons': []
	},
	'🇲🇰': {
		'keywords': [
			'macedonia',
			'mk'
		],
		'emoticons': []
	},
	'🇲🇱': {
		'keywords': [
			'mali',
			'ml'
		],
		'emoticons': []
	},
	'🇲🇲': {
		'keywords': [
			'myanmar',
			'mm'
		],
		'emoticons': []
	},
	'🇲🇳': {
		'keywords': [
			'mongolia',
			'mn'
		],
		'emoticons': []
	},
	'🇲🇴': {
		'keywords': [
			'macau',
			'mo'
		],
		'emoticons': []
	},
	'🇲🇵': {
		'keywords': [
			'northern_mariana_islands',
			'mp'
		],
		'emoticons': []
	},
	'🇲🇶': {
		'keywords': [
			'martinique',
			'mq'
		],
		'emoticons': []
	},
	'🇲🇷': {
		'keywords': [
			'mauritania',
			'mr'
		],
		'emoticons': []
	},
	'🇲🇸': {
		'keywords': [
			'montserrat',
			'ms'
		],
		'emoticons': []
	},
	'🇲🇹': {
		'keywords': [
			'malta',
			'mt'
		],
		'emoticons': []
	},
	'🇲🇺': {
		'keywords': [
			'mauritius',
			'mu'
		],
		'emoticons': []
	},
	'🇲🇻': {
		'keywords': [
			'maldives',
			'mv'
		],
		'emoticons': []
	},
	'🇲🇼': {
		'keywords': [
			'malawi',
			'mw'
		],
		'emoticons': []
	},
	'🇲🇽': {
		'keywords': [
			'mexico',
			'mx'
		],
		'emoticons': []
	},
	'🇲🇾': {
		'keywords': [
			'malaysia',
			'my'
		],
		'emoticons': []
	},
	'🇲🇿': {
		'keywords': [
			'mozambique',
			'mz'
		],
		'emoticons': []
	},
	'🇳🇦': {
		'keywords': [
			'namibia',
			'na'
		],
		'emoticons': []
	},
	'🇳🇨': {
		'keywords': [
			'new_caledonia',
			'nc'
		],
		'emoticons': []
	},
	'🇳🇪': {
		'keywords': [
			'niger',
			'ne'
		],
		'emoticons': []
	},
	'🇳🇫': {
		'keywords': [
			'norfolk_island',
			'nf'
		],
		'emoticons': []
	},
	'🇳🇬': {
		'keywords': [
			'nigeria',
			'ng'
		],
		'emoticons': []
	},
	'🇳🇮': {
		'keywords': [
			'nicaragua',
			'ni'
		],
		'emoticons': []
	},
	'🇳🇱': {
		'keywords': [
			'netherlands',
			'nl'
		],
		'emoticons': []
	},
	'🇳🇴': {
		'keywords': [
			'norway'
		],
		'emoticons': []
	},
	'🇳🇵': {
		'keywords': [
			'nepal',
			'np'
		],
		'emoticons': []
	},
	'🇳🇷': {
		'keywords': [
			'nauru',
			'nr'
		],
		'emoticons': []
	},
	'🇳🇺': {
		'keywords': [
			'niue',
			'nu'
		],
		'emoticons': []
	},
	'🇳🇿': {
		'keywords': [
			'new_zealand',
			'nz'
		],
		'emoticons': []
	},
	'🇴🇲': {
		'keywords': [
			'oman',
			'om'
		],
		'emoticons': []
	},
	'🇵🇦': {
		'keywords': [
			'panama',
			'pa'
		],
		'emoticons': []
	},
	'🇵🇪': {
		'keywords': [
			'peru',
			'pe'
		],
		'emoticons': []
	},
	'🇵🇫': {
		'keywords': [
			'french_polynesia',
			'pf'
		],
		'emoticons': []
	},
	'🇵🇬': {
		'keywords': [
			'papua_new_guinea',
			'pg'
		],
		'emoticons': []
	},
	'🇵🇭': {
		'keywords': [
			'philippines',
			'ph'
		],
		'emoticons': []
	},
	'🇵🇰': {
		'keywords': [
			'pakistan',
			'pk'
		],
		'emoticons': []
	},
	'🇵🇱': {
		'keywords': [
			'poland',
			'pl'
		],
		'emoticons': []
	},
	'🇵🇲': {
		'keywords': [
			'st_pierre_miquelon',
			'pm'
		],
		'emoticons': []
	},
	'🇵🇳': {
		'keywords': [
			'pitcairn_islands',
			'pn'
		],
		'emoticons': []
	},
	'🇵🇷': {
		'keywords': [
			'puerto_rico',
			'pr'
		],
		'emoticons': []
	},
	'🇵🇸': {
		'keywords': [
			'palestinian_territories',
			'ps',
			'palestine'
		],
		'emoticons': []
	},
	'🇵🇹': {
		'keywords': [
			'portugal',
			'pt'
		],
		'emoticons': []
	},
	'🇵🇼': {
		'keywords': [
			'palau',
			'pw'
		],
		'emoticons': []
	},
	'🇵🇾': {
		'keywords': [
			'paraguay',
			'py'
		],
		'emoticons': []
	},
	'🇶🇦': {
		'keywords': [
			'qatar',
			'qa'
		],
		'emoticons': []
	},
	'🇷🇪': {
		'keywords': [
			'reunion',
			're'
		],
		'emoticons': []
	},
	'🇷🇴': {
		'keywords': [
			'romania',
			'ro'
		],
		'emoticons': []
	},
	'🇷🇸': {
		'keywords': [
			'serbia',
			'rs'
		],
		'emoticons': []
	},
	'🇷🇺': {
		'keywords': [
			'ru',
			'russian',
			'federation'
		],
		'emoticons': []
	},
	'🇷🇼': {
		'keywords': [
			'rwanda',
			'rw'
		],
		'emoticons': []
	},
	'🇸🇦': {
		'keywords': [
			'saudi_arabia',
			'sa'
		],
		'emoticons': []
	},
	'🇸🇧': {
		'keywords': [
			'solomon_islands',
			'sb'
		],
		'emoticons': []
	},
	'🇸🇨': {
		'keywords': [
			'seychelles',
			'sc'
		],
		'emoticons': []
	},
	'🇸🇩': {
		'keywords': [
			'sudan',
			'sd'
		],
		'emoticons': []
	},
	'🇸🇪': {
		'keywords': [
			'sweden',
			'se'
		],
		'emoticons': []
	},
	'🇸🇬': {
		'keywords': [
			'singapore',
			'sg'
		],
		'emoticons': []
	},
	'🇸🇭': {
		'keywords': [
			'st_helena',
			'sh',
			'ascension',
			'tristan',
			'cunha'
		],
		'emoticons': []
	},
	'🇸🇮': {
		'keywords': [
			'slovenia',
			'si'
		],
		'emoticons': []
	},
	'🇸🇯': {
		'keywords': [
			'svalbard_jan_mayen',
			'sj'
		],
		'emoticons': []
	},
	'🇸🇰': {
		'keywords': [
			'slovakia',
			'sk'
		],
		'emoticons': []
	},
	'🇸🇱': {
		'keywords': [
			'sierra_leone',
			'sl'
		],
		'emoticons': []
	},
	'🇸🇲': {
		'keywords': [
			'san_marino',
			'sm'
		],
		'emoticons': []
	},
	'🇸🇳': {
		'keywords': [
			'senegal',
			'sn'
		],
		'emoticons': []
	},
	'🇸🇴': {
		'keywords': [
			'somalia',
			'so'
		],
		'emoticons': []
	},
	'🇸🇷': {
		'keywords': [
			'suriname',
			'sr'
		],
		'emoticons': []
	},
	'🇸🇸': {
		'keywords': [
			'south_sudan',
			'ss'
		],
		'emoticons': []
	},
	'🇸🇹': {
		'keywords': [
			'sao_tome_principe',
			'sao',
			'tome',
			'principe'
		],
		'emoticons': []
	},
	'🇸🇻': {
		'keywords': [
			'el_salvador',
			'sv'
		],
		'emoticons': []
	},
	'🇸🇽': {
		'keywords': [
			'sint_maarten',
			'sx',
			'dutch'
		],
		'emoticons': []
	},
	'🇸🇾': {
		'keywords': [
			'syria',
			'sy',
			'syrian',
			'arab'
		],
		'emoticons': []
	},
	'🇸🇿': {
		'keywords': [
			'swaziland',
			'sz'
		],
		'emoticons': []
	},
	'🇹🇦': {
		'keywords': [
			'tristan_da_cunha',
			'ta'
		],
		'emoticons': []
	},
	'🇹🇨': {
		'keywords': [
			'turks_caicos_islands',
			'tc'
		],
		'emoticons': []
	},
	'🇹🇩': {
		'keywords': [
			'chad',
			'td'
		],
		'emoticons': []
	},
	'🇹🇫': {
		'keywords': [
			'french_southern_territories',
			'tf'
		],
		'emoticons': []
	},
	'🇹🇬': {
		'keywords': [
			'togo',
			'tg'
		],
		'emoticons': []
	},
	'🇹🇭': {
		'keywords': [
			'thailand'
		],
		'emoticons': []
	},
	'🇹🇯': {
		'keywords': [
			'tajikistan',
			'tj'
		],
		'emoticons': []
	},
	'🇹🇰': {
		'keywords': [
			'tokelau',
			'tk'
		],
		'emoticons': []
	},
	'🇹🇱': {
		'keywords': [
			'timor_leste',
			'timor',
			'leste',
			'tl'
		],
		'emoticons': []
	},
	'🇹🇲': {
		'keywords': [
			'turkmenistan',
			'tm'
		],
		'emoticons': []
	},
	'🇹🇳': {
		'keywords': [
			'tunisia',
			'tn'
		],
		'emoticons': []
	},
	'🇹🇴': {
		'keywords': [
			'tonga',
			'to'
		],
		'emoticons': []
	},
	'🇹🇷': {
		'keywords': [
			'tr'
		],
		'emoticons': []
	},
	'🇹🇹': {
		'keywords': [
			'trinidad_tobago',
			'tt'
		],
		'emoticons': []
	},
	'🇹🇻': {
		'keywords': [
			'tuvalu',
			'tv'
		],
		'emoticons': []
	},
	'🇹🇼': {
		'keywords': [
			'taiwan',
			'tw'
		],
		'emoticons': []
	},
	'🇹🇿': {
		'keywords': [
			'tanzania',
			'tz',
			'united'
		],
		'emoticons': []
	},
	'🇺🇦': {
		'keywords': [
			'ukraine',
			'ua'
		],
		'emoticons': []
	},
	'🇺🇬': {
		'keywords': [
			'uganda',
			'ug'
		],
		'emoticons': []
	},
	'🇺🇲': {
		'keywords': [
			'us_outlying_islands',
			'us',
			'um',
			'u'
		],
		'emoticons': []
	},
	'🇺🇳': {
		'keywords': [
			'united_nations',
			'un'
		],
		'emoticons': []
	},
	'🇺🇸': {
		'keywords': [
			'us'
		],
		'emoticons': []
	},
	'🇺🇾': {
		'keywords': [
			'uruguay',
			'uy'
		],
		'emoticons': []
	},
	'🇺🇿': {
		'keywords': [
			'uzbekistan',
			'uz'
		],
		'emoticons': []
	},
	'🇻🇦': {
		'keywords': [
			'vatican_city',
			'va'
		],
		'emoticons': []
	},
	'🇻🇨': {
		'keywords': [
			'st_vincent_grenadines',
			'vc'
		],
		'emoticons': []
	},
	'🇻🇪': {
		'keywords': [
			'venezuela',
			've',
			'bolivarian'
		],
		'emoticons': []
	},
	'🇻🇬': {
		'keywords': [
			'british_virgin_islands',
			'vg',
			'bvi'
		],
		'emoticons': []
	},
	'🇻🇮': {
		'keywords': [
			'us_virgin_islands',
			'vi'
		],
		'emoticons': []
	},
	'🇻🇳': {
		'keywords': [
			'vietnam',
			'vn',
			'viet',
			'nam'
		],
		'emoticons': []
	},
	'🇻🇺': {
		'keywords': [
			'vanuatu',
			'vu'
		],
		'emoticons': []
	},
	'🇼🇫': {
		'keywords': [
			'wallis_futuna',
			'wf'
		],
		'emoticons': []
	},
	'🇼🇸': {
		'keywords': [
			'samoa'
		],
		'emoticons': []
	},
	'🇽🇰': {
		'keywords': [
			'kosovo',
			'xk'
		],
		'emoticons': []
	},
	'🇾🇪': {
		'keywords': [
			'yemen',
			'ye'
		],
		'emoticons': []
	},
	'🇾🇹': {
		'keywords': [
			'mayotte',
			'yt'
		],
		'emoticons': []
	},
	'🇿🇦': {
		'keywords': [
			'south_africa',
			'za'
		],
		'emoticons': []
	},
	'🇿🇲': {
		'keywords': [
			'zambia',
			'zm'
		],
		'emoticons': []
	},
	'🇿🇼': {
		'keywords': [
			'zimbabwe',
			'zw'
		],
		'emoticons': []
	},
	'🏴󠁧󠁢󠁥󠁮󠁧󠁿': {
		'keywords': [
			'england'
		],
		'emoticons': []
	},
	'🏴󠁧󠁢󠁳󠁣󠁴󠁿': {
		'keywords': [
			'scotland',
			'scottish'
		],
		'emoticons': []
	},
	'🏴󠁧󠁢󠁷󠁬󠁳󠁿': {
		'keywords': [
			'wales',
			'welsh'
		],
		'emoticons': []
	}
}

export default emojiShortcuts
