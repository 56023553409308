import { useContext, useState } from 'react'
import { Box, Typography, Button, Tooltip, IconButton } from '@mui/material'
import { ArrowForward, Info } from '@mui/icons-material'
import { UploadFile } from 'kitfit-community-common'

import OnboardingContext from '../context/onboardingContext'

const Logo = () => {
	const { onboarding, completeStep } = useContext(OnboardingContext)
	const [logoStorageLocation, setLogoStorageLocation] = useState<string>(onboarding?.logoStorageLocation || '')
	const [logoDownloadUrl, setLogoDownloadUrl] = useState<string>(onboarding?.logoDownloadUrl || '')

	if (!onboarding) {
		return null
	}

	const uploadFile = async (logoStorageLocation: string, logoDownloadUrl: string) => {
		setLogoStorageLocation(logoStorageLocation)
		setLogoDownloadUrl(logoDownloadUrl)
	}

	const handleLogo = () => {
		completeStep({ step: 'logo', logoStorageLocation, logoDownloadUrl })
	}

	const skipLogo = () => {
		completeStep({ step: 'logo' })
	}

	return (
		<Box sx={{ flex: 1, boxShadow: '0px 10px 10px rgb(0 0 0 / 10%)', borderRadius: '0.5rem' }}>
			<Box sx={{ backgroundColor: '#6AA84F', color: '#FFF', height: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }}>
				<Typography variant='h2' sx={{ flex: 1, display: 'flex', alignItems: 'center', textAlign: 'center' }}>{onboarding.name}</Typography>
				<Box sx={{ mb: 1 }}>Community Portal</Box>
			</Box>
			<Box sx={{ backgroundColor: '#FFF', minHeight: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderBottomLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }}>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<Box>Now upload your logo </Box>
					<Tooltip title='Your community logo grabs attention and helps reveal your identity'>
						<IconButton>
							<Info sx={{ color: '#2B78E4' }} fontSize='small' />
						</IconButton>
					</Tooltip>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '66%' }}>
					<UploadFile uploadCallback={uploadFile} uploadedImage={logoDownloadUrl} />
					<Button sx={{ mt: 5, mb: 1 }} onClick={handleLogo} fullWidth variant='contained' disabled={!(logoStorageLocation && logoDownloadUrl)}>Continue <ArrowForward fontSize='small' sx={{ ml: 1 }} /></Button>

					<Button sx={{ mb: 1 }} variant='text' onClick={skipLogo}>Skip</Button>
				</Box>
			</Box>
		</Box>
	)
}

export default Logo
