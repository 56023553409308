import { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

import Copyright from './components/Copyright'

import './ResetPassword.css'

const emailSchema = yup.object({
	email: yup.string().email().required()
}).required()

export type ResetPasswordEmailType = yup.InferType<typeof emailSchema>

const ResetPassword = () => {
	const { register: registerRE, handleSubmit: handleSubmitRE, formState: { errors: emailResetErrors } } = useForm<ResetPasswordEmailType>({ resolver: yupResolver(emailSchema) })
	const [resetSentResult, setResetSentResult] = useState<string>()

	const handleResetPassword = async (data: ResetPasswordEmailType) => {
		const email = data.email
		const auth = getAuth()
		const url = `${global.location.origin}/login`
		const actionCodeSettings = { url, handleCodeInApp: true }

		try {
			await sendPasswordResetEmail(auth, email, actionCodeSettings)
			setResetSentResult(`Reset email set to: ${email}`)
		} catch (ex) {
			setResetSentResult(`Reset email error: ${ex}`)
		}
	}

	return (
		<Container component="main" maxWidth="xs" sx={{ pt: 2 }}>
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					padding: '1rem 2rem',
					backgroundColor: 'background.paper',
					borderRadius: '0.25rem'
				}}
			>
				<Typography component="h1" variant="h5">Reset email password</Typography>
				<Box component="form" className="ResetPassword-form" onSubmit={handleSubmitRE(handleResetPassword)} noValidate sx={{ mt: 1 }}>
					<TextField
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						autoComplete="email"
						autoFocus
						{...registerRE('email')}
					/>
					{emailResetErrors?.email?.message && <Typography component="div" className="ResetPassword-error">{emailResetErrors.email?.message}</Typography>}
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						Reset password
					</Button>
					{resetSentResult && <Typography component="div" className="ResetPassword-sent">{resetSentResult}</Typography>}
					<Grid container>
						<Grid item xs>
							<Link href="/login" variant="body2">Return to login</Link>
						</Grid>
						<Grid item>
							<Link href="/signup" variant="body2">Don't have an account? Sign Up</Link>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Copyright sx={{ mt: 8, mb: 4 }} />
		</Container>
	)
}

export default ResetPassword
