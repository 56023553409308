import ReactDOMServer from 'react-dom/server'
import { NodeHtmlMarkdown } from 'node-html-markdown'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Markdown from '../../utilities/Markdown'
import { EditorText } from '../Editors/EditorText'
import UploadFile from '../../../UploadFile'
import { BlockItem } from '..'
import { useCommunityContext } from '../../../../context/CommunityContext'

import { BlockComponentType } from './Block'

export interface PinnedArticleBlockItemData {
	articles: Array<{
		articleId?: string,
		imageId?: string,
		title: string,
		description: string
	}>
}
export const pinnedArticleValue: Omit<BlockItem<PinnedArticleBlockItemData>, 'id'> = {
	type: 'pinnedarticle',
	data: {
		articles: [
			{
				title: 'Untitled Article',
				description: 'Article description'
			}
		]
	}
}

const PinnedArticleBlock: BlockComponentType<PinnedArticleBlockItemData> = ({ block, readOnly = false, onChange }) => {
	const { articles = [] } = block.data

	const navigate = useNavigate()

	const { community, addImage, availableImageIds } = useCommunityContext()
	const imageUrl = (imageId?: string) => imageId ? `https://${community?.imageHostname}/${community?.communityId}/images/${imageId}.png` : ''
	const articleUrl = (articleId?: string) => articleId ? `/articles/${articleId}` : ''

	const [imageDownloadUrl, setImageDownloadUrl] = useState<string>('')
	const [selectedImageId, setSelectedImageId] = useState<string>()

	const uploadFile = async (imageStorageLocation: string, imageDownloadUrl: string) => {
		if (!addImage) {
			throw new Error('Invalid context, addImage not found')
		}
		setImageDownloadUrl(imageDownloadUrl)
		const imageId = await addImage(imageStorageLocation, imageDownloadUrl)
		setSelectedImageId(imageId)
	}

	const [showDialogForIndex, setShowDialogForIndex] = useState(-1)
	const handleClose = () => {
		setShowDialogForIndex(-1)
	}
	const handleSaveImage = () => {
		const index = showDialogForIndex
		setShowDialogForIndex(-1)
		if (onChange) {
			const updatedArticles = [...articles]
			updatedArticles[index] = { ...updatedArticles[index], imageId: selectedImageId }
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, articles: updatedArticles } }
			})
		}
	}

	const handleArticleIdChange = (index: number) => (articleId: string) => {
		if (onChange) {
			const updatedArticles = [...articles]
			updatedArticles[index] = { ...updatedArticles[index], articleId }
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, articles: updatedArticles } }
			})
		}
	}

	const handleTitleChange = (index: number) => ({ doc }: { doc: string }) => {
		if (onChange) {
			const title = NodeHtmlMarkdown.translate(doc)
			const updatedArticles = [...articles]
			updatedArticles[index] = { ...updatedArticles[index], title }
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, articles: updatedArticles } }
			})
		}
	}

	const handleDescriptionChange = (index: number) => ({ doc }: { doc: string }) => {
		if (onChange) {
			const description = NodeHtmlMarkdown.translate(doc)
			const updatedArticles = [...articles]
			updatedArticles[index] = { ...updatedArticles[index], description }
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, articles: updatedArticles } }
			})
		}
	}

	const handleArticleAdd = () => {
		if (onChange) {
			const updatedArticles = [...articles]
			updatedArticles.push(pinnedArticleValue.data.articles[0])
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, articles: updatedArticles } }
			})
		}
	}

	const handleArticleRemove = (index: number) => () => {
		if (onChange) {
			const updatedArticles = [...articles.filter((article, i) => i !== index)]
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, articles: updatedArticles } }
			})
		}
	}

	return readOnly ? (
		<Box className='be-pinnedarticle' sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
			<Grid container sx={{ maxWidth: '80%', border: '2px solid #e6e6e6' }}>
				{articles.map((article, index) => (
					<Grid item onClick={() => navigate(articleUrl(article.articleId))} xs={6} key={`${block.id}_${index}`} sx={{ display: 'flex', borderRight: (index + 1) % 2 ? '2px solid #e6e6e6' : '', borderTop: index > 1 ? '2px solid #e6e6e6' : '', cursor: 'pointer' }}>
						{!!article.imageId && (
							<Box>
								<Box component='img' sx={{ width: '4rem', height: '4rem' }} src={imageUrl(article.imageId)} alt={article.title} />
							</Box>
						)}
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Box sx={{ fontWeight: 'bold' }}>
								<Markdown children={article.title} />
							</Box>
							<Markdown children={article.description} />
						</Box>
					</Grid>
				))}
			</Grid>
		</Box>
	) : (
		<Box className='be-pinnedarticle'>
			<Button onClick={handleArticleAdd}>Add</Button>
			{articles.map((article, index) => {
				const { articleId, title, description, imageId } = article
				return (
					<Box key={`${block.id}_${index}`}>
						<Box sx={{
							'display': 'flex',
							'position': 'relative',
							'&:hover': {
								'& .action': {
									display: 'block'
								}
							}
						}}>
							<Box className='action' sx={{ display: 'none', position: 'absolute', top: '1rem', left: '1rem' }}>
								<IconButton sx={{ width: '2rem', height: '2rem', backgroundColor: '#e6e6e6' }} onClick={() => setShowDialogForIndex(index)}><EditIcon /></IconButton>
							</Box>
							{imageId ? (
								<Box component='img' sx={{ width: '4rem', height: '4rem' }} src={imageUrl(imageId)} alt={title} />
							) : (
								<Box sx={{ width: '4rem', height: '4rem', border: '1px solid #e6e6e6' }} />
							)}
						</Box>
						<TextField value={articleId || ''} onChange={e => handleArticleIdChange(index)(e.target.value)}/>
						<EditorText
							tagName={'strong'}
							content={ReactDOMServer.renderToStaticMarkup(<Markdown children={title} />)}
							onChange={handleTitleChange(index)}
						/>
						<EditorText
							content={ReactDOMServer.renderToStaticMarkup(<Markdown children={description} />)}
							onChange={handleDescriptionChange(index)}
						/>
						<Button onClick={handleArticleRemove(index)}>Remove</Button>
					</Box>
				)
			})}
			{showDialogForIndex >= 0 && (
				<Dialog
					open={showDialogForIndex >= 0}
					onClose={handleClose}
				>
					<DialogTitle id="responsive-dialog-title">
						Image editor
					</DialogTitle>
					<DialogContent>
						<Box>Selected image</Box>
						<Box sx={{ width: '30rem', height: '18.75rem' }}>
							<Box component='img' src={imageUrl(selectedImageId)} alt={'Article Image'} sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
						</Box>
						<Box>Image gallery</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
							{(availableImageIds || []).map(imageId => (
								<Box key={imageId} sx={{ width: '8rem', height: '5rem', cursor: 'pointer' }} onClick={() => setSelectedImageId(imageId)}>
									<Box component='img' src={imageUrl(imageId)} sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
								</Box>
							))}
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '66%' }}>
							<UploadFile uploadCallback={uploadFile} uploadedImage={imageDownloadUrl} />
						</Box>
					</DialogContent>
					<DialogActions sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }}>
						<Button variant='contained' color='secondary' onClick={handleClose} autoFocus>
							Close
						</Button>
						<Button variant='contained' onClick={handleSaveImage} autoFocus>
							Save
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Box>
	)
}

export default PinnedArticleBlock
