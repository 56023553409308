import { Box, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { getFunctions, httpsCallable } from 'firebase/functions'

import { BlockItem } from '..'
import { DisplayProducts, InsertDataType, ProductIndexType, SearchTypeEnum, StatsType } from '../../../Products'
import InsertProducts from '../../../Products/InsertProducts'
import { ModeEnum } from '../../../Products/enums'

import { BlockComponentType } from './Block'

export interface ProductBlockItemData {
	displayMode?: ModeEnum,
	displayNumber?: number,
	displayPrices?: boolean,
	products?: Array<ProductIndexType>,
	query?: string,
	searchType: SearchTypeEnum
}

export const productValue: Omit<BlockItem<ProductBlockItemData>, 'id'> = {
	type: 'product',
	data: {
		searchType: SearchTypeEnum.livesearch
	}
}

const ProductBlock: BlockComponentType<ProductBlockItemData> = ({ block, readOnly = false, onChange }) => {
	const { searchType, query, displayMode, displayNumber, products, displayPrices } = block.data
	const [showInsertProductsDialog, setShowInsertProductsDialog] = useState(!query)

	const handleInsertProducts = (data: Partial<InsertDataType>) => {
		setShowInsertProductsDialog(false)
		if (onChange) {
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, ...data } }
			})
		}
	}

	const [liveProducts, setLiveProducts] = useState<Array<ProductIndexType>>()
	useEffect(() => {
		setLiveProducts(undefined)
		const getLiveProducts = async (query: string) => {
			const functions = getFunctions(undefined, 'australia-southeast1')
			// TODO: move the types and the function name into a shared package
			const { data } = await httpsCallable<{ query: string }, { success: boolean, results?: Array<ProductIndexType>, stats?: StatsType, error?: string }>(functions, 'searchProducts')({ query })
			if (data.success) {
				setLiveProducts(data.results)
			} else {
				console.error('Error calling searchProducts', data.error)
			}
		}
		if (readOnly && query) {
			getLiveProducts(query)
		}
	}, [query, readOnly])

	const readOnlyProducts = searchType === SearchTypeEnum.livesearch ? liveProducts : products

	if (readOnly) {
		if (displayMode && readOnlyProducts?.length) {
			return (
				<Box className='be-product' sx={{ display: 'flex', justifyContent: 'center', mb: displayMode === ModeEnum.gallery ? 3 : 1 }}>
					<Box sx={{ maxWidth: '80%' }}>
						<DisplayProducts displayMode={displayMode} products={readOnlyProducts} displayNumber={displayNumber || -1} displayPrices={displayPrices !== undefined ? displayPrices : true} />
					</Box>
				</Box>
			)
		}
		return null
	}

	return (
		<Box className='be-product'>
			{searchType === SearchTypeEnum.livesearch && (
				<Box>
					<Box>Live Search</Box>
					{!!query && (
						<Box>{displayMode} of {displayNumber !== -1 ? displayNumber : 'All'} "{query}" results, {displayPrices ? 'with prices' : 'without prices'}</Box>
					)}
				</Box>
			)}
			{searchType === SearchTypeEnum.selectproducts && (
				<Box>
					<Box>Select Products</Box>
					{!!products && (
						<Box>{displayMode} of {products.length} selected "{query}" results, {displayPrices ? 'with prices' : 'without prices'}</Box>
					)}
				</Box>
			)}
			<Button variant='contained' onClick={() => setShowInsertProductsDialog(true)}>Configure</Button>
			{!!showInsertProductsDialog && (
				<InsertProducts
					data={block.data}
					showDialog
					onClose={() => setShowInsertProductsDialog(false)}
					onInsert={handleInsertProducts}
				/>
			)}
		</Box>
	)
}

export default ProductBlock
