import ReactDOMServer from 'react-dom/server'
import { NodeHtmlMarkdown } from 'node-html-markdown'

import Markdown from '../../utilities/Markdown'
import ListStyles from '../enums/ListStyles'
import { EditorList } from '../Editors/EditorList'
import { BlockItem } from '..'

import { BlockComponentType } from './Block'

export interface ListBlockItemData {
	style: ListStyles,
	items: Array<string>
}
export const listValue: Omit<BlockItem<ListBlockItemData>, 'id'> = {
	type: 'list',
	data: {
		style: ListStyles.Unordered,
		items: [
			'List item text'
		]
	}
}

const ListBlock: BlockComponentType<ListBlockItemData> = ({ block, readOnly = false, onChange }) => {
	const style: ListStyles = block.data.style as ListStyles
	const items: string[] = block.data.items || []

	const list = style === ListStyles.Unordered ?
		<ul>{items.map(item => <li key={item}><Markdown children={item} /></li>)}</ul> :
		<ol>{items.map(item => <li key={item}><Markdown children={item} /></li>)}</ol>

	const handleChange = ({ doc }: { doc: string }) => {
		if (onChange) {
			const parser = new DOMParser()
			const listDocument = parser.parseFromString(doc, 'text/html')
			const lis = listDocument.querySelectorAll('li')
			const items = Array.from(lis).map(li => NodeHtmlMarkdown.translate(li.innerHTML))

			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, items } }
			})
		}
	}

	//  TODO: Use list styles in the editor, ie: allow Ordered, Unordered, Definition.
	return readOnly ?
		list :
		<EditorList content={ReactDOMServer.renderToStaticMarkup(<>{items.map(item => <li key={item}><Markdown children={item} /></li>)}</>)} onChange={handleChange} />
}

export default ListBlock
