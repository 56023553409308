import { useState, useRef, useContext, useEffect } from 'react'
import { Box } from '@mui/system'
import { Button } from '@mui/material'
import { BlockEditor, BlockItem, BlockRenderer, initialiseBlocks, ListStyles } from 'kitfit-community-common'

import HeaderContext from './context/headerContext'

const blocksData: Array<BlockItem> = [
	...initialiseBlocks(['topheader', 'search', 'pinnedarticle', 'populararticle', 'popularquestion']),
	{
		'type': 'product',
		'data': {
			'searchType': 'livesearch',
			'query': 'grinder',
			'displayNumber': 3,
			'displayPrices': true,
			'displayMode': 'List'
		},
		'id': 'b9c25bd0-82c8-4030-92cf-32c7363dc850'
	}
]

//	Just for testing
const _blocksData: Array<BlockItem> = [ // eslint-disable-line @typescript-eslint/no-unused-vars
	{
		id: 'abc123',
		type: 'header',
		data: {
			level: 2,
			text: 'Hello there.'
		}
	},
	{
		id: 'abc234',
		type: 'paragraph',
		data: {
			text: 'Hello world.'
		}
	},
	{
		id: 'abc345',
		type: 'list',
		data: {
			style: ListStyles.Unordered,
			items: [
				'It is a block-styled editor',
				'It returns *clean* data output in JSON',
				'Designed to be extendable and pluggable with a simple API'
			]
		}
	},
	{
		id: 'abc456',
		type: 'image',
		data: {
			url: 'https://www.tesla.com/tesla_theme/assets/img/_vehicle_redesign/roadster_and_semi/roadster/hero.jpg',
			caption: 'Roadster',
			border: true,
			background: false,
			fullwidth: true
		}
	}
]

const EditorDemo = () => {
	const headerContext = useRef(useContext(HeaderContext))
	useEffect(() => {
		headerContext.current.setHeader('Editor demo')
	}, [])

	const [blocks, setBlocks] = useState(blocksData)
	const [readOnly, setReadOnly] = useState(false)

	return (
		<Box>
			<Button onClick={() => setReadOnly(readOnly => !readOnly)}>ReadOnly</Button>
			{readOnly ? (
				<BlockRenderer
					blocks={blocks}
				/>
			) : (
				<BlockEditor
					blocks={blocks}
					setBlocks={(blocks) => {
						console.log('EditorDemo setBlocks', blocks)
						setBlocks(blocks)
					}}
					allowedBlockTypes={['header', 'image', 'list', 'paragraph', 'pinnedarticle', 'populararticle', 'popularquestion', 'product', 'search', 'topheader']}
				/>
			)}
		</Box>
	)
}

export default EditorDemo
