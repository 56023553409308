import { useRef, useContext, useEffect } from 'react'
import { Box, Paper } from '@mui/material'

import HeaderContext from './context/headerContext'
import TicketEditor from './components/TicketEditor'

const Ticket = () => {
	const headerContext = useRef(useContext(HeaderContext))
	useEffect(() => {
		//	TODO: Add filters, etc.
		headerContext.current.setHeader(<Box>Ticket</Box>)
	}, [])

	return (
		<Box sx={{ display: 'block', width: '100%' }} component={Paper}>
			<TicketEditor />
		</Box>
	)
}

export default Ticket
