//	Ref: https://github.com/csfrequency/react-firebase-hooks/tree/edab3f3f3b5ec01c8aafcc6096755dfcc69e4408/storage#useuploadfile
import { StorageError, StorageReference, uploadBytesResumable, UploadMetadata, UploadResult, UploadTaskSnapshot } from 'firebase/storage'
import { useMemo, useState } from 'react'

export type UploadFileHookType = [
	(
		storageRef: StorageReference,
		data: Blob | Uint8Array | ArrayBuffer,
		metadata?: UploadMetadata | undefined
	) => Promise<UploadResult | undefined>,
	boolean,
	UploadTaskSnapshot | undefined,
	StorageError | undefined
]

const UploadFileHook = () => {
	const [error, setError] = useState<StorageError>()
	const [uploading, setUploading] = useState<boolean>(false)
	const [snapshot, setSnapshot] = useState<UploadTaskSnapshot>()

	const uploadFile = async (
		storageRef: StorageReference,
		data: Blob | Uint8Array | ArrayBuffer,
		metadata?: UploadMetadata | undefined
	): Promise<UploadResult | undefined> => {
		return new Promise((resolve, reject) => {
			setUploading(true)
			setError(undefined)
			const uploadTask = uploadBytesResumable(storageRef, data, metadata)
			uploadTask.on(
				'state_changed',
				(snapshot) => {
					setSnapshot(snapshot)
				},
				(error) => {
					setUploading(false)
					setError(error)
					resolve(undefined)
				},
				() => {
					setUploading(false)
					setSnapshot(undefined)
					resolve({
						metadata: uploadTask.snapshot.metadata,
						ref: uploadTask.snapshot.ref
					})
				}
			)
		})
	}

	const resArray: UploadFileHookType = [uploadFile, uploading, snapshot, error]
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo<UploadFileHookType>(() => resArray, resArray)
}

export default UploadFileHook
