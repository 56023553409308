import { useEffect, useRef, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, MenuItem, TextField, Select, FormControl, InputLabel, Checkbox, FormControlLabel, ButtonGroup, IconButton } from '@mui/material'
import Search from '@mui/icons-material/Search'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Delete } from '@mui/icons-material'

import { InsertDataType, ProductIndexType, StatsType } from './types'
import { ModeEnum, SearchTypeEnum, StepsEnum } from './enums'
import DisplayProducts from './DisplayProducts'

interface InsertProductsProps {
	showDialog: boolean,
	onClose: () => void,
	onInsert: (data: Partial<InsertDataType>) => void,
	data?: Partial<InsertDataType>
}

const InsertProducts: React.FC<InsertProductsProps> = ({ showDialog, onClose, onInsert, data }) => {
	const [insertProductsDialogStep, setInsertProductsDialogStep] = useState<StepsEnum>(StepsEnum.search)
	const [insertProductsDialogSearchType, setInsertProductsDialogSearchType] = useState(data?.searchType || SearchTypeEnum.livesearch)
	const [query, setQuery] = useState<string>(data?.query || '')
	const [results, setResults] = useState<Array<ProductIndexType>>([])
	const [recentSearches, setRecentSearches] = useState<Array<{ query: string, resultsLength: number }>>([])
	const [error, setError] = useState<string>()
	const [stats, setStats] = useState<StatsType>()
	const [products, setProducts] = useState<Array<ProductIndexType>>(data?.products || [])

	const [displayNumber, setDisplayNumber] = useState(data?.displayNumber || -1)
	const [displayPrices, setDisplayPrices] = useState(data?.displayPrices !== undefined ? data.displayPrices : true)
	const [displayMode, setDisplayMode] = useState(data?.displayMode || ModeEnum.list)

	const handleInsertProductsDialogClose = () => {
		setQuery('')
		setResults([])
		setProducts([])
		setError(undefined)
		setStats(undefined)
		setInsertProductsDialogStep(StepsEnum.search)
		onClose()
	}

	const handleInsertProductsLiveSearchNext = () => {
		setProducts(results)
		setInsertProductsDialogSearchType(SearchTypeEnum.livesearch)
		setInsertProductsDialogStep(StepsEnum.display)
	}

	const handleInsertProductsSelectProductsNext = () => {
		setInsertProductsDialogSearchType(SearchTypeEnum.selectproducts)
		setInsertProductsDialogStep(StepsEnum.display)
	}

	const handleInsertProductsSelectProductsSelect = (product: ProductIndexType) => {
		setProducts(products => [...products.filter(p => p.path !== product.path), product])
	}

	const handleInsertProductsSelectProductsRemove = (product: ProductIndexType) => {
		setProducts(products => [...products.filter(p => p.path !== product.path)])
	}

	const handleInsertProductsDisplayInsert = () => {
		if (insertProductsDialogSearchType === SearchTypeEnum.livesearch) {
			onInsert({ products: undefined, query, searchType: insertProductsDialogSearchType, displayNumber, displayPrices, displayMode })
		} else {
			onInsert({ products, query, searchType: insertProductsDialogSearchType, displayNumber: undefined, displayPrices, displayMode })
		}
		handleInsertProductsDialogClose()
	}

	const handleSearch = async (newQuery?: string) => {
		setResults([])
		setStats(undefined)
		setError(undefined)
		const theQuery = newQuery || query
		if (!theQuery) {
			return
		}
		try {
			if (newQuery) {
				setQuery(newQuery)
			}
			const functions = getFunctions(undefined, 'australia-southeast1')
			// TODO: move the types and the function name into a shared package
			const { data } = await httpsCallable<{ query: string }, { success: boolean, results?: Array<ProductIndexType>, stats?: StatsType, error?: string }>(functions, 'searchProducts')({ query: theQuery })
			if (data.success) {
				setResults(data.results || [])
				const newRecentSearches = [{ query: theQuery, resultsLength: data.results?.length || 0 }, ...(recentSearches || []).splice(0, 4)]
				setRecentSearches(newRecentSearches)
			} else {
				setError(data.error || 'error')
			}
			setStats(data.stats)
		} catch (error) {
			setError(`${error}`)
		}
	}

	const searchOnLoad = useRef(() => {
		if (insertProductsDialogSearchType === SearchTypeEnum.livesearch && query) {
			handleSearch()
		}
	})

	useEffect(() => {
		searchOnLoad.current()
	}, [])

	if (!showDialog) {
		return null
	}

	return (
		<>
			{insertProductsDialogStep === StepsEnum.search && insertProductsDialogSearchType === SearchTypeEnum.livesearch && (
				<Dialog
					open={insertProductsDialogStep === StepsEnum.search && insertProductsDialogSearchType === SearchTypeEnum.livesearch}
					onClose={handleInsertProductsDialogClose}
				>
					<DialogTitle id="responsive-dialog-title">
						Insert Products
					</DialogTitle>
					<DialogContent>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Box>Enter a search term for the products you would like to see</Box>
							<Box sx={{ display: 'flex', flexDirection: 'row' }}>
								<TextField
									placeholder="Search"
									size="small"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Search />
											</InputAdornment>
										)
									}}
									value={query}
									onChange={event => setQuery(event.target.value)}
									onKeyDown={(event) => {
										if (event.key === 'Enter') {
											handleSearch()
										}
									}}
								/>
								<Button variant="outlined" onClick={() => handleSearch()} sx={{ marginLeft: 1 }}>
									Search
								</Button>
								<Button variant='text' onClick={() => setInsertProductsDialogSearchType(SearchTypeEnum.selectproducts)}>Select individual products instead</Button>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								{!query && !!recentSearches && recentSearches.map((recentSearch, i) => (
									<Box sx={{ border: '1px solid #000', display: 'flex', p: 0.5 }} key={i} onClick={() => {
										handleSearch(recentSearch.query)
									}}>
										{recentSearch.query} - {recentSearch.resultsLength} result{recentSearch.resultsLength !== 1 && 's'}
									</Box>
								))}
							</Box>
							<Box sx={{ display: 'flex' }}>
								{!!error && error}
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								{!!results && results.length === 0 && (
									<div>No Results</div>
								)}
								{!!results && results.length > 0 && (
									<div>{`${results.length} result${results.length !== 1 ? 's' : ''}`}</div>
								)}
								{!!stats && stats.nonexhaustiveSearch && !stats.resultsLimited && (
									<div>(There may be more results, use less common words in the query)</div>
								)}
								{!!stats && stats.resultsLimited && (
									<div>(There were more results)</div>
								)}
								{!!results && results.map(result => (
									<Box sx={{ border: '1px solid #000', borderRadius: '5px', display: 'flex', p: 0.5 }} key={result.path}>
										<Box sx={{ mr: 1 }}>
											<Box component='img' src={result.leadImage} sx={{ maxWidth: '5rem', maxHeight: '5rem' }} />
										</Box>
										<Box sx={{ display: 'flex', flexDirection: 'column' }}>
											<Box sx={{ fontWeight: 'bold' }}>{result.name}</Box>
											<Box>{result.description}</Box>
										</Box>
									</Box>
								))}
							</Box>
						</Box>
					</DialogContent>
					<DialogActions sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }}>
						<Button variant='contained' color='secondary' onClick={handleInsertProductsDialogClose} autoFocus>
							Cancel
						</Button>
						<Button variant='contained' disabled={!results?.length} onClick={handleInsertProductsLiveSearchNext} autoFocus>
							Next Step
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{insertProductsDialogStep === StepsEnum.search && insertProductsDialogSearchType === SearchTypeEnum.selectproducts && (
				<Dialog
					open={insertProductsDialogStep === StepsEnum.search && insertProductsDialogSearchType === SearchTypeEnum.selectproducts}
					onClose={handleInsertProductsDialogClose}
				>
					<DialogTitle id="responsive-dialog-title">
						Insert Individual Products
					</DialogTitle>
					<DialogContent>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Box>Search for the products you would like to use</Box>
							<Box sx={{ display: 'flex', flexDirection: 'row' }}>
								<TextField
									placeholder="Search"
									size="small"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Search />
											</InputAdornment>
										)
									}}
									value={query}
									onChange={event => setQuery(event.target.value)}
									onKeyDown={(event) => {
										if (event.key === 'Enter') {
											handleSearch()
										}
									}}
								/>
								<Button variant="outlined" onClick={() => handleSearch()} sx={{ marginLeft: 1 }}>
									Search
								</Button>
								<Button variant='text' onClick={() => setInsertProductsDialogSearchType(SearchTypeEnum.livesearch)}>Use live search instead</Button>
							</Box>
							<Box sx={{ display: 'flex' }}>
								{!!products && products.map(product => (
									<Box sx={{ border: '1px solid #000', borderRadius: '5px', p: 0.5, minWidth: '5rem', minHeight: '5rem' }} key={product.path}>
										<IconButton onClick={() => handleInsertProductsSelectProductsRemove(product)}><Delete /></IconButton>
										<Box component='img' src={product.leadImage} sx={{ maxWidth: '5rem', maxHeight: '5rem' }} />
									</Box>
								))}
							</Box>
							<Box sx={{ display: 'flex' }}>
								{!!error && error}
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								{!!results && results.length === 0 && (
									<div>No Results</div>
								)}
								{!!results && results.length > 0 && (
									<div>{`${results.length} result${results.length !== 1 ? 's' : ''}`}</div>
								)}
								{!!stats && stats.nonexhaustiveSearch && !stats.resultsLimited && (
									<div>(There may be more results, use less common words in the query)</div>
								)}
								{!!stats && stats.resultsLimited && (
									<div>(There were more results)</div>
								)}
								{!!results && results.map(result => (
									<Box sx={{ border: '1px solid #000', borderRadius: '5px', display: 'flex', p: 0.5 }} key={result.path}>
										<Box sx={{ mr: 1 }}>
											<Box component='img' src={result.leadImage} sx={{ maxWidth: '5rem', maxHeight: '5rem' }} />
										</Box>
										<Box sx={{ display: 'flex', flexDirection: 'column' }}>
											<Box sx={{ fontWeight: 'bold' }}>{result.name}</Box>
											<Box>{result.description}</Box>
										</Box>
										<Box sx={{ mr: 1 }}>
											<Button onClick={() => handleInsertProductsSelectProductsSelect(result)}>Select</Button>
										</Box>
									</Box>
								))}
							</Box>
						</Box>
					</DialogContent>
					<DialogActions sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }}>
						<Button variant='contained' color='secondary' onClick={handleInsertProductsDialogClose} autoFocus>
							Cancel
						</Button>
						<Button variant='contained' disabled={!products?.length} onClick={handleInsertProductsSelectProductsNext} autoFocus>
							Next Step
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{insertProductsDialogStep === StepsEnum.display && (
				<Dialog
					open={!!insertProductsDialogStep}
					onClose={handleInsertProductsDialogClose}
				>
					<DialogTitle id="responsive-dialog-title">
						Product Display
					</DialogTitle>
					<DialogContent>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Box>Choose how your product(s) will be displayed</Box>
							<FormControl>
								<InputLabel>How many products to display</InputLabel>
								<Select size='small' label='How many products to display' value={displayNumber <= products.length ? displayNumber : products.length} onChange={e => setDisplayNumber(e.target.value as number)}>
									<MenuItem value={-1}>All products</MenuItem>
									{(products || []).map((_, i) => {
										const num = i + 1
										return (
											<MenuItem key={num} value={num}>{`${num} product${num > 1 ? 's' : ''}`}</MenuItem>
										)
									})}
								</Select>
							</FormControl>
							<FormControlLabel control={<Checkbox checked={displayPrices} onChange={() => setDisplayPrices(displayPrices => !displayPrices)}/>} label='Display prices' />
							<ButtonGroup size="small">
								<Button variant={displayMode === ModeEnum.list ? 'contained' : 'outlined'} onClick={() => setDisplayMode(ModeEnum.list)}>List</Button>
								<Button variant={displayMode === ModeEnum.gallery ? 'contained' : 'outlined'} onClick={() => setDisplayMode(ModeEnum.gallery)}>Gallery</Button>
							</ButtonGroup>
							<DisplayProducts displayMode={displayMode} products={products} displayNumber={displayNumber} displayPrices={displayPrices} />
						</Box>
					</DialogContent>
					<DialogActions sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }}>
						<Button variant='contained' color='secondary' onClick={() => setInsertProductsDialogStep(StepsEnum.search)} autoFocus>
							Back
						</Button>
						<Button variant='contained' onClick={handleInsertProductsDisplayInsert} autoFocus>
							Insert
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	)
}

export default InsertProducts
