import { SyntheticEvent, ReactNode, useState } from 'react'
import { Box, InputAdornment, Tab, Tabs, TextField, Paper } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import SmileysIcon from '@mui/icons-material/InsertEmoticon'
import PeopleIcon from '@mui/icons-material/Groups'
import AnimalNatureIcon from '@mui/icons-material/Pets'
import FoodIcon from '@mui/icons-material/LunchDining'
import TravelIcon from '@mui/icons-material/Flight'
import ActivityIcon from '@mui/icons-material/SportsSoccer'
import ObjectsIcon from '@mui/icons-material/EmojiObjects'
import SymbolsIcon from '@mui/icons-material/Token'
import FlagsIcon from '@mui/icons-material/EmojiFlags'
import { distance } from 'fastest-levenshtein'

import emojis from '../utilities/emojis'

const niceCategoryText = (text: string) => {
	// 				category = tr.querySelector('th').innerText.split(' ').join('-').split('&').join('and').toLowerCase()
	let result = text.split('-').join(' ').split(' and ').join(' & ').toLowerCase()
	result = result.charAt(0).toUpperCase() + result.substring(1)
	return result
}

const CategoryIcons: { [key: string]: any } = {
	'smileys-and-emotion': SmileysIcon,
	'people-and-body': PeopleIcon,
	'animals-and-nature': AnimalNatureIcon,
	'food-and-drink': FoodIcon,
	'travel-and-places': TravelIcon,
	'activities': ActivityIcon,
	'objects': ObjectsIcon,
	'symbols': SymbolsIcon,
	'flags': FlagsIcon
}

const getCategories = () => {
	const categoryObj: { [key: string]: string } = {}
	Object.keys(emojis).forEach((key: string) => {
		const { category } = emojis[key]
		categoryObj[category] = category
	})
	return Object.keys(categoryObj)
}

const categories = getCategories()

const getEmojisByCategory = (categoryName: string) => {
	const emojisArray: string[] = []
	Object.keys(emojis).forEach((key: string) => {
		const { category } = emojis[key]
		if (category === categoryName) {
			emojisArray.push(emojis[key])
		}
	})
	return emojisArray
}

interface TabPanelProps {
	children?: ReactNode,
	index: number,
	value: number
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	)
}

const EmojiSelector = (props: any): JSX.Element => {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const { onSelect = () => { } } = props
	const [tabValue, setTabValue] = useState<number>(0)
	const [searchQuery, setSearchQuery] = useState<string>('')
	const [searchResults, setSearchResults] = useState<any[]>([])
	const [resultHeader, setResultHeader] = useState<string>(niceCategoryText(categories[0]))

	const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
		setSearchResults([])
		setSearchQuery('')
		setTabValue(newValue)
		setResultHeader(niceCategoryText(categories[newValue]))
	}

	const handleSearch = (queryValue: string) => {
		if (!queryValue) {
			setSearchQuery('')
			setSearchResults([])
			return
		}
		setResultHeader('Search results')
		setSearchQuery(queryValue)
		const query = queryValue.toLowerCase()
		const results: Array<any> = []
		const minDist = 2
		Object.keys(emojis).forEach((key: string, index: number) => {
			const emo = emojis[key]
			//	Build array and string for efficient searches - TODO: Could move this for performance
			const searchDescriptionString = emo.description.toLowerCase()
			const searchArray = [...emo.category.split('-'), ...emo.subCategory.split('-'), ...searchDescriptionString.split(' ')]
			if ((query.length > 1 && searchArray.some((word: string) => distance(query, word) < minDist)) ||
				searchArray.indexOf(query) !== -1 ||
				searchDescriptionString.indexOf(query) !== -1
			) {
				results.push(emo)
			}
		})
		setSearchResults(results)
	}

	const handleClearSearch = () => {
		setSearchQuery('')
		setResultHeader(niceCategoryText(categories[tabValue]))
	}

	return (<Box component={Paper} sx={{ p: 2, display: 'flex', flexDirection: 'column', width: '22rem', gap: 0.5, border: '1px solid #ccc', fontSize: '1.25rem' }}>
		<Box>
			<Tabs value={tabValue} onChange={handleChangeTab} sx={{ mb: 1 }}>
				{categories.map((cat: string) => {
					const CategoryIcon = CategoryIcons[cat]
					const title = niceCategoryText(cat)
					return <Tab key={cat} label={<CategoryIcon titleAccess={title} />} style={{ minWidth: '1rem', padding: '0.33rem' }} />
				})}
			</Tabs>
		</Box>
		<Box>
			<Box sx={{ pb: 1 }}>
				<TextField
					placeholder="Search"
					size="small"
					value={searchQuery}
					InputProps={{
						sx: { minWidth: '20rem' },
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<ClearIcon sx={{ cursor: 'pointer' }} onClick={handleClearSearch} />
							</InputAdornment>
						)
					}}
					onChange={e => handleSearch(e.target.value || '')}
				/>
			</Box>
		</Box>
		<Box sx={{ fontWeight: 'bold', flex: 2 }}>{resultHeader}</Box>
		{searchQuery && searchResults.length > 0 && (
			<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, height: '16rem', overflow: 'hidden', overflowY: 'scroll' }}>
				{/* {JSON.stringify(searchResults, null, 4)} */}
				{searchResults.map(emo => (
					<Box
						onClick={() => onSelect(emo)}
						key={emo.unicode}
						title={emo.description}
						sx={{ cursor: 'pointer' }}
					>
						{emo.character}
					</Box>
				))}
			</Box>
		)}
		{searchQuery && !(searchResults.length > 0) && (
			<Box>No matches for <strong>{searchQuery}</strong></Box>
		)}
		{!searchQuery && categories.map((cat: string, index: number) => {
			const myEmojis = getEmojisByCategory(cat)
			return (
				<TabPanel key={cat} value={tabValue} index={index}>
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, height: '16rem', overflow: 'hidden', overflowY: 'scroll' }}>
						{myEmojis.map((emo: any) => (
							<Box
								onClick={() => onSelect(emo)}
								key={emo.unicode}
								title={emo.description}
								sx={{ cursor: 'pointer' }}
							>
								{emo.character}
							</Box>
						))}
					</Box>
				</TabPanel>
			)
		})}
	</Box>)
}

export default EmojiSelector
