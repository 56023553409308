import { Box, Button } from '@mui/material'

import { ProductIndexType } from './types'

interface DisplayProductsListProps {
	products: Array<ProductIndexType>,
	displayNumber: number,
	displayPrices: boolean
}

const DisplayProductsList: React.FC<DisplayProductsListProps> = ({ products, displayNumber, displayPrices }) => {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			{!!products && products.slice(0, displayNumber > 0 ? displayNumber : undefined).map(product => (
				<Box sx={{ border: '1px solid #000', borderRadius: '5px', display: 'flex', p: 0.5 }} key={product.path}>
					<Box sx={{ mr: 1 }}>
						<Box component='img' src={product.leadImage} sx={{ maxWidth: '10rem', maxHeight: '10rem' }} />
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<Box sx={{ fontWeight: 'bold' }}>{product.name}</Box>
						<Box>{product.description}</Box>
						<Box sx={{ display: 'flex' }}>
							{displayPrices && (
								<>
									<Box>{product.currency}</Box>
									<Box>{product.price}</Box>
								</>
							)}
							<Box sx={{ flex: 1 }}>&nbsp;</Box>
							<Button variant='contained' target='_blank' rel='noopener noreferrer' href={product.buyUrl}>Buy Now</Button>
						</Box>
					</Box>
				</Box>
			))}
		</Box>

	)
}

export default DisplayProductsList
