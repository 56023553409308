import { useContext, useEffect, useState } from 'react'
import { Box, Button, Typography, Link } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import OnboardingContext from '../context/onboardingContext'

const Congratulations = () => {
	const { onboarding, completeStep } = useContext(OnboardingContext)
	const [copied, setCopied] = useState<boolean>(false)

	const link = `https://p1.io/${onboarding?.communityId}`

	useEffect(() => {
		if (!copied) {
			return
		}
		const copiedTimer = setTimeout(() => {
			setCopied(false)
		}, 2000)
		return () => clearTimeout(copiedTimer)
	}, [copied])

	if (!onboarding) {
		return null
	}

	const handleCongratulations = async () => {
		completeStep({ step: 'congratulations' })
	}

	return (
		<Box sx={{ flex: 1, boxShadow: '0px 10px 10px rgb(0 0 0 / 10%)', borderRadius: '0.5rem' }}>
			<Box sx={{ backgroundColor: '#6AA84F', color: '#FFF', height: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }}>
				{onboarding.logoDownloadUrl ? (
					<Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
						<Box sx={{ maxHeight: '8rem' }} component='img' alt='logo' src={onboarding.logoDownloadUrl} />
					</Box>
				) : (
					<Typography variant='h2' sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>{onboarding.name}</Typography>
				)}
				<Box sx={{ mb: 1 }}>{onboarding.name} Community Portal</Box>
			</Box>
			<Box sx={{ backgroundColor: '#FFF', minHeight: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderBottomLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }}>
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '66%' }}>
					<Box sx={{ mb: 4 }}>Congratulations! Your new community portal is ready!</Box>
					<Box sx={{ fontWeight: 'bold', fontSize: '2rem', mb: 1 }}>
						<Link href={link} target='_blank' rel='noopener noreferrer'>{link}</Link>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2 }}>
						<CopyToClipboard text={link} onCopy={() => setCopied(true)}>
							<Button variant="outlined" sx={{}}>{copied ? 'Copied!' : 'Copy link'}</Button>
						</CopyToClipboard>
					</Box>
					<Button sx={{ mt: 4, mb: 1 }} onClick={handleCongratulations} fullWidth variant='contained'>Continue <ArrowForward fontSize='small' sx={{ ml: 1 }} /></Button>
				</Box>
			</Box>
		</Box>
	)
}

export default Congratulations
