import React, { ReactNode } from 'react'

interface HeaderInterface {
	header?: ReactNode,
	setHeader: React.Dispatch<React.SetStateAction<ReactNode>>
}

const HeaderContext = React.createContext<HeaderInterface>({ header: undefined, setHeader: () => undefined })

export default HeaderContext
