import { useContext, useState } from 'react'
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import { ArrowForward, Info } from '@mui/icons-material'

import OnboardingContext from '../context/onboardingContext'
import { useAdminCommunityContext } from '../context/AdminCommunityContext'

const Colour = () => {
	const { onboarding, completeStep } = useContext(OnboardingContext)
	const [colourScheme, setColourScheme] = useState<string>(onboarding?.colourScheme || 'standard')

	const { community } = useAdminCommunityContext()

	if (!onboarding) {
		return null
	}

	const handleColour = async () => {
		completeStep({ step: 'colour', colourScheme })
	}

	const selectedStyle = { '&.MuiButton-root': { border: '3px #2B78E4 solid' } }

	return (
		<Box sx={{ flex: 1, boxShadow: '0px 10px 10px rgb(0 0 0 / 10%)', borderRadius: '0.5rem' }}>
			<Box sx={{ backgroundColor: '#6AA84F', color: '#FFF', height: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }}>
				{onboarding.logoDownloadUrl ? (
					<Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
						<Box sx={{ maxHeight: '8rem' }} component='img' alt='logo' src={community ? `https://${community.imageHostname}/${community.communityId}/logo.png` : ''} />
					</Box>
				) : (
					<Typography variant='h2' sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>{onboarding.name}</Typography>
				)}
				<Box sx={{ mb: 1 }}>{onboarding.name} Community Portal</Box>
			</Box>
			<Box sx={{ backgroundColor: '#FFF', minHeight: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderBottomLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }}>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2, mt: 2 }}>
					<Box>Lastly, let us choose a colour scheme </Box>
					<Tooltip title='The colours are used throughout your community, and can be adjusted at any time to suit'>
						<IconButton>
							<Info sx={{ color: '#2B78E4' }} fontSize='small' />
						</IconButton>
					</Tooltip>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '66%' }}>
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 1, gap: 1 }}>
						<Button variant='outlined' sx={{ display: 'flex', flexDirection: 'column', ...(colourScheme === 'colourful' ? selectedStyle : {}) }} onClick={() => setColourScheme('colourful')}>
							<Box sx={{ width: '100%', height: '4rem' }} component='img' alt='colourful' src='/images/colourful.png' />
							<Box>Colourful</Box>
						</Button>
						<Button variant='outlined' sx={{ display: 'flex', flexDirection: 'column', ...(colourScheme === 'standard' ? selectedStyle : {}) }} onClick={() => setColourScheme('standard')}>
							<Box sx={{ width: '100%', height: '4rem' }} component='img' alt='standard' src='/images/standard.png' />
							<Box>Standard</Box>
						</Button>
						<Button variant='outlined' sx={{ display: 'flex', flexDirection: 'column', ...(colourScheme === 'professional' ? selectedStyle : {}) }} onClick={() => setColourScheme('professional')}>
							<Box sx={{ width: '100%', height: '4rem' }} component='img' alt='professional' src='/images/professional.png' />
							<Box>Professional</Box>
						</Button>
					</Box>
					<Typography sx={{ color: '#E4E0E4', fontSize: '0.8rem', mt: 0.5 }}>(Don't worry, you can customise this later)</Typography>
					<Button sx={{ mt: 4, mb: 1 }} onClick={handleColour} fullWidth variant='contained'>Continue <ArrowForward fontSize='small' sx={{ ml: 1 }} /></Button>
				</Box>
			</Box>
		</Box>
	)
}

export default Colour
