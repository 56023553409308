import { useRef, useContext, useEffect, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Button, Select, MenuItem, FormControl } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-translatify-next'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ArrowCircleUp, ArrowCircleDown, FilterAlt } from '@mui/icons-material'

import HeaderContext from './context/headerContext'
import { ArticlesColumnsEnum, useAdminCommunityContext } from './context/AdminCommunityContext'
import ArticleStatusEnum, { compareStatus } from './enums/ArticleStatusEnum'
import ArticleStatusSelector from './components/ArticleStatusSelector'

dayjs.extend(relativeTime)

const Articles = () => {
	const headerContext = useRef(useContext(HeaderContext))
	const { translate } = useTranslation()

	const { articles, updateArticle, createArticle, articleSortBy, setArticleSortBy, articleFilterStatus, setArticleFilterStatus } = useAdminCommunityContext()
	const [showFilters, setShowFilters] = useState(false)

	const navigate = useNavigate()

	useEffect(() => {
		const handleNewArticle = async () => {
			const articleId = await createArticle()
			if (articleId) {
				navigate(`/article/${articleId}`)
			}
		}

		headerContext.current.setHeader(
			<Box sx={{ flex: '1' }}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Box sx={{ mr: 2 }}>
							{translate('Articles')}
						</Box>
					</Box>
					<Box sx={{ flex: '1', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
						<Box>
							<Button sx={{ mr: 1 }} variant='contained' startIcon={<FilterAlt />} endIcon={showFilters ? <ArrowCircleUp /> : <ArrowCircleDown />} onClick={() => setShowFilters(showFilters => !showFilters)}>Filters</Button>
						</Box>
						<FormControl>
							<Select
								size='small'
								value={articleSortBy}
								onChange={e => setArticleSortBy(e.target.value as ArticlesColumnsEnum)}
								inputProps={{ sx: { display: 'flex', background: '#fff' } }}
							>
								<MenuItem value={ArticlesColumnsEnum.Title}>Sort by&nbsp;<strong>Title</strong></MenuItem>
								<MenuItem value={ArticlesColumnsEnum.Status}>Sort by&nbsp;<strong>Status</strong></MenuItem>
								<MenuItem value={ArticlesColumnsEnum.Comments}>Sort by&nbsp;<strong>Comments</strong></MenuItem>
							</Select>
						</FormControl>
						<Box sx={{ ml: 1 }}><Button variant='contained' onClick={handleNewArticle}>New Article</Button></Box>
					</Box>
				</Box>
				{showFilters && <Box component={Paper} sx={{ display: 'flex', mt: 1, p: 2 }}>
					<Box>
						<Box sx={{ fontSize: '0.8rem' }}>Statuses</Box>
						<ArticleStatusSelector sx={{ mr: 2 }} multiple value={articleFilterStatus} setValue={(status: any) => setArticleFilterStatus(status as Array<ArticleStatusEnum>)} />
					</Box>
				</Box>}
			</Box>
		)
	}, [articleFilterStatus, setArticleFilterStatus, setArticleSortBy, showFilters, articleSortBy, translate, createArticle, navigate])

	return (
		<Box sx={{ display: 'block', p: 0, width: '100%' }}>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow sx={{ backgroundColor: '#fafafa' }}>
							<TableCell sx={{ fontWeight: 'bold' }}>{translate('Title')}</TableCell>
							<TableCell sx={{ fontWeight: 'bold' }}>{translate('Status')}</TableCell>
							<TableCell sx={{ fontWeight: 'bold' }}>{translate('Comments')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{articles ? (
							Object.values(articles)
								.sort((a, b) => {
									switch (articleSortBy) {
										case ArticlesColumnsEnum.Comments:
											return a.countComments - b.countComments
										case ArticlesColumnsEnum.Status:
											return compareStatus(a.status, b.status)
										case ArticlesColumnsEnum.Title:
										default:
											return a.title.localeCompare(b.title)
									}
								})
								.map(article => (
									<TableRow
										key={article.articleId}
										sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'cursor': 'pointer', ':hover': { backgroundColor: '#f3f3f3' } }}
									>
										<TableCell component="th" scope="row">
											<Box component={Link} to={`/article/${article.articleId}`} sx={{ color: 'black', textDecoration: 'none' }}>
												<Box sx={{ fontWeight: 'bold' }}>{article.title}</Box>
												<Box sx={{ color: 'gray', fontSize: '0.8rem' }}>By <Box component='span' sx={{ fontWeight: 'bold' }}>{article.title}</Box> - {`${dayjs(article.time.toDate()).fromNow()}`}</Box>
											</Box>
										</TableCell>
										<TableCell><ArticleStatusSelector value={article.status} setValue={(status: any) => updateArticle({ articleId: article.articleId, status })} /></TableCell>
										<TableCell component="th" scope="row">
											<Box>{article.countComments}</Box>
										</TableCell>
									</TableRow>
								))
						) : (
							<TableRow>
								<TableCell colSpan={5}>
									<CircularProgress />
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}

export default Articles
