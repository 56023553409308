import PriorityHighIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import PriorityMediumIcon from '@mui/icons-material/DragHandle'
import PriorityLowIcon from '@mui/icons-material/KeyboardDoubleArrowDown'

import PriorityEnum from './PriorityEnum'

const PriorityStyles = {
	[PriorityEnum.LOW]: {
		Title: 'Low',
		Icon: PriorityLowIcon,
		Colour: 'blue'
	},
	[PriorityEnum.MEDIUM]: {
		Title: 'Medium',
		Icon: PriorityMediumIcon,
		Colour: 'green'
	},
	[PriorityEnum.HIGH]: {
		Title: 'High',
		Icon: PriorityHighIcon,
		Colour: 'red'
	}
}

export default PriorityStyles
