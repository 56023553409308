import { useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'

import UploadFile from '../../../UploadFile'
import { BlockItem } from '..'

import { BlockComponentType } from './Block'

export interface ImageBlockItemData {
	url: string,
	caption: string,
	border: boolean,
	background: boolean,
	fullwidth: boolean
}
export const imageValue: Omit<BlockItem<ImageBlockItemData>, 'id'> = {
	type: 'image',
	data: {
		url: '/images/standard.png',
		caption: 'Image caption',
		border: true,
		background: false,
		fullwidth: true
	}
}

const ImageBlock: BlockComponentType<ImageBlockItemData> = ({ block, readOnly = false, onChange }) => {
	const { url, caption, border = false, fullwidth = true } = block.data
	const [selectedImageUrl, setSelectedImageUrl] = useState(url)

	const [showDialog, setShowDialog] = useState(false)
	const handleClose = () => {
		setShowDialog(false)
	}
	const handleSave = () => {
		setShowDialog(false)
		if (onChange) {
			onChange({
				prevBlock: block,
				block: {
					...block,
					data: {
						...block.data,
						url: selectedImageUrl
					}
				}
			})
		}
	}
	const style = {
		width: (fullwidth ? '100%' : 'inherit'),
		...(border ? { border: '1px solid #ccc' } : {})
	}
	const image = <img className='be-image' src={url} alt={caption || url} style={style} />

	//	TODO: These need to be loaded from a set of uploaded images
	const [availableImages, setAvailableImages] = useState<string[]>([
		'https://www.tesla.com/tesla_theme/assets/img/_vehicle_redesign/roadster_and_semi/roadster/hero.jpg',
		'https://tesla-cdn.thron.com/delivery/public/image/tesla/8e2df1b9-a4bf-4eb9-beec-2cf5cc77fca0/bvlatuR/std/2880x2400/Desktop-ModelY?quality=70',
		'https://tesla-cdn.thron.com/delivery/public/image/tesla/ddc135ed-1638-40fb-8ab1-f8045059ecef/bvlatuR/std/4096x2560/Homepage-Model-X-Desktop-LHD'
	])

	const [, setImageStorageLocation] = useState<string>()
	const [imageDownloadUrl, setImageDownloadUrl] = useState<string>()

	const uploadFile = async (imageStorageLocation: string, imageDownloadUrl: string) => {
		console.log('uploadFile', { imageStorageLocation, imageDownloadUrl })
		setImageStorageLocation(imageStorageLocation)
		setImageDownloadUrl(imageDownloadUrl)
		setSelectedImageUrl(imageDownloadUrl)
		setAvailableImages([...availableImages, imageDownloadUrl])
	}

	if (readOnly) {
		return image
	}

	return (
		<Box sx={{
			'display': 'flex',
			'position': 'relative',
			'&:hover': {
				'& .action': {
					display: 'block'
				}
			}
		}}>
			<Box className='action' sx={{ display: 'none', position: 'absolute', top: '1rem', left: '1rem' }}>
				<IconButton sx={{ width: '2rem', height: '2rem', backgroundColor: '#e6e6e6' }} onClick={() => setShowDialog(true)}><EditIcon /></IconButton>
			</Box>
			{image}
			{showDialog && (
				<Dialog
					open={showDialog}
					onClose={handleClose}
				>
					<DialogTitle id="responsive-dialog-title">
						Image editor
					</DialogTitle>
					<DialogContent>
						<Box>Selected image</Box>
						<Box sx={{ width: '30rem', height: '18.75rem' }}>
							<Box component='img' src={selectedImageUrl} alt={caption || url} style={style} sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
						</Box>
						<Box>Image gallery</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
							{availableImages.map(imageSrc => (
								<Box key={imageSrc} sx={{ width: '8rem', height: '5rem', cursor: 'pointer' }} onClick={() => setSelectedImageUrl(imageSrc)}>
									<Box component='img' src={imageSrc} sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
								</Box>
							))}
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '66%' }}>
							<UploadFile uploadCallback={uploadFile} uploadedImage={imageDownloadUrl} />
						</Box>
					</DialogContent>
					<DialogActions sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }}>
						<Button variant='contained' color='secondary' onClick={handleClose} autoFocus>
							Close
						</Button>
						<Button variant='contained' onClick={handleSave} autoFocus>
							Save
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Box>
	)
}

export default ImageBlock
