import React from 'react'

interface ThemeInterface {
	theme?: boolean,
	setTheme: React.Dispatch<React.SetStateAction<boolean>>
}

const ThemeContext = React.createContext<ThemeInterface>({ theme: true, setTheme: () => undefined })

export default ThemeContext
