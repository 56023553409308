import React, { useEffect, useRef } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useParams } from 'react-router'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { BlockEditor, BlockItem } from 'kitfit-community-common'

import ArticleStatusEnum from '../enums/ArticleStatusEnum'
import ArticleStatusStyles from '../enums/ArticleStatusStyles'
import { useAdminCommunityContext } from '../context/AdminCommunityContext'

import PersonLine from './PersonLine'

dayjs.extend(relativeTime)

const articleSchema = yup.object({
	title: yup.string().required().matches(/^[A-Za-z0-9 '\-]+$/, 'Article title must only contain valid characters') // eslint-disable-line no-useless-escape -- this is not a useless escape
}).required()

export type ArticleType = yup.InferType<typeof articleSchema>

interface ArticleEditorProps {
	showTitle?: boolean
}

const ArticleEditor: React.FC<ArticleEditorProps> = (props) => {
	const { showTitle = true } = props

	const { articleId } = useParams()
	const { selectArticle, article, updateArticle } = useAdminCommunityContext()

	const { register, reset, handleSubmit, formState: { errors } } = useForm<ArticleType>({ defaultValues: { title: '' }, resolver: yupResolver(articleSchema) })
	const form = useRef<HTMLFormElement>(null)

	useEffect(() => {
		if (articleId && article?.articleId !== articleId) {
			selectArticle({ articleId })
		}
	}, [selectArticle, article?.articleId, articleId])

	useEffect(() => {
		if (article?.articleId === articleId) {
			reset({ title: article?.title })
		}
	}, [article?.articleId, article?.title, articleId, reset])

	if (!articleId || article?.articleId !== articleId) {
		return null
	}

	const setBlocks = (blocks: Array<BlockItem>) => {
		updateArticle({ articleId: article.articleId, blocks })
	}

	const saveArticle = async (data: ArticleType) => {
		updateArticle({ articleId: article.articleId, title: data.title })
	}

	const doSubmit = () => {
		if (form.current) {
			form.current.requestSubmit()
		}
	}

	return (
		<Box sx={{ display: 'flex', gap: '1px' }}>
			<Box sx={{ flex: 1, borderRight: '1px solid #cccccc' }}>
				<Box component="form" ref={form} onSubmit={handleSubmit(saveArticle)}>
					{showTitle && (
						<Box sx={{ pl: 2, display: 'flex', borderBottom: '1px solid #cccccc', backgroundColor: '#fafafa', height: '48px', alignItems: 'center', borderTopLeftRadius: '8px' }}>
							<Box sx={{ fontSize: '1.2rem', fontWeight: 'bold', flex: 1 }}>
								<TextField
									size="small"
									{...register('title', {
										onBlur: doSubmit
									})}
								/>
								{errors?.title?.message && <Typography component="div" sx={{ color: '#F00' }}>{errors.title.message}</Typography>}
							</Box>
							<Box>Status: {ArticleStatusStyles[article.status].Title} <Button variant='contained' onClick={() => updateArticle({ articleId: article.articleId, status: article.status === ArticleStatusEnum.DRAFT ? ArticleStatusEnum.PUBLISHED : ArticleStatusEnum.DRAFT })}>{article.status === ArticleStatusEnum.DRAFT ? 'Publish' : 'Unpublish'}</Button></Box>
						</Box>
					)}
					<Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '64vh' }}>
						<Box sx={{ overflow: 'hidden', overflowY: 'auto' }}>
							<Box sx={{ p: 2 }}>
								<PersonLine uid={article.uid} name={article.name} timestamp={article.time} />
							</Box>
							<Box sx={{ p: 2, pl: 4, pt: 0 }}>
								<BlockEditor
									blocks={article.blocks}
									setBlocks={setBlocks}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default ArticleEditor
