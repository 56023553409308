import { FormControl, Select, MenuItem, SxProps } from '@mui/material'

import ArticleStatusEnum from '../enums/ArticleStatusEnum'
import ArticleStatusStyles from '../enums/ArticleStatusStyles'

interface ArticleStatusSelectorProps {
	value: ArticleStatusEnum | ArticleStatusEnum[],
	setValue: (value: ArticleStatusEnum | ArticleStatusEnum[]) => void,
	multiple?: boolean,
	sx?: SxProps,
	inputSx?: SxProps
}

const ArticleStatusSelector: React.FC<ArticleStatusSelectorProps> = (props) => {
	const { value, setValue, multiple, sx = {}, inputSx = {} } = props
	return (
		<FormControl sx={{ minWidth: 120, ...sx }} size='small'>
			<Select
				value={value}
				{...(multiple ? { multiple: true } : [])}
				onChange={e => setValue(e.target.value as ArticleStatusEnum)}
				inputProps={{ sx: { display: 'flex', background: '#fff', ...inputSx } }}
			>
				{Object.keys(ArticleStatusEnum).map((key) => {
					const StatusStyle = ArticleStatusStyles[key as ArticleStatusEnum]
					return <MenuItem key={key} value={key} sx={{ display: 'flex' }}><StatusStyle.Icon sx={{ mr: 1, color: StatusStyle.Colour }} />{StatusStyle.Title}</MenuItem>
				})}
			</Select>
		</FormControl >
	)
}

export default ArticleStatusSelector
