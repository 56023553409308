import StatusInprogressIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined'
import StatusCompleteIcon from '@mui/icons-material/CheckCircleOutlineOutlined'

import ArticleStatusEnum from './ArticleStatusEnum'

const ArticleStatusStyles = {
	[ArticleStatusEnum.DRAFT]: {
		Icon: StatusInprogressIcon,
		Colour: 'blue',
		Title: 'Draft'
	},
	[ArticleStatusEnum.PUBLISHED]: {
		Icon: StatusCompleteIcon,
		Colour: 'green',
		Title: 'Published'
	}
}

export default ArticleStatusStyles
