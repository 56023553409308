import { useFirebaseAuth } from 'kitfit-community-common'
import React, { useEffect } from 'react'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'

const PrivateRoute: React.ElementType = ({ children }) => {
	const user = useFirebaseAuth()
	const location = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		localStorage.setItem('desiredLocation', location.pathname)
		if (user.authenticated === false) {
			navigate('/login')
		}
	}, [location.pathname, navigate, user.authenticated])

	if (user.authenticated === false) {
		return null
	}

	return children || <Outlet />
}

export default PrivateRoute
