/*
	This is a copy of: https://github.com/lovasoa/react-contenteditable/
	It (along with ContentEditable.tsx) fixes the issue in https://github.com/lovasoa/react-contenteditable/issues/161, and makes Hooks work properly.
*/
import { createElement, useState, memo } from 'react'
import deepEqual from 'fast-deep-equal'

function normalizeHtml(str: string): string {
	return str && str.replace(/&nbsp;|\u202F|\u00A0/g, ' ')
}

//	TODO: We may not need this?
// function replaceCaret(el: HTMLElement) {
// 	// Place the caret at the end of the element
// 	const target = document.createTextNode('')
// 	el.appendChild(target)
// 	// do not move caret if element was not focused
// 	const isTargetFocused = document.activeElement === el
// 	if (target !== null && target.nodeValue !== null && isTargetFocused) {
// 		const sel = window.getSelection()
// 		if (sel !== null) {
// 			const range = document.createRange()
// 			range.setStart(target, target.nodeValue.length)
// 			range.collapse(true)
// 			sel.removeAllRanges()
// 			sel.addRange(range)
// 		}
// 		if (el instanceof HTMLElement) el.focus()
// 	}
// }

//	TS: https://www.aleksandrhovhannisyan.com/blog/dynamic-tag-name-props-in-react/

// A simple component for an html element with editable contents.
const ContentEditableBase = memo((props: any) => {
	const { tagName = 'div', children, innerRef, onBlur, onKeyUp, onKeyDown, onKeyPress, onChange, disabled, html, ...rest } = props

	// ...
	// const el: any = typeof innerRef === 'function' ? { current: null } : useRef<HTMLElement>()
	// const el = useRef<HTMLElement>(innerRef)
	const [lastHtml, setLastHtml] = useState<string>()
	const emitChange = (originalEvt: React.SyntheticEvent<any>) => {
		const element = innerRef.current
		if (!element) {
			return
		}
		const html = element.innerHTML
		if (onChange && html !== lastHtml) {
			onChange({ ...originalEvt, target: { value: html } })
		}
		setLastHtml(html)
	}

	//	TODO: We probably don't need this
	// const [mounted, setMounted] = useState<boolean>(false)
	// useEffect(() => {
	// 	setMounted(true)
	// }, [])
	// useEffect(() => {
	// 	if (!mounted) {
	// 		// componentDidMount
	// 		return
	// 	} else {
	// 		// componentDidUpdate
	// 		const element = el.current
	// 		if (!element) {
	// 			return
	// 		}

	// 		// Perhaps React (whose VDOM gets outdated because we often prevent
	// 		// rerendering) did not update the DOM. So we update it manually now.
	// 		if (html !== element.innerHTML) {
	// 			console.log('DIFFERENT!', html, element.innerHTML)
	// 			element.innerHTML = html
	// 			setLastHtml(() => {
	// 				replaceCaret(element)
	// 				return html
	// 			})
	// 		}
	// 	}
	// })

	return createElement(tagName || 'div', {
		...rest,
		//	We really want people to pass in a ref, (to customise the editor), so we don't need this
		// ref: typeof innerRef === 'function' ? (current: HTMLElement) => {
		// 	innerRef(current)
		// 	el.current = current
		// } : innerRef || el,
		ref: innerRef,
		//	TODO: Um, how can you pass onInput???
		onInput: emitChange,
		onBlur: onBlur || emitChange,
		onKeyUp: onKeyUp || emitChange,
		onKeyDown: onKeyDown || emitChange,
		onKeyPress: onKeyPress || emitChange,
		contentEditable: !disabled,
		dangerouslySetInnerHTML: { __html: html }
	}, children)
}, (props, nextProps): boolean => {
	// We need not rerender if the change of props simply reflects the user's edits.
	// Rerendering in this case would make the cursor/caret jump

	// Rerender if there is no ref
	if (!props.innerRef) {
		return false
	}

	// ...or if html really changed... (programmatically, not by user edit)
	if (normalizeHtml(nextProps.html) !== normalizeHtml(props.innerRef.current.innerHTML)) {
		return false
	}

	// Handle additional properties
	return !(props.disabled !== nextProps.disabled ||
		props.tagName !== nextProps.tagName ||
		props.className !== nextProps.className ||
		props.innerRef !== nextProps.innerRef ||
		props.placeholder !== nextProps.placeholder ||
		!deepEqual(props.style, nextProps.style))
})

export default ContentEditableBase
