import { useState, useEffect } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getFirestore, doc, onSnapshot, Unsubscribe } from 'firebase/firestore'

import useFirebase from './useFirebase'

export interface FirebaseUserType {
	authenticated?: boolean,
	uid?: string,
	email?: string,
	withFreshToken: <T>(func: (...args: any[]) => T) => (...args: any[]) => Promise<T> // eslint-disable-line @typescript-eslint/no-explicit-any
}

const passthrough = <T>(func: (...args: any[]) => T) => (...args: any[]) => Promise.resolve(func(...args)) // eslint-disable-line @typescript-eslint/no-explicit-any

const useFirebaseAuth = () => {
	const [app] = useFirebase()
	const [user, setUser] = useState<FirebaseUserType>({
		authenticated: undefined,
		uid: undefined,
		email: undefined,
		withFreshToken: passthrough
	})

	const setSessionCookie = (token: string) => {
		global.document.cookie = `__session=${token};path=/`
	}
	const clearSessionCookie = () => {
		global.document.cookie = '__session=;path=/'
	}

	useEffect(() => {
		if (app) {
			const auth = getAuth()
			const db = getFirestore()
			let unsubscribe: Unsubscribe = () => undefined
			const authCleanup = onAuthStateChanged(auth, async (user) => {
				// User props ref: https://firebase.google.com/docs/reference/js/firebase.User
				const withFreshToken = <T>(func: (...args: any[]) => T) => async (...args: any[]) => { // eslint-disable-line @typescript-eslint/no-explicit-any
					if (user?.uid) {
						// ensure the token is fresh
						setSessionCookie(await user.getIdToken())
					} else {
						clearSessionCookie()
					}
					return func(...args)
				}
				setUser({ authenticated: !!user, uid: user?.uid, email: user?.email || undefined, withFreshToken })
				if (user?.uid) {
					// get or refresh token
					setSessionCookie(await user.getIdToken())

					unsubscribe = onSnapshot(
						doc(db, '/users', user.uid),
						async () => {
							//	Get the user's token again
							setSessionCookie(await user.getIdToken(true))
						}
					)
				} else {
					clearSessionCookie()
				}
			})
			return function cleanup() {
				unsubscribe()
				authCleanup()
			}
		}
		return undefined
	}, [app])

	return user
}

export default useFirebaseAuth
