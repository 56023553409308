import { useRef, useContext, useEffect, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Button, Select, MenuItem, FormControl } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-translatify-next'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ArrowCircleUp, ArrowCircleDown, FilterAlt } from '@mui/icons-material'
import { CrownIcon } from 'kitfit-community-common'

import HeaderContext from './context/headerContext'
import PrioritySelector from './components/PrioritySelector'
import StatusSelector from './components/TicketStatusSelector'
import RoleSelector from './components/RoleSelector'
import AssignedChip from './components/AssignedChip'
import RoleChip from './components/RoleChip'
import { TicketsColumnsEnum, useAdminCommunityContext } from './context/AdminCommunityContext'
import { comparePriority } from './enums/PriorityEnum'
import TicketStatusEnum, { compareStatus } from './enums/TicketStatusEnum'

dayjs.extend(relativeTime)

const Tickets = () => {
	const headerContext = useRef(useContext(HeaderContext))
	const { translate } = useTranslation()

	const { tickets, updateTicket, ticketSortBy, setTicketSortBy, ticketFilterStatus, setTicketFilterStatus, ticketFilterPriority, setTicketFilterPriority, ticketFilterRole, setTicketFilterRole } = useAdminCommunityContext()
	const [showFilters, setShowFilters] = useState(false)

	useEffect(() => {
		headerContext.current.setHeader(
			<Box sx={{ flex: '1' }}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Box sx={{ mr: 2, fontWeight: 'bold' }}>
							{translate('Tasks')}
						</Box>
					</Box>
					<Box sx={{ flex: '1', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
						<Box>
							<Button sx={{ mr: 1 }} variant='contained' startIcon={<FilterAlt />} endIcon={showFilters ? <ArrowCircleUp /> : <ArrowCircleDown />} onClick={() => setShowFilters(showFilters => !showFilters)}>Filters</Button>
						</Box>
						<FormControl>
							<Select
								size='small'
								value={ticketSortBy}
								onChange={e => setTicketSortBy(e.target.value as TicketsColumnsEnum)}
								inputProps={{ sx: { display: 'flex', background: '#fff' } }}
							>
								<MenuItem value={TicketsColumnsEnum.Name}>Sort by&nbsp;<strong>Name</strong></MenuItem>
								{/* <MenuItem value={TicketsColumnsEnum.Group}>Group</MenuItem> */}
								{/* <MenuItem value={TicketsColumnsEnum.Assigned}>Assigned</MenuItem> */}
								<MenuItem value={TicketsColumnsEnum.Priority}>Sort by&nbsp;<strong>Priority</strong></MenuItem>
								<MenuItem value={TicketsColumnsEnum.Status}>Sort by&nbsp;<strong>Status</strong></MenuItem>
							</Select>
						</FormControl>
						<Box sx={{ ml: 1 }}><Button variant='contained'>New task</Button></Box>
					</Box>
				</Box>
				{showFilters && <Box component={Paper} sx={{ display: 'flex', mt: 1, p: 2 }}>
					<Box>
						<Box sx={{ fontSize: '0.8rem' }}>Statuses</Box>
						<StatusSelector sx={{ mr: 2 }} multiple value={ticketFilterStatus} setValue={(status: any) => setTicketFilterStatus(status as Array<TicketStatusEnum>)} />
					</Box>
					<Box>
						<Box sx={{ fontSize: '0.8rem' }}>Priority</Box>
						<PrioritySelector sx={{ mr: 2 }} allowAll value={ticketFilterPriority} setValue={(priority: any) => setTicketFilterPriority(priority)} />
					</Box>
					<Box>
						<Box sx={{ fontSize: '0.8rem' }}>Roles</Box>
						<RoleSelector sx={{ mr: 2 }} allowAll value={ticketFilterRole} setValue={(role: any) => setTicketFilterRole(role)} />
					</Box>
				</Box>}
			</Box>
		)
	}, [ticketFilterPriority, ticketFilterRole, ticketFilterStatus, setTicketFilterPriority, setTicketFilterRole, setTicketFilterStatus, setTicketSortBy, showFilters, ticketSortBy, translate])

	return (
		<Box sx={{ display: 'block', p: 0, width: '100%' }}>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow sx={{ backgroundColor: '#fafafa' }}>
							<TableCell sx={{ fontWeight: 'bold' }}>{translate('Title')}</TableCell>
							{/* <TableCell sx={{ fontWeight: 'bold' }}>{translate('Group')}</TableCell> */}
							<TableCell sx={{ fontWeight: 'bold' }}>{translate('Assigned')}</TableCell>
							<TableCell sx={{ fontWeight: 'bold' }}>{translate('Priority')}</TableCell>
							<TableCell sx={{ fontWeight: 'bold' }}>{translate('Status')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tickets ? (
							Object.values(tickets)
								.sort((a, b) => {
									switch (ticketSortBy) {
										case TicketsColumnsEnum.Name:
											return a.name.localeCompare(b.name)
										case TicketsColumnsEnum.Status:
											return compareStatus(a.status, b.status)
										case TicketsColumnsEnum.Priority:
										default:
											return comparePriority(a.priority, b.priority)
									}
								})
								.map(ticket => (
									<TableRow
										key={ticket.ticketId}
										sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'cursor': 'pointer', ':hover': { backgroundColor: '#f3f3f3' } }}
									>
										<TableCell component="th" scope="row">
											<Box component={Link} to={`/ticket/${ticket.ticketId}`} sx={{ color: 'black', textDecoration: 'none' }}>
												<Box sx={{ fontWeight: 'bold' }}>{ticket.tag === 'VIP' && (
													<Box sx={{ display: 'inline-flex', alignItems: 'center', backgroundColor: '#fda400', color: 'white', padding: '0.2rem 0.4rem 0.2rem 0.33rem', borderRadius: '0.2rem', fontSize: '0.7rem', marginRight: '0.25rem' }}>
														<CrownIcon height='0.8rem' fill='white' sx={{ mt: '-2px', ml: '-2px', mb: '-1px' }} /> VIP
													</Box>
												)}{ticket.title}</Box>
												<Box sx={{ color: 'gray', fontSize: '0.8rem' }}>By <Box component='span' sx={{ fontWeight: 'bold' }}>{ticket.name}</Box> - {`${dayjs(ticket.time.toDate()).fromNow()}`}</Box>
											</Box>
										</TableCell>
										{/* <TableCell>
											<RoleChip assignedRoles={ticket.assignedRoles} assignedAdmins={ticket.assignedAdmins} />
										</TableCell> */}
										<TableCell><AssignedChip assignedAdmins={ticket.assignedAdmins} /></TableCell>
										<TableCell><PrioritySelector value={ticket.priority} setValue={(priority: any) => updateTicket({ ticketId: ticket.ticketId, priority })} /></TableCell>
										<TableCell><StatusSelector value={ticket.status} setValue={(status: any) => updateTicket({ ticketId: ticket.ticketId, status })} /></TableCell>
									</TableRow>
								))
						) : (
							<TableRow>
								<TableCell colSpan={5}>
									<CircularProgress />
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}

export default Tickets
