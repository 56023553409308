import { collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, Timestamp } from 'firebase/firestore'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { QuestionStatusEnum } from '../enums'

export type CommunityType = {
	communityId: string,
	name: string,
	admins: Array<string>,
	hostname: string,
	imageHostname: string
}

export type QuestionType = {
	answerId?: string,
	title: string,
	name: string,
	body: string,
	status: QuestionStatusEnum,
	countComments: number,
	questionId: string,
	time: Timestamp,
	uid: string
}

export type AnswerType = {
	acceptedAnswer: boolean,
	answerId: string,
	name: string,
	body: string,
	countComments: number,
	questionId: string,
	time: Timestamp,
	uid: string
}

export type QuestionAndAnswerType = {
	question: QuestionType,
	answer?: AnswerType,
	lastAnswer?: AnswerType
}

export interface CommunityInterface {
	community?: CommunityType,
	setLogo?: (logoStorageLocation: string, logoDownloadUrl: string) => Promise<void>,
	login?: () => void,
	register?: () => void,
	search?: (query: string) => void,
	addImage?: (imageStorageLocation: string, imageDownloadUrl: string) => Promise<string>,
	availableImageIds?: Array<string>,
	popularQuestions?: Array<QuestionAndAnswerType>,
	getPopularQuestions?: (numberOfQuestions: number) => void
}

const CommunityContext = React.createContext<CommunityInterface | undefined>(undefined)

export const useCommunityContextValue = (childCommunityContext: CommunityInterface) => {
	const { community } = childCommunityContext

	const [numberOfQuestions, setNumberOfQuestions] = useState<number>()
	const [popularQuestions, setPopularQuestions] = useState<Array<QuestionAndAnswerType>>([])

	const getPopularQuestions = useCallback((numberOfQuestions: number) => {
		setNumberOfQuestions(numberOfQuestions)
	}, [])
	useEffect(() => {
		setPopularQuestions([])
		const getPopularQuestions = async (communityId: string, numberOfQuestions: number) => {
			const db = getFirestore()
			const questionsQuerySnap = await getDocs(query(collection(db, '/communities', communityId, 'questions'), limit(numberOfQuestions)))
			console.log('CommunityContext', `got ${questionsQuerySnap.docChanges().length} popular questions data for communityId:${communityId}`, questionsQuerySnap.docChanges())
			const questions = await Promise.all(questionsQuerySnap.docs.map(async (questionsDocSnap) => {
				const question = questionsDocSnap.data() as QuestionType
				const { answerId } = question
				const answer = answerId ? (await getDoc(doc(questionsDocSnap.ref, 'answers', answerId))).data() as AnswerType : undefined
				const lastAnswer = (await getDocs(query(collection(questionsDocSnap.ref, 'answers'), orderBy('time', 'desc')))).docs[0]?.data() as AnswerType | undefined
				return {
					question,
					answer,
					lastAnswer
				}
			}))
			setPopularQuestions(questions)
		}
		if (numberOfQuestions && community?.communityId) {
			getPopularQuestions(community.communityId, numberOfQuestions)
		}
	}, [community?.communityId, numberOfQuestions])

	const value = useMemo(() => ({
		popularQuestions,
		getPopularQuestions
	}), [getPopularQuestions, popularQuestions])
	return value
}

export const useCommunityContext = () => {
	const communityContext = useContext(CommunityContext)

	if (communityContext === undefined) {
		throw new Error('CommunityContext was used outside of a provider')
	}

	return communityContext
}

export default CommunityContext
