import { useContext, useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { doc, getDoc, getFirestore } from 'firebase/firestore'

import './Login.css'

import Copyright from './components/Copyright'
import HeaderContext from './context/headerContext'
import { steps } from './Onboarding'

const emailPasswordSchema = yup.object({
	email: yup.string().email().required(),
	password: yup.string().required()
}).required()

export type LoginEmailPasswordType = yup.InferType<typeof emailPasswordSchema>

const Login = () => {
	const { register: registerEP, handleSubmit: handleSubmitEP, formState: { errors: emailPasswordErrors } } = useForm<LoginEmailPasswordType>({ resolver: yupResolver(emailPasswordSchema) })
	const [loginResult, setLoginResult] = useState<string>()
	const navigate = useNavigate()

	const handleLoginWithEmailAndPassword = async (data: LoginEmailPasswordType) => {
		const auth = getAuth()
		try {
			const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password)
			setLoginResult('User logged in successfully')

			let desiredLocation = localStorage.getItem('desiredLocation') || '/'

			const db = getFirestore()
			const usersDocSnap = await getDoc(doc(db, '/users', userCredential.user?.uid))
			if (usersDocSnap.exists()) {
				const { onboarding } = usersDocSnap.data()
				if (!onboarding?.complete) {
					if (onboarding?.step) {
						desiredLocation = `/onboarding/${onboarding?.step}`
					} else {
						desiredLocation = `/onboarding/${steps[0]}`
					}
				}
			} else {
				desiredLocation = `/onboarding/${steps[0]}`
			}

			navigate(desiredLocation)
		} catch (ex) {
			setLoginResult(`Error logging in: ${ex}`)
		}
	}

	const headerContext = useRef(useContext(HeaderContext))
	useEffect(() => {
		headerContext.current.setHeader(undefined)
	}, [])

	return (
		<Container component="main" maxWidth="xs" sx={{ pt: 2 }}>
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					padding: '1rem 2rem',
					backgroundColor: 'background.paper',
					borderRadius: '0.25rem'
				}}
			>
				<Typography component="h1" variant="h5">Log in to continue</Typography>
				<Box component="form" className="Login-form" onSubmit={handleSubmitEP(handleLoginWithEmailAndPassword)} noValidate sx={{ mt: 1 }}>
					<TextField
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						autoComplete="email"
						autoFocus
						{...registerEP('email')}
					/>
					{emailPasswordErrors?.email?.message && <Typography component="div" className="Login-error">{emailPasswordErrors.email?.message}</Typography>}
					<TextField
						margin="normal"
						required
						fullWidth
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
						{...registerEP('password')}
					/>
					{emailPasswordErrors?.password?.message && <Typography component="div" className="Login-error">{emailPasswordErrors.password?.message}</Typography>}
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						Log in
					</Button>
					{loginResult && <Typography component="div" className="Login-error">{loginResult}</Typography>}
					<Grid container>
						<Grid item xs>
							<Link href="/resetpassword" variant="body2">Reset password</Link>
						</Grid>
						{/* <Grid item>
							<Link href="/signup" variant="body2">Don't have an account? Sign Up</Link>
						</Grid> */}
					</Grid>
				</Box>
			</Box>
			<Copyright sx={{ mt: 8, mb: 4 }} />
		</Container>
	)
}

export default Login
