import { useRef, useEffect, useCallback } from 'react'

import ContentEditableBase from './ContentEditableBase'

// A simple component for an html element with editable contents.
const ContentEditable = (props: any) => {
	const { onBlur, onKeyUp, onKeyDown, onKeyPress, onChange, onInput, ...rest } = props
	const onChangeRef = useRef(onChange)
	const onInputRef = useRef(onInput)
	const onBlurRef = useRef(onBlur)
	const onKeyPressRef = useRef(onKeyPress)
	const onKeyDownRef = useRef(onKeyDown)
	const onKeyUpRef = useRef(onKeyUp)

	useEffect(() => {
		onChangeRef.current = onChange
	}, [onChange])
	useEffect(() => {
		onInputRef.current = onInput
	}, [onInput])
	useEffect(() => {
		onBlurRef.current = onBlur
	}, [onBlur])
	useEffect(() => {
		onKeyPressRef.current = onKeyPress
	}, [onKeyPress])
	useEffect(() => {
		onKeyDownRef.current = onKeyDown
	}, [onKeyDown])
	useEffect(() => {
		onKeyUpRef.current = onKeyUp
	}, [onKeyUp])

	const onBlurCallback = useCallback((...args: any) => onBlurRef?.current && onBlurRef.current(...args), [])
	const onKeyUpCallback = useCallback((...args: any) => onKeyUpRef?.current && onKeyUpRef.current(...args), [])
	const onKeyDownCallback = useCallback((...args: any) => onKeyDownRef?.current && onKeyDownRef.current(...args), [])
	const onKeyPressCallback = useCallback((...args: any) => onKeyPressRef?.current && onKeyPressRef.current(...args), [])
	const onChangeCallback = useCallback((...args: any) => onChangeRef?.current && onChangeRef.current(...args), [])
	const onInputCallback = useCallback((...args: any) => onInputRef?.current && onInputRef.current(...args), [])

	return <ContentEditableBase
		{...rest}
		onBlur={onBlurCallback}
		onKeyUp={onKeyUpCallback}
		onKeyDown={onKeyDownCallback}
		onKeyPress={onKeyPressCallback}
		onChange={onChangeCallback}
		onInput={onInputCallback}
	/>
}

export default ContentEditable
