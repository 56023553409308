import Box from '@mui/material/Box'

import Blocks from '../BlockEditor/Blocks'
import { BlockItem } from '../BlockEditor'

interface BlockRendererProps {
	blocks: Array<BlockItem>
}

const BlockRenderer: React.FC<BlockRendererProps> = ({ blocks }) => {
	return (
		<Box>
			<Box sx={{ backgroundColor: '#fff', padding: '1rem 2rem' }}>
				{blocks.map((block) => {
					const BlockTag = Blocks[block.type]
					return (
						<BlockTag
							key={block.id}
							readOnly={true}
							block={block as BlockItem<never>}
						/>
					)
				})}
			</Box>
		</Box>
	)
}

export default BlockRenderer
