import StatusInprogressIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined'
import StatusOnholdIcon from '@mui/icons-material/PauseCircleOutlineOutlined'
import StatusCompleteIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import StatusRejectedIcon from '@mui/icons-material/CancelOutlined'

import TicketStatusEnum from './TicketStatusEnum'

const TicketStatusStyles = {
	[TicketStatusEnum.INPROGRESS]: {
		Icon: StatusInprogressIcon,
		Colour: 'blue',
		Title: 'In progress'
	},
	[TicketStatusEnum.COMPLETE]: {
		Icon: StatusCompleteIcon,
		Colour: 'green',
		Title: 'Complete'
	},
	[TicketStatusEnum.ONHOLD]: {
		Icon: StatusOnholdIcon,
		Colour: 'orange',
		Title: 'On hold'
	},
	[TicketStatusEnum.REJECTED]: {
		Icon: StatusRejectedIcon,
		Colour: 'red',
		Title: 'Rejected'
	}
}

export default TicketStatusStyles
