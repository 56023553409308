import { useContext } from 'react'
import { Box, Button, IconButton, Menu, MenuItem, Divider, CircularProgress } from '@mui/material'
import SelectedUserIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { TranslatifyContext } from 'react-translatify-next'

import { UserProfileType } from '../context/AdminCommunityContext'
import RoleEnum from '../enums/RoleEnum'

import UserProfileImage from './UserProfileImage'

interface AssignedSelectorProps {
	assignedUsers?: Array<string>,
	assignedRoles?: Array<RoleEnum>,
	usersAvailable?: Record<string, UserProfileType>,
	handleAddUser: (user: UserProfileType) => void,
	handleRemoveUser: (user: UserProfileType) => void,
	handleClose: () => void,
	anchorEl?: Element
}

const UserSelector: React.FC<AssignedSelectorProps> = (props) => {
	const { assignedUsers, usersAvailable, handleAddUser, handleRemoveUser, handleClose, anchorEl } = props
	const { translate } = useContext(TranslatifyContext)

	return (
		<Menu anchorEl={anchorEl} open={true}>
			<Box sx={{ display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', minWidth: '12rem', width: '100%', mb: 1 }}>
				<Box sx={{ fontWeight: 'bold' }}>{translate('Members')}</Box>
				<Box sx={{ position: 'absolute', right: 0 }}><IconButton onClick={handleClose} ><CloseIcon fontSize='small' /></IconButton></Box>
			</Box>
			<Divider />
			{Object.values(usersAvailable || {}).map((user) => {
				const isSelected = assignedUsers?.includes(user.uid)
				const handleClick = () => {
					if (!isSelected) {
						handleAddUser(user)
					} else {
						handleRemoveUser(user)
					}
				}
				return (<MenuItem onClick={handleClick} key={user.uid} sx={{ display: 'flex', flexDirection: 'row' }}>
					<UserProfileImage {...user} sx={{ mr: 1 }} />
					<Box sx={{ mr: 1 }}>{user.name}</Box>
					{isSelected && <SelectedUserIcon />}
				</MenuItem>)
			})}
			{!usersAvailable && (<MenuItem sx={{ display: 'flex', flexDirection: 'row', height: '4rem', mt: 1, justifyContent: 'center', alignItems: 'center' }}>
				<Box>
					<CircularProgress />
				</Box>
			</MenuItem>)}
			{!!usersAvailable && (<MenuItem onClick={handleClose} sx={{ display: 'flex', flexDirection: 'row', height: '4rem', mt: 1, justifyContent: 'center', alignItems: 'center' }}>
				<Button variant='contained'>Ok</Button>
			</MenuItem>)}
		</Menu>
	)
}

export default UserSelector
