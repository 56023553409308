import Avatar from '@mui/material/Avatar'
import { Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

const AtMentionList = (props: any) => {
	const { mentionUserClick, handleMentionBoxClose, mentionUsers, selectMentionAtIndex, sx } = props
	const onClickBinder = (user: any) => (e: any) => {
		e.preventDefault()
		mentionUserClick(user.userName)
	}
	return !mentionUsers ? null : (
		<Box component='section' sx={{
			display: 'flex',
			flexDirection: 'column',
			zIndex: 1,
			position: 'absolute',
			border: '1px solid black',
			backgroundColor: '#fff',
			width: '300px',
			padding: '0.25rem',
			paddingRight: '2.5rem',
			boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
			borderRadius: '0.2rem',
			...sx
		}}>
			{mentionUsers.map((user: any, index: number) => ( // eslint-disable-next-line react/no-array-index-key
				<Box component='button' key={`${user.userName}_${index}`} type="button" onClick={onClickBinder(user)} sx={{
					display: 'flex',
					border: 'none',
					padding: '0.25rem',
					backgroundColor: (selectMentionAtIndex === index ? '#eee' : 'transparent'),
					alignItems: 'center'
				}}>
					{user.image && <Avatar src={user.image} />}
					{!user.image && <Avatar><AccountCircleIcon /></Avatar>}
					<Box component='span'>{user.userName} {user.fullName}</Box>
				</Box>
			))}
			<Box sx={{ position: 'absolute', right: '0.25rem', top: '0.25rem' }}>
				<IconButton
					key="close"
					aria-label="Close"
					size="small"
					onClick={handleMentionBoxClose}
				>
					<CloseIcon />
				</IconButton>
			</Box>
		</Box>
	)
}

export default AtMentionList
