import { Box } from '@mui/material'

import UserProfileImage from './UserProfileImage'

const AssignedChip = (props: any) => {
	const { assignedAdmins } = props

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			{assignedAdmins?.map((uid: string) => <UserProfileImage key={uid} uid={uid} />)}
		</Box>
	)
}

export default AssignedChip
