import { Box, Grid, TextField } from '@mui/material'
import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react'

import Markdown from '../../utilities/Markdown'
import { BlockItem } from '..'
import { useCommunityContext } from '../../../../context/CommunityContext'

import { BlockComponentType } from './Block'

export interface PopularQuestionBlockItemData {
	numberOfQuestions: number
}
export const popularQuestionValue: Omit<BlockItem<PopularQuestionBlockItemData>, 'id'> = {
	type: 'popularquestion',
	data: {
		numberOfQuestions: 10
	}
}

const PopularQuestionBlock: BlockComponentType<PopularQuestionBlockItemData> = ({ block, readOnly = false, onChange }) => {
	const { numberOfQuestions } = block.data

	const navigate = useNavigate()
	const { popularQuestions, getPopularQuestions } = useCommunityContext()

	useEffect(() => {
		if (getPopularQuestions) {
			getPopularQuestions(numberOfQuestions)
		}
	}, [getPopularQuestions, numberOfQuestions])

	const questionUrl = (questionId?: string) => questionId ? `/questions/${questionId}` : ''

	const [numberOfQuestionsValue, setNumberOfQuestionsValue] = useState(`${numberOfQuestions}`)
	const handleNumberOfQuestionsChange = (value: string) => {
		setNumberOfQuestionsValue(value)
		if (onChange) {
			const numberOfQuestions = Number(value) || 10
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, numberOfQuestions } }
			})
		}
	}

	return readOnly ? (
		<Box className='be-popularquestion' sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
			<Grid container spacing={2}>
				{(popularQuestions || []).map((qanda, index) => {
					const { question, answer, lastAnswer } = qanda
					return (
						<Grid item onClick={() => navigate(questionUrl(question.questionId))} xs={12} key={`${block.id}_${index}`} sx={{ display: 'flex', cursor: 'pointer' }}>
							<Box sx={{ display: 'flex', flexDirection: 'column', border: '1px solid black', p: 1 }}>
								<Box sx={{ fontWeight: 'bold' }}>
									<Markdown children={question.title} />
								</Box>
								<Box sx={{ fontStyle: 'italic' }}>
									<Markdown children={question.name} />
								</Box>
								<Markdown children={question.body} />
								{!!answer && (
									<Box sx={{ display: 'flex', flexDirection: 'column', border: '1px solid green', p: 1 }}>
										<Box sx={{ backgroundColor: '#e6e6e6' }}>Accepted answer</Box>
										<Box sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>
											<Markdown children={answer.name} />
										</Box>
										<Markdown children={answer.body} />
									</Box>
								)}
								{!answer && !!lastAnswer && (
									<Box sx={{ display: 'flex', flexDirection: 'column', border: '1px solid #e6e6e6', p: 1 }}>
										<Box sx={{ backgroundColor: '#e6e6e6' }}>Most recent answer</Box>
										<Box sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>
											<Markdown children={lastAnswer.name} />
										</Box>
										<Markdown children={lastAnswer.body} />
									</Box>
								)}
							</Box>
						</Grid>
					)
				})}
			</Grid>
		</Box>
	) : (
		<Box className='be-popularquestion'>
			<TextField value={numberOfQuestionsValue} onChange={e => handleNumberOfQuestionsChange(e.target.value)}/>
		</Box>
	)
}

export default PopularQuestionBlock
