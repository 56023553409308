import { useState, useEffect, PropsWithChildren, useRef, useContext, ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'
import UserIcon from '@mui/icons-material/AccountCircle'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
// import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import MenuCollapseIcon from '@mui/icons-material/ChevronLeft'
import MenuExpandIcon from '@mui/icons-material/ChevronRight'
import { InputAdornment, SvgIcon } from '@mui/material'
//	Ref: https://mui.com/material-ui/customization/breakpoints/
import useMediaQuery from '@mui/material/useMediaQuery'
import { useFirebaseAuth, HomeIcon, TasksIcon, UsersIcon, SearchIcon, PlaybookIcon, CogIcon } from 'kitfit-community-common'

// import SidebarButton from '../components/SidebarButton'
import SidebarMenu, { MenuStructureType } from '../components/SidebarMenu'
import Copyright from '../components/Copyright'
import ThemeContext from '../context/themeContext'
import HeaderContext from '../context/headerContext'
import { useAdminCommunityContext } from '../context/AdminCommunityContext'

const Layout: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
	const user = useFirebaseAuth()
	const [header, setHeader] = useState<ReactNode>()
	const [showMenu, setShowMenu] = useState<boolean>(false)
	const [pathname, setPathname] = useState<string>()
	const [locationSearch, setLocationSearch] = useState<string>()
	const [collapsed, setCollapsed] = useState(!!(localStorage.getItem('menuCollapsed') === 'true'))

	const theme = useTheme()
	const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'))

	const location = useLocation()
	useEffect(() => {
		if (pathname !== location.pathname) {
			setPathname(location.pathname)
		}
		if (locationSearch !== location.search) {
			setLocationSearch(location.search)
		}
	}, [location, locationSearch, pathname])

	const themeContext = useRef(useContext(ThemeContext))
	const light = themeContext.current.theme

	useEffect(() => {
		localStorage.setItem('menuCollapsed', `${!!collapsed}`)
	}, [collapsed])

	const { community, ticketsSummary, questionsSummary, articlesSummary } = useAdminCommunityContext()

	//	TODO: The menu structure will be dynamic for different user roles, so we define it here for now so - we need to move this somewhere (maybe in the SidebarMenu component), and be able to provide overrides, eg: a user role might not need access to Questions, etc...
	const menuStructure: MenuStructureType = {
		top: [
			{ Icon: HomeIcon, label: 'Home', href: '/home', number: 3 },
			{
				Icon: TasksIcon,
				label: 'Tickets',
				href: '/tickets',
				number: ticketsSummary?.total,
				isSelected: pathname?.indexOf('/ticket') === 0
				// items: [
				// 	{ label: 'Assigned to me', number: ticketsSummary?.assignedToMe, href: '/tickets?filter=assigned-to-me', isSelected: pathname === '/tickets' && locationSearch === '?filter=assigned-to-me' },
				// 	{ label: 'My groups', number: ticketsSummary?.myGroups, href: '/tickets?filter=my-groups', isSelected: pathname === '/tickets' && locationSearch === '?filter=my-groups' },
				// 	{ label: 'Unassigned', number: ticketsSummary?.unassigned, href: '/tickets?filter=unassigned', isSelected: pathname === '/tickets' && locationSearch === '?filter=unassigned' }
				// ]
			},
			{
				// Icon: QuestionCircleIcon,
				Icon: UsersIcon,
				label: 'Questions',
				href: '/questions',
				number: questionsSummary?.total,
				isSelected: pathname?.indexOf('/question') === 0
			},
			{
				Icon: PlaybookIcon,
				label: 'Articles',
				href: '/articles',
				number: articlesSummary?.total,
				isSelected: pathname?.indexOf('/article') === 0
			},
			{ Icon: CogIcon, label: 'Settings', href: '/settings', isSelected: pathname?.indexOf('/settings') === 0 }
		],
		bottom: [
			{ Icon: SearchIcon, label: 'Search', href: '/search' }
		]
	}

	const handleShowMenu = () => {
		setShowMenu(true)
	}

	const handleHideMenu = () => {
		setShowMenu(false)
	}

	const glass = {
		backgroundColor: 'rgba(255, 255, 255, .66)',
		backdropFilter: 'blur(10px)'
	}

	const glassSideBar = {
		backgroundColor: 'rgba(42, 42, 47, .90)',
		backdropFilter: 'blur(10px)'
	}

	const glassBright = {
		// backgroundColor: 'rgba(0, 0, 0, .25)',	//	<- Woah! Dark glass.
		backgroundColor: 'rgba(255, 255, 255, .75)',
		backdropFilter: 'blur(10px)'
	}

	if (!community) {
		return null
	}

	return (
		<HeaderContext.Provider value={{ header, setHeader }}>
			<Box sx={{ minHeight: 'calc(100vh - 10rem)', display: 'flex', gap: '1px', boxShadow: '0px 10px 10px rgb(0 0 0 / 10%)', m: 2, borderBottomLeftRadius: '0.5rem', borderTopLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }}>
				{!isSmallBreakpoint && (<Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', ...({ width: (collapsed ? '4.5rem' : '11.8rem') }) }}>
					<Box sx={{ height: '6rem', ...glassSideBar, display: 'flex', p: 0, justifyContent: 'center', alignItems: 'center', borderTopLeftRadius: '0.5rem' }}>
						<Box sx={{ height: '52px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
							<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<img alt="Logo" src='/logo.svg' style={{
									height: '3rem',
									marginRight: (!collapsed ? '0.2rem' : 'inherit')
								}} />
							</Box>
							{!collapsed && <Box sx={{ ml: 0.5 }}>Priority One</Box>}
						</Box>
					</Box>

					<Box sx={{ ...glassSideBar, display: 'flex', flex: 1, zIndex: 1, ...({ width: (collapsed ? '4.5rem' : '11.8rem') }), position: 'relative', flexDirection: 'column', transition: 'all 0.2s', borderBottomLeftRadius: '0.5rem' }}>
						<SidebarMenu menuStructure={menuStructure} collapsed={collapsed} pathname={pathname} />
						{/* <Button
							onClick={() => setCollapsed(coll => !coll)}
							sx={{
								'position': 'absolute', 'bottom': '1.5rem', 'right': '-0.66rem', 'width': '1.25rem', 'minWidth': '1.25rem', 'height': '1.25rem', 'minHeight': '1.25rem', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'border': '1px solid #888888', 'borderRadius': '100%', 'backgroundColor': '#fafafa', ':hover': { 'backgroundColor': '#ffffff' }
							}}>
							{!collapsed && <MenuCollapseIcon fontSize='small' />}
							{collapsed && <MenuExpandIcon fontSize='small' />}
						</Button> */}
					</Box>
				</Box>)}

				<Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: '1px', width: '100%' }}>
					{/* {!isSmallBreakpoint && (<Box sx={{ ...glass, display: 'flex', p: 1, pl: 2, gap: 2, height: '3.5rem' }}>
						<Box sx={{ display: 'flex', alignItems: 'center', flex: 1, gap: 1, minWidth: '20rem' }}>
							<Box sx={{ flex: 1 }}>{community.name}</Box>
							<Box component="div" sx={{ display: 'flex' }}>
								<Box component="span" sx={{ position: 'relative', display: 'inline-flex' }}>
									<TextField
										placeholder="Search"
										size="small"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
											sx: { backgroundColor: 'primary.contrastText' }
										}}
									/>
									<IconButton
										sx={{ position: 'absolute', top: 0, right: 0 }}
									>
										<CloseIcon />
									</IconButton>
								</Box>
							</Box>

							<Box sx={{ flexGrow: 0 }}>
								{user.authenticated ? (
									<IconButton size='small'>
										<Link to="/user">
											<Avatar sx={{ width: 32, height: 32 }} alt="User profile">
												<UserIcon fontSize="medium" />
											</Avatar>
										</Link>
									</IconButton>
								) : (
									<Button component={Link} variant='loginbutton' classes={{ root: 'theme.loginbutton' }} to="/login">Log in</Button>
								)}
							</Box>
						</Box>
					</Box>)} */}
					<Box sx={{ display: 'flex', p: 2, ...glassBright, minHeight: '4.5rem', alignItems: 'center', borderTopRightRadius: '0.5rem' }}>
						{isSmallBreakpoint && (<>
							<Box sx={{ position: 'absolute', right: '1rem', top: '1rem' }}>
								{!showMenu && <MenuIcon onClick={handleShowMenu} />}
								{showMenu && <CloseIcon onClick={handleHideMenu} />}
							</Box>
							{showMenu && (
								<Box sx={{ position: 'absolute', zIndex: 2, top: '3rem', right: 0, ...glass, boxShadow: '0px 10px 10px rgb(0 0 0 / 10%)', borderRadius: 1 }}>
									<SidebarMenu menuStructure={menuStructure} pathname={pathname} />
								</Box>
							)}
						</>)}
						{header}
					</Box>
					<Box sx={{ display: 'flex', p: 2, flex: 1, ...glass, borderBottomRightRadius: '0.5rem' }}>
						<Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }} >
							{children}
						</Box>
					</Box>
				</Box>
			</Box>
			<Copyright sx={{ mt: 8, mb: 4 }} />
		</HeaderContext.Provider>
	)
}

export default Layout
