import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/system'

export interface ReactComponentProps {
	fill?: string,
	style?: {
		width: string,
		height: string
	}
}

interface IconBaseProps {
	ReactComponent: React.FC<ReactComponentProps>,
	width?: string,
	height?: string,
	sx?: SxProps<Theme>,
	fill?: string
}

export type IconProps = Omit<IconBaseProps, 'ReactComponent'>
export type IconType = React.FC<IconProps>

const IconBase: React.FC<IconBaseProps> = (props) => {
	const { ReactComponent, width = '24px', height = '24px', sx, fill = 'black', ...rest } = props
	return <Box component='span' sx={{ display: 'inline-flex', verticalAlign: 'middle', justifyContect: 'center', alignContect: 'center', ...sx }} {...rest}>
		<ReactComponent
			fill={fill}
			style={{ width, height }}
		/>
	</Box>
}

export default IconBase
