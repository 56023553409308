import StatusInprogressIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined'
import StatusCompleteIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { QuestionStatusEnum } from 'kitfit-community-common'

const QuestionStatusStyles = {
	[QuestionStatusEnum.DRAFT]: {
		Icon: StatusInprogressIcon,
		Colour: 'blue',
		Title: 'Draft'
	},
	[QuestionStatusEnum.PUBLISHED]: {
		Icon: StatusCompleteIcon,
		Colour: 'green',
		Title: 'Published'
	}
}

export default QuestionStatusStyles
