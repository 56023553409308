import { Box } from '@mui/material'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { useAdminCommunityContext } from '../context/AdminCommunityContext'

import UserProfileImage from './UserProfileImage'

dayjs.extend(relativeTime)

const PersonLine = (props: any) => {
	const { uid, name, timestamp } = props
	const { admins } = useAdminCommunityContext()
	const displayName = (admins?.[uid] ? admins[uid].name : name) || uid

	return (
		<Box sx={{ display: 'flex', alignItems: 'center', height: '1.7rem' }}>
			<UserProfileImage key={uid} uid={uid} />
			<Box sx={{ ml: 1, mr: 1, fontWeight: 'bold' }}>{displayName}</Box>
			{dayjs(timestamp.toDate()).fromNow()}
		</Box>
	)
}

export default PersonLine
