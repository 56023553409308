import { useEffect, useState, useRef } from 'react'
import { Box, CircularProgress, Tab, Tabs } from '@mui/material'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { useTranslation } from 'react-translatify-next'
import { useParams } from 'react-router'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ChatEditor, Markdown } from 'kitfit-community-common'

import { useAdminCommunityContext } from '../context/AdminCommunityContext'

import AssignedSelector from './AssignedSelector'
import StatusSelector from './TicketStatusSelector'
import PrioritySelector from './PrioritySelector'
import Label from './Label'
import UserProfileImage, { ProfileImageSize } from './UserProfileImage'
import PersonLine from './PersonLine'

dayjs.extend(relativeTime)

interface TabPanelProps {
	children?: React.ReactNode,
	index: number,
	value: number
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<Box
			sx={{ maxHeight: '64vh' }}
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					{children}
				</Box>
			)}
		</Box>
	)
}

function a11yProps(index: number) {
	return {
		'id': `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

interface TicketEditorProps {
	showTitle?: boolean
}

const TicketEditor: React.FC<TicketEditorProps> = (props) => {
	const { showTitle = true } = props
	const [tabSelected, setTabSelected] = useState(0)
	const [commentMessage, setCommentMessage] = useState<{ text?: string }>({})
	const [replyMessage, setReplyMessage] = useState<{ text?: string }>({})

	const { translate } = useTranslation()

	const { ticketId } = useParams()
	const { selectTicket, ticket, comments, replies, admins, updateTicket, updateTicketAddAssignedAdmin, updateTicketRemoveAssignedAdmin, updateTicketAddComment, updateTicketAddReply } = useAdminCommunityContext()
	const repliesEndRef = useRef<HTMLElement>()
	const chatEndRef = useRef<HTMLElement>()

	const [hasScrolledReplies, setHasScrolledReplies] = useState<boolean>(false)
	const [hasScrolledChat, setHasScrolledChat] = useState<boolean>(false)

	useEffect(() => {
		if (ticketId && ticket?.ticketId !== ticketId) {
			selectTicket({ ticketId })
		}
	}, [selectTicket, ticket?.ticketId, ticketId])

	useEffect(() => {
		if (replies && !hasScrolledReplies) {
			setHasScrolledReplies(() => {
				if (repliesEndRef.current) {
					repliesEndRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
				}
				return true
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [replies])

	useEffect(() => {
		if (comments && !hasScrolledChat) {
			setHasScrolledChat(() => {
				if (chatEndRef.current) {
					chatEndRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
				}
				return true
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [comments])

	if (!ticketId || ticket?.ticketId !== ticketId) {
		return null
	}

	const handleCommentSend = () => {
		if (commentMessage.text) {
			const comment = {
				body: commentMessage.text
			}
			updateTicketAddComment(ticket, comment)
		}
		setCommentMessage({})
		setTimeout(() => {
			if (chatEndRef.current) {
				chatEndRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
			}
		}, 100)
	}

	const handleReplySend = () => {
		if (replyMessage.text) {
			const reply = {
				body: replyMessage.text
			}
			updateTicketAddReply(ticket, reply)
		}
		setReplyMessage({})
		setTimeout(() => {
			if (repliesEndRef.current) {
				repliesEndRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
			}
		}, 100)
	}

	return (
		<Box sx={{ display: 'flex', gap: '1px' }}>
			<Box sx={{ flex: 1, borderRight: '1px solid #cccccc' }}>
				{showTitle && (
					<Box sx={{ pl: 2, display: 'flex', borderBottom: '1px solid #cccccc', backgroundColor: '#fafafa', height: '48px', alignItems: 'center', borderTopLeftRadius: '8px' }}>
						<Box sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}> {ticket.title}</Box>
					</Box>
				)}
				<Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '64vh' }}>
					<Box sx={{ overflow: 'hidden', overflowY: 'auto' }}>
						<Box sx={{ p: 2 }}>
							<PersonLine uid={ticket.uid} name={ticket.name} timestamp={ticket.time} />
						</Box>
						<Box sx={{ p: 2, pl: 4, pt: 0 }}>
							<Markdown children={ticket.body} />
						</Box>
						<Box>
							{replies ?
								Object.values(replies)
									.sort((a, b) => a.time === b.time ? 0 : a.time < b.time ? -1 : 1)
									.map((reply) => {
										return (<Box key={reply.time.seconds}>
											<Box sx={{ p: 2 }}>
												<PersonLine uid={reply.uid} timestamp={reply.time} />
											</Box>
											<Box sx={{ p: 1, pl: 4, pt: 0 }}><Markdown children={reply.body} /></Box>
										</Box>)
									}) :
								(
									<CircularProgress />
								)}
							<Box ref={repliesEndRef} sx={{ height: '1px' }}></Box>
						</Box>
					</Box>
					<Box>
						<Box>
							<ChatEditor
								title={'Reply'}
								chatMessage={replyMessage}
								setChatMessage={setReplyMessage}
								handleSend={handleReplySend}
							/>
						</Box>
					</Box>
				</Box>
			</Box >
			<Box sx={{ width: '24rem' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs value={tabSelected} onChange={(e, value) => setTabSelected(value)} aria-label="basic tabs example">
						<Tab label={translate('Details')} {...a11yProps(0)} />
						<Tab label={translate('Comments')} {...a11yProps(1)} />
						<Tab label={translate('History')} {...a11yProps(2)} />
					</Tabs>
				</Box>
				<TabPanel value={tabSelected} index={0}>
					<Box sx={{ p: 2 }}>
						<Box sx={{ p: 2, display: 'flex', backgroundColor: '#f8f8f8', borderRadius: 2, mb: 2 }}>
							<Box>
								<UserProfileImage size={ProfileImageSize.LARGE} uid={ticket.uid} />
							</Box>
							<Box sx={{ ml: 2 }}>
								{/* TODO: Add proper contact details here */}
								<Box sx={{ fontWeight: 'bold' }}>{ticket.name}</Box>
								<Box>james@example.com</Box>
								<Box>0400000000</Box>
								<Box sx={{ display: 'flex', gap: 0.5 }}>
									<TwitterIcon />
									<LinkedInIcon />
									<WhatsAppIcon sx={{ color: 'lightgray' }} />
								</Box>
							</Box>
						</Box>
						<Label sx={{ mt: 0 }}>{translate('Assigned')}</Label>
						<AssignedSelector
							assignedUsers={ticket.assignedAdmins}
							assignedRoles={ticket.assignedRoles}
							usersAvailable={admins}
							handleAddUser={(user: any) => updateTicketAddAssignedAdmin(ticket, user)}
							handleRemoveUser={(user: any) => updateTicketRemoveAssignedAdmin(ticket, user)}
						/>
						<Label>{translate('Priority')}</Label>
						<PrioritySelector value={ticket.priority} setValue={(priority: any) => updateTicket({ ticketId: ticket.ticketId, priority })} />
						<Label>{translate('Status')}</Label>
						<StatusSelector value={ticket.status} setValue={(status: any) => updateTicket({ ticketId: ticket.ticketId, status })} />
					</Box>
				</TabPanel>
				<TabPanel value={tabSelected} index={1}>
					<Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '64vh' }}>
						<Box sx={{ overflow: 'hidden', overflowY: 'auto' }}>
							{comments ?
								Object.values(comments)
									.sort((a, b) => a.time === b.time ? 0 : a.time < b.time ? -1 : 1)
									.map(comment => (
										<Box key={comment.time.seconds}>
											<Box sx={{ p: 2 }}>
												<PersonLine uid={comment.uid} timestamp={comment.time} />
											</Box>
											<Box>
												<Box sx={{ p: 1, pl: 4, pt: 0 }}><Markdown children={comment.body} /></Box>
											</Box>
										</Box>
									)) :
								(
									<CircularProgress />
								)}
							<Box ref={chatEndRef} sx={{ height: '1px' }}></Box>
						</Box>
						<Box>
							<ChatEditor
								title={'Internal comment'}
								chatMessage={commentMessage}
								setChatMessage={setCommentMessage}
								handleSend={handleCommentSend}
							/>
						</Box>
					</Box>
				</TabPanel>
				<TabPanel value={tabSelected} index={2}>
					<Box sx={{ p: 2 }}>
						{translate('History')}
					</Box>
				</TabPanel>
			</Box>
		</Box >
	)
}

export default TicketEditor
