import ReactDOMServer from 'react-dom/server'
import { NodeHtmlMarkdown } from 'node-html-markdown'

import Markdown from '../../utilities/Markdown'
import { EditorText } from '../Editors/EditorText'
import { BlockItem } from '..'

import { BlockComponentType } from './Block'

export interface HeaderBlockItemData {
	level: 1 | 2 | 3 | 4 | 5 | 6,
	text: string
}
export const headerValue: Omit<BlockItem<HeaderBlockItemData>, 'id'> = {
	type: 'header',
	data: {
		level: 1,
		text: 'Header text'
	}
}

//	Our component
const HeaderBlock: BlockComponentType<HeaderBlockItemData> = ({ block, readOnly = false, onChange }) => {
	const level = block.data.level || 1
	if ([1, 2, 3, 4, 5, 6].indexOf(level) === -1) {
		//	TODO: Maybe throw a consistent block render exception?
		return null
	}
	const text = block.data.text
	const Tag = `h${level}` as keyof JSX.IntrinsicElements
	const handleChange = ({ doc }: { doc: string }) => {
		if (onChange) {
			const text = NodeHtmlMarkdown.translate(doc)
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, text } }
			})
		}
	}
	return readOnly ?
		<Tag className='be-header'><Markdown children={text} /></Tag> :
		<Tag className='be-header'>
			<EditorText
				enableMenu={false}
				content={ReactDOMServer.renderToStaticMarkup(<Markdown children={text} />)}
				onChange={handleChange}
			/>
		</Tag>
}

export default HeaderBlock
