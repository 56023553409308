import { Box, Button, TextField } from '@mui/material'
import { useState } from 'react'

import { BlockItem } from '..'
import { useCommunityContext } from '../../../../context/CommunityContext'

import { BlockComponentType } from './Block'

export const searchValue: Omit<BlockItem, 'id'> = {
	type: 'search',
	data: {}
}

const SearchBlock: BlockComponentType = ({ readOnly = false }) => {
	const { search } = useCommunityContext()
	const [query, setQuery] = useState('')

	return readOnly ? (
		<Box className='be-search' sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
			<TextField value={query} onChange={e => setQuery(e.target.value)} />
			<Button disabled={!query} onClick={() => {
				if (search) {
					search(query)
				}
			}}>Search</Button>
		</Box>
	) : (
		<Box className='be-search'>
			Search Block
		</Box>
	)
}

export default SearchBlock
