import { Box, Button, TableCell, TableRow } from '@mui/material'
import EllipsisVerticalIcon from 'kitfit-community-common/components/icons/EllipsisVerticalIcon'
import GripVerticalIcon from 'kitfit-community-common/components/icons/GripVerticalIcon'
import { FC } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import { CustomerSegmentType } from '../context/AdminCommunityContext'

interface CustomerSegmentProps {
	segment: CustomerSegmentType,
	segmentIndex: number,
	openMenu: (el: HTMLElement) => void,
	moveSegment: (fromIndex: number, toIndex: number) => void,
	editSegment: () => void
}

interface CustomerSegmentDnDItemType {
	segment: CustomerSegmentType,
	segmentIndex: number
}

const CustomerSegment: FC<CustomerSegmentProps> = ({ segment, segmentIndex, openMenu, moveSegment, editSegment }) => {
	const [{ isDragging }, drag, dragPreview] = useDrag<CustomerSegmentDnDItemType, unknown, { isDragging: boolean }>(() => ({
		type: 'segment',
		item: {
			segment,
			segmentIndex
		},
		collect: monitor => ({
			isDragging: monitor.isDragging()
		})
	}))

	const [{ canDrop, isOver }, drop] = useDrop({
		accept: 'segment',
		canDrop: (item) => {
			return item.segmentIndex !== segmentIndex
		},
		drop: (item: CustomerSegmentDnDItemType) => {
			moveSegment(item.segmentIndex, segmentIndex)
		},
		collect: monitor => ({
			canDrop: monitor.canDrop(),
			isOver: monitor.isOver({ shallow: true })
		})
	})

	const refs = (rs: Array<any>) => (ref: any) => {
		rs.forEach(r => r(ref))
	}

	return (
		<TableRow
			ref={refs([dragPreview, drop])}
			sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'cursor': 'pointer', ':hover': { backgroundColor: '#f3f3f3' }, 'opacity': isDragging ? 0.5 : 1, 'border': isOver && canDrop ? 'solid 3px #d6d6d6' : 'inherit' }}
			onClick={editSegment}
		>
			<TableCell ref={drag}><GripVerticalIcon /></TableCell>
			<TableCell>{segment.name}</TableCell>
			<TableCell>{(segment.stages || []).length}</TableCell>
			<TableCell sx={{ display: 'flex', alignItems: 'center' }}>
				{segment.description}
				<Box sx={{ flex: '1', display: 'flex', justifyContent: 'flex-end' }}>
					<Button onClick={(e) => {
						e.stopPropagation()
						openMenu(e.currentTarget)
					}}><EllipsisVerticalIcon /></Button>
				</Box>
			</TableCell>
		</TableRow>
	)
}

export default CustomerSegment
