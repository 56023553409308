import { FormControl, Select, MenuItem, SxProps } from '@mui/material'

import TicketStatusEnum from '../enums/TicketStatusEnum'
import TicketStatusStyles from '../enums/TicketStatusStyles'

interface TicketStatusSelectorProps {
	value: TicketStatusEnum | TicketStatusEnum[],
	setValue: (value: TicketStatusEnum | TicketStatusEnum[]) => void,
	multiple?: boolean,
	sx?: SxProps,
	inputSx?: SxProps
}

const TicketStatusSelector: React.FC<TicketStatusSelectorProps> = (props) => {
	const { value, setValue, multiple, sx = {}, inputSx = {} } = props
	return (
		<FormControl sx={{ minWidth: '10rem', ...sx }} size='small'>
			<Select
				value={value}
				{...(multiple ? { multiple: true } : [])}
				onChange={e => setValue(e.target.value as TicketStatusEnum)}
				inputProps={{ sx: { display: 'flex', background: '#fff', ...inputSx } }}
			>
				{Object.keys(TicketStatusEnum).map((key) => {
					const StatusStyle = TicketStatusStyles[key as TicketStatusEnum]
					return <MenuItem key={key} value={key} sx={{ display: 'flex' }}><StatusStyle.Icon sx={{ mr: 1, color: StatusStyle.Colour }} />{StatusStyle.Title}</MenuItem>
				})}
			</Select>
		</FormControl >
	)
}

export default TicketStatusSelector
