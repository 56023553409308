import { FormControl, MenuItem, Select, SxProps } from '@mui/material'

import RoleEnum from '../enums/RoleEnum'

import RoleChip from './RoleChip'

interface RoleSelectorProps {
	value: RoleEnum | '',
	setValue: (value: RoleEnum | '') => void,
	allowAll?: boolean,
	sx?: SxProps,
	inputSx?: SxProps
}

const RoleSelector: React.FC<RoleSelectorProps> = (props) => {
	const { value, setValue, allowAll, sx = {}, inputSx = {} } = props
	return (
		<FormControl sx={{ minWidth: 120, ...sx }} size="small">
			<Select
				displayEmpty
				value={value}
				onChange={e => setValue(e.target.value as RoleEnum)} // Ref: https://github.com/mui/material-ui/issues/33399
				inputProps={{ sx: { display: 'flex', background: '#fff', ...inputSx } }}
			>
				{allowAll && <MenuItem value='' sx={{ display: 'flex' }}>All roles</MenuItem>}
				{Object.keys(RoleEnum).map((key: string) => (
					<MenuItem key={key} value={key} sx={{ display: 'flex' }}>
						<RoleChip assignedRoles={[key]} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

export default RoleSelector
