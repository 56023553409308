import { useState, useRef, useCallback, useEffect } from 'react'
import Box from '@mui/material/Box'
// import ContentEditable from 'react-contenteditable'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatLinkIcon from '@mui/icons-material/Link'
import FormatHeaderIcon from '@mui/icons-material/Title'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material'

import ContentEditable from '../utilities/ContentEditable'
import { getSelectionOffset, setSelectionOffset } from '../utilities/selection'
import emojiShortcuts from '../utilities/emojiShortcuts'
import AtMentionList from '../utilities/AtMentionList'
import { useOutsideClick } from '../../../hooks'

import EditorButton from './EditorBaseButton'

import './EditorBase.css'

//	TODO: There's an issue where changes to the editor whilst developing is causing 100% CPU usage - seems to be a problem with CRE - should be fixed in the next version of Create React App?

/*
	Ok, so some strings can break content editable, eg: apostrophes and quotes, as they get encoded.
	TODO: There are likely more, ref: https://www.codetable.net/
*/
const cleanText = (text: string) => {
	const replacements: { [key: string]: string } = {
		'&#x27;': '\'',
		'&#x22;': '"'
	}
	let newText = text || ''
	Object.keys(replacements).forEach((key: string) => newText = newText.split(key).join(replacements[key]))
	return newText
}

const cursorInfo = (element: HTMLElement) => {
	const cursorPos = window.getSelection()?.getRangeAt(0)?.getBoundingClientRect()
	if (cursorPos) {
		const cursorY = cursorPos.bottom - (element).getBoundingClientRect().top
		const cursorX = cursorPos.left - (element).getBoundingClientRect().left

		return { cursorY, cursorX }
	}
	return {}
}

interface EditorBaseProps {
	content: string,
	sx?: object,
	tagName?: string,
	minHeight?: string,
	addImage?: ({ imgSrc, index }: { imgSrc: string, index: number }) => void,
	removeImage?: ({ imgSrc, index }: { imgSrc: string, index: number }) => void,
	apiBinder?: (...args: any[]) => void,
	onKeyDown?: (e: any) => void,
	onChange?: ({ doc }: { doc: any }) => void,
	enableMenu?: boolean,
	onAtMentionUserSearch?: ({ query }: { query: string }) => void,
	mentionUsers?: Array<any>,
	onHashTagSearch?: () => void
}

const EditorBase = ({
	sx = {},
	tagName = 'div',
	minHeight,
	addImage,
	apiBinder,
	removeImage,
	onKeyDown,
	onChange,
	content,
	enableMenu = true,
	onAtMentionUserSearch,
	mentionUsers,
	onHashTagSearch,
	...rest
}: EditorBaseProps) => {
	const contentInstance = useRef(content)
	const surroundRef = useRef<HTMLElement>()
	const ceRef = useRef<HTMLElement>()
	const menuRef = useRef<HTMLElement>()

	const [showMentionBox, setShowMentionBox] = useState<boolean>(false)
	const [mentionAtTextIndex, setMentionAtTextIndex] = useState<number | null>()
	const [maxMentionAtTextIndex, setMaxMentionAtTextIndex] = useState<number | null>()
	const [selectMentionAtIndex, setSelectMentionAtIndex] = useState<number | null>()
	const [mentionSearchTerm, setMentionSearchTerm] = useState<string>()
	const [cursorX, setCursorX] = useState<number | null>(null)
	const [cursorY, setCursorY] = useState<number | null>(null)

	//	TODO: pass in add and remove image functions
	const handleImages = ({ prevDoc, doc }: { prevDoc: string, doc: string }) => {
		//	If new image, we need to upload
		//	If removed image, we need to delete
		const parser = new DOMParser()
		const newDoc = parser.parseFromString(doc, 'text/html')
		const oldDoc = parser.parseFromString(prevDoc, 'text/html')
		const newImgs = Array.from(newDoc.querySelectorAll('img')).map(img => img.src)
		const oldImgs = Array.from(oldDoc.querySelectorAll('img')).map(img => img.src)
		const removeImage = ({ imgSrc, index }: { imgSrc: string, index: number }) => {
			console.log('TODO: remove image', index, imgSrc)
		}
		const addImage = ({ imgSrc, index }: { imgSrc: string, index: number }) => {
			console.log('TODO: add image', index, imgSrc)
		}
		//	TODO: If pasting the same image more than once, this won't work...
		newImgs.forEach((imgSrc: string, index: number) => {
			if (oldImgs.indexOf(imgSrc) === -1) {
				addImage({ imgSrc, index })
			}
		})
		oldImgs.forEach((imgSrc: string, index: number) => {
			if (newImgs.indexOf(imgSrc) === -1) {
				removeImage({ imgSrc, index })
			}
		})
	}

	const handleChange = (evt: any) => {
		const prevDoc = contentInstance.current

		handleImages({ prevDoc, doc: evt.target.value })

		contentInstance.current = evt.target.value

		if (onChange && prevDoc !== contentInstance.current) {
			onChange({ doc: contentInstance.current })
		}
	}

	const handleSelectMentionUser = (username: string) => {
		const sel = global.document.getSelection()

		//	Select the typed text (if any)
		const range = document.createRange()
		let index = (mentionAtTextIndex || 0) - 1
		if (index < 0) {
			index = 0
		}
		if (sel?.anchorNode) {
			range.setStart(sel.anchorNode, index)
			range.setEnd(sel.anchorNode, sel.focusOffset)
			sel.removeAllRanges()
			sel.addRange(range)
		}

		global.document.execCommand('insertText', false, `@${username} `)
		if (onAtMentionUserSearch) {
			setShowMentionBox(false)
		}
	}

	const handleMentionBoxClose = () => {
		setShowMentionBox(false)
		setMaxMentionAtTextIndex(0)
	}

	//	Ref: https://stackoverflow.com/a/14698158/6637365
	const isSelectionInsideElement = (tagName: string) => { // eslint-disable-line class-methods-use-this
		let containerNode
		const TagName = tagName.toUpperCase()
		if (window.getSelection) {
			const sel = window.getSelection()
			if ((sel?.rangeCount || 0) > 0) {
				containerNode = (sel?.getRangeAt(0).commonAncestorContainer as any)
			}
		}
		while (containerNode) {
			if (containerNode.nodeType === 1 && containerNode?.tagName === TagName) {
				return true
			}
			containerNode = containerNode.parentNode
		}
		return false
	}

	const handleKeyDown = (e: any) => {
		// nodeWalk: walk the element tree, stop if func(node) returns false
		function nodeWalk(node: any, func: any) {
			let result = func(node)
			for (node = node.firstChild; result !== false && node; node = node.nextSibling) {
				result = result || nodeWalk(node, func)
			}
			return result
		}

		function getCaretDetails(elem: any) {
			const sel = window.getSelection()
			const start = sel?.anchorOffset || 0
			return (sel?.anchorNode === elem) ? { element: elem, start } : undefined
		}

		if (e.key === ' ') {
			//	Create a list from a '* ' on a new line
			const sel = global.document.getSelection()
			if (sel) {
				const text = sel?.anchorNode?.textContent?.slice(0, sel?.focusOffset)
				if (text === '*') {
					global.document.execCommand('delete', false, '')
					global.document.execCommand('insertUnorderedList', false, '')
					e.preventDefault()
				}
				const textLength = text?.length || 0
				if (text && textLength > 0) {
					//	TODO: Show a popup when typing ':something' with a mininmum of 2 chars as per slack functionality.
					//	Add an emoji if we typed either an emoticon, or a keyword
					let hasInserted = false
					Object.keys(emojiShortcuts).forEach((emoji: string) => {
						const shortcut = emojiShortcuts[emoji]
						shortcut.emoticons.forEach((emoticon: string) => {
							if (!hasInserted && text.substring(textLength - emoticon.length) === emoticon && ceRef.current) {
								const [, end] = getSelectionOffset(ceRef.current)
								hasInserted = true
								setSelectionOffset(ceRef.current, end - emoticon.length, end)
								global.document.execCommand('insertHTML', false, emoji)
							}
						})
						if (!hasInserted) {
							shortcut.keywords.forEach((keyword: string) => {
								const kw = `:${keyword}:`
								if (!hasInserted && text.substring(textLength - kw.length) === kw && ceRef.current) {
									const [, end] = getSelectionOffset(ceRef.current)
									hasInserted = true
									setSelectionOffset(ceRef.current, end - kw.length, end)
									global.document.execCommand('insertHTML', false, emoji)
								}
							})
						}
					})
				}
			}
		}

		if (e.key === 'Enter') {
			if (onAtMentionUserSearch && showMentionBox && mentionUsers) {
				e.preventDefault()
				// select the item we have chosen in the box
				const userName = mentionUsers[selectMentionAtIndex || 0].userName
				handleSelectMentionUser(userName)
			} else {
				const isList = isSelectionInsideElement('LI')
				//	Prevents new paragraph on enter
				if (!isList) {
					global.document.execCommand('insertLineBreak')
					e.preventDefault()
				}
			}
		}

		//	If at the start of a LI, and press tab, we indent.
		if (e.keyCode === 9) {
			e.preventDefault()
			const caret = nodeWalk(ceRef.current, getCaretDetails)

			//	TODO: If caret is on '*' we add LI

			if (caret?.element?.nodeName === 'LI' && caret.start === 0) {
				if (!e.shiftKey) {
					global.document.execCommand('indent', false)
				} else {
					global.document.execCommand('outdent', false)
				}
			} else {
				// add text tab
				global.document.execCommand('insertText', false, '\t')
				// add rich tab
				// 	global.document.execCommand('insertText', false, '&#009')
			}
		}

		if (onAtMentionUserSearch) {
			if (e.key === 'ArrowUp') {
				if (showMentionBox) {
					// Decrease the selectMentionAtIndex
					let newSelectMentionAtIndex = selectMentionAtIndex || 0
					newSelectMentionAtIndex -= 1
					if (newSelectMentionAtIndex < 0) {
						newSelectMentionAtIndex = 0
					}
					setSelectMentionAtIndex(newSelectMentionAtIndex)
					e.preventDefault()
				}
			}

			if (e.key === 'ArrowDown') {
				if (showMentionBox) {
					// Increase the selectMentionAtIndex
					const maxIndex = (mentionUsers?.length || 0) - 1
					let newSelectMentionAtIndex = selectMentionAtIndex || 0
					newSelectMentionAtIndex += 1
					if (newSelectMentionAtIndex > maxIndex) {
						newSelectMentionAtIndex = maxIndex
					}
					setSelectMentionAtIndex(newSelectMentionAtIndex)
					e.preventDefault()
				}
			}

			if (e.key === 'Escape') {
				setShowMentionBox(false)
			}
		}

		//	Pass the event through
		if (onKeyDown) {
			onKeyDown(e)
		}
	}

	const handleKeyUp = (e: any) => {
		handleCursorPosition()
		const sel = global.document.getSelection()
		if (sel) {
			const theKeyValue = (sel?.anchorNode?.textContent?.slice(((sel?.focusOffset || 1) - 1), sel?.focusOffset) || '').trim()

			if (onAtMentionUserSearch) {
				if (theKeyValue === '@') {
					if (surroundRef.current) {
						const { cursorX, cursorY } = cursorInfo(surroundRef.current)
						setCursorX(cursorX || null)
						setCursorY(cursorY || null)
					}
					setShowMentionBox(true)
					setMentionAtTextIndex(sel.focusOffset)
					if (!mentionUsers || mentionUsers?.length === 0) {
						setSelectMentionAtIndex(0)
					}
				} else if (showMentionBox) {
					//	Check if text changed, and search
					setMaxMentionAtTextIndex(Math.max((maxMentionAtTextIndex || 0), sel.focusOffset))
					const text = (sel?.anchorNode?.textContent?.slice(mentionAtTextIndex || 0, maxMentionAtTextIndex || 0) || '')?.trim()
					if (mentionSearchTerm !== text) {
						setMentionSearchTerm(text)
						onAtMentionUserSearch({ query: text })
					}
				}
			}
		}
	}
	const [enabledButtons, setEnabledButtons] = useState<any>({})
	const [showMenu, setShowMenu] = useState<any>(false)
	const [menuLeftOffset, setMenuLeftOffset] = useState<number | null>(null)

	// Check if the current selection is inside a particular tag
	const isSelectionInTag = (tag: string) => {
		const selection = window.getSelection()
		// Get the current node
		let currentNode = selection?.anchorNode as HTMLElement | null | undefined
		if (!currentNode) {
			return false
		}
		// While the node is not the editor division
		while (currentNode !== ceRef.current) {
			// Check if the node is the requested tag
			if (currentNode?.tagName === tag) {
				return true
			}
			// Move up in the tree
			currentNode = currentNode?.parentNode as HTMLElement | null | undefined
		}
		return false
	}

	//	TODO: Handle when there's more than 1 line of selection, the arrow for the menu is incorrectly placed.
	const handleSelectText = useCallback(() => {
		const selection = window.getSelection()
		const collapsed = selection?.getRangeAt(0)?.collapsed
		setMenuLeftOffset(null)
		if (!collapsed) {
			const range = selection?.getRangeAt(0)
			//	Get a rect from around the last line of the selection
			const getRectFromRanges = (ranges: any) => {
				const lRange = ranges[ranges.length - 1]
				const lastHeight = lRange.height
				const lastY = lRange.y + lRange.height
				const lastX = lRange.x + lRange.width
				let minX = 10000
				Array.from(ranges).forEach((r: any) => {
					minX = Math.min(minX, r.x)
				})
				return {
					left: minX,
					top: lastY - lastHeight,
					width: lastX - minX,
					height: lastHeight
				}
			}

			//	Show menu when selection is active
			setShowMenu(() => {
				const rangeRects: any = range?.getClientRects()
				return getRectFromRanges(rangeRects)
			})

			if (selection?.anchorNode) {
				//	Check which buttons should be enabled
				setEnabledButtons({
					bold: isSelectionInTag('B'),
					italic: isSelectionInTag('I'),
					header: [1, 2, 3, 4, 5, 6].some(num => isSelectionInTag('H' + num))
				})
			}
		} else {
			//	Hide menu when no selection
			setShowMenu(false)
		}
	}, [])

	const [hideTimer, setHideTimer] = useState<string | number | NodeJS.Timeout | undefined>()
	const [leaveMenuOpen, setLeaveMenuOpen] = useState<boolean>(false)	//	TODO: testing, should be false by default

	const handleBlur = () => {
		if (!leaveMenuOpen) {
			//	TODO: Sanitize if needed
			//	Use timer, so that if the user clicks button, it still works.
			clearTimeout(hideTimer)
			setHideTimer(() => setTimeout(() => {
				setShowMenu(false)
				setMenuLeftOffset(null)
			}, 250))
		}
	}

	useEffect(() => {
		if (showMenu) {
			const menuRect = menuRef?.current?.getBoundingClientRect()
			setMenuLeftOffset((menuRect?.width || 0) / 2)
		}
	}, [showMenu])

	useEffect(() => {
		const menuRect = menuRef?.current?.getBoundingClientRect()

		let offset = (menuRect?.width || 0) / 2

		//	Check if that pushes it outside the viewport
		const positionLeft = showMenu.left - (menuLeftOffset || 0) + (showMenu.width / 2)
		const positionRight = positionLeft + (menuRect?.width || 0)
		const vpWitdh = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
		//	TODO: Check height of menu and adjust if needed - could show above selection instead, etc...
		// const vpHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

		if (positionLeft < 0) {
			offset = -positionLeft + 16
			if (offset !== menuLeftOffset) {
				setMenuLeftOffset(offset)
			}
		}

		if (positionRight > vpWitdh) {
			offset = ((menuRect?.width || 0) / 2) + (positionRight - vpWitdh)
			if (offset !== menuLeftOffset) {
				setMenuLeftOffset(offset)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menuLeftOffset])

	//	Ensure we don't allow the user to enter more than a "sane-ish" amount of characters
	const limitContent = (content = '') => content.substr(0, 65536)

	const [undoCount, setUndoCount] = useState<number>(0)

	const handleUndoStateCount = ({ isUndo, isRedo }: { isUndo: boolean, isRedo: boolean }) => {
		let newUndoCount = undoCount || 0
		if (isUndo) {
			newUndoCount += 1
		} else if (isRedo) {
			newUndoCount -= 1
			if (newUndoCount < 0) {
				newUndoCount = 0
			}
		} else {
			newUndoCount = 0
		}
		return newUndoCount
	}

	const [originalContent, setOriginalContent] = useState<string | null>(null)
	//	TODO: Make undo work - see app rich editor.
	const [, setIsOriginalContent] = useState<boolean>(true)

	if (!originalContent && content) {
		setOriginalContent(content)
	}

	//	Click button changes to apply commands
	const handleExecCommand = ({ command }: { command: string }) => {
		clearTimeout(hideTimer)
		const content = limitContent(ceRef?.current?.innerHTML)
		const newUndoCount = handleUndoStateCount({
			isUndo: command === 'undo',
			isRedo: command === 'redo'
		})
		const isOriginalContent = content === originalContent
		setIsOriginalContent(isOriginalContent)
		setUndoCount(newUndoCount)
		// this.setState({ content, isOriginalContent, undoCount }, () => this.props.onChange({ target: { value: content } }))
	}

	const [showHeaderMenu, setShowHeaderMenu] = useState<boolean>()
	const [showLinkEdit, setShowLinkEdit] = useState<boolean>()
	const [selectionRanges, setSelectionRanges] = useState<Range[]>()
	const [linkValue, setLinkValue] = useState<string>()

	//	Check if selection range includes a link already
	const getContainingNodeByTag = (tag: string | string[]) => {
		const checkTags = Array.isArray(tag) ? tag : [tag]
		const selection = window.getSelection()
		// Get the current node
		let currentNode = selection?.anchorNode as HTMLElement | null | undefined
		if (!currentNode) {
			return false
		}

		const maxChecks = 1000
		let checkCount = 0

		// While the node is not the editor division
		while (currentNode && currentNode !== ceRef.current) {
			checkCount += 1
			if (checkCount > maxChecks) {
				break
			}
			// Check if the node is the requested tag
			if (checkTags.indexOf(currentNode?.tagName || '') !== -1) {
				return currentNode
			}
			// Move up in the tree
			currentNode = currentNode?.parentNode as HTMLElement | null | undefined
		}
		return false
	}

	//	For the anchor editor
	useEffect(() => {
		setLinkValue('')
		const selection = window.getSelection()
		//	Save the selection till after we edit
		if (selection?.getRangeAt && selection?.rangeCount) {
			const containingAnchor = getContainingNodeByTag('A')

			if (containingAnchor) {
				//	We're editing the node - expand the selection
				const range = document.createRange()
				range.selectNodeContents(containingAnchor)
				selection.removeAllRanges()	// Remove all ranges from the selection.
				selection.addRange(range)	// Add the new range.
				//	Set the value
				setLinkValue(containingAnchor?.getAttribute('href') || '')
			}

			const ranges = []
			for (let i = 0; i < selection.rangeCount; i += 1) {
				ranges.push(selection.getRangeAt(i))
			}
			setSelectionRanges(ranges)
		}
	}, [showLinkEdit])

	//	For the header editor
	useEffect(() => {
		if (showHeaderMenu) {
			setLeaveMenuOpen(true)
			clearTimeout(hideTimer)
		} else {
			setLeaveMenuOpen(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showHeaderMenu])

	const handleInsertLink = () => {
		if (selectionRanges) {
			const sel = window.getSelection()
			sel?.removeAllRanges()
			for (let i = 0; i < selectionRanges.length; i += 1) {
				sel?.addRange(selectionRanges[i])
			}
		}
		document.execCommand('CreateLink', false, linkValue)
		setShowLinkEdit(false)
	}

	const handleRemoveLink = () => {
		if (selectionRanges) {
			const sel = window.getSelection()
			sel?.removeAllRanges()
			for (let i = 0; i < selectionRanges.length; i += 1) {
				sel?.addRange(selectionRanges[i])
			}
		}
		document.execCommand('UnLink', false)
		setShowLinkEdit(false)
	}

	const headerMenuRef = useRef(null)
	useOutsideClick(headerMenuRef, () => setShowHeaderMenu(false))

	const handleHeaderBinder = (level: number) => () => {
		const selection = window.getSelection()

		if (level === 0) {
			const text = selection?.toString() || ''
			const containingAnchor = getContainingNodeByTag(['H1', 'H2', 'H3', 'H4', 'H5', 'H6'])

			if (containingAnchor) {
				containingAnchor.parentNode?.removeChild(containingAnchor)
				global.document.execCommand('insertText', false, text)
			}
		} else if (isSelectionInTag(`H${level}`)) {
			const text = selection?.toString() || ''
			const containingAnchor = getContainingNodeByTag(`H${level}`)

			if (containingAnchor) {
				containingAnchor.parentNode?.removeChild(containingAnchor)
				global.document.execCommand('insertText', false, text)
			}
		} else {
			global.document.execCommand('insertHTML', false, `<h${level}>${selection}</h${level}>`)
		}
		setShowMenu(false)
		setMenuLeftOffset(null)
		setShowHeaderMenu(false)
		setLeaveMenuOpen(false)
	}

	const [selectionStart, setSelectionStart] = useState<number | null>(null)
	const [selectionEnd, setSelectionEnd] = useState<number | null>(null)

	const handleCursorPosition = () => {
		if (ceRef?.current) {
			const [start, end] = getSelectionOffset(ceRef.current)
			setSelectionStart(start)
			setSelectionEnd(end)
		}
	}

	//	API access
	useEffect(() => {
		if (apiBinder) {
			apiBinder({
				getSelectionOffset,
				setSelectionOffset,
				selectionStart,
				selectionEnd,
				element: ceRef.current,
				insertText: (text: string) => global.document.execCommand('insertText', false, text)
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectionStart, selectionEnd, ceRef.current])

	return (
		<Box ref={surroundRef} sx={{ position: 'relative', ...sx }}>
			<ContentEditable
				innerRef={ceRef}
				tagName={tagName}
				html={cleanText(contentInstance.current)}
				onKeyDown={handleKeyDown}
				onKeyUp={handleKeyUp}
				onChange={handleChange}
				onSelect={handleSelectText}
				onBlur={handleBlur}
				onMouseUp={handleCursorPosition}
				{...(minHeight ? { style: { minHeight } } : [])}
				{...rest}
			/>
			{onAtMentionUserSearch && mentionUsers && mentionUsers?.length > 0 && showMentionBox && (
				<AtMentionList
					sx={{
						top: `${cursorY || 0}px`,
						left: `${cursorX || 0}px`
					}}
					mentionUsers={mentionUsers}
					selectMentionAtIndex={selectMentionAtIndex}
					handleMentionBoxClose={handleMentionBoxClose}
					mentionUserClick={handleSelectMentionUser}
				/>
			)}

			{/* TODO: Move menu to own component */}
			{enableMenu && showMenu && (
				<Box ref={menuRef} sx={{
					'zIndex': 1,
					'position': 'fixed',
					'visibility': (menuLeftOffset ? 'visible' : 'hidden'),
					'top': (showMenu.top + showMenu.height + 10) + 'px',
					'left': (showMenu.left - (menuLeftOffset || 0) + (showMenu.width / 2)) + 'px',
					'border': '1px solid #999',
					'boxShadow': '0px 3px 7px rgb(0 0 0 / 50%)',
					'borderRadius': '0.25rem',
					'display': 'flex',
					'flexWrap': 'nowrap',
					'justifyContent': 'center',
					'backgroundColor': '#292A2B',
					'color': 'white',
					'padding': '0.25rem 0.5rem',
					'::before': {
						position: 'fixed',
						content: '""',
						borderBottom: '10px solid #292A2B',
						borderLeft: '10px solid transparent',
						borderRight: '10px solid transparent',
						left: (showMenu.left + (showMenu.width / 2) - 10) + 'px',
						top: (showMenu.top + showMenu.height + 2) + 'px'
					}
				}}>
					<Box sx={{ display: 'flex' }}>
						<EditorButton
							title='Bold'
							command='bold'
							onCommand={handleExecCommand}
							icon={<FormatBoldIcon />}
							selected={enabledButtons['bold']}
						/>
						<EditorButton
							title='Italic'
							command='italic'
							onCommand={handleExecCommand}
							icon={<FormatItalicIcon />}
							selected={enabledButtons['italic']}
						/>
						<EditorButton
							title='Header'
							onClick={() => setShowHeaderMenu(true)}
							icon={<FormatHeaderIcon />}
							selected={enabledButtons['header']}
						/>
						<EditorButton
							title='Link'
							onClick={() => setShowLinkEdit(true)}
							icon={<FormatLinkIcon />}
							selected={enabledButtons['link']}
						/>
					</Box>
					{showHeaderMenu && (
						<Box ref={headerMenuRef} sx={{
							'zIndex': 1,
							'position': 'fixed',
							'visibility': (menuLeftOffset ? 'visible' : 'hidden'),
							'top': (showMenu.top + showMenu.height + 56) + 'px',
							'left': (showMenu.left - (menuLeftOffset || 0) + (showMenu.width / 2)) + 'px',
							'border': '1px solid #999',
							'boxShadow': '0px 3px 7px rgb(0 0 0 / 50%)',
							'borderRadius': '0.25rem',
							'display': 'flex',
							'flexDirection': 'column',
							'justifyContent': 'center',
							'backgroundColor': '#292A2B',
							'color': 'white',
							'padding': '0.25rem 0.5rem'
						}}>
							<EditorButton
								title='Normal text'
								description='Normal text'
								onClick={handleHeaderBinder(0)}
								selected={enabledButtons['header']}
							/>
							<EditorButton
								title='Header 1'
								description={<h1>Header 1</h1>}
								onClick={handleHeaderBinder(1)}
								selected={enabledButtons['header']}
							/>
							<EditorButton
								title='Header 2'
								description={<h2>Header 2</h2>}
								onClick={handleHeaderBinder(2)}
								selected={enabledButtons['header']}
							/>
							<EditorButton
								title='Header 3'
								description={<h3>Header 3</h3>}
								onClick={handleHeaderBinder(3)}
								selected={enabledButtons['header']}
							/>
							<EditorButton
								title='Header 4'
								description={<h4>Header 4</h4>}
								onClick={handleHeaderBinder(4)}
								selected={enabledButtons['header']}
							/>
							<EditorButton
								title='Header 5'
								description={<h5>Header 5</h5>}
								onClick={handleHeaderBinder(5)}
								selected={enabledButtons['header']}
							/>
							<EditorButton
								title='Header 6'
								description={<h6>Header 6</h6>}
								onClick={handleHeaderBinder(6)}
								selected={enabledButtons['header']}
							/>
						</Box>
					)}
				</Box>
			)}
			{showLinkEdit && (
				<Dialog
					open={showLinkEdit}
					onClose={() => setShowLinkEdit(false)}
					maxWidth='sm'
					fullWidth
				>
					<DialogTitle id="responsive-dialog-title">
						Link properties
					</DialogTitle>
					<DialogContent>
						<Box>
							<TextField
								placeholder="URL"
								size="small"
								fullWidth
								value={linkValue}
								onChange={e => setLinkValue(e.target.value)}
							/>
						</Box>
					</DialogContent>
					<DialogActions sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }}>
						<Button variant='contained' color='secondary' onClick={() => setShowLinkEdit(false)} autoFocus>
							Close
						</Button>
						<Button variant='contained' color='secondary' onClick={handleRemoveLink} autoFocus>
							Remove Link
						</Button>
						<Button variant='contained' onClick={handleInsertLink} autoFocus>
							Save
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Box>
	)
}

export default EditorBase
