import { useState, useRef, useContext, useEffect } from 'react'
import { Box } from '@mui/material'
import { ChatEditor } from 'kitfit-community-common'

import HeaderContext from './context/headerContext'

const demoChatMessage = {
	text: 'Looks to me like there\'s a [missing](http://local.test:3000/editordemochat) DNS record - our nameservers no longer have a record of this sub-domain @Peter do **you** _know_ of any config changes? 😃',
	attachments: [
		{
			id: 'kmf102',
			name: 'A picture of a cat.jpg',
			thumbNail: 'https://placekitten.com/64/64'
		}
	]
}

const EditorDemoChat = () => {
	const headerContext = useRef(useContext(HeaderContext))
	useEffect(() => {
		headerContext.current.setHeader('Chat editor demo')
	}, [])

	const [chatMessage, setChatMessage] = useState(demoChatMessage)

	const removeAttachment = (attachment: any) => {
		console.log('remove attachment', attachment)
	}

	const addAttachment = (attachment: any) => {
		console.log('add attachment', attachment)
	}

	const handleSend = () => {
		console.log('send', chatMessage)
	}

	return !chatMessage ? null : (
		<Box sx={{ width: '40rem', m: 2 }}>
			<ChatEditor
				chatMessage={chatMessage}
				setChatMessage={setChatMessage}
				addAttachment={addAttachment}
				removeAttachment={removeAttachment}
				handleSend={handleSend}
			/>
		</Box>
	)
}

export default EditorDemoChat
