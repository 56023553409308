import { useContext, useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'

import Copyright from './components/Copyright'
import ThemeContext from './context/themeContext'

const NotFound = () => {
	const themeContext = useRef(useContext(ThemeContext))
	useEffect(() => {
		const currentContext = themeContext.current
		currentContext.setTheme(false)
		return () => {
			currentContext.setTheme(true)
		}
	}, [])

	return (
		<Container component="main" maxWidth="xs" sx={{ pt: 2 }}>
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					padding: '1rem 2rem',
					backgroundColor: 'background.paper',
					borderRadius: '0.25rem'
				}}
			>
				<h1>404 Not found</h1>
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<Box>
						<p>
							Sorry, but we cannot find that.<br />
							Maybe try on the <Link href="/" variant="body2">home page</Link>?
						</p>
					</Box>
					<Box>
						<img alt="Not Found" src='/404.gif' style={{ marginBottom: '-1.35rem' }} />
					</Box>
				</Box >
			</Box >
			<Copyright sx={{ mt: 8, pb: 8 }} />
		</Container >
	)
}

export default NotFound
