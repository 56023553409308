enum PriorityEnum {
	'LOW' = 'LOW',
	'MEDIUM' = 'MEDIUM',
	'HIGH' = 'HIGH'
}

export const comparePriority = (a: PriorityEnum, b: PriorityEnum): number => {
	const order = [PriorityEnum.HIGH, PriorityEnum.MEDIUM, PriorityEnum.LOW]
	return order.indexOf(a) - order.indexOf(b)
}

export default PriorityEnum
