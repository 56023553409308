import { FormControl, MenuItem, Select, SxProps } from '@mui/material'

import PriorityEnum from '../enums/PriorityEnum'
import PriorityStyles from '../enums/PriorityStyles'

interface PrioritySelectorProps {
	value: PriorityEnum | '',
	setValue: (value: PriorityEnum | '') => void,
	allowAll?: boolean,
	sx?: SxProps,
	inputSx?: SxProps
}

const PrioritySelector: React.FC<PrioritySelectorProps> = (props) => {
	const { value, setValue, allowAll, sx = {}, inputSx = {} } = props
	return (
		<FormControl sx={{ minWidth: '10rem', ...sx }} size="small">
			<Select
				displayEmpty
				value={value}
				onChange={e => setValue(e.target.value as PriorityEnum)} // Ref: https://github.com/mui/material-ui/issues/33399
				inputProps={{ sx: { display: 'flex', background: '#fff', ...inputSx } }}
			>
				{allowAll && <MenuItem value='' sx={{ display: 'flex' }}>All Priorities</MenuItem>}
				{Object.keys(PriorityEnum).map((key: string) => {
					const PriorityStyle = PriorityStyles[key as PriorityEnum]
					return <MenuItem key={key} value={key} sx={{ display: 'flex' }}><PriorityStyle.Icon sx={{ mr: 1, color: PriorityStyle.Colour }} />{PriorityStyle.Title}</MenuItem>
				})}
			</Select>
		</FormControl>
	)
}

export default PrioritySelector
