import { useEffect, useRef } from 'react'

const useOutsideClick = (ref: any, callback: any) => {
	const cb = useRef(callback)
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target)) {
				cb.current()
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref])
}

export default useOutsideClick
