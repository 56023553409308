import { Box } from '@mui/material'

import FilterButton from './components/FilterButton'

const InsertFilterDemo = () => {
	return (
		<Box>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				Insert filter Demo
				<FilterButton
					filterAttribute='finance.mrr'
					filterModifier='isGreaterThan'
					onChange={(value: any) => {
						console.log('change', value)
					}}
				/>
			</Box>
		</Box>
	)
}

export default InsertFilterDemo
