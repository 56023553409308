import React from 'react'

export type OnboardingType = {
	complete?: boolean,
	step: string,
	name?: string,
	communityId?: string,
	logoStorageLocation?: string,
	logoDownloadUrl?: string,
	colourScheme?: string
}

interface OnboardingInterface {
	completeStep: (onboarding: OnboardingType) => void,
	onboarding?: OnboardingType
}

const OnboardingContext = React.createContext<OnboardingInterface>({
	completeStep: () => ({ complete: false, step: '' }),
	onboarding: undefined
})

export default OnboardingContext
