enum ArticleStatusEnum {
	'DRAFT' = 'DRAFT',
	'PUBLISHED' = 'PUBLISHED'
}

export const compareStatus = (a: ArticleStatusEnum, b: ArticleStatusEnum): number => {
	const order = [ArticleStatusEnum.DRAFT, ArticleStatusEnum.PUBLISHED]
	return order.indexOf(a) - order.indexOf(b)
}

export default ArticleStatusEnum
