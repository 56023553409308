import update from 'immutability-helper'
import { useState, useCallback } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import ButtonGroup from '@mui/material/ButtonGroup'
import DesktopIcon from '@mui/icons-material/Tv'
import TabletIcon from '@mui/icons-material/Tablet'
import MobileIcon from '@mui/icons-material/Smartphone'
import stringify from 'json-stable-stringify'
import { v4 as uuidv4 } from 'uuid'

// import { Editor } from './BlockEditor/Editor'
import Blocks, { BlockValues } from './Blocks'
import Block, { OnChangeProps } from './Blocks/Block'
import InsertTypes from './enums/InsertTypes'

/*
	Note: You'd think we could just use a 3rd part block editor (eg: Smartblock, Editor.js, Automattic block, etc.), however all of them suffer from either terrible React support, (older than 18), are abandoned, and/or are limited/clumsy in how things can be integrated (I'm looking at you Remirror, grrr). TLDR; I've been unable to find a block-based editor that is suitable for our requirements.

	## Block types

	We specify blocks so they are display/style/implementation agnostic - that is to say we can generate Markdown/HTML or any other formats we wish, but more importantly we get clean JSON, (rather than browser specific styling and such). For example:

	Note: format based on: https://editorjs.io/saving-data

	Example for lists:

	{
		type: 'list',
		data: {
			style: 'unordered',
			items: [
			   'It is a **block-styled** editor',
			   'It returns _clean_ data output in JSON',
			   'Designed to be extendable and pluggable with a simple API'
			]
		}
	}
*/

export type BlockType = 'header' | 'image' | 'list' | 'paragraph' | 'pinnedarticle' | 'populararticle' | 'popularquestion' | 'search' | 'topheader' | 'product'

export interface BlockItem<T = unknown> {
	id: string,
	type: BlockType,
	data: T // type argument provided by the block implementation
}

interface BlockEditorProps {
	blocks: Array<BlockItem>,
	setBlocks: (blocks: Array<BlockItem>) => void,
	allowedBlockTypes?: Array<BlockType>
}

export const initialiseBlocks = (template: Array<BlockType>) => {
	const blocks: Array<BlockItem> = template.map((blockName) => {
		const blockValue = BlockValues[blockName]
		return { ...blockValue, id: uuidv4() }
	})
	return blocks
}

const BlockEditor: React.FC<BlockEditorProps> = ({ blocks: blocksExternal, setBlocks: setBlocksExternal, allowedBlockTypes }) => {
	const [blocks, setBlocks] = useState(blocksExternal)

	const blocksChanged = useCallback(({ prevBlocks, blocks }: { prevBlocks: Array<BlockItem>, blocks: Array<BlockItem> }) => {
		if (stringify(prevBlocks) !== stringify(blocks)) {
			setBlocksExternal(blocks)
		}
	}, [setBlocksExternal])

	const moveBlock = useCallback((dragIndex: number, hoverIndex: number) => {
		setBlocks((blocks) => {
			const newBlocks = update(blocks, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, blocks[dragIndex]]
				]
			})
			blocksChanged({ prevBlocks: blocks, blocks: newBlocks })
			return newBlocks
		})
	}, [blocksChanged])

	const [breakpointWidth, setBreakpointWidth] = useState('100%')

	const handleBlockOnChange = ({ prevBlock, block }: OnChangeProps) => {
		if (stringify(prevBlock) !== stringify(block)) {
			const newBlocks = blocks.map(pBlock => pBlock.id === block.id ? { ...block } : pBlock)
			blocksChanged({ prevBlocks: blocks, blocks: newBlocks })
			setBlocks(newBlocks)
		}
	}

	const addBlockClick = (blockName: BlockType, where: InsertTypes, index: number) => {
		//	Testing by adding a new paragraph for now.
		const newBlocks: Array<BlockItem> = []
		const blockValue = BlockValues[blockName]

		blocks.forEach((block, bIndex) => {
			if (where === InsertTypes.Above && bIndex === index) {
				newBlocks.push({ ...blockValue, id: uuidv4() })
			}
			newBlocks.push(block)
			if (where === InsertTypes.Below && bIndex === index) {
				newBlocks.push({ ...blockValue, id: uuidv4() })
			}
		})
		blocksChanged({ prevBlocks: blocks, blocks: newBlocks })
		setBlocks(newBlocks)
	}

	const removeBlockClickBinder = (index: number) => () => {
		const newBlocks = blocks.filter((_block, bIndex) => bIndex !== index)
		blocksChanged({ prevBlocks: blocks, blocks: newBlocks })
		setBlocks(newBlocks)
	}

	return (
		<Box>
			<Box sx={{ height: '3.5rem', backgroundColor: '#fafafa', display: 'flex', justifyContent: 'center', alignContent: 'center', padding: '0.75rem 0' }}>
				<ButtonGroup size='small' variant='contained'>
					<Button onClick={() => setBreakpointWidth('100%')} title='100% wide' sx={breakpointWidth === '100%' ? { backgroundColor: '#fff', color: '#000' } : { backgroundColor: '#fff', color: '#aaa' }}><DesktopIcon /></Button>
					<Button onClick={() => setBreakpointWidth('990px')} title='990px wide' sx={breakpointWidth === '990px' ? { backgroundColor: '#fff', color: '#000' } : { backgroundColor: '#fff', color: '#aaa' }}><TabletIcon /></Button>
					<Button onClick={() => setBreakpointWidth('375px')} title='375px wide' sx={breakpointWidth === '375px' ? { backgroundColor: '#fff', color: '#000' } : { backgroundColor: '#fff', color: '#aaa' }}><MobileIcon /></Button>
				</ButtonGroup>
			</Box>
			<Box sx={{ backgroundColor: '#fff', padding: '1rem 2rem' }}>
				{blocks.map((block, index) => {
					const BlockTag = Blocks[block.type]
					return (
						<Block
							key={block.id}
							index={index}
							id={block.id}
							moveBlock={moveBlock}
							breakpointWidth={breakpointWidth}
							addBlockClick={addBlockClick}
							removeBlockClickBinder={removeBlockClickBinder}
							allowedBlockTypes={allowedBlockTypes}
						>
							<BlockTag
								block={block as BlockItem<never>}
								onChange={handleBlockOnChange}
							/>
						</Block>
					)
				})}
			</Box>
		</Box>
	)
}

export * from './enums'

export default BlockEditor
