import DisplayProductsGallery from './DisplayProductsGallery'
import DisplayProductsList from './DisplayProductsList'
import { ModeEnum } from './enums'
import { ProductIndexType } from './types'

interface DisplayProductsProps {
	products: Array<ProductIndexType>,
	displayNumber: number,
	displayPrices: boolean,
	displayMode: ModeEnum
}

const DisplayProducts: React.FC<DisplayProductsProps> = ({ displayMode, ...rest }) => {
	return (displayMode === ModeEnum.gallery) ? (
		<DisplayProductsGallery {...rest} />
	) : (
		<DisplayProductsList {...rest} />
	)
}

export default DisplayProducts
