export enum SearchTypeEnum {
	livesearch = 'livesearch',
	selectproducts = 'selectproducts'
}

export enum StepsEnum {
	search = 'search',
	display = 'display'
}

export enum ModeEnum {
	list = 'List',
	gallery = 'Gallery'
}
