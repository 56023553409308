enum QuestionStatusEnum {
	'DRAFT' = 'DRAFT',
	'PUBLISHED' = 'PUBLISHED'
}

export const compareQuestionStatus = (a: QuestionStatusEnum, b: QuestionStatusEnum): number => {
	const order = [QuestionStatusEnum.DRAFT, QuestionStatusEnum.PUBLISHED]
	return order.indexOf(a) - order.indexOf(b)
}

export default QuestionStatusEnum
