import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter, SyntaxHighlighterProps } from 'react-syntax-highlighter'

import './Markdown.css'

const Markdown = (props: any): JSX.Element | null => {
	const { children } = props
	return (
		<ReactMarkdown
			className='markdown-body'	//	Required by Markdown.css
			children={children}
			remarkPlugins={[remarkGfm]}
			components={{
				// Rewrite ems to i so it works with content editable - this is reversed automatically when converting to Markdown
				em: ({ node, ...props }) => {
					return <i {...props} />
				},
				// Rewrite strong to b so it works with content editable - this is reversed automatically when converting to Markdown
				strong: ({ node, ...props }) => {
					return <b {...props} />
				},
				//	Syntax highlight code, ensure it wraps
				code: ({ node, inline, className, children, ...props }) => {
					const match = /language-(\w+)/.exec(className || '')
					return !inline && match ? (
						<SyntaxHighlighter
							customStyle={{
								whiteSpace: 'pre-wrap',
								wordWrap: 'break-word'
							}}
							language={match[1]}
							PreTag="div"
							{...props as SyntaxHighlighterProps}
							children={String(children).replace(/\n$/, '')}
						/>
					) : (
						<code className={className} style={{
							whiteSpace: 'pre-wrap',
							wordWrap: 'break-word'
						}} {...props}>
							{children}
						</code>
					)
				}
			}}
		/>
	)
}

export default Markdown
