//	Note: This is a work in progress...
import { Clear, Search } from '@mui/icons-material'
import { Box, Button, InputAdornment, Paper, TextField } from '@mui/material'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { forwardRef, useState } from 'react'
import { boolean } from 'yup'
import { number } from 'yup/lib/locale'

const filterMenu = [
	{ name: 'Churned', value: 'customer.isChurned', type: boolean },
	{ name: 'VIP', value: 'customer.isVip', type: boolean },
	{ name: 'Health score', value: 'customer.healthScore', type: number },
	{
		name: 'Financial',
		value: 'financial',
		children: [
			{ name: 'MRR', value: 'financial.mrr', type: number },
			{ name: 'ARR', value: 'financial.arr', type: number },
			{ name: 'Currency code', value: 'financial.currencyCode', type: String }
		]
	},
	{
		name: 'Surveys and VOC',
		value: 'surveysAndVoc',
		children: [
			//	Ref: https://delighted.com/blog/choose-customer-satisfaction-metric-csat-nps-ces
			{ name: 'NPS', value: 'nps', type: number },
			{ name: 'CSAT', value: 'csat', type: number },
			{ name: 'CES', value: 'ces', type: number },
			//	The idea here is that we allow feature votes to be passed in - these are likely abitrary values the client passes in.
			{ name: 'Some new feature', value: 'someNewFeature', type: number },
			{ name: 'Some other feature', value: 'someOtherFeature', type: number }
		]
	}
]

const filterModifiers = {
	number: [
		{ name: 'is greater than', value: 'isGreaterThan' },
		{ name: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo' },
		{ name: 'is less than', value: 'isLessThan' },
		{ name: 'is less than or equal to', value: 'isLessThanOrEqualTo' },
		{ name: 'is between', value: 'isBetween' },
		{ name: 'is equal to', value: 'isEqualTo' }
	]
}

const FilterButton = (props: any, ref: any) => {
	const [showFilterSelector, setShowFilterSelector] = useState(props.filterAttribute && !props.filterValue ? true : false)
	const [showMenuKey, setShowMenuKey] = useState(null)
	const [filterAttribute, setFilterAttribute] = useState<string | null>(props.filterAttribute || null)
	const [filterModifier, setFilterModifier] = useState<string | null>(props.filterModifier || null)
	const [filterValue, setFilterValue] = useState<string>(props.filterValue || '')
	// making this work with FormControl
	const name = props.name

	//	For when we have attrib, modif, value set.
	const onChange = props.onChange

	const renderMenu = (menu: any, index?: number, forceMenu?: boolean) => {
		const key = menu.name + (index || 0)
		const hasChildren = !!menu.children
		const isDisplay = showMenuKey && showMenuKey === key

		return (
			<Box key={key} sx={{ overflowX: 'hidden', cursor: 'pointer' }}>
				{!hasChildren && (!showMenuKey || isDisplay || forceMenu) && <Box onClick={() => setFilterAttribute(menu.value)}>{menu.name}</Box>}
				{hasChildren && (
					<Box sx={{ display: showMenuKey && showMenuKey !== key && !forceMenu ? 'none' : 'flex', width: '32rem', transition: 'margin-left 0.25s', ...(showMenuKey === key ? { marginLeft: '-16rem' } : []) }}>
						<Box onClick={() => setShowMenuKey(showMenuKey === key ? null : key)} sx={{ display: 'flex', width: '16rem' }}>
							{menu.name} <ArrowRightIcon />
						</Box>
						<Box sx={{ display: isDisplay ? 'block' : 'none', width: '16rem' }}>
							<Box onClick={() => setShowMenuKey(showMenuKey === key ? null : key)} sx={{ display: 'flex', width: '16rem', fontWeight: 'bold' }}>
								<ArrowLeftIcon /> {menu.name}
							</Box>
							{menu.children.map((child: any, index: number) => (
								<Box key={child.name + (index || 0)} sx={{ ml: 4 }}>{renderMenu(child, (index || 0) + 1, showMenuKey === key)}</Box>
							))}
						</Box>
					</Box>
				)}
			</Box>
		)
	}

	const fmInstance = filterModifiers['number'].find(fm => fm.value === filterModifier)

	return (
		<div onClick={(e) => {
			e.stopPropagation()
		}}>
			<Button sx={{ maxWidth: '12rem' }} variant='contained' onClick={() => setShowFilterSelector(true)}>
				{filterAttribute ? filterAttribute : 'Add filter +'}
				{filterModifier && fmInstance && ` ${fmInstance.name}`}
				{filterValue && ` ${filterValue}`}
			</Button>
			{showFilterSelector && (
				<Box component={Paper} sx={{ width: '16rem', position: 'relative', top: '0.2rem', p: 1 }}>
					{!filterAttribute && !filterModifier && <TextField
						size='small'
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Search />
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									<Clear />
								</InputAdornment>
							)
						}}
						placeholder='Search'
					/>}
					{!filterAttribute && !filterModifier && filterMenu.map(menu => renderMenu(menu))}
					{filterAttribute && !filterModifier && (
						<>
							<Box onClick={() => setFilterAttribute(null)} sx={{ display: 'flex', cursor: 'pointer', width: '16rem', fontWeight: 'bold' }}>
								<ArrowLeftIcon /> {filterAttribute}
							</Box>
							<Box sx={{ ml: 4, cursor: 'pointer' }}>
								{filterModifiers['number'].map((fm: any) => (
									<Box key={fm.value} onClick={() => setFilterModifier(fm.value)}>{fm.name}</Box>
								))}
							</Box>
						</>
					)}
					{filterAttribute && filterModifier && (
						<>
							<Box onClick={() => {
								setFilterModifier(null)
								setFilterValue('')
							}} sx={{ display: 'flex', cursor: 'pointer', width: '16rem', fontWeight: 'bold' }}>
								<ArrowLeftIcon /> {filterAttribute} {filterModifier}
							</Box>
							<TextField
								size='small'
								placeholder={filterModifier}
								value={filterValue}
								onChange={e => setFilterValue(e.target.value)}
							/>
							<Button onClick={() => {
								setShowFilterSelector(false)
								onChange({
									target: {
										name,
										value: [filterAttribute, filterModifier, filterValue]
									}
								})
							}}>Okay</Button>
						</>
					)}
				</Box>
			)}
		</div>
	)
}

export default forwardRef(FilterButton)
