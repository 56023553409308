import { useRef, useContext, useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-translatify-next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { uuidv4 } from '@firebase/util'

import HeaderContext from './context/headerContext'
import { customerSegmentSchema, customerSegmentStageSchema, CustomerSegmentStageType, CustomerSegmentType, PlaybookSummaryType, useAdminCommunityContext } from './context/AdminCommunityContext'
import FilterButton from './components/FilterButton'
import CustomerSegment from './components/CustomerSegment'
import CustomerSegmentStage from './components/CustomerSegmentStage'

const Segments = () => {
	const headerContext = useRef(useContext(HeaderContext))
	useEffect(() => {
		headerContext.current.setHeader('Customer segments configuration')
	}, [])
	const { translate } = useTranslation()

	const { customerSegments, saveCustomerSegment, canDeleteCustomerSegment, deleteCustomerSegment, canDeleteCustomerSegmentStage } = useAdminCommunityContext()

	const { register: registerSegment, handleSubmit: handleSubmitSegment, reset: resetSegment, formState: { errors: errorsSegment }, watch: watchSegment } = useForm<CustomerSegmentType>({ defaultValues: { name: '', description: '' }, resolver: yupResolver(customerSegmentSchema) })
	const form = useRef<HTMLFormElement>(null)

	const doSubmit = () => {
		if (form.current) {
			form.current.requestSubmit()
		}
	}

	const [segmentAnchorEl, setSegmentAnchorEl] = useState<HTMLElement>()
	const [menuSegment, setMenuSegment] = useState<Partial<CustomerSegmentType>>()
	const [editSegment, setEditSegment] = useState<Partial<CustomerSegmentType>>()
	const handleEditSegmentDialogClose = () => {
		setEditSegment(undefined)
	}
	const handleEditSegment = (data: CustomerSegmentType) => {
		handleEditSegmentDialogClose()
		saveCustomerSegment({ ...data, ...(editSegment?.stages ? { stages: editSegment?.stages } : {}) })
	}
	const [deleteSegment, setDeleteSegment] = useState<CustomerSegmentType>()
	const [canDeleteSegment, setCanDeleteSegment] = useState<boolean>()
	const [playbooks, setPlaybooks] = useState<Array<PlaybookSummaryType>>()
	useEffect(() => {
		setCanDeleteSegment(undefined)
		setPlaybooks(undefined)
		const checkCanDeleteSegment = async (segment: CustomerSegmentType) => {
			const { canDelete, playbooks } = await canDeleteCustomerSegment(segment)
			setPlaybooks(playbooks)
			setCanDeleteSegment(canDelete)
		}
		if (deleteSegment) {
			checkCanDeleteSegment(deleteSegment)
		}
	}, [canDeleteCustomerSegment, deleteSegment])
	const handleDeleteSegmentDialogClose = () => {
		setDeleteSegment(undefined)
	}
	const handleDeleteSegment = () => {
		handleDeleteSegmentDialogClose()
		if (deleteSegment) {
			deleteCustomerSegment(deleteSegment)
		}
	}

	const newSegment = () => ({
		order: customerSegments && Object.keys(customerSegments).length ? Object.values(customerSegments).reduce((max, segment) => (Math.max(max, segment.order)), 0) + 1 : 0
	})
	useEffect(() => {
		if (editSegment) {
			resetSegment(editSegment)
		}
	}, [editSegment, resetSegment])

	const moveSegment = (from: number, to: number) => {
		if (customerSegments) {
			let segments = Object.values(customerSegments).sort((a, b) => a.order - b.order)
			const segment = segments.splice(from, 1)[0]
			segments = [...segments.slice(0, to), segment, ...segments.slice(to)]
			segments.forEach((s, i) => {
				if (s.order !== i) {
					saveCustomerSegment({ segmentId: s.segmentId, order: i })
				}
			})
		}
	}

	const { register: registerStage, handleSubmit: handleSubmitStage, reset: resetStage, formState: { errors: errorsStage } } = useForm<CustomerSegmentStageType>({ defaultValues: { name: '' }, resolver: yupResolver(customerSegmentStageSchema) })

	const [menuStage, setMenuStage] = useState<Partial<CustomerSegmentStageType>>()
	const [editStage, setEditStage] = useState<Partial<CustomerSegmentStageType>>()
	const handleEditStage = (data: CustomerSegmentStageType) => {
		setEditStage(undefined)
		setEditSegment((editSegment) => {
			if (!editSegment) {
				return editSegment
			}
			const stages = [
				...(editSegment.stages || []).filter(stage => stage.stageId !== data.stageId),
				data
			]
			return {
				...editSegment,
				stages
			}
		})
	}
	const [deleteStage, setDeleteStage] = useState<CustomerSegmentStageType>()
	const [canDeleteStage, setCanDeleteStage] = useState<boolean>()
	useEffect(() => {
		setCanDeleteStage(undefined)
		setPlaybooks(undefined)
		const checkCanDeleteStage = async (stage: CustomerSegmentStageType) => {
			const { canDelete, playbooks } = await canDeleteCustomerSegmentStage(editSegment as CustomerSegmentType, stage)
			setPlaybooks(playbooks)
			setCanDeleteStage(canDelete)
		}
		if (deleteStage) {
			checkCanDeleteStage(deleteStage)
		}
	}, [canDeleteCustomerSegmentStage, deleteStage, editSegment])
	const handleDeleteStageDialogClose = () => {
		setDeleteStage(undefined)
	}
	const handleDeleteStage = () => {
		handleDeleteStageDialogClose()
		if (deleteStage) {
			setEditSegment((editSegment) => {
				if (!editSegment?.stages) {
					return editSegment
				}
				let stages = editSegment.stages.filter(s => s.stageId !== deleteStage.stageId)
				stages = stages.map((s, i) => ({ ...s, order: i }))
				return {
					...editSegment,
					stages
				}
			})
		}
	}

	const newStage = () => ({
		stageId: uuidv4(),
		name: '',
		order: editSegment?.stages && Object.keys(editSegment.stages).length ? Object.values(editSegment.stages).reduce((max, stage) => (Math.max(max, stage.order)), 0) + 1 : 0
	})
	useEffect(() => {
		if (editStage) {
			resetStage(editStage)
		}
	}, [editStage, resetStage])
	const [stageAnchorEl, setStageAnchorEl] = useState<HTMLElement>()
	const moveStage = (from: number, to: number) => {
		setEditSegment((editSegment) => {
			if (!editSegment?.stages) {
				return editSegment
			}
			let stages = [...editSegment.stages.sort((a, b) => a.order - b.order)]
			const stage = stages.splice(from, 1)[0]
			stages = [...stages.slice(0, to), stage, ...stages.slice(to)]
				.map((s, i) => ({ ...s, order: i }))
			return {
				...editSegment,
				stages
			}
		})
	}

	const [filterAttribute, filterModifier, filterValue] = editSegment?.criteria || []

	return (
		<Box sx={{ display: 'block', p: 0, width: '100%' }}>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow sx={{ backgroundColor: '#fafafa' }}>
							<TableCell style={{ width: '0' }} sx={{ fontWeight: 'bold' }}></TableCell>
							<TableCell sx={{ fontWeight: 'bold' }}>{translate('Name')}</TableCell>
							<TableCell sx={{ fontWeight: 'bold' }}>{translate('Stages')}</TableCell>
							<TableCell sx={{ fontWeight: 'bold' }}>{translate('Description')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{customerSegments ? (
							Object.values(customerSegments)
								.sort((a, b) => a.order - b.order)
								.map((segment, i) => (
									<CustomerSegment
										segment={segment}
										segmentIndex={i}
										key={i}
										openMenu={(el) => {
											setMenuSegment(segment)
											setSegmentAnchorEl(el)
										}}
										moveSegment={moveSegment}
										editSegment={() => {
											setEditSegment(segment)
										}}
									/>
								))
						) : (
							<TableRow>
								<TableCell colSpan={5}>
									<CircularProgress />
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
				<Button onClick={() => setEditSegment(newSegment())}>New segment</Button>
				<Menu
					anchorEl={segmentAnchorEl}
					open={!!segmentAnchorEl}
					onClose={() => setSegmentAnchorEl(undefined)}
				>
					<MenuItem onClick={() => {
						setSegmentAnchorEl(undefined)
						setEditSegment(menuSegment)
					}}>Edit</MenuItem>
					<MenuItem onClick={() => {
						setSegmentAnchorEl(undefined)
						setDeleteSegment(menuSegment as CustomerSegmentType)
					}}>Delete</MenuItem>
				</Menu>
			</TableContainer>
			{!!editSegment && (
				<Dialog
					open={!!editSegment}
					onClose={handleEditSegmentDialogClose}
				>
					<DialogTitle id="responsive-dialog-title">
						{editSegment.segmentId ? 'Edit customer segment' : 'Add customer segment'}
					</DialogTitle>
					<DialogContent>
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<Box component="form" ref={form} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: 450 }} onSubmit={handleSubmitSegment(handleEditSegment)}>
								<TextField
									margin="normal"
									fullWidth
									id="name"
									label="Segment Name"
									autoFocus
									{...registerSegment('name')}
								/>
								{errorsSegment?.name?.message && <Typography component="div" sx={{ color: '#F00' }}>{errorsSegment.name.message}</Typography>}
								<TextField
									margin="normal"
									fullWidth
									id="description"
									label="Description"
									{...registerSegment('description')}
								/>
								{errorsSegment?.description?.message && <Typography component="div" sx={{ color: '#F00' }}>{errorsSegment.description.message}</Typography>}
								<FormControl
									component={FilterButton}
									margin="normal"
									fullWidth
									id="criteria"
									label="Criteria"
									filterAttribute={filterAttribute}
									filterModifier={filterModifier}
									filterValue={filterValue}
									{...registerSegment('criteria')}
								/>
								{errorsSegment?.criteria?.message && <Typography component="div" sx={{ color: '#F00' }}>{errorsSegment.criteria.message}</Typography>}
							</Box>
							{!!watchSegment('criteria') && (
								<TableContainer component={Paper}>
									<form onSubmit={handleSubmitStage(handleEditStage)}>
										<Table aria-label='simple table'>
											<TableHead>
												<TableRow sx={{ backgroundColor: '#fafafa' }}>
													<TableCell style={{ width: '0' }} sx={{ fontWeight: 'bold' }}></TableCell>
													<TableCell sx={{ fontWeight: 'bold' }}>{translate('Name')}</TableCell>
													<TableCell sx={{ fontWeight: 'bold' }}>{translate('Filter')}</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{Object.values(editSegment.stages || [])
													.sort((a, b) => a.order - b.order)
													.map((stage, i) => {
														return stage.stageId === editStage?.stageId ? (
															<TableRow
																key={i}
																sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'cursor': 'pointer', ':hover': { backgroundColor: '#f3f3f3' } }}
															>
																<TableCell></TableCell>
																<TableCell>
																	<TextField
																		margin="normal"
																		fullWidth
																		id="name"
																		label="Segment Name"
																		autoFocus
																		{...registerStage('name')}
																	/>
																	{errorsStage?.name?.message && <Typography component="div" sx={{ color: '#F00' }}>{errorsStage.name.message}</Typography>}
																</TableCell>
																<TableCell>
																	<FormControl
																		key={`edit-${i}`} // ensure the instance is not shared between edit and read mode (lets cancel work)
																		component={FilterButton}
																		margin="normal"
																		fullWidth
																		id="filter"
																		label="Filter"
																		filterAttribute={stage.filter[0]}
																		filterModifier={stage.filter[1]}
																		filterValue={stage.filter[2]}
																		{...registerStage('filter')}
																	/>
																	{errorsStage?.filter?.message && <Typography component="div" sx={{ color: '#F00' }}>{errorsStage.filter.message}</Typography>}
																	<Button variant='contained' onClick={() => {
																		setEditStage(undefined)
																	}}>Cancel</Button><Button type='submit' variant='contained'>Save</Button>
																</TableCell>
															</TableRow>
														) : (
															<CustomerSegmentStage
																key={i}
																stage={stage}
																stageIndex={i}
																openMenu={(el) => {
																	setMenuStage(stage)
																	setStageAnchorEl(el)
																}}
																editFilter={(val) => {
																	handleEditStage({ ...stage, filter: val })
																}}
																moveStage={moveStage}
																draggable={editStage === undefined}
																editStage={() => {
																	setEditStage(stage)
																}}
															/>
														)
													})
												}
												{!!editStage && !editSegment.stages?.find(s => s.stageId === editStage.stageId) && (
													<TableRow
														sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'cursor': 'pointer', ':hover': { backgroundColor: '#f3f3f3' } }}
													>
														<TableCell></TableCell>
														<TableCell>
															<TextField
																margin="normal"
																fullWidth
																id="name"
																label="Segment Name"
																autoFocus
																{...registerStage('name')}
															/>
															{errorsStage?.name?.message && <Typography component="div" sx={{ color: '#F00' }}>{errorsStage.name.message}</Typography>}
														</TableCell>
														<TableCell>
															<FormControl
																component={FilterButton}
																margin="normal"
																fullWidth
																id="filter"
																label="Filter"
																{...registerStage('filter')}
															/>
															{errorsStage?.filter?.message && <Typography component="div" sx={{ color: '#F00' }}>{errorsStage.filter.message}</Typography>}
															<Button variant='contained' onClick={() => setEditStage(undefined)}>Cancel</Button><Button type='submit' variant='contained'>Save</Button>
														</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>
										<Button onClick={() => setEditStage(newStage())}>New stage</Button>
										<Menu
											anchorEl={stageAnchorEl}
											open={!!stageAnchorEl}
											onClose={() => setStageAnchorEl(undefined)}
										>
											<MenuItem onClick={() => {
												setStageAnchorEl(undefined)
												setEditStage(menuStage)
											}}>Edit</MenuItem>
											<MenuItem onClick={() => {
												setStageAnchorEl(undefined)
												setDeleteStage(menuStage as CustomerSegmentStageType)
											}}>Delete</MenuItem>
										</Menu>
									</form>
								</TableContainer>
							)}
						</Box>
					</DialogContent>
					<DialogActions sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }}>
						<Button variant='contained' color='secondary' onClick={handleEditSegmentDialogClose}>
							Cancel
						</Button>
						<Button variant='contained' onClick={doSubmit}>
							Save
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{!!deleteSegment && (
				<Dialog
					open={!!deleteSegment}
					onClose={handleDeleteSegmentDialogClose}
				>
					<DialogTitle id="responsive-dialog-title">
						Delete segment
					</DialogTitle>
					<DialogContent>
						{canDeleteSegment === undefined && (
							<CircularProgress />
						)}
						{canDeleteSegment ? (
							<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								Are you sure you want to delete "{deleteSegment.name}" segment?
							</Box>

						) : (
							<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								Cannot delete "{deleteSegment.name}" segment as it is used in the following playbooks:
								<Box>
									{playbooks?.map(playbook => (
										<Box key={playbook.playbookId}>{playbook.name}</Box>
									))}
								</Box>
								You must remove the logic from that playbook first.
							</Box>
						)}
					</DialogContent>
					<DialogActions sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }}>
						<Button variant='contained' color='secondary' onClick={handleDeleteSegmentDialogClose}>
							Cancel
						</Button>
						<Button disabled={!canDeleteSegment} variant='contained' onClick={handleDeleteSegment}>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{!!deleteStage && (
				<Dialog
					open={!!deleteStage}
					onClose={handleDeleteStageDialogClose}
				>
					<DialogTitle id="responsive-dialog-title">
						Delete stage
					</DialogTitle>
					<DialogContent>
						{canDeleteStage === undefined && (
							<CircularProgress />
						)}
						{canDeleteStage ? (
							<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								Are you sure you want to delete "{deleteStage.name}" stage?
							</Box>

						) : (
							<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								Cannot delete "{deleteStage.name}" stage as it is used in the following playbooks:
								<Box>
									{playbooks?.map(playbook => (
										<Box key={playbook.playbookId}>{playbook.name}</Box>
									))}
								</Box>
								You must remove the logic from that playbook first.
							</Box>
						)}
					</DialogContent>
					<DialogActions sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }}>
						<Button variant='contained' color='secondary' onClick={handleDeleteStageDialogClose}>
							Cancel
						</Button>
						<Button disabled={!canDeleteStage} variant='contained' onClick={handleDeleteStage}>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Box>
	)
}

export default Segments
