import { useRef, useContext, useEffect } from 'react'
import { Box, Button } from '@mui/material'
import { UsersIcon } from 'kitfit-community-common'
import { useNavigate } from 'react-router'

import HeaderContext from './context/headerContext'

const Settings = () => {
	const headerContext = useRef(useContext(HeaderContext))
	useEffect(() => {
		headerContext.current.setHeader('Settings')
	}, [])
	const navigate = useNavigate()

	return (
		<Box sx={{ display: 'block', p: 0, width: '100%' }}>
			<Button variant='outlined' onClick={() => navigate('/settings/segments')}><UsersIcon fill={'#6AA84F'} sx={{ mr: 1 }} /> Customer segments configuration</Button>
		</Box>
	)
}

export default Settings
