import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import React, { useState, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DragHandleIcon from '@mui/icons-material/DragIndicator'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'

import InsertTypes from '../enums/InsertTypes'
import { BlockItem, BlockType } from '..'
import { useOutsideClick } from '../../../../hooks'
import { HeadingIcon, ImageIcon, ListUlIcon, ParagraphIcon, UnknownBlockIcon } from '../../../icons'

const itemType = 'Block'

const defaultAllowedBlockTypes: Array<BlockType> = ['header', 'image', 'list', 'paragraph', 'product']

export interface BlockDisplayProps {
	id: any,
	children: React.ReactNode,
	index: number,
	moveBlock: (dragIndex: number, hoverIndex: number) => void,
	breakpointWidth: string,
	addBlockClick: any,
	removeBlockClickBinder: any,
	allowedBlockTypes?: Array<BlockType>
}

export interface OnChangeProps {
	prevBlock: BlockItem,
	block: BlockItem
}

interface BlockProps<T = unknown> {
	block: BlockItem<T>, // type argument provided by the block implementation
	readOnly?: boolean,
	onChange?: ({ prevBlock, block }: OnChangeProps) => void
}

export type BlockComponentType<T = unknown> = FC<BlockProps<T>>

interface DragItem {
	index: number,
	id: string,
	type: string
}

const Block: FC<BlockDisplayProps> = ({ id, children, index, moveBlock, breakpointWidth, addBlockClick, removeBlockClickBinder, allowedBlockTypes = defaultAllowedBlockTypes }) => {
	const ref = useRef<HTMLDivElement>(null)
	const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
		accept: itemType,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId()
			}
		},
		hover(item: DragItem, monitor) {
			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return
			}

			const hoverBoundingRect = ref.current?.getBoundingClientRect()
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

			// Determine mouse position
			const clientOffset = monitor.getClientOffset()

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}

			moveBlock(dragIndex, hoverIndex)

			item.index = hoverIndex
		}
	})

	const [showAddMenu, setShowAddMenu] = useState<boolean>()
	const [addMenuDims, setAddMenuDims] = useState<any>({})

	const [{ isDragging }, drag] = useDrag({
		type: itemType,
		item: () => {
			return { id, index }
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging()
		})
	})

	const opacity = isDragging ? 0.66 : 1
	drag(drop(ref))

	const buttonMenuRef = useRef(null)
	useOutsideClick(buttonMenuRef, () => setShowAddMenu(false))

	const [addBlockWhere, setAddBlockWhere] = useState<string>()
	const [addBlockIndex, setAddBlockIndex] = useState<number>()

	const addBlockClickBinder = (where: string, index: number) => (e: any) => {
		const target = e.target
		setAddBlockWhere(where)
		setAddBlockIndex(index)
		setAddMenuDims(() => {
			const { top, left, height, width } = target?.getBoundingClientRect() as DOMRect
			setShowAddMenu(true)
			return { top, left, height, width }
		})
	}

	const handleClickAddBlockBinder = (blockType: BlockType) => () => {
		addBlockClick(blockType, addBlockWhere, addBlockIndex)
		setShowAddMenu(false)
	}

	return (
		<Box ref={ref}
			sx={{
				'display': 'flex',
				'justifyContent': 'center',
				'border': (isDragging ? '1px solid #dbb' : '1px solid transparent'),
				'flexDirection': 'row',
				'position': 'relative',
				'opacity': opacity,
				'&:hover': {
					'& .action': {
						visibility: 'visible'
					}
				}
			}}
			data-handler-id={handlerId}
		>
			<Box className='action' sx={{ visibility: 'hidden', borderTopLeftRadius: '0.3rem', borderBottomLeftRadius: '0.3rem', display: 'flex', position: 'relative', padding: 0, paddingTop: '1rem', paddingBottom: '1rem', backgroundColor: '#fafafa', width: '3.6rem', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginRight: '1rem', cursor: 'move' }}>
				<IconButton onClick={addBlockClickBinder(InsertTypes.Above, index)} sx={{ width: '2rem', height: '2rem', position: 'absolute', top: '-1rem', backgroundColor: '#e6e6e6' }}><AddIcon /></IconButton>
				<DragHandleIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
				<IconButton onClick={addBlockClickBinder(InsertTypes.Below, index)} sx={{ width: '2rem', height: '2rem', position: 'absolute', bottom: '-1rem', backgroundColor: '#e6e6e6' }}><AddIcon /></IconButton>
			</Box>
			<Box style={{ width: breakpointWidth, transition: 'all 0.5s' }}>
				{children}
			</Box>
			<Box className='action' sx={{ visibility: 'hidden', borderTopRightRadius: '0.3rem', borderBottomRightRadius: '0.3rem', display: 'flex', padding: 0, paddingTop: '1rem', paddingBottom: '1rem', backgroundColor: '#fafafa', width: '3.6rem', alignItems: 'center', flexDirection: 'column', marginLeft: '1rem' }}>
				<IconButton onClick={removeBlockClickBinder(index)} sx={{ width: '2rem', height: '2rem', backgroundColor: '#e6e6e6' }}><DeleteIcon /></IconButton>
			</Box>
			{showAddMenu && (
				<Box ref={buttonMenuRef} sx={{
					'zIndex': 1,
					'position': 'fixed',
					'top': (addMenuDims.top + addMenuDims.height + 8) + 'px',
					'left': (addMenuDims.left) + 'px',
					'border': '1px solid #999',
					'boxShadow': '0px 3px 7px rgb(0 0 0 / 50%)',
					'borderRadius': '0.25rem',
					'display': 'flex',
					'flexDirection': 'column',
					'justifyContent': 'center',
					'backgroundColor': '#292A2B',
					'color': 'white',
					'padding': '0.25rem 0.5rem'
				}}>
					{allowedBlockTypes.includes('header') && <Button sx={{ color: 'white', justifyContent: 'flex-start' }} onClick={handleClickAddBlockBinder('header')}><HeadingIcon fill='white' sx={{ mr: 1 }} /> Header</Button>}
					{allowedBlockTypes.includes('paragraph') && <Button sx={{ color: 'white', justifyContent: 'flex-start' }} onClick={handleClickAddBlockBinder('paragraph')}><ParagraphIcon fill='white' sx={{ mr: 1 }} /> Paragraph</Button>}
					{allowedBlockTypes.includes('list') && <Button sx={{ color: 'white', justifyContent: 'flex-start' }} onClick={handleClickAddBlockBinder('list')}><ListUlIcon fill='white' sx={{ mr: 1 }} /> List</Button>}
					{allowedBlockTypes.includes('image') && <Button sx={{ color: 'white', justifyContent: 'flex-start' }} onClick={handleClickAddBlockBinder('image')}><ImageIcon fill='white' sx={{ mr: 1 }} /> Image</Button>}
					{allowedBlockTypes.includes('topheader') && <Button sx={{ color: 'white', justifyContent: 'flex-start' }} onClick={handleClickAddBlockBinder('topheader')}><UnknownBlockIcon fill='white' sx={{ mr: 1 }} /> TopHeader</Button>}
					{allowedBlockTypes.includes('search') && <Button sx={{ color: 'white', justifyContent: 'flex-start' }} onClick={handleClickAddBlockBinder('search')}><UnknownBlockIcon fill='white' sx={{ mr: 1 }} /> Search</Button>}
					{allowedBlockTypes.includes('pinnedarticle') && <Button sx={{ color: 'white', justifyContent: 'flex-start' }} onClick={handleClickAddBlockBinder('pinnedarticle')}><UnknownBlockIcon fill='white' sx={{ mr: 1 }} /> PinnedArticle</Button>}
					{allowedBlockTypes.includes('populararticle') && <Button sx={{ color: 'white', justifyContent: 'flex-start' }} onClick={handleClickAddBlockBinder('populararticle')}><UnknownBlockIcon fill='white' sx={{ mr: 1 }} /> PopularArticle</Button>}
					{allowedBlockTypes.includes('popularquestion') && <Button sx={{ color: 'white', justifyContent: 'flex-start' }} onClick={handleClickAddBlockBinder('popularquestion')}><UnknownBlockIcon fill='white' sx={{ mr: 1 }} /> PopularQuestion</Button>}
					{allowedBlockTypes.includes('product') && <Button sx={{ color: 'white', justifyContent: 'flex-start' }} onClick={handleClickAddBlockBinder('product')}><UnknownBlockIcon fill='white' sx={{ mr: 1 }} /> Product</Button>}
				</Box>
			)}
		</Box >
	)
}

export default Block
