import ReactDOMServer from 'react-dom/server'
import { NodeHtmlMarkdown } from 'node-html-markdown'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, ButtonProps } from '@mui/material'
import { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'

import Markdown from '../../utilities/Markdown'
import { EditorText } from '../Editors/EditorText'
import { useCommunityContext } from '../../../../context/CommunityContext'
import UploadFile from '../../../UploadFile'
import { BlockItem } from '..'

import { BlockComponentType } from './Block'

export interface TopHeaderBlockItemData {
	text: string,
	loginText: string,
	loginButtonProps?: ButtonProps,
	registerText: string,
	registerButtonProps?: ButtonProps
}
export const topHeaderValue: Omit<BlockItem<TopHeaderBlockItemData>, 'id'> = {
	type: 'topheader',
	data: {
		text: 'Top Header Text',
		loginText: 'Login',
		registerText: 'Register'
	}
}

const TopHeaderBlock: BlockComponentType<TopHeaderBlockItemData> = ({ block, readOnly = false, onChange }) => {
	const { community, setLogo, login, register } = useCommunityContext()
	const [showLogoDialog, setShowLogoDialog] = useState(false)

	const handleTextChange = ({ doc }: { doc: string }) => {
		if (onChange) {
			const text = NodeHtmlMarkdown.translate(doc)
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, text } }
			})
		}
	}

	const handleLoginTextChange = (loginText: string) => {
		if (onChange) {
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, loginText } }
			})
		}
	}

	const handleRegisterTextChange = (registerText: string) => {
		if (onChange) {
			onChange({
				prevBlock: { ...block },
				block: { ...block, data: { ...block.data, registerText } }
			})
		}
	}

	const [logoStorageLocation, setLogoStorageLocation] = useState<string>('')
	const [logoDownloadUrl, setLogoDownloadUrl] = useState<string>('')
	const uploadFile = async (logoStorageLocation: string, logoDownloadUrl: string) => {
		setLogoStorageLocation(logoStorageLocation)
		setLogoDownloadUrl(logoDownloadUrl)
	}

	const handleLogoDialogClose = () => {
		setShowLogoDialog(false)
	}
	const handleSaveImage = () => {
		handleLogoDialogClose()
		if (onChange && setLogo) {
			setLogo(logoStorageLocation, logoDownloadUrl)
		}
	}

	const handleLogin = () => {
		if (login) {
			login()
		}
	}

	const handleRegister = () => {
		if (register) {
			register()
		}
	}

	if (!community) {
		return null
	}
	const { text, loginText, registerText, loginButtonProps, registerButtonProps } = block.data
	const logo = `https://${community?.imageHostname}/${community?.communityId}/logo.png`

	return readOnly ? (
		<Box className='be-topheader' sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
			<Box sx={{ display: 'flex', mb: 2 }}>
				<Box sx={{ flex: '1' }}>
					<Box sx={{ maxHeight: '8rem' }} component='img' alt='logo' src={logoDownloadUrl || logo} />
				</Box>
				<Box>
					<Button onClick={handleLogin} {...(loginButtonProps || {})}>{loginText}</Button>
					<Button onClick={handleRegister} {...(registerButtonProps || {})}>{registerText}</Button>
				</Box>
			</Box>
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<Markdown children={text} />
			</Box>
		</Box>
	) : (
		<Box className='be-topheader'>
			<Box sx={{
				'display': 'flex',
				'position': 'relative',
				'&:hover': {
					'& .action': {
						display: 'block'
					}
				}
			}}>
				<Box className='action' sx={{ display: 'none', position: 'absolute', top: '1rem', left: '1rem' }}>
					<IconButton sx={{ width: '2rem', height: '2rem', backgroundColor: '#e6e6e6' }} onClick={() => setShowLogoDialog(true)}><EditIcon /></IconButton>
				</Box>
				<Box component='img' sx={{ width: '4rem', height: '4rem' }} src={logoDownloadUrl || logo} alt={'Logo'} />
			</Box>
			<TextField value={loginText} onChange={e => handleLoginTextChange(e.target.value)}/>
			<TextField value={registerText} onChange={e => handleRegisterTextChange(e.target.value)}/>
			<EditorText
				enableMenu={false}
				content={ReactDOMServer.renderToStaticMarkup(<Markdown children={text} />)}
				onChange={handleTextChange}
			/>
			{!!showLogoDialog && (
				<Dialog
					open={showLogoDialog}
					onClose={handleLogoDialogClose}
				>
					<DialogTitle id="responsive-dialog-title">
						Image editor
					</DialogTitle>
					<DialogContent>
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '66%' }}>
							<UploadFile uploadCallback={uploadFile} uploadedImage={logoDownloadUrl || logo} />
						</Box>
					</DialogContent>
					<DialogActions sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }}>
						<Button variant='contained' color='secondary' onClick={handleLogoDialogClose} autoFocus>
							Close
						</Button>
						<Button variant='contained' onClick={handleSaveImage} autoFocus>
							Save
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Box>
	)
}

export default TopHeaderBlock
